import { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getNewSessionFilter } from '../../../handlers/session/getNewSessionFilter';
import { LoggedUser, MiniData, MiniUser } from '../../../types';
import { getFullName } from '../../../util/common.utils';
import { useQuery } from '../../../util/useQuery';
import DatePicker from '../../Common/DatePicker';
import Input from '../../Common/Input';
import styles from './styles.module.scss';

type AnnotationType = 'rectangle' | 'circle' | 'polygon' | 'dot';

type SessionFilterType = {
	awi_is_rejected_annotation_present: boolean;
	awi_class_ids: number[];
	awi_user_ids: number[];
	awi_annotation_type: AnnotationType[];
	awi_request_ids: number[];
	awi_start_date: Date;
	awi_end_date: Date;
	awi_is_empty_sessions: number[];
	awi_user_assigned_level: string;
};

interface FilterModalProps {
	handleToggleFilterModal: () => void;
	handleFilterChange: (id: number | Date, name: string) => void;
	filter: SessionFilterType;
	loggedUser: LoggedUser;
	annotationData: MiniData[];
	handleDateChange: (date: Date, name: string) => void;
	handleResetFilter: () => void;
	selectedTab: string;
	handleApplyFilter: () => void;
}

const SessionFilterModal: React.FC<FilterModalProps> = ({
	handleFilterChange,
	filter,
	handleResetFilter,
	handleToggleFilterModal,
	handleDateChange,
	annotationData,
	loggedUser,
	selectedTab,
	handleApplyFilter
}) => {
	const [getNewSessionFilterMetric, getNewSessionFilterMetricFunction] = getNewSessionFilter();
	const { data: sessionFilterMetric } = useQuery(getNewSessionFilterMetric, getNewSessionFilterMetricFunction);
	const [assignedUserLevel, setAssignedUserLevel] = useState<string>('select level');

	if (!sessionFilterMetric) return null;

	const assignedUserLevelData = [
		{
			awi_id: 0,
			awi_label: 'awi_verifier'
		},
		{
			awi_id: 1,
			awi_label: 'awi_annotator'
		}
	];

	return (
		<>
			<div className={styles.backdrop} onClick={handleToggleFilterModal}></div>
			<div className={styles.sessions__filter}>
				<div className={styles.sessions__filter__heading}>Filters</div>

				{/* <form> */}
				<div className={styles.sessions__filter__grid}>
					{!['awi_annotator'].includes(loggedUser.userObj.awi_level) && (
						<>
							<label htmlFor="Assigned Users">Assigned Users</label>
							<Input
								placeholder="Select Level"
								searchString={filter.awi_user_assigned_level}
								data={
									loggedUser.userObj.awi_level !== 'awi_verifier'
										? assignedUserLevelData
										: [
												{
													awi_id: 1,
													awi_label: 'awi_annotator'
												}
										  ]
								}
								onClick={handleFilterChange}
								type="drop-down"
								name="awi_user_assigned_level"
							/>
						</>
					)}
					{filter.awi_user_assigned_level !== 'select level' && <label></label>}
					{filter.awi_user_assigned_level !== 'select level' && (
						<Input
							placeholder="Select Users"
							searchString={sessionFilterMetric?.result[0].awi_users
								?.filter(user => filter.awi_user_ids.includes(user.awi_id))
								.map(user => getFullName(user as MiniUser))
								.join(', ')}
							data={sessionFilterMetric?.result[0].awi_users
								.filter(user => user.awi_level === filter.awi_user_assigned_level)
								?.map(user => {
									return { awi_id: user.awi_id, awi_label: getFullName(user as MiniUser) };
								})}
							onClick={handleFilterChange}
							type="drop-down"
							name="awi_user_ids"
							selectedData={filter.awi_user_ids}
						/>
					)}

					{selectedTab === 'annotator' && (
						<>
							<label htmlFor="user">Classes</label>
							<Input
								placeholder="Select Classes"
								searchString={sessionFilterMetric?.result[0].awi_classes
									?.filter(ele => filter.awi_class_ids.includes(ele.awi_id))
									.map(ele => ele.awi_label)
									.join(', ')}
								data={sessionFilterMetric?.result[0].awi_classes?.map(ele => {
									return { awi_id: ele.awi_id, awi_label: ele.awi_label };
								})}
								onClick={handleFilterChange}
								type="drop-down"
								name="awi_class_ids"
							/>
						</>
					)}

					{selectedTab === 'annotator' && (
						<>
							<label htmlFor="Annotation Type">Annotation Type</label>
							<Input
								placeholder="Select Annotation"
								searchString={annotationData
									?.filter(ele => filter.awi_annotation_type.includes(ele.awi_label as AnnotationType))
									.map(ele => ele.awi_label)
									.join(', ')}
								data={annotationData?.map(ele => {
									return { awi_id: ele.awi_id, awi_label: ele.awi_label };
								})}
								onClick={handleFilterChange}
								type="drop-down"
								name="awi_annotation_type"
							/>
						</>
					)}

					<label htmlFor="request">Requests</label>
					<Input
						placeholder="Select requests"
						searchString={sessionFilterMetric?.result[0].awi_requests
							?.filter(request => filter.awi_request_ids.includes(request.awi_id))
							.map(request => request.awi_label)
							.join(', ')}
						data={sessionFilterMetric?.result[0].awi_requests?.map(request => {
							return { awi_id: request.awi_id, awi_label: request.awi_label };
						})}
						onClick={handleFilterChange}
						type="drop-down"
						name="awi_request_ids"
					/>
					<div>Start date</div>
					<DatePicker
						value={filter.awi_start_date}
						onChange={(date: Date) => handleDateChange(date, 'awi_start_date')}
						dateFormat="MMMM dd, yyyy"
						maxDate={filter.awi_end_date || new Date()}
					/>
					<div>End date</div>
					<DatePicker
						value={filter.awi_end_date}
						onChange={(date: Date) => handleDateChange(date, 'awi_end_date')}
						dateFormat="MMMM dd, yyyy"
						maxDate={new Date()}
						minDate={filter.awi_start_date || new Date(2000, 0, 0)}
					/>
					{selectedTab === 'annotator' && (
						<>
							<label>Clear empty sessions</label>
							<input
								name="awi_is_empty_sessions"
								checked={Boolean(filter.awi_is_empty_sessions.length === 0 ? 0 : filter.awi_is_empty_sessions[0])}
								type={'checkbox'}
								onChange={() => {
									handleFilterChange(
										Math.floor(((filter.awi_is_empty_sessions.length === 0 ? 0 : filter.awi_is_empty_sessions[0]) + 1) % 2),
										'awi_is_empty_sessions'
									);
								}}
							/>
						</>
					)}

					{selectedTab === 'verifier' && (
						<>
							<label>Rejected annotations</label>
							<input
								name="awi_is_rejected_annotation"
								checked={filter.awi_is_rejected_annotation_present}
								type={'checkbox'}
								onChange={() => handleFilterChange(2, 'awi_is_rejected_annotation_present')}
							/>
						</>
					)}
				</div>
				<div className={styles.sessions__filter__buttons}>
					<button onClick={handleApplyFilter}>Apply</button>
					<button onClick={handleResetFilter}>Reset</button>
				</div>
				{/* </form> */}
			</div>
		</>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps, null)(SessionFilterModal));
