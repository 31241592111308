import ReduxActionEnum from '../../models/ReduxActionEnum';
import generalInitialState from '../states/GeneralInitialState';

const GeneralReducer = (state = generalInitialState, action) => {
	switch (action.type) {
		case ReduxActionEnum.SET_BATCH_ID:
			return {
				...state,
				batchID: action.payload
			};

		case ReduxActionEnum.SET_BATCH_DETAILS:
			return {
				...state,
				batchDetails: action.payload
			};

		default:
			return state;
	}
};

export default GeneralReducer;
