import { client } from './../../util/client';
export const fetchJobDetails = (jobId: string) => {
	const queryKey = ['jobDetails', jobId];
	const queryFn = () => {
		return client('/fetch_job_details', {
			method: 'POST',
			baseURL: 'training',
			data: {
				awi_job_uuid: jobId
			}
		}).then(res => {
			return res.data;
		});
	};

	return [queryKey, queryFn] as const;
};
