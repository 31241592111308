import { useState } from 'react';
import { useQueryClient } from 'react-query';

import { getOrganisation } from '../../../handlers/organisation/getOrganization';

import { Table } from '../../Common';
import Container from '../../Container';
import CreateOrganisation from './CreateOrganisation';

import { useQuery } from '../../../util/useQuery';

import styles from './styles.module.scss';

const Organisations: React.FC = () => {
	const queryClient = useQueryClient();

	const [isCreateOrgModalOpen, setIsCreateOrgModalOpen] = useState<boolean>(false);

	const [getOrganisationKey, getOrganisationFunction] = getOrganisation();

	const { data: organisations } = useQuery(getOrganisationKey, getOrganisationFunction);

	const toggleCreateOrgModal = () => {
		setIsCreateOrgModalOpen(prev => !prev);
	};

	const updateOrganisations = () => {
		queryClient.invalidateQueries(getOrganisationKey);
	};

	// useEffect(() => {
	// 	fetchOrganisations();
	// }, []);

	if (!organisations) {
		return null;
	}

	const organisationHeader = ['Name', 'Managers', 'Developers', 'Supervisor', 'Annotators', 'Verifiers'];

	const getOrganisationTableContent = () => {
		return organisations.result.map(org => {
			const temp: any[] = [];
			temp.push(org.awi_label);
			temp.push(org.awi_managers);
			temp.push(org.awi_developers);
			temp.push(org.awi_supervisors);
			temp.push(org.awi_annotators);
			temp.push(org.awi_verifiers);
			return temp;
		});
	};

	return (
		<Container>
			<Table headers={organisationHeader} content={getOrganisationTableContent()} />
			<div className={styles.org__raise_button}>
				<button title="Create Org" onClick={toggleCreateOrgModal}>
					<img src="/icons/upload.svg" alt="create org" />
				</button>
			</div>
			{isCreateOrgModalOpen && <CreateOrganisation updateOrganisations={updateOrganisations} toggleCreateOrgModal={toggleCreateOrgModal} />}
		</Container>
	);
};
export default Organisations;
