import { client } from '../../util/client';

export const fetchRequestUsers = (data: { awi_request_id: number; role: string }) => {
	const queryKey = ['users', { data }] as const;
	const queryFunction = () =>
		client('/fetch_users', {
			baseURL: 'request',
			method: 'POST',
			data: data
		}).then(res => res.data.result);

	return [queryKey, queryFunction] as const;
};
