import clsx from 'clsx';
import { useState, useRef, useEffect } from 'react';

import styles from './styles.module.scss';

interface InputProps {
	data?: { awi_id: number | string; awi_label: string }[];
	onClick?: (...arg) => void;
	onChange?: (s: string, event?: React.ChangeEvent<HTMLInputElement>) => void;
	className?: string;
	type?: 'drop-down' | 'input';
	keyDown?: (...arg) => void;
	placeholder?: string;
	searchString?: string;
	required?: boolean;
	disabled?: boolean;
	name?: string;
	selectedData?: number[];
}

const Input: React.FC<InputProps> = ({
	data = [],
	onClick,
	onChange,
	keyDown,
	className = '',
	type = 'drop-down',
	placeholder = '',
	searchString,
	required = false,
	disabled = false,
	name = '',
	selectedData = []
}) => {
	const [open, setOpen] = useState<boolean>(false);

	const inputRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		window.addEventListener('keydown', event => {
			if (event.which === 27) {
				setOpen(false);
				if (inputRef.current) {
					inputRef.current.blur();
				}
			}
		});

		return () => {
			window.removeEventListener('keydown', () => {
				setOpen(false);
			});
		};
	}, []);

	useEffect(() => {
		if (inputRef.current && open) {
			inputRef.current.focus();
		}
	}, [open]);

	const handleKeyDown = event => {
		if (typeof keyDown === 'function') keyDown(event);
	};

	return (
		<>
			<div style={{ position: 'relative' }}>
				<div
					className={clsx(styles.common_styles__input, {
						[className]: className
					})}
					onClick={() => {
						if (type === 'drop-down') {
							setOpen(prev => !prev);
						}
					}}
					style={{ backgroundColor: disabled ? 'rgba(239, 239, 239, 0.3)' : '' }}
				>
					<input
						disabled={disabled}
						value={searchString}
						ref={inputRef}
						placeholder={placeholder}
						type="text"
						onFocus={() => {
							setOpen(true);
						}}
						onClick={() => setOpen(prev => !prev)}
						onChange={event => {
							if (typeof onChange === 'function') {
								onChange!((event.target as HTMLInputElement).value, event);
							}
						}}
						onKeyDown={event => {
							if (event.which === 27) {
								setOpen(false);
								if (inputRef.current) inputRef.current.blur();
							} else {
								handleKeyDown(event);
							}
						}}
						required={required && selectedData.length === 0}
					/>
					{<img src="/icons/drop-down.svg" alt="drop-down icon" />}
				</div>
				{open && !disabled && (
					<div
						className={styles.common_styles__backdrop}
						onClick={() => {
							setOpen(false);
						}}
					></div>
				)}
				{(open || (type === 'input' && document.activeElement === inputRef.current)) && !disabled && (
					<>
						<ul className={styles.common_styles__drop_down}>
							{data.map(ele => {
								const selectedEle = (selectedData || ([] as number[]))?.filter((selectedEle: number) => selectedEle === ele.awi_id);
								return (
									<li
										key={ele.awi_id}
										className={clsx({ [styles.common_styles__drop_down__selected]: selectedEle.length > 0 })}
										onClick={() => {
											if (typeof onClick === 'function') {
												if (name !== '') {
													onClick(ele.awi_id, name);
												} else {
													onClick(ele.awi_id);
												}
												// if (type === 'input') setOpen(false);
											}
										}}
									>
										{ele.awi_label.trim()}
									</li>
								);
							})}
						</ul>
					</>
				)}
			</div>
		</>
	);
};

export default Input;
