import { CommonResponse } from './../../types/common.d';
import { Organisation } from './../../types/organisation.d';
import { client } from './../../util/client';

type OrganisationResposne = {
	result: Organisation[];
} & CommonResponse;

export const getOrganisation = () => {
	const queryKey = ['organisations'] as const;
	const queryFunction = () => client<OrganisationResposne>('/get_all_organizations', { baseURL: 'org', method: 'GET' }).then(res => res.data);

	return [queryKey, queryFunction] as const;
};
