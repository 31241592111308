import { MiniData } from '../../types';
import { Requests } from './../../types/request.d';
import { client } from './../../util/client';

type GetRequestDataResponse = {
	result: Requests[] & { awi_classes: (MiniData & { awi_color: 'string' })[] };
};

export const getRequestData = (id: number) => {
	const queryKey = ['request', id] as const;

	const queryFunction = () =>
		client<GetRequestDataResponse>(`/get_request/${id}`, {
			baseURL: 'request'
		}).then(res => res.data.result[0]);

	return [queryKey, queryFunction] as const;
};
