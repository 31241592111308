import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import { getClass } from '../../../handlers/class';

import Table from '../../Common/Table';
import Container from '../../Container';
import CreateClassModal from './CreateClassModal';

import { LoggedUser } from '../../../types';

import { getFullName } from '../../../util/common.utils';
import { useQuery } from '../../../util/useQuery';

import styles from './styles.module.scss';

interface ClassListProps {
	loggedUser: LoggedUser;
}

const ClassList: React.FC<ClassListProps> = ({ loggedUser }) => {
	const [createClassModalOpen, setCreateClassModalOpen] = useState<boolean>(false);
	const [classId, setClassId] = useState<number>(-1);
	const location = useLocation();
	const { awi_class_ids, awi_request_label } = location.state || [];

	const [getClasskey, getClassFunction] = getClass({ awi_class_ids });
	const { data: classes } = useQuery(getClasskey, getClassFunction);

	const headers = ['S. no', 'Name', 'Attributes', 'Created By', 'Description'];

	const handleClassUpdate = (id: number) => {
		handleCreateClassModalOpen();
		setClassId(id);
	};

	const convertClassData = () => {
		return (classes || []).map((_class, index) => {
			const temp: any[] = [];
			temp.push(index + 1);
			temp.push(
				<div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
					<div style={{ backgroundColor: _class.awi_color, width: 16, height: 16, borderRadius: '50%' }}></div>
					{_class.awi_label}
				</div>
			);
			temp.push(
				_class.awi_attributes.length > 0 ? (
					<Link
						to={{
							pathname: `/attributelist`,
							state: { awi_class_id: _class.awi_id }
						}}
					>
						<span style={{ textDecoration: 'none' }}> {_class.awi_attributes.length} </span>
					</Link>
				) : (
					_class.awi_attributes.length
				)
			);
			temp.push(getFullName(_class.awi_user_data));
			temp.push(_class.awi_description);
			if (loggedUser.userObj.awi_level === 'awi_developer' || loggedUser.userObj.awi_level === 'awi_admin') {
				temp.push(<img onClick={() => handleClassUpdate(_class.awi_id)} src="/icons/pencil/transparent--black.svg" alt="edit" />);
			}
			return temp;
		});
	};

	const handleCreateClassModalOpen = () => {
		if (createClassModalOpen && classId !== -1) {
			setClassId(-1);
		}
		setCreateClassModalOpen(prev => !prev);
	};

	if ((awi_class_ids || []).length === 0 && loggedUser.userObj.awi_level !== 'awi_developer' && loggedUser.userObj.awi_level !== 'awi_admin') {
		return <Redirect to="/"></Redirect>;
	}

	return (
		<Container>
			<div className={styles.actions__container}>
				{['awi_developer', 'awi_admin'].includes(loggedUser.userObj.awi_level) && (
					<div className={styles.actions__container__iconcontainer}>
						<img src="/icons/request/addIcon.svg" onClick={handleCreateClassModalOpen} />
					</div>
				)}
			</div>
			{awi_request_label && (
				<div style={{ marginBottom: 16 }}>
					<h2>{awi_request_label}</h2>
				</div>
			)}

			{/* <div className={styles.classlist__options}>
				<img src="/icons/request/addIcon.svg" onClick={handleCreateClassModalOpen} />
			</div> */}
			<Table headers={[...headers]} content={convertClassData()} />
			{createClassModalOpen && <CreateClassModal isUpdating={classId !== -1} classId={classId} handleModalClose={handleCreateClassModalOpen} />}
		</Container>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps, null)(ClassList));
