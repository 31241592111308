import { client } from './../../util/client';
export const getClassAttributes = (id: number) => {
	const queryKey = ['class_attributes', id] as const;
	const queryFunction = () =>
		client(`/fetch_class_attributes/${id}`, {
			baseURL: 'class'
		}).then(res => res.data.result[0]);

	return [queryKey, queryFunction] as const;
};
