import { useRef, useCallback } from 'react';

interface InfiniteScrollProps {
	isLoading: boolean;
	hasMore: boolean;
	next: () => void;
	loader?: JSX.Element;
	children: React.ReactNode;
}

const InfiniteScroll: React.FC<InfiniteScrollProps> = ({ children, hasMore, next, isLoading, loader = <></> }) => {
	const observer = useRef<IntersectionObserver | null>(null);

	const lastElementRef = useCallback(
		node => {
			if (isLoading) {
				return false;
			}

			if (observer.current) {
				observer.current.disconnect();
			}

			observer.current = new IntersectionObserver(
				entries => {
					if (entries[0].isIntersecting && hasMore) {
						next();
					}
				},
				{ threshold: 0.5 }
			);

			if (node) {
				observer.current.observe(node);
			}
		},
		[hasMore, isLoading, next]
	);

	return (
		<>
			{children}
			<span ref={lastElementRef} style={{ width: '100%', display: 'block', height: 1, marginBottom: '-16px' }}></span>
			{isLoading && loader}
		</>
	);
};

export default InfiniteScroll;
