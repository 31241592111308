import { connect } from 'react-redux';
import { toast } from 'react-hot-toast';
import { withRouter } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import clsx from 'clsx';

import { postUserToRequest } from '../../../handlers/request';
import { postImages } from '../../../handlers/content/postImages';
import { fetchImageBank, getImageBankFilters, postImagesfromImageBank } from '../../../handlers/content';

import Container from '../../Container';
import CreateBatch from './CreateBatch';
import UploadImages from './UploadImages';

import { Carousel, ImageCardList, ImageCardWithoutDetails, Input, InfiniteScroll, Loader, DatePicker } from '../../Common';

import { useQuery } from '../../../util/useQuery';
import { useMutation } from '../../../util/useMutation';
import { useInfiniteQuery } from '../../../util/useInfiniteQuery';
import RequestActions from '../../../redux/actions/RequestActions';
import { getFirstDayOfMonth, getFullName } from '../../../util/common.utils';

import { LoggedInUser, PostImagesToRequest, PostUsersToRequest, MiniUser, ImageBankFilters } from '../../../types';

import styles from './styles.module.scss';
import { deleteImagesfromImageBank } from '../../../handlers/content/deleteImages';
import DeleteImagesConfirmationModal from './DeleteImagesConfirmationModal';

interface ImageBankProps {
	loggedUser: {
		token: string;
		userObj: LoggedInUser;
	};
	setRequest: (data: { awi_id?: number; awi_label: string }) => void;
}

const initialFilters: ImageBankFilters = {
	request: [],
	owner: [],
	location: [],
	classes: [],
	tags: [],
	source: [],
	awi_status: '',
	awi_annotation_status: '',
	awi_content_keys: [],
	awi_start_date: null,
	awi_end_date: null
};

const Imagebank: React.FC<ImageBankProps> = ({ loggedUser, setRequest }) => {
	const queryClient = useQueryClient();

	const [imageBankLimit, setImageBankLimit] = useState<number>(0);
	const [selectedImg, setSelectedImage] = useState<number[]>([]);
	const [galleryView, setGalleryView] = useState<'list' | 'grid'>('grid');
	const [selectedRequest, setSelectedRequest] = useState<number | undefined>(+localStorage.getItem('requestID')!);
	const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
	const [numberOfImagesToBeSelected, setNumberOfImagesToBeSelected] = useState<number>(0);

	// const [tags, setTags] = useState<Tag[]>();

	const uploadImageMutation = useMutation(postImages);

	const deleteImagesMutation = useMutation(deleteImagesfromImageBank);

	const filterRef = useRef(initialFilters);

	const [filters, setFilters] = useState<ImageBankFilters>(initialFilters);

	const [showPercentage, setShowPercentage] = useState<boolean>(false);

	const [isUploadOpen, setIsUploadOpen] = useState<boolean>(false);
	const [isBatchOpen, setIsBatchOpen] = useState<boolean>(false);
	const [deletImagesConfirmationModal, setDeleteImagesConfirmationModal] = useState<boolean>(false);

	const [isCarouselOpen, setIsCarouselOpen] = useState(false);

	const [isSelectingMultiple, setIsSelectingMultiple] = useState<boolean>(false);

	const [fetchImageBankKey, fetchImageBankFunction] = fetchImageBank({
		...filterRef.current,
		awi_end_date: filterRef.current.awi_end_date ? new Date(filterRef.current.awi_end_date.setHours(23, 59, 59)) : null
	});
	const [fetchImageBankFilterKey, fetchImageBankFilterFunction] = getImageBankFilters();

	const [percentage, setPercentage] = useState<number>(0);
	const [timeRemaining, setTimeRemaining] = useState<string>('');
	const [scrollPos, setScrollPos] = useState<number>(0);

	const checkFilterSelect = (): boolean => {
		return JSON.stringify(filters) === JSON.stringify(initialFilters);
	};

	const {
		data: imagebank,
		hasNextPage,
		fetchNextPage,
		isLoading,
		isFetchingNextPage,
		refetch: refetchImageBank
	} = useInfiniteQuery(fetchImageBankKey, fetchImageBankFunction, {
		getNextPageParam: lastPage => {
			if (lastPage.next) {
				return [new URL(lastPage.next).searchParams.get('cursor'), imageBankLimit || 40] as const;
			}
		}
	});

	const { data: filterData } = useQuery(fetchImageBankFilterKey, fetchImageBankFilterFunction);

	useEffect(() => {
		const requestState = localStorage.getItem('request');
		if (requestState) {
			setRequest(JSON.parse(requestState));
			setSelectedRequest(+JSON.parse(requestState).awi_id);
		}
		localStorage.removeItem('request');

		return () => {
			setRequest({ awi_id: undefined, awi_label: '' });
		};
	}, []);

	useEffect(() => {
		if (imageBankLimit > 0) {
			fetchNextPage();
		}
	}, [imageBankLimit]);

	useEffect(() => {
		if (numberOfImagesToBeSelected > 0 && imageBankLimit > 0) {
			setSelectedImage(
				imagebank?.pages
					.map(page => page.result.map(res => res.awi_id))
					.flat()
					.splice(0, numberOfImagesToBeSelected) || []
			);
		}
	}, [imagebank]);

	const handleImagesFetching = noOfImages => {
		setNumberOfImagesToBeSelected(noOfImages);
	};

	const handleImageSelection = () => {
		const limit = numberOfImagesToBeSelected - (imagebank?.pages.reduce((acc, page) => acc + page.result.length, 0) || 0);
		if (limit > 0) {
			setImageBankLimit(limit);
		} else {
			setSelectedImage(
				imagebank?.pages
					.map(page => page.result.map(res => res.awi_id))
					.flat()
					.splice(0, numberOfImagesToBeSelected) || []
			);
		}
	};

	const handleImageSelect = (id: number) => {
		const tempId = +id;

		setSelectedImage(prev => {
			const temp: number[] = [...prev];
			if (prev.includes(+tempId)) {
				temp.splice(temp.indexOf(+tempId), 1);
				return [...temp];
			} else {
				temp.push(+tempId);
				return temp;
			}
		});
	};

	const handleUploadModalToggle = () => {
		setIsUploadOpen(prev => !prev);
	};
	const handleBatchModalToggle = () => {
		setIsBatchOpen(prev => !prev);
	};

	const handleFilterToggle = () => {
		setIsFilterOpen(prev => !prev);
	};

	const handleListViewClick = () => {
		setGalleryView('list');

		setSelectedImage(prev => [...prev]);
	};

	const handleBatchAssignment = async (requestId: number, annotatorId: number[], verifierId: number[], _selectedImg: number[] = selectedImg) => {
		const tempRequests: any[] = [];

		if (!requestId) {
			toast.error('Select Request');
		}

		if (_selectedImg.length > 0) {
			const tempContent: PostImagesToRequest = { awi_request_id: requestId, awi_content_key: [..._selectedImg] };
			tempRequests.push(postImagesfromImageBank(tempContent));
		}

		if (annotatorId.length > 0) {
			const annotatorData: PostUsersToRequest = {
				awi_request_id: requestId,
				awi_users: annotatorId,
				awi_level: 'awi_annotator'
			};
			tempRequests.push(postUserToRequest(annotatorData));
		}

		if (verifierId.length > 0) {
			const verifierData: PostUsersToRequest = {
				awi_request_id: requestId,
				awi_users: verifierId,
				awi_level: 'awi_verifier'
			};
			tempRequests.push(postUserToRequest(verifierData));
		}

		const [assignImages, assign_annotators, assign_verifiers] = await Promise.all([...tempRequests]);

		if (assignImages?.success || assign_annotators?.success || assign_verifiers?.success) {
			toast.success('Assignment successful');
			setIsBatchOpen(false);
			setSelectedImage([]);
			queryClient.invalidateQueries(fetchImageBankKey);
		}
	};

	const handleFormSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		handleFilterToggle();
	};

	const handleUpdateFilter = (id: number, name: string) => {
		const temp = { ...filters };

		if (temp[name]?.includes(id)) {
			temp[name] = temp[name].filter(ele => ele !== id);
		} else {
			temp[name] = [...temp[name], id];
		}
		setFilters({ ...temp });
	};

	const handleDateChange = (date: Date, key: string) => {
		setFilters(prev => {
			const temp = { ...prev };
			temp[key] = date;
			return { ...temp };
		});
	};

	const handleUpdateStatus = (id: number) => {
		setFilters(prev => {
			const temp = { ...prev };
			if (temp['awi_status'] === 'assigned' && id === 1) {
				temp['awi_status'] = 'unassigned';
			} else if (temp['awi_status'] === 'unassigned' && id === 0) {
				temp['awi_status'] = 'assigned';
			} else if (temp['awi_status'] === '') {
				if (id === 0) {
					temp['awi_status'] = 'assigned';
					return { ...temp };
				} else {
					temp['awi_status'] = 'unassigned';
					return { ...temp };
				}
			} else {
				temp['awi_status'] = '';
			}
			return { ...temp };
		});
	};

	const handleUpdateAnnotationStatus = (id: number) => {
		setFilters(prev => {
			const temp = { ...prev };
			if (temp['awi_annotation_status'] === 'annotated' && id === 1) {
				temp['awi_annotation_status'] = 'unannotated';
			} else if (temp['awi_annotation_status'] === 'unannotated' && id === 0) {
				temp['awi_annotation_status'] = 'annotated';
			} else if (temp['awi_annotation_status'] === '') {
				if (id === 0) {
					temp['awi_annotation_status'] = 'annotated';
					return { ...temp };
				} else {
					temp['awi_annotation_status'] = 'unannotated';
					return { ...temp };
				}
			} else {
				temp['awi_annotation_status'] = '';
			}
			return { ...temp };
		});
	};

	const handleApplyFilters = () => {
		filterRef.current = { ...filters };
		refetchImageBank();
		handleFilterToggle();
	};

	const resetFilters = () => {
		setFilters(initialFilters);
		filterRef.current = initialFilters;
		refetchImageBank();
		handleFilterToggle();
	};

	const handleUploadImages = async (files: File[], tag: number[], location?: number, cb?: (ids: any[]) => void) => {
		setShowPercentage(true);
		const formData = new FormData();

		const onUploadProgress = (progressEvent, timeStarted: Date) => {
			const { loaded, total } = progressEvent;

			const speed = loaded / ((Date.now() - timeStarted.getTime()) * 1000);
			const _timeRemaining = Math.round(((total - loaded) / speed + Number.EPSILON) * 100) / 100 + ' seconds';
			setTimeRemaining(_timeRemaining);
			const percent = Math.floor((loaded * 100) / total);
			setPercentage(percent);
		};

		files.forEach(file => {
			formData.append('file', file);
		});
		formData.append('tags', JSON.stringify(tag));
		if (location) {
			formData.append('location', location.toString());
		}

		setIsUploadOpen(false);

		uploadImageMutation.mutate(
			{ data: formData, uploadProgress: onUploadProgress },
			{
				onSuccess: res => {
					if (res.success) {
						setShowPercentage(false);
						setPercentage(0);
						setTimeRemaining('');
						toast.success(
							t => (
								<div className={styles.toast}>
									<span>{res.message}</span>
									<button className={styles.toast__dismiss} onClick={() => toast.dismiss(t.id)}>
										Dismiss
									</button>
								</div>
							),
							{
								duration: Infinity,
								position: 'bottom-left'
							}
						);
						if (typeof cb === 'function') {
							cb(res.result!);
						}
						queryClient.invalidateQueries(fetchImageBankKey);
					}
				}
			}
		);
	};

	const handleToggleCarousel = () => {
		setIsBatchOpen(false);
		setIsCarouselOpen(prev => !prev);
	};

	const resetImages = () => {
		setSelectedImage([]);
	};

	const handleMouseDown = () => {
		setIsSelectingMultiple(true);
	};
	const handleMouseUp = () => {
		setIsSelectingMultiple(false);
	};

	const getImageLength = (images: number[], status: 'all' | 'assigned' | 'unassigned' | 'allocated' | 'unallocated' = 'all') => {
		const length = imagebank?.pages.reduce((acc, page) => {
			return (
				acc +
				page.result.filter(image => {
					return (
						images.indexOf(image.awi_id) !== -1 &&
						(status === 'all' ||
							(status === 'assigned' && image.awi_status.awi_assigned) ||
							(status === 'unassigned' && !image.awi_status.awi_assigned) ||
							(status === 'allocated' && image.awi_annotated) ||
							(status === 'unallocated' && !image.awi_annotated))
					);
				}).length
			);
		}, 0);

		return length;
	};

	const handleDeleteButtonClick = async () => {
		const noOfAssignedImages = getImageLength(selectedImg, 'assigned') || 0;
		if (noOfAssignedImages > 0) {
			toast.error('Cannot delete assigned images');
			return;
		}
		setDeleteImagesConfirmationModal(true);
	};

	const handleCloseConfirmationModal = () => {
		setDeleteImagesConfirmationModal(false);
	};

	const handleDeleteImages = async () => {
		deleteImagesMutation.mutate(
			{ awiContentKeys: selectedImg },
			{
				onSuccess: res => {
					if (res.success) {
						toast.success('Images deleted successfully');
						setSelectedImage([]);
						queryClient.invalidateQueries(fetchImageBankKey);
					} else {
						toast.error(res.message || "Couldn't delete images");
					}
				}
			}
		);

		handleCloseConfirmationModal();
	};

	const handleSelectMulipleImages = (contentKey: number) => {
		if (isSelectingMultiple) {
			setSelectedImage(prev => {
				if (prev.indexOf(contentKey) === -1) {
					return [...prev, contentKey];
				} else {
					return [...prev];
				}
			});
		}
	};

	useEffect(() => {
		const handleScroll = () => {
			setScrollPos(window.pageYOffset);
		};

		if (typeof window !== undefined) {
			window.addEventListener('scroll', handleScroll, { passive: true });
		}

		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	if (isLoading) {
		return (
			<Container>
				<Loader marginTop={32} />
			</Container>
		);
	}

	if (!loggedUser.token) {
		return null;
	}

	return (
		<Container>
			<div className={styles.header} style={{ top: scrollPos > 100 ? (scrollPos > 650 ? '0px' : '60px') : '80px' }}>
				<div className={styles.imagebank__metrics}>
					<h1 style={{ display: scrollPos > 100 ? 'none' : 'block' }}>Images</h1>
				</div>
				<div className={styles.header__card__container}>
					{[
						{
							backgroundColor: '#FEE3E5',
							iconBackgroundColor: '#F05E7C',
							count: selectedImg.length > 0 ? getImageLength(selectedImg) : imagebank?.pages[0].awi_status?.reduce((acc, val) => val.awi_total + acc, 0) || 0,
							about: 'Total Images'
						},
						{
							backgroundColor: '#FEF4DE',
							iconBackgroundColor: '#FF937D',
							count:
								selectedImg.length > 0 ? getImageLength(selectedImg, 'assigned') : imagebank?.pages[0].awi_status?.filter(ele => ele._id)[0]?.awi_total || 0,
							about: 'Total Images Assigned'
						},
						{
							backgroundColor: '#DDFCE6',
							iconBackgroundColor: '#3CD755',
							count:
								selectedImg.length > 0 ? getImageLength(selectedImg, 'unassigned') : imagebank?.pages[0].awi_status?.filter(ele => !ele._id)[0]?.awi_total || 0,
							about: 'Total Images Unassigned'
						},
						{
							backgroundColor: '#F2E8FF',
							iconBackgroundColor: '#BE84FE',
							count:
								selectedImg.length > 0
									? getImageLength(selectedImg, 'allocated')
									: imagebank?.pages[0].awi_annotation_status?.filter(ele => ele._id)[0]?.awi_total || 0,
							about: 'Total Images Annotated'
						},
						{
							backgroundColor: '#F2E8FF',
							iconBackgroundColor: '#BE84FE',
							count:
								selectedImg.length > 0
									? getImageLength(selectedImg, 'unallocated')
									: imagebank?.pages[0].awi_annotation_status?.filter(ele => !ele._id)[0]?.awi_total || 0,
							about: 'Total Images Unannotated'
						}
					].map((element, index) => {
						return (
							<div
								className={clsx(styles.metrics, {
									[styles.metrics__update]: scrollPos > 100
								})}
								style={{ backgroundColor: `${element.backgroundColor}` }}
								key={index}
							>
								<div
									className={clsx({
										[styles.metrics__header]: scrollPos > 100
									})}
								>
									<div
										className={clsx(styles.metrics__icon, {
											[styles.metrics__icon__update]: scrollPos > 100
										})}
										style={{ backgroundColor: `${element.iconBackgroundColor}` }}
									>
										<img
											src="/icons/thumbnail.svg"
											className={clsx({
												[styles.metrics__icon__image]: scrollPos > 100
											})}
										/>
									</div>
									<div
										className={clsx(styles.metrics__count, {
											[styles.metrics__count__update]: scrollPos > 100
										})}
									>
										{element.count}
									</div>
									<div className={styles.metrics__total}>{element.about}</div>
								</div>
							</div>
						);
					})}
				</div>
				<div className={styles.imagebank__image__actions}>
					<div className={styles.imagebank__image__actions__left}>
						<div>
							Select <input type="number" value={numberOfImagesToBeSelected} onChange={e => handleImagesFetching(parseInt(e.target.value))}></input> images
						</div>
						<button onClick={handleImageSelection}>select</button>
						{selectedImg.length > 0 && (
							<>
								<button className={styles.modal__carousel_button} onClick={handleToggleCarousel}>
									Show Images in carousel
								</button>
								<button className={styles.modal__carousel_button} onClick={resetImages}>
									Reset selected images
								</button>
							</>
						)}
					</div>
					<div className={styles.imagebank__image__actions__right}>
						{selectedImg.length > 0 && (
							<div className={styles.icons} onClick={handleDeleteButtonClick}>
								{<img src="/icons/delete.svg" alt="Delete Images" title="Delete Images" style={{ width: '15px' }} />}
							</div>
						)}
						{galleryView === 'list' ? (
							<div
								className={styles.icons}
								onClick={() => {
									setGalleryView('grid');
								}}
							>
								<img src="/icons/gallery/grid--view.svg" alt="sort icon" width="100%" height="100%" title="Grid view" />
							</div>
						) : (
							<div className={styles.icons} onClick={handleListViewClick}>
								<img src="/icons/gallery/list--view.svg" alt="sort icon" width="100%" height="100%" title="List view" />
							</div>
						)}

						<div className={styles.icons}>
							<img
								src={`/icons/filter/filter${checkFilterSelect() ? '' : '--selected'}.svg`}
								onClick={handleFilterToggle}
								alt="filter icons"
								width="100%"
								height="100%"
								title="Filters"
							/>
							{isFilterOpen && (
								<>
									<div
										onClick={() => {
											handleFilterToggle();
										}}
										style={{ backgroundColor: 'transparent' }}
										className={styles.backdrop}
									></div>
									<div className={styles.imagebank__filter}>
										<div className={styles.imagebank__filter__heading}>Filters</div>
										{/* <form className={styles.imagebank__filter} onSubmit={handleFormSubmit}> */}
										<div className={styles.imagebank__filter__grid}>
											<label htmlFor="class">Uploaded By</label>
											<Input
												placeholder="Select Users"
												searchString={filterData.awi_users
													?.filter(user => filters.owner.includes(user.awi_id))
													.map(user => getFullName(user as MiniUser))
													.join(', ')}
												data={filterData.awi_users
													?.filter(user => !['awi_annotator', 'awi_verifier'].includes(user.awi_level))
													.map(user => {
														return { awi_id: user.awi_id, awi_label: getFullName(user as MiniUser) };
													})}
												onClick={handleUpdateFilter}
												type="drop-down"
												name="owner"
												selectedData={filters.owner}
											/>

											<label htmlFor="class">Requests</label>
											<Input
												placeholder="Select Requests"
												searchString={filterData.awi_requests
													?.filter(request => filters.request.includes(request.awi_id))
													.map(request => request.awi_label)
													.join(', ')}
												data={filterData.awi_requests?.map(request => {
													return { awi_id: request.awi_id, awi_label: request.awi_label };
												})}
												onClick={handleUpdateFilter}
												type="drop-down"
												name="request"
												selectedData={filters.request}
											/>

											<label htmlFor="class">Classes</label>
											<Input
												placeholder="Select Classes"
												searchString={filterData.awi_classes
													?.filter(ele => filters.classes.includes(ele.awi_id))
													.map(ele => ele.awi_label)
													.join(', ')}
												data={filterData.awi_classes?.map(ele => {
													return { awi_id: ele.awi_id, awi_label: ele.awi_label };
												})}
												onClick={handleUpdateFilter}
												type="drop-down"
												name="classes"
												selectedData={filters.classes}
											/>

											<label htmlFor="class">Locations</label>
											<Input
												placeholder="Select Locations"
												searchString={filterData.awi_locations
													?.filter(location => filters.location.includes(location.awi_id))
													.map(location => location.awi_label)
													.join(', ')}
												data={filterData.awi_locations?.map(location => {
													return { awi_id: location.awi_id, awi_label: location.awi_label };
												})}
												onClick={handleUpdateFilter}
												type="drop-down"
												name="location"
												selectedData={filters.location}
											/>

											<label htmlFor="class">Tags</label>
											<Input
												placeholder="Select Tags"
												searchString={filterData.awi_tags
													?.filter(tag => filters.tags.includes(tag.awi_id))
													.map(tag => tag.awi_label)
													.join(', ')}
												data={filterData.awi_tags?.map(tag => {
													return { awi_id: tag.awi_id, awi_label: tag.awi_label };
												})}
												onClick={handleUpdateFilter}
												type="drop-down"
												name="tags"
												selectedData={filters.tags}
											/>

											<label>Status</label>
											<Input
												placeholder="Select Image Status"
												onClick={handleUpdateStatus}
												searchString={filters.awi_status}
												data={[
													{ awi_id: 0, awi_label: 'Assigned' },
													{ awi_id: 1, awi_label: 'Unassigned' }
												]}
												selectedData={filters.awi_status === 'assigned' ? [0] : filters.awi_status === 'unassigned' ? [1] : []}
											/>
											<label>Annotation Status</label>
											<Input
												placeholder="Select Image Annotation Status"
												onClick={handleUpdateAnnotationStatus}
												searchString={filters.awi_annotation_status}
												data={[
													{ awi_id: 0, awi_label: 'Annotated' },
													{ awi_id: 1, awi_label: 'Unannotated' }
												]}
												selectedData={filters.awi_annotation_status === 'annotated' ? [0] : filters.awi_annotation_status === 'unannotated' ? [1] : []}
											/>
											<div>Start date</div>
											<DatePicker
												value={filters.awi_start_date}
												onChange={(date: Date) => handleDateChange(date, 'awi_start_date')}
												dateFormat="MMMM dd, yyyy"
												maxDate={filters.awi_end_date || new Date()}
											/>

											<div>End date</div>
											<DatePicker
												value={filters.awi_end_date}
												onChange={(date: Date) => handleDateChange(date, 'awi_end_date')}
												dateFormat="MMMM dd, yyyy"
												maxDate={new Date()}
												minDate={filters.awi_start_date || new Date(2000, 0, 0)}
											/>
										</div>
										<div className={styles.imagebank__filter__buttons}>
											<button onClick={handleApplyFilters}>Apply</button>
											<button disabled={checkFilterSelect()} onClick={resetFilters}>
												Reset
											</button>
										</div>
										{/* </form> */}
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
			{(imagebank?.pages[0].result || []).length === 0 ? (
				<p style={{ padding: 24, textAlign: 'center', fontSize: 32 }}>No image to display</p>
			) : (
				<InfiniteScroll hasMore={Boolean(hasNextPage)} isLoading={isLoading || isFetchingNextPage} next={fetchNextPage} loader={<Loader marginTop={16} />}>
					<div className={styles.imagebank__container} onMouseDown={handleMouseDown} onMouseLeave={handleMouseUp} onMouseUp={handleMouseUp}>
						{galleryView === 'grid' ? (
							(imagebank?.pages || []).map(page =>
								(page?.result || []).map((image, index) => (
									<ImageCardWithoutDetails
										key={index}
										handleImageSelect={handleImageSelect}
										isSelected={selectedImg.includes(+image.awi_content_key)}
										contentKey={+image.awi_content_key}
										imageUrl={image.awi_content_url}
										isAssigned={image.awi_status?.awi_assigned}
										handleSelectMulipleImages={handleSelectMulipleImages}
									/>
								))
							)
						) : (
							<ImageCardList selectedImage={selectedImg} images={imagebank!} handleImageSelect={handleImageSelect} galleryView={galleryView} />
						)}
					</div>
				</InfiniteScroll>
			)}

			<div className={styles.imagebank__bank__actions}>
				{/* <button disabled={selectedImg.length === 0}><img src="/icons/download.svg" alt="download icon" /></button> */}
				{loggedUser.userObj.awi_level === 'awi_supervisor' && (
					<button style={{ position: 'relative' }} onClick={handleBatchModalToggle} disabled={selectedImg.length === 0}>
						{selectedImg.length > 0 && <div className={styles.imagesCount}>{selectedImg.length}</div>}
						<img src="/icons/batch.svg" alt="batch creation icon" />
					</button>
				)}
				{['awi_supervisor', 'awi_manager'].includes(loggedUser.userObj.awi_level) && (
					<button onClick={handleUploadModalToggle}>
						<img src="/icons/upload.svg" alt="upload icon" title="Upload Images" />
					</button>
				)}
			</div>
			<UploadImages
				handleUploadImages={handleUploadImages}
				handleModalToggle={handleUploadModalToggle}
				isOpen={isUploadOpen}
				requests={(filterData?.awi_requests || []).filter(request => request.awi_image_assignable_request)}
				selectedRequest={filterData?.awi_requests.find(ele => ele.awi_id === selectedRequest)}
				annotators={filterData?.awi_users.filter(ele => ele.awi_level === 'awi_annotator') || []}
				verifiers={filterData?.awi_users.filter(ele => ele.awi_level === 'awi_verifier') || []}
				handleBatchAssignment={handleBatchAssignment}
				handleBatchModalToggle={handleBatchModalToggle}
			/>
			{isBatchOpen && (
				<CreateBatch
					handleToggleCarousel={handleToggleCarousel}
					annotators={filterData?.awi_users.filter(ele => ele.awi_level === 'awi_annotator') || []}
					requests={(filterData?.awi_requests || []).filter(request => request.awi_image_assignable_request)}
					handleModalToggle={handleBatchModalToggle}
					isOpen={isBatchOpen}
					selectedImageLength={selectedImg.length}
					handleAssignment={handleBatchAssignment}
					verifiers={filterData?.awi_users.filter(ele => ele.awi_level === 'awi_verifier') || []}
				/>
			)}

			{deletImagesConfirmationModal && (
				<DeleteImagesConfirmationModal handleDeleteImages={handleDeleteImages} handleModalClose={handleCloseConfirmationModal} />
			)}
			{isCarouselOpen && <Carousel handleToggleCarousel={handleToggleCarousel} imageKeys={selectedImg.map(imageKey => +imageKey)} />}

			{showPercentage && (
				<div className={styles.upload__percentage}>
					Images uploading images
					<div className={styles.upload__percentage__bar}>
						<div style={{ width: `${percentage}%` }}></div>
					</div>
					<div>{timeRemaining}</div>
				</div>
			)}
		</Container>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setRequest: data => dispatch(RequestActions.setRequest(data))
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Imagebank));
