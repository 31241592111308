import { MonthlyBill } from '../../types/session';
import { client } from './../../util/client';

type BillResponse = {
	success: boolean;
	message?: string;
	result: MonthlyBill;
};

export const getMonthlyBill = () => {
	const queryKey = ['monthly-bill'] as const;
	const queryFunction = () =>
		client<BillResponse>('/fetch_monthly_bill', {
			baseURL: 'session',
			method: 'GET'
		}).then(res => res.data);

	return [queryKey, queryFunction] as const;
};
