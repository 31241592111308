import { client } from './../../util/client';
export const fetchSample = (awi_id: number) => {
	const queryKey = ['request-sample', { awi_id }] as const;
	const queryFunction = () =>
		client(`/fetch_sample_images/${awi_id}`, {
			baseURL: 'request',
			method: 'GET'
		}).then(res => res.data.result[0]);

	return [queryKey, queryFunction] as const;
};
