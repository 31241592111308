import { clientWithoutToken } from './../../util/clientWithoutToken';

export const closeNewSession = ({ awi_session_id, awi_request_id, token }: { awi_session_id: number; awi_request_id: number; token: string }) =>
	clientWithoutToken('/close_new_session', {
		baseURL: 'session',
		method: 'POST',
		data: {
			awi_session_id,
			awi_request_id
		},
		headers: {
			Authorization: token
		}
	}).then(res => res.data);
