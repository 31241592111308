import { VerifierSession } from '../../types/session';
import { client } from './../../util/client';
import { SessionFilterType } from './getNewSessions';

export type VerifierSessionResponse = {
	result: VerifierSession[];
	msg?: string;
	next?: string | null;
	success: boolean;
};

export const getVerifierSessions = (data: SessionFilterType) => {
	const queryKey = ['verifier_sessions', data] as const;
	const queryFunction = ({ pageParam }: { pageParam?: any }) => {
		const filters = {
			...data,
			awi_is_empty_sessions: Boolean(data.awi_is_empty_sessions[0])
		};
		return client<VerifierSessionResponse>('/fetch_sessions', {
			baseURL: 'session',
			method: 'POST',
			data: { ...filters },
			params: {
				cursor: pageParam
			}
		}).then(res => res.data);
	};
	return [queryKey, queryFunction] as const;
};
