import clsx from 'clsx';
import styles from './styles.module.scss';

interface RequestFlowModalProps {
	handleModalClose: () => void;
}

const RequestFlowModal: React.FC<RequestFlowModalProps> = ({ handleModalClose }) => {
	return (
		<>
			<div className={clsx(styles.request__flow__modal, styles.modal)}>
				{/* <div className={styles.modal__close_button} onClick={handleModalClose}>
					<img src="/icons/close.svg" />
				</div> */}
				<div>
					You first need to set the flow of request to be able to perform request actions. Click on icon next to action button to set the flow of request.
				</div>
			</div>
			<div className={styles.backdrop} onClick={handleModalClose}></div>
		</>
	);
};
export default RequestFlowModal;
