import styles from './styles.module.scss';
import { useEffect } from 'react';

interface LoaderProps {
	color?: '#55a8fd' | 'black';
	width?: number;
	marginTop?: number;
}

const Loader: React.FC<LoaderProps> = ({ color = '#55a8fd', width = 32, marginTop = 0 }) => {
	useEffect(() => {
		const r: HTMLElement | null = document.querySelector(':root');
		if (r) {
			r.style.setProperty('--loader-color', color);
			r.style.setProperty('--width', width + 'px');
			r.style.setProperty('--margin-top', marginTop + 'px');
		}
	}, []);

	return (
		<div className={styles.loader}>
			<div className={styles.loader__spinner}>
				<div className={styles.loader__spinner__outer}></div>
				<div className={styles.loader__spinner__inner}></div>
			</div>
		</div>
	);
};
export default Loader;
