import { CancelToken } from 'axios';
import { client } from './../../util/client';

type getDatasetResponse = {
	success: boolean;
	result?: any;
	total?: number;
	msg?: string;
};

export const fetchJobLogs = (data: { awi_job_uuid: string; job_status: string; cancelToken: CancelToken }) => {
	const queryKey = ['dataset-jobs-logs', data] as const;

	const queryFunction = ({ pageParam }: { pageParam?: any }) => {
		console.log(pageParam);
		return client<getDatasetResponse>('/fetch_job_logs', {
			baseURL: 'training',
			method: 'POST',
			data: {
				awi_job_uuid: data.awi_job_uuid,
				last_file: data.job_status === 'running' ? null : pageParam === undefined ? '' : pageParam[0],
				last_time: data.job_status !== 'running' ? null : pageParam === undefined ? null : pageParam[1]
			},
			cancelToken: data.cancelToken
		}).then(res => res.data);
	};
	return [queryKey, queryFunction] as const;
};
