import React from 'react';
import ReactDOM from 'react-dom';
import './scss/_base.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import store from './redux/store';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { GoogleOAuthProvider } from '@react-oauth/google';

const queryClient = new QueryClient();

ReactDOM.render(
	<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_SIGNUP_CLIENT_ID}>
		<React.StrictMode>
			<Provider store={store}>
				<QueryClientProvider client={queryClient}>
					<App />
					<ReactQueryDevtools initialIsOpen={false} position={'bottom-left'} />
				</QueryClientProvider>
			</Provider>
		</React.StrictMode>
	</GoogleOAuthProvider>,

	document.getElementById('root')
);

reportWebVitals();
