import styles from './styles.module.scss';
import Container from '../../Container';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { LoggedUser } from '../../../types';
import TableInfiniteScroll from '../../Common/TableInfiniteScroll';
import TableHeaders from '../../Common/TableInfiniteScroll/TableHeaders';
import TableHeader from '../../Common/TableInfiniteScroll/TableHeader';
import TableBody from '../../Common/TableInfiniteScroll/TableBody';
import TableRow from '../../Common/TableInfiniteScroll/TableRow';
import TableCell from '../../Common/TableInfiniteScroll/TableCell';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getRequestClasses } from '../../../handlers/class/getRequestClasses';
import RequestPriceModal from './RequestPriceModal';

interface GenerateBillProps {
	loggedUser: LoggedUser;
	detailedViewData: any;
	awi_request_id: number;
	selectedUser: any;
	selectedBill?: any;
}

const DetailViewPage: React.FC<GenerateBillProps> = ({ loggedUser, detailedViewData, awi_request_id, selectedUser, selectedBill }) => {
	const [getClasskey, getClassFunction] = getRequestClasses({ awi_request_id: awi_request_id });
	const { data: requestClasses } = useQuery(getClasskey, getClassFunction);

	const [attributeLabels, setAttributeLabels] = useState<any>({});

	const [selectedPricingStructure, setSelectedPricingStructure] = useState<number | null>(null);
	const [openRequestPriceModal, setOpenRequestPriceModal] = useState(false);

	const [activeIndex, setActiveIndex] = useState<number[]>([]);

	const toggleQuestion = index => {
		const temp = [...activeIndex];
		if (temp.includes(index)) {
			setActiveIndex(temp.filter(item => item !== index));
		} else {
			setActiveIndex([...temp, index]);
		}
	};

	useEffect(() => {
		const temp = (detailedViewData || []).map((item, index) => index);
		setActiveIndex(temp);
	}, []);

	useEffect(() => {
		const temp_obj = {};
		if ((requestClasses || []).length > 0) {
			requestClasses.forEach(item => {
				temp_obj[item.awi_id] = item.awi_label;
				temp_obj[`${item.awi_id}_outline`] = 'Outline';
				item.awi_attributes.forEach(attr => {
					temp_obj[`${item.awi_id}_${attr.awi_id}`] = attr.awi_label;
				});
			});
		}
		setAttributeLabels(temp_obj);
	}, [requestClasses]);

	const handleRequestPricingClick = requestPricingId => {
		setSelectedPricingStructure(requestPricingId);
		setOpenRequestPriceModal(true);
	};

	const handleRequestPricingModalClose = () => {
		setOpenRequestPriceModal(false);
	};

	console.log('detailedViewData', detailedViewData);

	console.log('attributeLabels', attributeLabels);

	return (
		<>
			<div>
				{(detailedViewData || []).map((priceItem, index) => {
					return (
						<div className={styles.detailview__} style={{ marginTop: '24px' }} key={index}>
							<div style={{ marginBottom: '24px', cursor: 'pointer' }} onClick={() => toggleQuestion(index)} className={styles.detailview__heading}>
								{/* onClick={() => handleRequestPricingClick(priceItem._id)} */}
								<div className={styles.detailview__heading__left}>
									<img
										src="/icons/billing/arrow.svg"
										style={{ transform: activeIndex.includes(index) ? 'rotate(180deg)' : 'rotate(0deg', width: '15px', aspectRatio: '1/1' }}
									></img>{' '}
									<p style={{ wordSpacing: '0.3em' }}>Pricing-{String(index + 1).padStart(2, '0')}</p>
								</div>
								<div className={styles.detailview__heading__right}>
									<p className={priceItem.price_cost >= 0 ? styles.detailview__heading__green : styles.detailview__heading__red}>
										{priceItem.price_cost.toFixed(2)}
									</p>
									<img src="/icons/billing/pricestructure.svg" onClick={() => handleRequestPricingClick(priceItem._id)}></img>
								</div>
							</div>
							{activeIndex.includes(index) && (
								<div className="answer">
									<div>
										<TableInfiniteScroll>
											<TableHeaders>
												<TableHeader>S. No</TableHeader>
												<TableHeader>CLASS</TableHeader>
												<TableHeader>ATTRIBUTE</TableHeader>
												<TableHeader>PENDING</TableHeader>
												<TableHeader>APPROVED</TableHeader>
												<TableHeader>REJECTED</TableHeader>
												<TableHeader>CORRECTED</TableHeader>
												<TableHeader>CREATED</TableHeader>
												<TableHeader>TOTAL AMOUNT</TableHeader>
											</TableHeaders>
											<TableBody>
												{(priceItem.awi_classwise_counts || []).map((classItem, classIndex) => {
													classItem.awi_attributewise_counts.reverse();
													return classItem.awi_attributewise_counts.map((attributeItem, attributeIndex) => (
														<>
															<TableRow>
																<TableCell>{attributeIndex === 0 ? classIndex + 1 : ''}</TableCell>
																<TableCell>{attributeIndex === 0 ? attributeLabels[`${classItem.awi_class_id}`] : ''}</TableCell>
																<TableCell>{attributeLabels[`${classItem.awi_class_id}_${attributeItem.awi_attribute_id}`]}</TableCell>
																<TableCell>{attributeItem.awi_pending_count}</TableCell>
																<TableCell>{attributeItem.awi_approved_count}</TableCell>
																<TableCell>{attributeItem.awi_rejected_count}</TableCell>
																<TableCell>{attributeItem.awi_corrected_count}</TableCell>
																<TableCell>{attributeItem.awi_created_count}</TableCell>
																<TableCell>{attributeItem.attribute_cost.toFixed(2)}</TableCell>
															</TableRow>
														</>
													));
												})}
											</TableBody>
										</TableInfiniteScroll>
									</div>
								</div>
							)}
						</div>
					);
				})}
				<div className={styles.detailview}>
					<div>Total Payable Amount=</div>
					<div className={styles.detailview__totalstring}>
						{(detailedViewData || []).map((priceItem, index) => (
							<span key={index} style={{ color: 'green' }}>
								{priceItem.price_cost.toFixed(2)} {index < (detailedViewData || []).length - 1 ? ' + ' : ' - '}
							</span>
						))}
						<span style={{ color: 'red' }}>
							{selectedBill ? selectedBill.awi_already_paid_amount : selectedUser?.awi_alreadypaid_amount.toFixed(2) || 0} ( Already paid amount )
						</span>
					</div>
					<div style={{ color: 'green' }}>= {selectedBill ? selectedBill.awi_total_payable_amount : selectedUser?.awi_payable_amount.toFixed(2)}</div>
				</div>
				{openRequestPriceModal && (
					<RequestPriceModal
						attributesLabels={attributeLabels}
						requestPriceID={selectedPricingStructure || -1}
						handleModalClose={handleRequestPricingModalClose}
						requestId={awi_request_id}
					></RequestPriceModal>
				)}
			</div>
		</>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps, null)(DetailViewPage));
