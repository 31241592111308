import { env } from '../env';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

type BaseURL = '' | 'user' | 'request' | 'org' | 'class' | 'content' | 'annotation' | 'misc' | 'session' | 'payment';

interface ClientOptions extends AxiosRequestConfig {
	baseURL?: BaseURL;
}

type Client = <T = any>(url: string, options?: ClientOptions) => Promise<AxiosResponse<T>>;

/**
 * It's a function that takes a url and an options object, and returns an axios request with the url
 * and options.
 * @param url - The url of the request
 * @param  - Client = async (url, { baseURL = '', ...options } = {}) => {
 * @returns A function that returns a promise.
 */
export const clientWithoutToken: Client = async (url, { baseURL = '', ...options } = {}) => {
	const api = env.REACT_APP_BACKEND! + env.REACT_APP_ROUTE_CONFIG;

	if (url.startsWith('/') || baseURL === '') {
		return await axios(api + baseURL + url, {
			...options
		});
	} else {
		return await axios(api + baseURL + '/' + url, {
			...options
		});
	}
};
