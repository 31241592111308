import { client } from './../../util/client';

export const getProgress = (date: Date = new Date()) => {
	const queryKey = ['progress', date] as const;
	const queryFn = () => {
		return client('/get_annotation_progress', {
			method: 'POST',
			data: {
				date
			},
			baseURL: 'org'
		}).then(res => res.data);
	};

	return [queryKey, queryFn] as const;
};
