import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useQueryClient } from 'react-query';

import { postTag } from '../../../handlers/common';
import { searchTags } from '../../../handlers/content';
import { searchLocations } from '../../../handlers/search';
import { postLocation } from '../../../handlers/common/postLocation';

import Input from '../../Common/Input';

import { useQuery } from '../../../util/useQuery';
import { byteToSize, getFullName } from '../../../util/common.utils';
import RequestActions from '../../../redux/actions/RequestActions';

import { MiniData, MiniUser } from '../../../types';

import styles from './styles.module.scss';

interface UploadImagesProps {
	isOpen: boolean;
	handleModalToggle: () => void;
	annotators: MiniUser[];
	verifiers: MiniUser[];
	requests: MiniData[];
	handleUploadImages: (file: File[], tag: number[], location: number, cb?: (ids: any[]) => void) => void;
	requestID?: { awi_id: number; awi_label: string };
	handleBatchAssignment: (requestId: number, annotatorId: number[], verifierId: number[], _selectedImg?: number[]) => void;
}

const UploadImages: React.FC<UploadImagesProps> = ({
	isOpen,
	verifiers,
	handleModalToggle,
	annotators,
	handleUploadImages,
	requestID,
	requests,
	handleBatchAssignment
}) => {
	const CancelToken = axios.CancelToken;
	const source = CancelToken.source();

	const queryClient = useQueryClient();

	const [files, setFiles] = useState<File[]>([]);
	const [tag, setTag] = useState<{ awi_id: number; awi_label: string }[]>([]);
	const [location, setLocation] = useState<{ awi_id: number; awi_label: string }>();
	const [isLoading, setIsLoading] = useState(false);
	const [searchTag, setSearchTag] = useState('');
	const [searchLocation, setSearchLocation] = useState('');
	const [selectedRequest, setSelectedRequest] = useState<number | undefined>(requestID!.awi_id);

	const [selectedAnnotator, setSelectedAnnotator] = useState<number[]>([]);
	const [selectedVerifier, setSelectedVerifier] = useState<number[]>([]);

	const [searchInput, setSearchInput] = useState({
		annotatorSearch: '',
		verifierSearch: ''
	});

	useEffect(() => {
		if (requestID) {
			setSelectedRequest(requestID.awi_id);
		}
	}, [requestID]);

	const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFiles(prev => [...prev, ...Array.from(e.target.files!)]);
	};

	const handleModalClose = () => {
		setFiles([]);
		handleModalToggle();
	};

	const [fetchTagsKey, fetchTagsFunction] = searchTags({ s: searchTag, source });
	const { data: tags } = useQuery(fetchTagsKey, fetchTagsFunction);

	const [fetchLocationKey, fetchLocationFunction] = searchLocations({ s: searchLocation, source });
	const { data: locations } = useQuery(fetchLocationKey, fetchLocationFunction);

	const handleSearchTags = async (s: string) => {
		source.cancel();
		const query = s.split(',')[s.split(',').length > 0 ? s.split(',').length - 1 : s.split(',').length];

		const awiTag = tag.findIndex(awiTag => awiTag.awi_label === query);
		if (searchTag === '' && awiTag !== -1) {
			setTag(prev => {
				const temp = [...prev];
				temp.splice(awiTag, 1);

				return [...temp];
			});
			setSearchTag('');
		} else {
			setSearchTag(query);
		}
	};

	const handleTagsChange = (tagId: number) => {
		if (tagId) {
			setTag(prev => {
				const index = prev.findIndex(awiTag => awiTag.awi_id === tagId);
				if (index === -1) {
					const tempTag = tags!.result.find(awiTags => awiTags.awi_id === tagId)!;
					return [...prev, { awi_id: tempTag.awi_id, awi_label: tempTag.awi_label }];
				} else {
					const temp = [...prev];
					temp.splice(index, 1);
					return [...temp];
				}
			});
			setSearchTag('');
		}
	};

	const keyDownEvent = async event => {
		if (event.which === 13) {
			event.preventDefault();
			if ((tags!.result || []).length > 0) {
				setTag(prev => {
					setSearchTag('');
					if (prev.findIndex(awiTag => awiTag.awi_id === tags!.result[0].awi_id) === -1) {
						return [...prev, { awi_id: tags!.result[0].awi_id, awi_label: tags!.result[0].awi_label }];
					} else {
						return [...prev];
					}
				});
			} else {
				const tagRes = await postTag({ awi_label: searchTag.toLocaleLowerCase() });

				if (!tagRes.success) {
					toast.error(tagRes.message || 'Something went wrong');
					return;
				}

				setTag(prev => {
					setSearchTag('');
					if (prev.findIndex(awiTag => awiTag.awi_id === tagRes.result.awi_id) === -1) {
						return [...prev, { awi_id: tagRes.result.awi_id, awi_label: tagRes.result.awi_label }];
					} else {
						return [...prev];
					}
				});
				queryClient.invalidateQueries(['tags']);
			}
		}
	};

	const handleSearchLocations = async (s: string) => {
		source.cancel();
		if (s !== '' && location?.awi_id) {
			setLocation(undefined);
			setSearchLocation('');
		} else {
			setSearchLocation(s);
		}
	};

	const handleLocationsChange = (tagId: number) => {
		if (tagId) {
			setLocation(() => {
				const tag = locations!.result.find(awiTags => awiTags.awi_id === tagId)!;
				if (tag) {
					return { awi_id: tag.awi_id, awi_label: tag.awi_label };
				}
			});
			setSearchLocation('');
		}
	};

	const keyDownEventLocation = async event => {
		if (event.which === 13) {
			event.preventDefault();
			if ((locations!.result || []).length > 0) {
				setSearchLocation('');
				setLocation({ awi_id: locations!.result[0].awi_id, awi_label: locations!.result[0].awi_label });
			} else {
				const tags = await postLocation({ awi_label: searchLocation.toLocaleLowerCase() });
				handleLocationsChange(tags.result.awi_id);

				queryClient.invalidateQueries(['locations']);
				setLocation({ awi_id: tags!.result.awi_id, awi_label: tags!.result.awi_label });
			}
		}
	};

	const handleRequestSelect = (requestId: number) => {
		if (requestId === selectedRequest) {
			setSelectedAnnotator([]);
			setSelectedVerifier([]);
			setSelectedRequest(undefined);
		} else {
			setSelectedRequest(requestId);
		}
	};

	const handleSelectAnnotator = (annoID: number) => {
		setSelectedAnnotator(prev => {
			const temp = [...prev];
			if (prev?.indexOf(annoID) === -1) {
				temp.push(annoID);
			} else {
				temp.splice(temp.indexOf(annoID), 1);
			}
			return [...temp];
		});
	};

	const handleSelectVerifier = (annoID: number) => {
		setSelectedVerifier(prev => {
			const temp = [...prev];
			if (prev?.indexOf(annoID) === -1) {
				temp.push(annoID);
			} else {
				temp.splice(temp.indexOf(annoID), 1);
			}
			return [...temp];
		});
	};

	const handleModalClosing = () => {
		setFiles([]);
		setTag([]);
		setLocation(undefined);
		setSelectedRequest(undefined);
		setSelectedAnnotator([]);
		setSelectedVerifier([]);
		handleModalToggle();
	};

	const handleSubmitForm = async (event: React.FormEvent) => {
		event?.preventDefault();

		//check if images are selected
		if ((files || []).length === 0) {
			toast.error('Select images first');
			return;
		}

		//check if correct file type are being uploaded
		const supportedExtensions = ['image/jpeg', 'image/jpg', 'image/png'];
		let isSupported = true;
		files.forEach(file => {
			const path = file.type.split('.');

			const extension = `${path[path.length - 1]}`;

			if (!supportedExtensions.includes(extension)) {
				isSupported = false;
				return false;
			}
			return true;
		});

		if (!isSupported) {
			toast.error('Only images can be uploaded!!');
			return;
		}

		// upload the images
		setIsLoading(true);
		handleUploadImages(
			files,
			tag.map(awiTag => awiTag.awi_id),
			location!.awi_id,
			result => {
				if ((result || []).length > 0 && selectedRequest) {
					const contentIds = result.map(single => parseInt(single.awi_content_key));
					handleBatchAssignment(selectedRequest, selectedAnnotator, selectedVerifier, contentIds);
				}
			}
		);
		setIsLoading(false);
		setFiles([]);
		setTag([]);
		setLocation(undefined);
		setSelectedRequest(undefined);
		setSelectedAnnotator([]);
		setSelectedVerifier([]);
	};

	if (!isOpen) {
		return null;
	}

	return (
		<>
			<div className={styles.modal}>
				<div className={styles.modal__close_button} onClick={handleModalClosing}>
					<img src="/icons/close.svg" />
				</div>
				<form className={styles.upload__form} onSubmit={handleSubmitForm}>
					<div className={styles.upload__form__image}>
						<label htmlFor="uploadFile">Select Images</label>
						<div className={styles.upload__form__image__detail}>
							<label htmlFor="uploadFile" className={styles.upload__form__image__detail__button}>
								Browse Images
							</label>
							{/* accept only images */}
							<input id="uploadFile" hidden type={'file'} accept={'image/png, image/jpeg'} multiple onChange={handleFileUpload} />
							<p>
								*{files.length} images selected {files.length > 0 && byteToSize(files.reduce((acc, file) => acc + file.size, 0))}
							</p>
						</div>
					</div>
					<div className={styles.upload__form__grid}>
						<label>Tags</label>
						<div>
							<Input
								type="input"
								placeholder="Select Tags"
								data={tags?.result}
								onClick={handleTagsChange}
								onChange={handleSearchTags}
								keyDown={keyDownEvent}
								required={true}
								selectedData={tag.map(ele => ele.awi_id)}
							/>
							<div className={styles.upload__form__grid__tags}>
								{tag.map((tag, index) => (
									<div key={index} className={styles.upload__form__grid__tags__item}>
										<p>{tag.awi_label}</p>
										<img src="/icons/close.svg" width={8} height={8} onClick={() => handleTagsChange(tag.awi_id)} />
									</div>
								))}
							</div>
						</div>

						<label>Location</label>
						<Input
							type="input"
							searchString={location?.awi_label || searchLocation}
							placeholder="Select Location"
							data={locations?.result || []}
							onClick={handleLocationsChange}
							onChange={handleSearchLocations}
							keyDown={keyDownEventLocation}
							required={true}
						/>
						{/* <label>Source of Data</label>
                        <input className={styles.common_styles__input} type={"text"} /> */}
						<label>Request Label</label>
						<Input
							type="drop-down"
							searchString={requests.filter(request => request.awi_id === selectedRequest)[0]?.awi_label || ''}
							onClick={handleRequestSelect}
							data={requests}
							placeholder="Select Request"
						/>
					</div>
					{selectedRequest && (
						<>
							<hr />
							<div className={styles.upload__form__grid}>
								<label>Assign Annotators</label>
								<Input
									type="input"
									placeholder="Search Annotator"
									onClick={handleSelectAnnotator}
									searchString={searchInput.annotatorSearch}
									data={
										searchInput.annotatorSearch !== ''
											? annotators
													.filter(annotator => {
														return getFullName(annotator).toLowerCase().includes(searchInput.annotatorSearch.toLowerCase());
													})
													.map(annotator => {
														return { awi_id: annotator.awi_id, awi_label: getFullName(annotator) };
													})
											: annotators.map(annotator => {
													return { awi_id: annotator.awi_id, awi_label: getFullName(annotator) };
											  })
									}
									onChange={e => setSearchInput({ ...searchInput, annotatorSearch: e })}
									selectedData={selectedAnnotator}
								/>
							</div>

							<div className={styles.upload__form__grid}>
								<label>Assign Verifiers</label>
								<Input
									type="input"
									placeholder="Search Verifier"
									onClick={handleSelectVerifier}
									searchString={searchInput.verifierSearch}
									data={
										searchInput.verifierSearch !== ''
											? verifiers
													.filter(verifier => {
														return getFullName(verifier).toLowerCase().includes(searchInput.verifierSearch.toLowerCase());
													})
													.map(verifier => {
														return { awi_id: verifier.awi_id, awi_label: getFullName(verifier) };
													})
											: verifiers.map(verifier => {
													return { awi_id: verifier.awi_id, awi_label: getFullName(verifier) };
											  })
									}
									onChange={e => setSearchInput({ ...searchInput, verifierSearch: e })}
									selectedData={selectedVerifier}
								/>
							</div>
						</>
					)}

					<button type={'submit'} className={styles.upload__form__submit} disabled={isLoading && files.length === 0}>
						Upload
					</button>
				</form>
			</div>
			<div className={styles.backdrop} onClick={handleModalClosing}></div>
		</>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser,
		requestID: { awi_id: state.RequestReducer.awi_id, awi_label: state.RequestReducer.awi_label }
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setRequest: data => dispatch(RequestActions.setRequest(data))
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UploadImages));
