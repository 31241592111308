import { client } from './../../util/client';

export const fetchModels = (data: {
	awi_project_name: string;
	awi_status?: string;
	awi_dataset_id?: number;
	start_time?: number;
	status?: number;
	end_time?: number;
	accuracy?: number;
}) => {
	const queryKey = ['models', data] as const;
	const queryFn = () =>
		client('/fetch_models', {
			method: 'POST',
			baseURL: 'training',
			data: { ...data }
		}).then(res => res.data);

	return [queryKey, queryFn] as const;
};
