import { useState } from 'react';
import styles from './styles.module.scss';

interface SearchCompProps {
	placeholder?: string;
	showFilters?: boolean;
	setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
}

const SearchComp: React.FC<SearchCompProps> = ({ placeholder, showFilters = false, setSearchQuery }) => {
	const [inputString, setInputString] = useState<string>('');
	const handleInputChange = event => {
		setInputString(event.target.value);
		setSearchQuery(event.target.value);
	};

	return (
		<div>
			<div className={styles.searchbar}>
				<img src="/icons/search.svg" className={styles.searchbar__searchicon} />
				<input value={inputString} placeholder={placeholder} onChange={e => handleInputChange(e)} />
				{showFilters && <img src="/icons/filter/filter.svg" className={styles.searchbar__filtericon} />}
			</div>
		</div>
	);
};
export default SearchComp;
