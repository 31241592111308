import { useInfiniteQuery as useInfiniteReactQuery, QueryFunction, UseInfiniteQueryOptions, QueryKey, UseInfiniteQueryResult } from 'react-query';

/* prettier-ignore */
export const useInfiniteQuery = <TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
	queryKey: TQueryKey,
	queryFn: QueryFunction<TQueryFnData, TQueryKey>,
	options?: UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>
): UseInfiniteQueryResult<TData, TError> => {
	return useInfiniteReactQuery<TQueryFnData, TError, TData, TQueryKey>(queryKey, queryFn, {
		refetchOnWindowFocus: false,
		staleTime: 1000 * 60 * 30,
		...options
	});
};
