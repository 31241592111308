import { useEffect, useState } from 'react';

import styles from './styles.module.scss';

import { LoggedUser } from '../../../../types';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import UserActions from '../../../../redux/actions/UserActions';
import { getUserDetails } from '../../../../handlers/user';
import { useQuery } from '../../../../util/useQuery';
import { useMutation } from '../../../../util/useMutation';
import { verifyUserDetails } from '../../../../handlers/user/verifyUserDetails';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { format } from 'date-fns';

interface DashboardProps {
	loggedUser: LoggedUser;
	loginUser: (data) => any;
	handleVerificationModalClose: () => void;
	userId: number;
}

const Dashboard: React.FC<DashboardProps> = ({ loggedUser, loginUser, handleVerificationModalClose, userId }) => {
	const [fetchUserKey, fetchUserFunction] = getUserDetails(userId);
	const verifyUserDetailsMutation = useMutation(verifyUserDetails);

	const { data: user } = useQuery(fetchUserKey, fetchUserFunction);
	const [submitting, setSubmitting] = useState(false);

	const queryClient = useQueryClient();

	if (!user?.awi_verification_status) {
		return <div>Not applicable for this user</div>;
	}

	const handleVerificationClick = async e => {
		const destructuredName = e.target.name.split('_');
		e.preventDefault();
		if (destructuredName[0] === 'submit') {
			setSubmitting(true);
		}

		await verifyUserDetailsMutation.mutateAsync(
			{
				awi_user_id: userId,
				awi_field: destructuredName[0],
				awi_value: destructuredName[1] === 'approve' ? true : false,
				awi_submit: destructuredName[0] === 'submit' ? true : false
			},
			{
				onSuccess: res => {
					toast.success(res.message);
					queryClient.invalidateQueries(fetchUserKey).then(() => {
						return;
					});

					if (destructuredName[0] === 'submit') {
						handleVerificationModalClose();
					}
				},
				onError: (err: any) => {
					const errMessage = err.response?.data.message;
					toast.error(errMessage);
				}
			}
		);

		setSubmitting(false);
	};

	const handleSubmit = () => {
		if (user?.awi_aadhaar.awi_status === 'pending') {
			toast.error('Aadhaar not verified');
		} else if (user?.awi_pancard.awi_status === 'pending') {
			toast.error('Pan not verified');
		} else if (user?.awi_bank_details.awi_status === 'pending') {
			toast.error('Bank details not verified');
		}
	};

	return (
		<>
			<div className={styles.backdrop} onClick={handleVerificationModalClose}></div>
			<div className={styles.verification__modal}>
				<div className={styles.verification__modal__header}>
					<div className={styles.verification__modal__header__title}>User&apos;s Document Verification</div>
					<div className={styles.verification__modal__header__close} onClick={handleVerificationModalClose}>
						<img src="/icons/close.svg" />
					</div>
				</div>
				<div className={styles.verification__modal__body}>
					<div className={styles.verification__modal__row}>
						<div className={styles.verification__modal__row__left}>
							<img src={user?.awi_personal_info.awi_avatar || ''} alt="Profile photo" className={styles.verification__modal__row__image}></img>
						</div>
						<div className={styles.verification__modal__row__right}>
							<div className={styles.verification__modal__row__right__container}>
								<p className={styles.verification__modal__row__right__label}>Name</p>
								<span>{`${user?.awi_personal_info?.awi_firstname || ''} ${user?.awi_personal_info?.awi_lastname || ''}`}</span>
							</div>
							<div className={styles.verification__modal__row__right__container}>
								<p className={styles.verification__modal__row__right__label}>Father&apos;s Name</p>
								<span>{`${user?.awi_personal_info?.awi_fathers_firstname || ''} ${user?.awi_personal_info?.awi_fathers_lastname || ''}`}</span>
							</div>
							<div className={styles.verification__modal__row__right__container}>
								<p className={styles.verification__modal__row__right__label}>Date of Birth</p>
								<span>{user?.awi_personal_info?.awi_dob ? format(new Date(user.awi_personal_info.awi_dob), 'dd/M/yyyy') : ''}</span>
							</div>
							<div className={styles.verification__modal__row__right__container}>
								<p className={styles.verification__modal__row__right__label}>Address</p>
								<span>{user?.awi_personal_info.awi_address}</span>
							</div>
						</div>
					</div>
					<div className={styles.verification__modal__row}>
						<div className={styles.verification__modal__row__left}>
							<img src={user?.awi_pancard.awi_front_image} alt="PAN photo" className={styles.verification__modal__row__image}></img>
						</div>
						<div className={styles.verification__modal__row__right}>
							<div className={styles.verification__modal__row__right__container}>
								<p className={styles.verification__modal__row__right__label}>PAN</p>
								<div>
									<div className={styles.verification__modal__cardInput__container}>
										{Array.from({ length: 10 }, (_, index) => (
											<span key={index} className={styles.verification__modal__cardInput__digit}>
												{user?.awi_pancard.awi_no[index] || ''}
											</span>
										))}
									</div>{' '}
									{user?.awi_verification_status !== 'approved' && (
										<div className={styles.verification__modal__row__right__container__buttons}>
											{user?.awi_pancard.awi_status !== 'rejected' ? (
												<button
													className={styles.verification__modal__row__right__container__buttons__secondary}
													name="pan_reject"
													onClick={handleVerificationClick}
												>
													Reject
												</button>
											) : (
												<span className={styles.verification__modal__row__right__container__rejectedstatus}>Rejected</span>
											)}
											{user?.awi_pancard.awi_status !== 'approved' ? (
												<button
													className={styles.verification__modal__row__right__container__buttons__primary}
													name="pan_approve"
													onClick={handleVerificationClick}
												>
													Approve
												</button>
											) : (
												<span className={styles.verification__modal__row__right__container__approvedstatus}>Approved</span>
											)}
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className={styles.verification__modal__row}>
						<div className={styles.verification__modal__row__left}>
							<img src={user?.awi_aadhaar.awi_front_image} alt="AADHHAR photo" className={styles.verification__modal__row__image}></img>
						</div>
						<div className={styles.verification__modal__row__right}>
							<div className={styles.verification__modal__row__right__container}>
								<p className={styles.verification__modal__row__right__label}>AADHAAR</p>
								<div>
									<div className={styles.verification__modal__cardInput__container}>
										{Array.from({ length: 12 }, (_, index) => (
											<span key={index} className={styles.verification__modal__cardInput__digit}>
												{user?.awi_aadhaar.awi_no[index] || ''}
											</span>
										))}
									</div>{' '}
									{user?.awi_verification_status !== 'approved' && (
										<div className={styles.verification__modal__row__right__container__buttons}>
											{user?.awi_aadhaar.awi_status !== 'rejected' ? (
												<button
													className={styles.verification__modal__row__right__container__buttons__secondary}
													name="aadhaar_reject"
													onClick={handleVerificationClick}
												>
													Reject
												</button>
											) : (
												<span className={styles.verification__modal__row__right__container__rejectedstatus}>Rejected</span>
											)}
											{user?.awi_aadhaar.awi_status !== 'approved' ? (
												<button
													className={styles.verification__modal__row__right__container__buttons__primary}
													name="aadhaar_approve"
													onClick={handleVerificationClick}
												>
													Approve
												</button>
											) : (
												<span className={styles.verification__modal__row__right__container__approvedstatus}>Approved</span>
											)}
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className={styles.verification__modal__row}>
						<div className={styles.verification__modal__row__left}>
							<img src={user?.awi_bank_details.awi_cancelled_cheque} alt="Cancelled Cheque" className={styles.verification__modal__row__image}></img>
						</div>
						<div className={styles.verification__modal__row__right}>
							<div className={styles.verification__modal__row__right__container}>
								<p className={styles.verification__modal__row__right__label}>Bank Name</p>
								<span>{user?.awi_bank_details.awi_bank_name}</span>
							</div>
							<div className={styles.verification__modal__row__right__container}>
								<p className={styles.verification__modal__row__right__label}>Account Number</p>
								<span>{user?.awi_bank_details.awi_account_no}</span>
							</div>
							<div className={styles.verification__modal__row__right__container}>
								<p className={styles.verification__modal__row__right__label}>IFSC Code</p>
								<span>{user?.awi_bank_details.awi_ifsc_code}</span>
							</div>
							<div className={styles.verification__modal__row__right__container}>
								<p className={styles.verification__modal__row__right__label}>Beneficiary Name</p>
								<span>{user?.awi_bank_details.awi_beneficiary_name}</span>
								{user?.awi_verification_status !== 'approved' && (
									<div>
										<div className={styles.verification__modal__row__right__container__buttons}>
											{user?.awi_bank_details.awi_status !== 'rejected' ? (
												<button
													className={styles.verification__modal__row__right__container__buttons__secondary}
													name="bank_reject"
													onClick={handleVerificationClick}
												>
													Reject
												</button>
											) : (
												<span className={styles.verification__modal__row__right__container__rejectedstatus}>Rejected</span>
											)}
											{user?.awi_bank_details.awi_status !== 'approved' ? (
												<button
													className={styles.verification__modal__row__right__container__buttons__primary}
													name="bank_approve"
													onClick={handleVerificationClick}
												>
													Approve
												</button>
											) : (
												<span className={styles.verification__modal__row__right__container__approvedstatus}>Approved</span>
											)}
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				{user?.awi_verification_status !== 'approved' && (
					<div className={styles.verification__modal__footer}>
						<button
							className={styles.verification__modal__row__right__container__buttons__primary}
							disabled={submitting}
							name="submit"
							style={{ backgroundColor: submitting ? 'grey' : '' }}
							onClick={handleVerificationClick}
						>
							Submit
						</button>
					</div>
				)}
			</div>
		</>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

const mapDispatchToProps = dispatch => {
	return {
		loginUser: data => dispatch(UserActions.loginUser(data))
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
