import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { fetchRequestPrices } from '../../../handlers/request/fetchRequestPrices';
import { useQuery } from 'react-query';
import { TableBody, TableCell, TableHeader, TableHeaders, TableInfiniteScroll, TableRow } from '../../Common';

interface RequestPriceModalProps {
	requestId: number;
	requestPriceID: number;
	handleModalClose: () => void;
	attributesLabels: any;
}

const RequestPriceModal: React.FC<RequestPriceModalProps> = ({ requestId, handleModalClose, requestPriceID, attributesLabels }) => {
	const [fetchRequestPricesKey, fetchRequestPricesFunction] = fetchRequestPrices({ awi_request_id: requestId, awi_request_pricing_id: requestPriceID });
	const { data: requestPrice, refetch: refetchRequestPrices } = useQuery(fetchRequestPricesKey, fetchRequestPricesFunction);
	let currentClassId = -1;

	useEffect(() => {
		currentClassId = -1;
	}, [requestPrice]);
	return (
		<>
			<div className={styles.modal}>
				{requestPriceID === -1 ? (
					<div>Not applicable</div>
				) : (
					<TableInfiniteScroll>
						<TableHeaders>
							<TableHeader>CLASS</TableHeader>
							<TableHeader>ATTRIBUTE</TableHeader>
							<TableHeader>PENDING</TableHeader>
							<TableHeader>APPROVED</TableHeader>
							<TableHeader>REJECTED</TableHeader>
							<TableHeader>CORRECTED</TableHeader>
							<TableHeader>CREATED</TableHeader>
							<TableHeader>VERIFIED</TableHeader>
						</TableHeaders>
						<TableBody>
							{(requestPrice?.result || []).length > 0 &&
								requestPrice?.result[0].awi_prices.map((price: any, index: number) => {
									let display = false;
									if (price.awi_class_id !== currentClassId) {
										display = true;
										currentClassId = price.awi_class_id;
									}
									return (
										<>
											<TableRow>
												<TableCell>{display ? attributesLabels[price.awi_class_id] : ''}</TableCell>
												<TableCell>{attributesLabels[`${price.awi_class_id}_${price.awi_attribute_id}`]}</TableCell>
												<TableCell>{price.awi_pending_cost}</TableCell>
												<TableCell>{price.awi_approved_cost}</TableCell>
												<TableCell>{price.awi_rejected_cost}</TableCell>
												<TableCell>{price.awi_corrected_cost}</TableCell>
												<TableCell>{price.awi_created_cost}</TableCell>
												<TableCell>{price.awi_verified_cost}</TableCell>
											</TableRow>
										</>
									);
								})}
						</TableBody>
					</TableInfiniteScroll>
				)}
			</div>
			<div className={styles.backdrop} onClick={handleModalClose}></div>
		</>
	);
};
export default RequestPriceModal;
