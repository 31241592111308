export const annotationData = [
	{
		awi_id: 0,
		awi_label: 'rectangle'
	},
	{
		awi_id: 1,
		awi_label: 'circle'
	},
	{
		awi_id: 2,
		awi_label: 'polygon'
	},
	{
		awi_id: 3,
		awi_label: 'dot'
	}
];

export const requestType = [
	{
		awi_id: 0,
		awi_label: 'detector'
	},
	{
		awi_id: 1,
		awi_label: 'classifier'
	}
];

export const datasetTypeData = [
	{
		awi_id: 0,
		awi_label: 'total'
	},
	{
		awi_id: 1,
		awi_label: 'verified'
	}
];
