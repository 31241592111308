import { Annotation } from '../../types/annotations';

import { client } from './../../util/client';

export interface AnnotationResponse {
	result: Annotation[];
	success: boolean;
	message?: string;
}

export const fetchAnnotations = (data: { awi_request_id: number; awi_content_id: number }) => {
	const queryKey = ['annotations', data] as const;

	const queryFunc = () => client<AnnotationResponse>('/fetch_annotation_content', { baseURL: 'annotation', method: 'POST', data }).then(res => res.data);

	return [queryKey, queryFunc] as const;
};
