import { client } from './../../util/client';

export const updateUser = (data: {
	awi_firstname: string;
	awi_lastname: string;
	awi_email: string;
	awi_vpa: string;
	awi_aadhar_no: string;
	awi_bank_acc_no: string;
	awi_pan_no: string;
	awi_ifsc_code: string;
}) =>
	client('/update_user', {
		baseURL: 'user',
		method: 'PATCH',
		data
	}).then(res => res.data);
