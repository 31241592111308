import { useState } from 'react';
import toast from 'react-hot-toast';

import { postOrganisation } from '../../../handlers/organisation/postOrganisation';

import { useMutation } from '../../../util/useMutation';

import styles from './styles.module.scss';

interface CreateOrganisationProps {
	toggleCreateOrgModal: () => void;
	updateOrganisations: () => void;
}

const CreateOrganisation: React.FC<CreateOrganisationProps> = ({ toggleCreateOrgModal, updateOrganisations }) => {
	const [orgName, setOrgName] = useState('');
	const [email, setEmail] = useState('');
	const postOrganisationMutation = useMutation(postOrganisation);

	const handleFormSubmit = async event => {
		event.preventDefault();
		const orgData = {
			awi_label: orgName,
			awi_email: email
		};
		postOrganisationMutation.mutate(orgData, {
			onSuccess: res => {
				if (res.success) {
					toast.success(res.message);
					updateOrganisations();
					toggleCreateOrgModal();
				} else {
					toast.error(res.message);
				}
			}
		});
	};

	const handleInputChange = event => {
		const tempVal = event.target.value.trimStart();

		if (event.target.name === 'awi_label') {
			setOrgName(tempVal);
		} else if (event.target.name === 'awi_email') {
			setEmail(event.target.value.trimStart());
		}
	};

	return (
		<>
			<div className={styles.modal}>
				<div className={styles.modal__close_button} onClick={toggleCreateOrgModal}>
					<img src="/icons/close.svg" />
				</div>
				<div style={{ padding: '24px 0' }}>
					<p style={{ fontSize: 24, fontWeight: 500 }}>Create Organisation</p>
				</div>
				<form onSubmit={handleFormSubmit}>
					<div className={styles.upload__form__grid}>
						<label>Organisation Name: </label>
						<input onChange={handleInputChange} value={orgName} name="awi_label" />
						<label>Admin Email: </label>
						<input onChange={handleInputChange} value={email} type={'email'} name="awi_email" />
					</div>
					<div className={styles.modal__buttons}>
						<button
							style={{
								backgroundColor: orgName === '' || email === '' || postOrganisationMutation.isLoading ? 'grey' : '',
								cursor: 'pointer'
							}}
							disabled={orgName === '' || email === '' || postOrganisationMutation.isLoading}
							type="submit"
						>
							Submit
						</button>
					</div>
				</form>
			</div>
			<div className={styles.backdrop} onClick={toggleCreateOrgModal}></div>
		</>
	);
};
export default CreateOrganisation;
