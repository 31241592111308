import { client } from '../../util/client';

type BillResponse = {
	success: boolean;
	message?: string;
	result: any;
};

export type BillInfo = {
	awi_classifier: number;
	awi_dot: number;
	awi_attributes: number;
	awi_copied: number;
	awi_corrected: number;
	awi_bonus: number;
	awi_copy_attributes: number;
	awi_corrected_attributes: number;
	awi_approved: number;
};

export type PaymentAnnotationParametersInfo =
	| {
			awi_classifier?: number;
			awi_attribute?: number;
			awi_copied_attribute?: number;
			awi_corrected_attribute?: number;
			awi_dot?: number;
			awi_copied_annotation?: number;
			awi_corrected_annotation?: number;
			awi_approved_annotation?: number;
			awi_bonus?: number;
	  }
	| undefined;

export type paymentClassParametersInfo =
	| {
			awi_class_id: number;
			awi_attribute_id: number | string;
			awi_pending_cost: number;
			awi_approved_cost: number;
			awi_rejected_cost: number;
			awi_corrected_cost: number;
			awi_verified_cost: number;
			awi_created_cost: number;
	  }[]
	| undefined;

export const postMonthlyBill = (data: BillInfo) =>
	client<BillResponse>('/create_monthly_bill', {
		baseURL: 'session',
		method: 'POST',
		data
	}).then(res => res.data);
