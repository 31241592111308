import styles from './styles.module.scss';
import Container from '../../Container';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { LoggedUser } from '../../../types';
import { useLocation } from 'react-router-dom';
import { useMutation } from '../../../util/useMutation';
import { fetchGenerateBillCounts } from '../../../handlers/billing/fetchGenerateBillCounts';
import { toast } from 'react-hot-toast';
import TableInfiniteScroll from '../../Common/TableInfiniteScroll';
import TableHeaders from '../../Common/TableInfiniteScroll/TableHeaders';
import TableHeader from '../../Common/TableInfiniteScroll/TableHeader';
import TableBody from '../../Common/TableInfiniteScroll/TableBody';
import TableRow from '../../Common/TableInfiniteScroll/TableRow';
import TableCell from '../../Common/TableInfiniteScroll/TableCell';
import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import { getFormattedDate, getFullName } from '../../../util/common.utils';
import { useEffect, useState } from 'react';
import { DatePicker, Input } from '../../Common';
import { generateRequestBill } from '../../../handlers/billing/generateRequestBill';
import { fetchVerifierGenerateBillCounts } from '../../../handlers/billing/fetchVerifierGenerateBillCounts';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { fetchDetailedDistribution } from '../../../handlers/billing/fetchDetailedDestribution';
import { getRequestClasses } from '../../../handlers/class/getRequestClasses';
import RequestPriceModal from './RequestPriceModal';
import DetailView from './DetailView';
import { fetchRequestPrices } from '../../../handlers/request/fetchRequestPrices';
import { useHistory } from 'react-router-dom';

interface GenerateBillProps {
	loggedUser: LoggedUser;
}

export type GenerateBillForm = {
	awi_start_date: Date | null;
	awi_end_date: Date | null;
	awi_request_id: number | null;
};

const GenerateBillPage: React.FC<GenerateBillProps> = ({ loggedUser }) => {
	const location = useLocation();
	const { awi_request_id, awi_request_label } = location.state;
	const history = useHistory();

	const initialGenerateFormdata: GenerateBillForm = {
		awi_start_date: null,
		awi_end_date: null,
		awi_request_id: awi_request_id
	};

	const [getClasskey, getClassFunction] = getRequestClasses({ awi_request_id: awi_request_id });
	const { data: requestClasses } = useQuery(getClasskey, getClassFunction);

	const fetchGenerateBillCountsMutation = useMutation(fetchGenerateBillCounts);

	const [fetchRequestPricesKey, fetchRequestPricesFunction] = fetchRequestPrices({ awi_request_id: awi_request_id });

	const [requestPricingAvailable, setRequestPricingAvailable] = useState<boolean>(false);

	const fetchDetailedDistributionMutation = useMutation(fetchDetailedDistribution);

	const [showDetailedView, setShowDetailedView] = useState(false);

	const [detailedViewData, setDetailedViewData] = useState<any[]>([]);

	const [selectedUser, setSelectedUser] = useState<any>(null);

	const generateRequestBillMutation = useMutation(generateRequestBill);
	const [generateBillCounts, setGenerateBillCounts] = useState<any>(null);

	const [openGenerateBillModal, setOpenGenerateBillModal] = useState(true);

	const [generateBillForm, setGenerateBillForm] = useState<GenerateBillForm>(initialGenerateFormdata);

	useEffect(() => {
		(async () => {
			try {
				const requestPrices = await fetchRequestPricesFunction();
				if ((requestPrices?.result || []).length > 0) {
					setRequestPricingAvailable(true);
				}
			} catch (err) {
				console.log('Error occured when fetching request prices');
			}
		})();
	}, []);

	useEffect(() => {
		const temp_obj = {};
		if ((requestClasses || []).length > 0) {
			requestClasses.forEach(item => {
				temp_obj[item.awi_id] = item.awi_label;
				temp_obj[`${item.awi_id}_outline`] = 'Outline';
				item.awi_attributes.forEach(attr => {
					temp_obj[`${item.awi_id}_${attr.awi_id}`] = attr.awi_label;
				});
			});
		}
	}, [requestClasses]);

	if (!requestPricingAvailable) {
		return (
			<Container>
				<p>Pricing details have not been filled yet</p>
			</Container>
		);
	}

	const handleModalClose = () => {
		setOpenGenerateBillModal(false);
	};

	const handleSubmitGenerateBill = event => {
		event.preventDefault();
		if (!generateBillForm.awi_end_date || !generateBillForm.awi_start_date || !generateBillForm.awi_request_id) {
			toast.error('Please fill all the fields');
			return;
		}

		fetchGenerateBillCountsMutation.mutate(
			{ ...generateBillForm, awi_end_date: new Date(generateBillForm.awi_end_date.setHours(23, 59, 59)) },
			{
				onSuccess: response => {
					console.log(response, 'response');
					if ((response.result || []).length > 0) {
						setGenerateBillCounts(response.result);
					}
					toast.success('Bill count generated successfully');
				}
			}
		);
		setOpenGenerateBillModal(false);
	};

	const handleGenerateBill = () => {
		generateRequestBillMutation.mutate(
			{
				awi_request_id: awi_request_id,
				awi_start_date: generateBillForm.awi_start_date,
				awi_end_date: generateBillForm.awi_end_date ? new Date(generateBillForm.awi_end_date.setHours(23, 59, 59)) : generateBillForm.awi_end_date,
				generateBillData: generateBillCounts
			},
			{
				onSuccess: response => {
					console.log(response, 'response');
					if ((response.result || []).length > 0) {
						setGenerateBillCounts(response.result[0]);
					}
					toast.success('Bill generation successfull');

					setTimeout(() => {
						history.push('/requests');
					}, 1000);
				},
				onError: error => {
					toast.error('Failed to generate bills ');
				}
			}
		);
	};

	const handleStartDateChange = (date: Date | null) => {
		setGenerateBillForm({
			...generateBillForm,
			awi_start_date: date
		});
	};

	const handleEndDateChange = (date: Date | null) => {
		setGenerateBillForm({
			...generateBillForm,
			awi_end_date: date
		});
	};

	const handleClassClick = user => {
		fetchDetailedDistributionMutation.mutate(
			{
				...user,
				awi_request_id: awi_request_id,
				awi_user_level: user.awi_user_info.awi_level
			},
			{
				onSuccess: response => {
					setDetailedViewData(response.result);
					setSelectedUser(user);
					setShowDetailedView(true);
				}
			}
		);
	};

	const handleBackClick = () => {
		setShowDetailedView(false);
	};

	return (
		<Container className={styles.rootContainer}>
			<div className={styles.rootContainer__breadcrumps}>
				<img
					onClick={
						showDetailedView
							? handleBackClick
							: () => {
									history.goBack();
							  }
					}
					style={{ transform: 'rotate(90deg)' }}
					src="/icons/drop-down.svg"
				></img>
				<p>{`${awi_request_label} ${showDetailedView ? `/ ${selectedUser.awi_user_info.awi_name}` : ''}`}</p>
			</div>
			{!showDetailedView && (
				<TableInfiniteScroll>
					<TableHeaders>
						<TableHeader>S NO.</TableHeader>
						<TableHeader>USER</TableHeader>
						<TableHeader>USER LEVEL</TableHeader>
						<TableHeader>CLASS</TableHeader>
						<TableHeader>PENDING</TableHeader>
						<TableHeader>APPROVED</TableHeader>
						<TableHeader>REJECTED</TableHeader>
						<TableHeader>CORRECTED</TableHeader>
						<TableHeader>CREATED</TableHeader>
						<TableHeader>PAYABLE AMOUNT</TableHeader>
						<TableHeader>ALREADY PAID</TableHeader>
						<TableHeader>{''}</TableHeader>
					</TableHeaders>
					<TableBody>
						{(generateBillCounts || []).length > 0
							? (generateBillCounts || []).map((user, index) => (
									<TableRow key={index}>
										<TableCell>
											<span>{index + 1}</span>
										</TableCell>
										<TableCell>{user.awi_user_info.awi_name}</TableCell>
										<TableCell>{user.awi_user_level.split('_')[1]}</TableCell>
										<TableCell>
											<span>{(user.awi_class_ids || []).length}</span>
										</TableCell>
										<TableCell>
											<span>{user.awi_pending_count}</span>
										</TableCell>
										<TableCell>
											<span>{user.awi_approved_count}</span>
										</TableCell>
										<TableCell>
											<span>{user.awi_rejected_count}</span>
										</TableCell>
										<TableCell>
											<span>{user.awi_corrected_count}</span>
										</TableCell>
										<TableCell>
											<span>{user.awi_created_count}</span>
										</TableCell>
										<TableCell>
											<span>{user.awi_payable_amount.toFixed(2)}</span>
										</TableCell>
										<TableCell>
											<span>{user.awi_alreadypaid_amount.toFixed(2)}</span>
										</TableCell>
										<TableCell>
											<button onClick={() => handleClassClick(user)} className={clsx(styles.rootContainer__paybutton)}>
												View Details
											</button>
										</TableCell>
									</TableRow>
							  ))
							: !openGenerateBillModal && <div>No bills to be generated for given inputs</div>}
					</TableBody>
				</TableInfiniteScroll>
			)}

			{showDetailedView && (
				<>
					<DetailView detailedViewData={detailedViewData} selectedUser={selectedUser} awi_request_id={awi_request_id}></DetailView>
				</>
			)}

			{openGenerateBillModal && (
				<div className={styles.generate__bill__form__modal}>
					<div className={styles.generate__bill__form__modal__heading}>GENERATE BILL</div>
					<div className={styles.generate__bill__form__modal__requestname}>{awi_request_label}</div>
					<form onSubmit={handleSubmitGenerateBill}>
						<>
							<div className={styles.generate__bill__form__grid}>
								<div>Start date</div>
								<DatePicker
									value={generateBillForm.awi_start_date}
									onChange={(date: Date) => handleStartDateChange(date)}
									dateFormat="MMMM dd, yyyy h:mm aa"
									isTimePicker={true}
									maxDate={(generateBillForm.awi_end_date as Date) || new Date()}
								/>
								<div>End date</div>
								<DatePicker
									value={generateBillForm.awi_end_date}
									onChange={(date: Date) => handleEndDateChange(date)}
									dateFormat="MMMM dd, yyyy h:mm aa"
									isTimePicker={true}
									minDate={generateBillForm.awi_start_date as Date}
									// maxDate={new Date()}
								/>
							</div>
							<div className={styles.modal__buttons}>
								<button type="submit">Generate Bill Preview</button>
							</div>
						</>
					</form>
				</div>
			)}
			{openGenerateBillModal && <div className={styles.backdrop} onClick={handleModalClose}></div>}

			{!showDetailedView && !openGenerateBillModal && (generateBillCounts || []).length > 0 && (
				<button
					className={styles.button}
					disabled={generateRequestBillMutation.isLoading}
					onClick={handleGenerateBill}
					style={{ marginTop: '50px', cursor: 'pointer' }}
				>
					Generate bills
				</button>
			)}
		</Container>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps, null)(GenerateBillPage));
