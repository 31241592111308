import clsx from 'clsx';
import { useState } from 'react';
import { AxiosError } from 'axios';
import HotKeys from 'react-hot-keys';
import { connect } from 'react-redux';
import { toast } from 'react-hot-toast';
import { withRouter } from 'react-router';
import { useQueryClient } from 'react-query';

import { getRequestData } from '../../../handlers/request/getRequestData';
import { fetchAnnotations } from '../../../handlers/annotation/fetchAnnotations';
import { deleteAnnotation } from '../../../handlers/annotation/deleteAnnotation';
import { updateAnnotationsStatus } from '../../../handlers/annotation/updateAnnotationsStatus';
import { AnnotationAccordion } from '../../Common';
import { TableBody, TableCell, TableHeader, TableHeaders, TableInfiniteScroll, TableRow } from '../../Common';

import { SessionContent } from './utils/useAnnotations';
import { useMutation } from '../../../util/useMutation';
import { useQuery } from '../../../util/useQuery';

import { LoggedUser } from '../../../types';
import { Annotation } from '../../../types/annotations';

import styles from './styles.module.scss';
import { refImageAnnotationCompletion } from '../../../handlers/request/refImageAnnotationCompletion';
interface AnnotationDetailsProps {
	currentImage: number;
	request_id: number;
	content_key: number;
	loggedUser: LoggedUser;
	totalImages: number;
	handleCopyAnnotations: (anno: Annotation[]) => void;
	handlePasteAnnotations: () => void;
	setSessionData: React.Dispatch<React.SetStateAction<SessionContent[]>>;
	selectIndex?: number;
	handleSelectAnnotationIndex?: (index: number) => void;
	copyAnnotations: Annotation[];
	handleUpdateNewSession: (newSession: SessionContent[], awi_deleted_annotations_count?: number) => void;
	handleCompleteverification: () => void;
	handleSelectTool: (tool: 'edit' | 'annotate') => void;
	handleUpdateCurrentClass: (class_id: number) => void;
	currentClass: number;
	sessionId: number;
	checkSessionCompleted: () => number;
	handleUpdateCurrentClassColor: (id: number, color: string) => void;
	classColor: { awi_id: number; awi_color: string }[];
	isLoadingSaveSession: boolean;
}

const AnnotationDetails: React.FC<AnnotationDetailsProps> = ({
	currentImage,
	content_key,
	request_id,
	loggedUser,
	totalImages,
	handleCopyAnnotations,
	handlePasteAnnotations,
	selectIndex,
	handleSelectAnnotationIndex,
	// copyAnnotations,
	handleCompleteverification,
	handleSelectTool,
	handleUpdateCurrentClass,
	sessionId,
	checkSessionCompleted,
	handleUpdateCurrentClassColor,
	classColor,
	isLoadingSaveSession
	// currentClass
}) => {
	const queryClient = useQueryClient();

	const [fetchAnnotationsKey, fetchAnnotationsFunction] = fetchAnnotations({ awi_content_id: +content_key, awi_request_id: request_id });
	const [openActionModal, setOpenActionModal] = useState<boolean>(false);

	const { data: annotationsData } = useQuery(fetchAnnotationsKey, fetchAnnotationsFunction);

	const { data: request } = useQuery(...getRequestData(request_id), {
		enabled: !!request_id && request_id !== -1
	});

	const deleteAnnotationMutation = useMutation(deleteAnnotation, {
		onError: (error: AxiosError) => {
			toast.error(error.response?.data.message || 'Something went wrong');
			if (error.response?.data.redirect) {
				window.location.href = '/redirect';
			}
		}
	});
	const [openShortCutKeyModal, setOpenShortCutKeyModal] = useState<boolean>(false);

	const updateAnnotationsStatusMutation = useMutation(updateAnnotationsStatus);
	const referenceImageAnnotationCompletion = useMutation(refImageAnnotationCompletion);

	const handleCompleteReferenceImageAnnotation = () => {
		referenceImageAnnotationCompletion.mutate(
			{
				awi_request_id: request_id
			},
			{
				onSuccess: () => {
					toast.success('Reference image annotation completed');
					setTimeout(() => {
						window.location.href = '/requests';
					}, 2000);
				},
				onError: error => {
					toast.error('Could not complete reference image annotation');
				}
			}
		);
	};

	const handleAddCopyAnnotation = (awi_id: number) => {
		const temp = [...annotationsData!.result.filter(ann => ann.awi_id === awi_id)!];
		if (temp.length > 0) {
			handleCopyAnnotations(temp);
		}
	};

	const handleDeleteAnnotations = (id: number[] = []) => {
		const contentKey = (annotationsData?.result || []).length === id.length ? content_key : null;
		//passing the content key to delete from session if all the annotations in an images are deleted
		deleteAnnotationMutation.mutate(
			{
				awiAnnotationIds: id,
				awiSessionId: sessionId,
				awiContentKey: contentKey
			},
			{
				onSuccess: () => {
					handleSelectTool('annotate');
					queryClient.invalidateQueries(fetchAnnotationsKey);
					return;
				}
			}
		);
	};

	const handleUpdateAnnotationsStatus = (id: number[] = [], awi_status: string) => {
		updateAnnotationsStatusMutation.mutate(
			{
				awiAnnotationIds: id,
				awiStatus: awi_status,
				awiSessionId: sessionId
			},
			{
				onSuccess: () => {
					queryClient.invalidateQueries(fetchAnnotationsKey);
					return;
				}
			}
		);
	};

	if (!request) {
		return null;
	}

	const getShortCutKeys = () => {
		if (loggedUser.userObj.awi_level === 'awi_supervisor') {
			return [
				['next image', 'd'],
				['previous image', 'a']
			];
		} else {
			return [
				['next image', 'd'],
				['previous image', 'a'],
				['Switch to edit', 'e'],
				['Switch to create', 'q'],
				['Copy all annotations', 'ctrl + c'],
				['Paste all annotations', 'ctrl + v'],
				// ['Toggle Scale', 'ctrl + b'],
				// ['Move Left', 'ArrowLeft'],
				// ['Move Right', 'ArrowRight'],
				// ['Move Up', 'ArrowUp'],
				// ['Move Down', 'ArrowDown'],
				['Toggle Annotations', 'Escape']
			];
		}
	};

	return (
		<>
			{['awi_annotator', 'awi_verifier', 'awi_developer'].includes(loggedUser.userObj.awi_level) && (
				<>
					<HotKeys keyName="ctrl+c" onKeyDown={() => handleCopyAnnotations(annotationsData!.result)}></HotKeys>
					<HotKeys keyName="ctrl+v" onKeyDown={handlePasteAnnotations}></HotKeys>
				</>
			)}

			<div className={styles.annotation__details}>
				<div className={styles.annotation__details_container}>
					<div className={styles.annotation__details__current}>
						<p>
							Image {content_key} ({currentImage + 1}/{totalImages}) , Annotations - {(annotationsData?.result || []).length}
						</p>
						<div
							className={styles.annotation__details__current__actions}
							onClick={event => {
								event.stopPropagation();
								setOpenActionModal(true);
							}}
						>
							<div style={{ width: '24px', height: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
								<img src="/icons/3dots/3dots.png"></img>
							</div>
							{openActionModal && ['awi_annotator', 'awi_verifier'].includes(loggedUser.userObj.awi_level) && (
								<div>
									<div
										onClick={event => {
											event.stopPropagation();
											setOpenActionModal(false);
										}}
										className={clsx(styles.backdrop, styles.backdrop__transparent)}
									></div>
									<div className={styles.actions__modal}>
										<ul>
											{loggedUser.userObj.awi_level === 'awi_verifier' && request.awi_request_for !== 'attributes' && (
												<>
													<li
														onClick={() => {
															handleUpdateAnnotationsStatus(
																(annotationsData?.result || []).map(annotation => annotation.awi_id),
																'approved'
															);
														}}
														style={{ color: '#55A8FD' }}
													>
														Approve all outlines
													</li>
													<li
														onClick={() => {
															handleUpdateAnnotationsStatus(
																(annotationsData?.result || []).map(annotation => annotation.awi_id),
																'rejected'
															);
														}}
														style={{ color: '#EA4D6D' }}
													>
														Reject all outlines
													</li>
												</>
											)}
											{request.awi_request_for !== 'attributes' && (
												<>
													<li onClick={() => handleCopyAnnotations(annotationsData!.result)} style={{ color: '#55A8FD' }}>
														Copy Annotations
													</li>
													<li onClick={handlePasteAnnotations} style={{ color: '#55A8FD' }}>
														Paste Annotations
													</li>
													<li
														onClick={() => {
															handleDeleteAnnotations((annotationsData?.result || []).map(annotation => annotation.awi_id));
														}}
														style={{ color: '#EA4D6D' }}
													>
														Delete Annotation(s)
													</li>
												</>
											)}
										</ul>
									</div>
								</div>
							)}
						</div>
						{/* <div className={styles.annotation__details__current__icons}>
							<img src="/icons/copy.svg" alt="copy annotations" onClick={() => handleCopyAnnotations(annotationsData!.result)} title="Copy all annotations" />
							<img src="/icons/paste.svg" style={{ width: '16px' }} alt="paste annotations" onClick={handlePasteAnnotations} title="Paste annotations" />
							{loggedUser.userObj.awi_level === 'awi_verifier' && (annotationsData?.result || []).length > 0 ? (
								<>
									{(annotationsData?.result || []).filter(annotation => annotation.awi_status !== 'corrected').map(annotation => annotation.awi_id).length >
										0 && (
										<img
											src="/icons/correct.svg"
											alt="approve annotations"
											onClick={() => {
												handleUpdateAnnotationsStatus(
													(annotationsData?.result || []).map(annotation => annotation.awi_id),
													'approved'
												);
											}}
											title="Approve all annotations"
										/>
									)}
									<img
										src="/icons/reject.svg"
										alt="reject annotations"
										onClick={() => {
											handleUpdateAnnotationsStatus(
												(annotationsData?.result || []).map(annotation => annotation.awi_id),
												'rejected'
											);
										}}
										title="Reject all annotations"
									/>
								</>
							) : null}
							{['awi_annotator', 'awi_verifier'].includes(loggedUser.userObj.awi_level) && (
								<img
									src="/icons/delete.svg"
									alt="delete annotations"
									onClick={() => {
										handleDeleteAnnotations((annotationsData?.result || []).map(annotation => annotation.awi_id));
									}}
									title="Delete all annotations"
								/>
							)}
						</div> */}
					</div>
				</div>

				{(annotationsData?.result || []).length > 0 && (
					<div className={styles.annotation__details__annotation_container}>
						{[...(annotationsData?.result || [])].map((ele, index) => {
							return (
								<AnnotationAccordion
									classOptions={request.awi_classes}
									annotationId={ele.awi_id}
									handleDeleteAnnotations={handleDeleteAnnotations}
									classId={ele.awi_classes[0]?.awi_id}
									key={ele.awi_id.toString() + index}
									handleUpdateAnnotationsStatus={handleUpdateAnnotationsStatus}
									status={ele.awi_status}
									handleCopyAnnotations={handleAddCopyAnnotation}
									fetchAnnotationsKey={fetchAnnotationsKey}
									selectIndex={selectIndex}
									handleSelectAnnotationIndex={handleSelectAnnotationIndex}
									handleUpdateCurrentClass={handleUpdateCurrentClass}
									openAccordion={selectIndex === ele.awi_id}
									sessionId={sessionId}
									handleUpdateCurrentClassColor={handleUpdateCurrentClassColor}
									classColor={classColor}
									content_key={content_key}
									annotationNumber={index + 1}
									requestFor={request.awi_request_for}
									annotation_type={request.awi_annotation_type}
								/>
							);
						})}
						{currentImage === totalImages - 1 && ['awi_annotator', 'awi_verifier', 'awi_developer'].includes(loggedUser.userObj.awi_level) && (
							<div className={styles.complete__session}>
								{loggedUser.userObj.awi_level === 'awi_developer' ? 'Complete Reference Image Annotation' : 'Complete session'} :
								<button
									className={styles.complete__session__button}
									disabled={isLoadingSaveSession}
									onClick={async () => {
										if (loggedUser.userObj.awi_level === 'awi_developer') {
											handleCompleteReferenceImageAnnotation();
											return;
										}
										const isSessionComplete = await checkSessionCompleted();
										if (isSessionComplete === -1) {
											handleCompleteverification();
										} else {
											toast.error('Pending annotations are there. Please verify them first.');
										}
									}}
								>
									{'Submit'}
								</button>
							</div>
						)}
					</div>
				)}

				<div className={styles.info__button}>
					<img src="/icons/keyboard-settings-outline.svg" title="See short-cut keys" width="30px" onClick={() => setOpenShortCutKeyModal(prev => !prev)}></img>
					{openShortCutKeyModal && (
						<>
							<div className={styles.short__cut__modal}>
								<TableInfiniteScroll disableResize={true}>
									<TableHeaders>
										<TableRow>
											<TableHeader>S No.</TableHeader>
											<TableHeader>Command</TableHeader>
											<TableHeader>KeyBinding</TableHeader>
										</TableRow>
									</TableHeaders>
									<TableBody>
										{(getShortCutKeys() || []).map((ele, index) => {
											return (
												<TableRow key={index}>
													<TableCell>{index + 1}</TableCell>
													<TableCell>{ele[0]}</TableCell>
													<TableCell>{ele[1]}</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</TableInfiniteScroll>
							</div>
						</>
					)}
				</div>
				{openShortCutKeyModal && <div className={clsx(styles.backdrop, styles.backdrop__transparent)} onClick={() => setOpenShortCutKeyModal(false)}></div>}
			</div>
		</>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps, null)(AnnotationDetails));
