import clsx from 'clsx';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

import { splitRequest } from '../../../handlers/request/splitRequest';
import { AddUserModal } from '../../Common';
import { DatePicker, Input } from '../../Common';

import { useMutation } from '../../../util/useMutation';

import { LoggedInUser, SplitRequestForm } from '../../../types';

import styles from './styles.module.scss';
import { useQueryClient } from 'react-query';

interface SplitRequestModalProps {
	request_id: number;
	requestLabel: string;
	loggedUser: {
		token: string;
		userObj: LoggedInUser;
	};
	setReloadRequestPage: React.Dispatch<React.SetStateAction<any>>;
}

const SplitRequestModal: React.FC<SplitRequestModalProps> = ({ request_id, loggedUser, requestLabel, setReloadRequestPage }) => {
	const queryClient = useQueryClient();
	const [openSplitRequestModal, setOpenSplitRequestModal] = useState<boolean>(false);

	const splitRequestMutation = useMutation(splitRequest);
	const initialFormData = {
		requestName: requestLabel,
		awi_request_type: '',
		outline_deadline: new Date(),
		attribute_deadline: new Date(),
		awi_request_id: request_id
	};

	const [splitRequestData, setSplitRequestData] = useState<SplitRequestForm>(initialFormData);

	const handleCloseModal = () => {
		setOpenSplitRequestModal(false);
		setSplitRequestData(initialFormData);
	};

	const handleCloseSplitRequestModal = () => {
		setOpenSplitRequestModal(false);
	};

	const handleSplitRequest = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		let type = '';
		if (splitRequestData.awi_request_type === 'Outline and Attribute separate') {
			type = 'separate';
		} else if (splitRequestData.awi_request_type === 'Only outline') {
			type = 'outline';
		} else if (splitRequestData.awi_request_type === 'Outline and attribute together') {
			type = 'together';
		} else if (splitRequestData.awi_request_type === 'Only attribute') {
			type = 'attribute';
		}
		splitRequestMutation.mutate(
			{
				...splitRequestData,
				awi_request_type: type
			},
			{
				onSuccess: res => {
					if (res.success === true) {
						toast.success(res.message);
						setReloadRequestPage(true);
					}
				},
				onError: (err: any) => {
					console.log(err, 'err');
					toast.error(err.message || 'Something went wrong');
				}
			}
		);

		handleCloseSplitRequestModal();
		queryClient.invalidateQueries('request');
	};

	const handleInputChange = (id: number, name: string) => {
		if (name === 'awi_request_type') {
			setSplitRequestData(prev => {
				let type = '';
				if (id === 0) {
					type = 'Outline and Attribute separate';
				} else if (id === 1) {
					type = 'Only outline';
				} else if (id === 2) {
					type = 'Outline and attribute together';
				} else if (id === 3) {
					type = 'Only attribute';
				}
				prev[name] = type;
				return { ...prev };
			});
			return;
		}
	};
	const requestTypeData = [
		{
			awi_id: 0,
			awi_label: 'Outline and Attribute separate'
		},
		{
			awi_id: 1,
			awi_label: 'Only outline'
		},
		{
			awi_id: 2,
			awi_label: 'Outline and attribute together'
		},
		{
			awi_id: 3,
			awi_label: 'Only attribute'
		}
	];

	const handleDateChange = (date: Date, type) => {
		setSplitRequestData(prev => {
			const temp = { ...prev };
			if (type === 'outline') {
				temp.outline_deadline = date;
			} else if (type === 'attribute') {
				temp.attribute_deadline = date;
			}

			return { ...temp };
		});
	};

	return (
		<>
			<img
				src="/icons/edit/edit_option.png"
				alt="set request flow"
				width={20}
				height={20}
				onClick={() => {
					setOpenSplitRequestModal(true);
				}}
				title="Set Request Flow"
			/>
			{openSplitRequestModal && (
				<>
					<div className={styles.split__request__modal}>
						<div>
							<div className={styles.modal__close_button} onClick={handleCloseModal}>
								<img src="/icons/close.svg" />
							</div>
							<div style={{ padding: '24px 0' }}>
								<p style={{ fontSize: 24, fontWeight: 500 }}>Update Request</p>
							</div>
							<form onSubmit={handleSplitRequest}>
								<div className={styles.upload__form__grid}>
									<label>Request Label</label>
									<input type={'text'} required name="awi_label" placeholder="Enter Request Label" value={splitRequestData.requestName} disabled />
									<label>Request Flow</label>
									<Input
										placeholder="Select type"
										searchString={splitRequestData.awi_request_type}
										data={requestTypeData}
										onClick={handleInputChange}
										type="drop-down"
										name="awi_request_type"
									/>
									{splitRequestData.awi_request_type == 'Outline and Attribute separate' && (
										<>
											<div>Outline Deadline</div>
											<DatePicker
												value={new Date(splitRequestData.outline_deadline)}
												onChange={(date: Date) => handleDateChange(date, 'outline')}
												dateFormat="MMMM dd, yyyy h:mm aa"
												isTimePicker={true}
												minDate={new Date()}
											/>
											<div>Attribute Deadline</div>
											<DatePicker
												value={new Date(splitRequestData.attribute_deadline)}
												onChange={(date: Date) => handleDateChange(date, 'attribute')}
												dateFormat="MMMM dd, yyyy h:mm aa"
												isTimePicker={true}
												minDate={new Date()}
											/>
										</>
									)}
								</div>
								<div className={styles.modal__buttons}>
									<button disabled={splitRequestData.awi_request_type === ''}>Set Request Flow</button>
								</div>
							</form>
						</div>
					</div>
					<div onClick={handleCloseModal} className={clsx(styles.backdrop)}></div>
				</>
			)}
			{openSplitRequestModal && <div onClick={handleCloseModal} className={clsx(styles.backdrop, styles.backdrop__transparent)}></div>}
		</>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps, null)(SplitRequestModal));
