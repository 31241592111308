import { client } from './../../util/client';
export const postDownloadModel = (modelId: string) => {
	return client('/fetch_model_outputs', {
		method: 'POST',
		baseURL: 'training',
		data: {
			awi_job_uuid: modelId
		}
	}).then(res => {
		return res.data;
	});
};
