import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import UserActions from '../../../redux/actions/UserActions';
import { LoggedInUser } from '../../../types';
import styles from './styles.module.scss';
import Container from '../../Container';
import clsx from 'clsx';

interface NavbarProps {
	loggedUser: {
		token: string;
		userObj: LoggedInUser;
	};
	iconUrl: string;
	title: string;
	description: string;
	messageType?: 'information' | 'warning' | 'error' | 'success';
}

const MessageScreen: React.FC<NavbarProps> = ({ loggedUser, iconUrl, title, description, messageType = 'information' }) => {
	return (
		<div className={styles.message__container}>
			<img src={iconUrl} className={styles.message__container__icon}></img>
			<h1
				className={clsx(styles.message__container__title, {
					[styles.message__container__title__success]: messageType === 'success'
				})}
			>
				{title}
			</h1>
			<p className={styles.message__container__description}>{description}</p>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		loggedIn: state.UserReducer.loggedIn,
		loggedUser: state.UserReducer.loggedUser
	};
};

const mapDispatchToProps = dispatch => {
	return {
		logoutUser: () => dispatch(UserActions.logoutUser())
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MessageScreen));
