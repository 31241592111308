const regexExpression = {
	email:
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	panCard: /^[A-Z]{5}\d{4}[A-Z]{1}$/,
	aadharCard: /^[2-9][0-9]{3}[0-9]{4}[0-9]{4}$/,
	mobileNumber: /^\d{10}$/,
	bankAccountNumber: /^[0-9]{9,18}$/,
	upiId: /^[0-9A-Za-z.-]{2,256}@[A-Za-z]{2,64}$/,
	ifscCode: /^[A-Z]{4}0[A-Z0-9]{6}$/
};

module.exports = {
	regexExpression
};
