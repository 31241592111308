class ReduxActionEnum {
	// User
	static LOGIN_ACTION = 'login';
	static LOGOUT_ACTION = 'logout';

	// General
	static SET_BATCH_ID = 'setBatchID';
	static SET_BATCH_DETAILS = 'setBatchDetails';

	// Settings
	static SET_DARK_MODE = 'setDarkMode';

	static SET_REQUEST_ID = 'setRequestID';
}

export default ReduxActionEnum;
