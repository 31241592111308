import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { TableInfiniteScroll, TableBody, TableCell, TableHeader, TableHeaders, TableRow, Input } from '../../Common';
import { LoggedUser } from '../../../types';
import styles from './styles.module.scss';
import Container from '../../Container';
import { useQuery } from '../../../util/useQuery';
import { fetchModels } from '../../../handlers/models/fetchModels';
import { fetchProjects } from '../../../handlers/projects/fetchProjects';
import AddAppModal from './AddAppModal';
import ModelCell from './ModelCell';

interface DatasetProps {
	loggedUser: LoggedUser;
}

const ModelsPage: React.FC<DatasetProps> = ({ loggedUser }) => {
	const [selectedProject, setSelectedProject] = useState<number>(-1);
	const [isAddAppModalOpen, setIsAddAppModalOpen] = useState<string>('');

	const [fetchProjectsKey, fetchProjectsFunctiobn] = fetchProjects();
	const { data: projects } = useQuery(fetchProjectsKey, fetchProjectsFunctiobn);

	// sort
	const [sortOrders, setSortOrders] = useState<{ [key: string]: number }>({ start_time: 0, end_time: 0, status: 0, accuracy: 0 });

	const [fetchModelsKey, fetchModelsFunction] = fetchModels({
		awi_project_name: (projects?.result || []).find(project => project.awi_id === selectedProject)?.awi_label || '',
		awi_status: 'succeeded',
		start_time: sortOrders.start_time,
		end_time: sortOrders.end_time,
		status: sortOrders.status,
		accuracy: sortOrders.accuracy
	});
	const { data: modelsData } = useQuery(fetchModelsKey, fetchModelsFunction, { enabled: selectedProject !== -1 });

	const handleProjectSelection = (id: number, name: string) => {
		if (name === 'awi_project') {
			setSelectedProject(id);
		}
	};

	if (!['awi_developer'].includes(loggedUser.userObj.awi_level)) {
		return <Redirect to="/"></Redirect>;
	}

	useEffect(() => {
		setSelectedProject(projects?.result?.[0]?.awi_id || -1);
	}, [projects]);

	const handleHeaderSortClick = (name: string) => {
		const newSortOrders = { ...sortOrders };
		if (newSortOrders[name] === 0) {
			newSortOrders[name] = 1;
		} else if (newSortOrders[name] === 1) {
			newSortOrders[name] = -1;
		} else if (newSortOrders[name] === -1) {
			newSortOrders[name] = 0;
		}
		setSortOrders(newSortOrders);
	};

	return (
		<Container className={styles.root__container}>
			{/* {downloadDatasetMutation.isLoading && (
				<div className={styles.loader}>
					<Loader></Loader>
				</div>
			)} */}

			<div className={styles.model__header}>
				<span>
					Project Name :{' '}
					<span style={{ color: '#55a8fd' }}>{(projects?.result || []).find(project => project.awi_id === selectedProject)?.awi_label || ''}</span>
				</span>
				<span className={styles.model__header__input}>
					Project :
					<Input
						placeholder="Select Project"
						searchString={projects?.result?.find(project => project.awi_id === selectedProject)?.awi_label || ''}
						data={(projects?.result || [])?.map(project => {
							return { awi_id: project.awi_id, awi_label: project.awi_label };
						})}
						onClick={handleProjectSelection}
						type="drop-down"
						name="awi_project"
					/>
				</span>
			</div>
			<div className={styles.model__panel__container}>
				<TableInfiniteScroll>
					<TableHeaders className={styles.model__panel__header}>
						<TableRow>
							<TableHeader>
								<></>
							</TableHeader>
							<TableHeader>Model Name</TableHeader>
							<TableHeader>Project Name</TableHeader>
							<TableHeader>Architecture</TableHeader>
							<TableHeader>
								<div
									onClick={() => {
										handleHeaderSortClick('start_time');
									}}
								>
									<span>
										START TIME
										{sortOrders.start_time === 0 ? (
											<img src="./icons/sorting/neutral.svg" className={styles.model__panel__header__arrow}></img>
										) : sortOrders.start_time === 1 ? (
											<img src="./icons/sorting/asc.svg" className={styles.model__panel__header__arrow}></img>
										) : (
											<img src="./icons/sorting/dsc.svg" className={styles.model__panel__header__arrow}></img>
										)}
									</span>
								</div>
							</TableHeader>
							<TableHeader>
								<div
									onClick={() => {
										handleHeaderSortClick('end_time');
									}}
								>
									<span>
										END TIME
										{sortOrders.end_time === 0 ? (
											<img src="./icons/sorting/neutral.svg" className={styles.model__panel__header__arrow}></img>
										) : sortOrders.end_time === 1 ? (
											<img src="./icons/sorting/asc.svg" className={styles.model__panel__header__arrow}></img>
										) : (
											<img src="./icons/sorting/dsc.svg" className={styles.model__panel__header__arrow}></img>
										)}
									</span>
								</div>
							</TableHeader>
							<TableCell>
								<div
									onClick={() => {
										handleHeaderSortClick('status');
									}}
								>
									<span>
										STATUS
										{sortOrders.status === 0 ? (
											<img src="./icons/sorting/neutral.svg" className={styles.model__panel__header__arrow}></img>
										) : sortOrders.status === 1 ? (
											<img src="./icons/sorting/asc.svg" className={styles.model__panel__header__arrow}></img>
										) : (
											<img src="./icons/sorting/dsc.svg" className={styles.model__panel__header__arrow}></img>
										)}
									</span>
								</div>
							</TableCell>
							<TableCell>
								<div
									onClick={() => {
										handleHeaderSortClick('accuracy');
									}}
								>
									<span>
										TEST ACCURACY
										{sortOrders.accuracy === 0 ? (
											<img src="./icons/sorting/neutral.svg" className={styles.model__panel__header__arrow}></img>
										) : sortOrders.accuracy === 1 ? (
											<img src="./icons/sorting/asc.svg" className={styles.model__panel__header__arrow}></img>
										) : (
											<img src="./icons/sorting/dsc.svg" className={styles.model__panel__header__arrow}></img>
										)}
									</span>
								</div>
							</TableCell>
							<TableCell>ASSOCIATED APPS</TableCell>
							<TableCell>OUTPUT</TableCell>
						</TableRow>
					</TableHeaders>
					<TableBody>
						{(modelsData?.result || []).map((model, index) => {
							return <ModelCell key={index} index={index} model={model} selectedProject={selectedProject} setIsAddAppModalOpen={setIsAddAppModalOpen} />;
						})}
					</TableBody>
				</TableInfiniteScroll>
			</div>
			{isAddAppModalOpen !== '' && <AddAppModal fetchModelsKey={fetchModelsKey} modelId={isAddAppModalOpen} closeModal={() => setIsAddAppModalOpen('')} />}
		</Container>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps, null)(ModelsPage));
