import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { TableInfiniteScroll, TableBody, TableCell, TableHeader, TableHeaders, TableRow, Input } from '../../Common';
import { LoggedUser } from '../../../types';
import Container from '../../Container';
import { useLocation } from 'react-router-dom';

interface DatasetProps {
	loggedUser: LoggedUser;
}

const Counts: React.FC<DatasetProps> = ({ loggedUser }) => {
	const location = useLocation();
	const { awi_prices, awi_counts, awi_user_name, awi_count, awi_user_level } = location.state;

	return (
		<Container>
			<div>Username : {awi_user_name}</div>
			{awi_user_level === 'awi_annotator' ? (
				<TableInfiniteScroll>
					<TableHeaders>
						<TableHeader>S.No.</TableHeader>
						<TableHeader>Attribute</TableHeader>
						<TableHeader>Pending</TableHeader>
						<TableHeader>Approved</TableHeader>
						<TableHeader>Rejected</TableHeader>
						<TableHeader>Corrected</TableHeader>
					</TableHeaders>
					<TableBody>
						{awi_counts?.map((attrObj, classIndex) => {
							return (
								<>
									<TableRow key={classIndex}>
										<TableCell>{classIndex + 1}</TableCell>
										<TableCell>{awi_prices.awi_attributes.find(ele => ele.awi_attribute_id === attrObj.awi_attribute_id)?.awi_attribute_label}</TableCell>
										<TableCell>
											{Math.round(
												(attrObj.awi_statuswise_distribution.find(ele => ele.awi_attribute_status === 'pending')?.awi_attribute_count || 0) *
													(awi_count ? 1 : awi_prices.awi_attributes.find(ele => ele.awi_attribute_id === attrObj.awi_attribute_id)?.awi_pending_cost) *
													100
											) / 100}
										</TableCell>
										<TableCell>
											{Math.round(
												(attrObj.awi_statuswise_distribution.find(ele => ele.awi_attribute_status === 'approved')?.awi_attribute_count ||
													0 * (awi_count ? 1 : awi_prices.awi_attributes.find(ele => ele.awi_attribute_id === attrObj.awi_attribute_id)?.awi_pending_cost)) *
													100
											) / 100}
										</TableCell>
										<TableCell>
											{Math.round(
												((attrObj.awi_statuswise_distribution.find(ele => ele.awi_attribute_status === 'rejected')?.awi_attribute_count || 0) *
													(awi_count ? 1 : awi_prices.awi_attributes.find(ele => ele.awi_attribute_id === attrObj.awi_attribute_id)?.awi_rejected_cost) +
													(attrObj.awi_statuswise_distribution.find(ele => ele.awi_attribute_status === 'rejected')?.awi_attribute_count ||
														0 * (awi_count ? 1 : awi_prices.awi_attributes.find(ele => ele.awi_attribute_id === attrObj.awi_attribute_id)?.awi_pending_cost))) *
													100
											) / 100}
										</TableCell>
										<TableCell>
											{Math.round(
												((attrObj.awi_statuswise_distribution.find(ele => ele.awi_attribute_status === 'corrected')?.awi_attribute_count || 0) *
													(awi_count ? 1 : awi_prices.awi_attributes.find(ele => ele.awi_attribute_id === attrObj.awi_attribute_id)?.awi_corrected_cost) +
													(attrObj.awi_statuswise_distribution.find(ele => ele.awi_attribute_status === 'corrected')?.awi_attribute_count ||
														0 * (awi_count ? 1 : awi_prices.awi_attributes.find(ele => ele.awi_attribute_id === attrObj.awi_attribute_id)?.awi_pending_cost))) *
													100
											) / 100}
										</TableCell>
									</TableRow>
								</>
							);
						})}
					</TableBody>
				</TableInfiniteScroll>
			) : (
				<TableInfiniteScroll>
					<TableHeaders>
						<TableHeader>S.No.</TableHeader>
						<TableHeader>Attribute</TableHeader>
						<TableHeader>Verified</TableHeader>
					</TableHeaders>
					<TableBody>
						{awi_counts?.map((attrObj, classIndex) => {
							return (
								<>
									<TableRow key={classIndex}>
										<TableCell>{classIndex + 1}</TableCell>
										<TableCell>{awi_prices.awi_attributes.find(ele => ele.awi_attribute_id === attrObj.awi_attribute_id)?.awi_attribute_label}</TableCell>
										<TableCell>
											{Math.round(
												(attrObj.awi_attribute_count || 0) *
													(awi_count ? 1 : awi_prices.awi_attributes.find(ele => ele.awi_attribute_id === attrObj.awi_attribute_id)?.awi_verified_cost) *
													100
											) / 100}
										</TableCell>
									</TableRow>
								</>
							);
						})}
					</TableBody>
				</TableInfiniteScroll>
			)}
		</Container>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps, null)(Counts));
