import { client } from './../../util/client';
import { CommonResponse } from './../../types/common.d';
import { Organisation } from './../../types/organisation.d';

type OrganisationResponse = {
	result: Organisation;
} & CommonResponse;

export const postOrganisation = (data: { awi_label: string; awi_email: string }) =>
	client<OrganisationResponse>('/create_new_organisation', { baseURL: 'org', method: 'POST', data }).then(res => res.data);
