import { client } from './../../util/client';

type UploadImageResponse = {
	success: boolean;
	message: string;
	result?: any[];
};

export const postImages = ({ data, uploadProgress }: { data: FormData; uploadProgress?: (progressEvent: any, timeStarted) => void }) => {
	const startTime = new Date();

	return client<UploadImageResponse>('/upload_images', {
		baseURL: 'content',
		onUploadProgress: progressEvent => {
			if (typeof uploadProgress === 'function') uploadProgress(progressEvent, startTime);
		},
		data,
		method: 'POST'
	}).then(res => res.data);
};
