import { client } from '../../util/client';

type fetchNotificationsResponse = {
	success: boolean;
	result: any;
	message?: string;
	msg?: string;
};

export const fetchNotifications = (data: { awi_user_id: number }) => {
	const queryKey = ['notifications', data] as const;
	const queryFunction = () =>
		client<fetchNotificationsResponse>('/fetch_notifications', { baseURL: 'notification', data, method: 'POST' }).then(res => res.data);

	return [queryKey, queryFunction] as const;
};
