import { DatasetFilterType } from '../../types/dataset';
import { client } from './../../util/client';

type getDatasetResponse = {
	success: boolean;
	result: any[];
	msg?: string;
	next?: string;
};

export const getDatasets = (data: DatasetFilterType) => {
	const queryKey = ['datasets', data] as const;
	const queryFunction = ({ pageParam }: { pageParam?: any }) =>
		client<getDatasetResponse>('/get_datasets', {
			baseURL: 'dataset',
			method: 'POST',
			data: { ...data },
			params: {
				cursor: pageParam
			}
		}).then(res => res.data);

	return [queryKey, queryFunction] as const;
};
