import { client } from './../../util/client';
export const getUsers = async (
	data: { roles: string[]; get_stats: boolean; searchUserString?: string; role?: string; verified_users?: boolean } = {
		roles: [],
		get_stats: false,
		searchUserString: '',
		role: '',
		verified_users: false
	}
) =>
	await client('fetch_users', {
		method: 'POST',
		baseURL: 'user',
		data
	}).then(res => res.data);
