import { client } from './../../util/client';

type getDatasetResponse = {
	success: boolean;
	signed_url: string;
	msg?: string;
};

export const exportDatasetFromDatasetPage = (data: { awi_dataset_id: number }) => {
	return client<getDatasetResponse>('/export_dataset_from_dataset_page', { baseURL: 'dataset', method: 'POST', data: { ...data } }).then(res => res.data);
};
