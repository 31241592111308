import { client } from '../../util/client';

type getDatasetClassesResponse = {
	success: boolean;
	result: any;
};

export const getDatasetClasses = (data: { awi_dataset_id: number | undefined }) => {
	const queryKey = ['dataset-classes', data] as const;
	const queryFunction = () =>
		client<getDatasetClassesResponse>('/get_dataset_classes', { baseURL: 'dataset', method: 'POST', data: { ...data } }).then(res => res.data);

	return [queryKey, queryFunction] as const;
};
