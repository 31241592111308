import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { login, LoginData } from '../../../handlers/user/login';
import { useMutation } from '../../../util/useMutation';
import { Link } from 'react-router-dom';

import UserActions from '../../../redux/actions/UserActions';

import styles from './styles.module.scss';
import { passwordResetInvitation } from '../../../handlers/user/passwordResetInvitation';
import { updatePassword } from '../../../handlers/user';
import { resetPassword } from '../../../handlers/user/resetPassword';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { googleSignup } from '../../../handlers/user/googleAuth';
import { signupUser } from '../../../handlers/user/signupUser';
// import { googleLogout, useGoogleLogin } from '@react-oauth/google';

interface LoginUserProps {
	loginUser: (data) => any;
}

interface pageInterface {
	currentProcess: 'login' | 'resetpassword' | 'forgotpassword' | 'signup';
}
const LoginUser: React.FC<LoginUserProps> = ({ loginUser }) => {
	const search = useLocation().search;
	const pathname = useLocation().pathname;

	const [currentProcess, setCurrentProcess] = useState<pageInterface['currentProcess']>('login');

	const [successfulTask, setSuccessfulTask] = useState<'' | 'forgotpasswordinvitation' | 'resetpassword'>('');

	useEffect(() => {
		if (pathname === '/resetpassword') {
			setCurrentProcess('resetpassword');
		}
	}, [pathname]);

	const token = new URLSearchParams(search).get('token');

	const [loginCreds, setLoginCreds] = useState<LoginData>({
		awi_email: '',
		awi_password: '',
		awi_forgotpassword_email: '',
		awi_reset_password: '',
		awi_reset_confirmpassword: '',
		awi_agreement_accepted: false
	});

	const [showPassword, setShowPassword] = useState({
		loginpassword: false,
		resetpassword: false,
		resetconfirmpassword: false,
		signuppassword: false,
		confirmsignuppassword: false
	});

	const [signupDetails, setSignupDetails] = useState({
		email: '',
		password: '',
		confirmPassword: '',
		acceptedPolicies: false
	});

	const loginMutation = useMutation(login);
	const googleSignupMutation = useMutation(googleSignup);
	const resetPasswordMutation = useMutation(resetPassword);
	const signupUserMutation = useMutation(signupUser);

	const passwordResetInvitationMutation = useMutation(passwordResetInvitation);

	// const googleLogin = useGoogleLogin({
	// 	onSuccess: codeResponse => console.log('Login Success:', codeResponse),

	// 	onError: error => console.log('Login Failed:', error)
	// });

	// const googleLogin = useGoogleLogin({
	// 	onSuccess: codeResponse => {
	// 		console.log('Login Success:', codeResponse);
	// 		googleSignupMutation.mutate(
	// 			{
	// 				awi_google_access_token: codeResponse.access_token
	// 			},
	// 			{
	// 				onSuccess: res => {
	// 					localStorage.setItem('token', res.token);
	// 					loginUser({ token: res.token, userObj: res.userObj });
	// 					toast.success(res.message);
	// 				},
	// 				onError: (err: any) => {
	// 					const errMessage = err.response?.data.message;
	// 					toast.error(errMessage);
	// 				}
	// 			}
	// 		);
	// 	},
	// 	onError: error => console.log('Login Failed:', error)
	// });

	const handleFormSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		if (!loginCreds.awi_agreement_accepted) {
			toast.error('Please accept the terms and conditions');
			return;
		}
		loginMutation.mutate(loginCreds, {
			onSuccess: res => {
				localStorage.setItem('token', res.token);
				if (
					res.userObj.awi_verification_status &&
					res.userObj.awi_verification_status !== 'approved' &&
					(res.userObj.awi_level === 'awi_annotator' || res.userObj.awi_level === 'awi_verifier')
				) {
					window.location.href = '/onboarding';
					return;
				}

				loginUser({ token: res.token, userObj: res.userObj });
				toast.success(res.message);
			},
			onError: (err: any) => {
				const errMessage = err.response?.data.message;
				toast.error(errMessage);
			}
		});
	};

	const handleForgotPasswordClick = () => {
		setCurrentProcess('forgotpassword');
	};

	const handleBackButtonClick = () => {
		setCurrentProcess('login');
	};

	const handleForgotPasswordSubmit = () => {
		passwordResetInvitationMutation.mutate(
			{
				awi_forgotpassword_email: loginCreds.awi_forgotpassword_email
			},
			{
				onSuccess: res => {
					if (!res.success) {
						toast.error(res.msg || 'Could not send reset password link');
						return;
					}
					setSuccessfulTask('forgotpasswordinvitation');
				},
				onError: (err: any) => {
					const errMessage = err.response?.data.message;
					toast.error(errMessage);
					console.log(err, 'err');
				}
			}
		);
	};

	const handleResetPassword = () => {
		if (loginCreds.awi_reset_password !== loginCreds.awi_reset_confirmpassword) {
			toast.error('Passwords do not match');
			return;
		}

		resetPasswordMutation.mutate(
			{
				awi_reset_password: loginCreds.awi_reset_password,
				awi_reset_confirmpassword: loginCreds.awi_reset_confirmpassword,
				token: token || ''
			},
			{
				onSuccess: res => {
					setSuccessfulTask('resetpassword');
				},
				onError: (err: any) => {
					const errMessage = err.response?.data.message;
					toast.error(errMessage || 'Could not reset password');
				}
			}
		);
	};

	const handleSignUp = () => {
		if (!signupDetails.acceptedPolicies) {
			toast.error('Please accept the terms and conditions');
			return;
		}

		if (signupDetails.password !== signupDetails.confirmPassword) {
			toast.error('Passwords do not match');
			return;
		}

		signupUserMutation.mutate(
			{
				awi_email: signupDetails.email,
				awi_password: signupDetails.password
			},
			{
				onSuccess: res => {
					toast.success(res.message);
				},
				onError: (err: any) => {
					const errMessage = err.response?.data.message;
					toast.error(errMessage);
				}
			}
		);
	};

	const handleInputChange = (event: React.ChangeEvent) => {
		setLoginCreds(prev => {
			const temp: any = { ...prev };
			temp[(event.target as HTMLInputElement).name as keyof LoginData] = (event.target as HTMLInputElement).value;
			return { ...temp };
		});
	};

	const handleSignupDetailsChange = (event: React.ChangeEvent) => {
		setSignupDetails(prev => {
			const temp: any = { ...prev };
			temp[(event.target as HTMLInputElement).name] = (event.target as HTMLInputElement).value;
			return { ...temp };
		});
	};

	const toggleShowPassword = name => {
		setShowPassword({
			...showPassword,
			[name]: !showPassword[name]
		});
	};

	return (
		<section className={styles.login__container}>
			{successfulTask !== 'resetpassword' ? (
				<div>
					<div className={styles.login__logo}>
						<img width="100%" height="100%" alt="atom logo" src="/AtomLogo.svg" />
					</div>
					<div className={styles.login__form_container}>
						<div className={styles.login__form_container__header}>
							{currentProcess === 'login' && (
								<>
									<h2>Sign In</h2>
									<p>Use your e-mail ID and password for login.</p>
								</>
							)}
							{currentProcess === 'forgotpassword' && (
								<>
									<h2>Forgot Password?</h2>
									{successfulTask === 'forgotpasswordinvitation' ? (
										<div className={styles.login__form_container__forgotpassword}>
											<img className={styles.login__form_container__forgotpassword__tick} src="/icons/tick/resetpasswordtick.png"></img>
											<div className={styles.login__form_container__forgotpassword__message}>
												A Reset Password link has been shared on your registered e-mail address.
											</div>
										</div>
									) : (
										<p>Enter your registered email ID.</p>
									)}
								</>
							)}
							{currentProcess === 'resetpassword' && (
								<>
									<h2>Reset Password</h2>
								</>
							)}
							{currentProcess === 'signup' && (
								<>
									<h2>Sign Up</h2>
								</>
							)}
						</div>
						<form className={styles.login__form} onSubmit={handleFormSubmit}>
							{currentProcess === 'login' && (
								<>
									<div className={styles.login__form__password}>
										<input
											value={loginCreds.awi_email}
											onChange={handleInputChange}
											name="awi_email"
											className={styles.login__form__input}
											required
											type="email"
											placeholder="E-mail"
										/>
									</div>
									<div className={styles.login__form__password}>
										<input
											value={loginCreds.awi_password}
											onChange={handleInputChange}
											name="awi_password"
											required
											type={showPassword.loginpassword ? 'text' : 'password'}
											placeholder="Password"
										/>
										<div className={styles.login__form__password__show}>
											{showPassword.loginpassword ? (
												<img
													width="100%"
													onClick={() => toggleShowPassword('loginpassword')}
													height="100%"
													src="/icons/password/hide.png"
													alt="hide password"
												/>
											) : (
												<img
													width="100%"
													onClick={() => toggleShowPassword('loginpassword')}
													height="100%"
													src="/icons/password/show.png"
													alt="show password"
												/>
											)}
										</div>
									</div>
									<div className={styles.login__form__forgotpassword}>
										<span onClick={handleForgotPasswordClick}>Forgot Password</span>
									</div>

									<div className={styles.login__form__agreement}>
										<input
											type="checkbox"
											id="agreement"
											name="agreement"
											checked={loginCreds.awi_agreement_accepted}
											className={styles.login__form__agreement__input}
											onChange={() =>
												setLoginCreds(prev => {
													const temp: any = { ...prev };
													temp.awi_agreement_accepted = !temp.awi_agreement_accepted;
													return { ...temp };
												})
											}
										/>
										<span>
											I have accepted the{' '}
											<a target="_blank" href="/docs/Terms_of_use.pdf" className={styles.login__form__agreement__links}>
												Terms of Use
											</a>{' '}
											/{' '}
											<a target="_blank" href="/docs/awidit_privacy_policy.pdf" className={styles.login__form__agreement__links}>
												Privacy Policy
											</a>
										</span>
									</div>
								</>
							)}
							{currentProcess === 'forgotpassword' && (
								<>
									<div className={styles.login__form__password}>
										<input
											value={loginCreds.awi_forgotpassword_email}
											onChange={handleInputChange}
											name="awi_forgotpassword_email"
											className={styles.login__form__input}
											required
											type="email"
											placeholder="Email ID"
										/>
									</div>
								</>
							)}

							{currentProcess === 'resetpassword' && (
								<>
									<div className={styles.login__form__password}>
										<input
											value={loginCreds.awi_reset_password}
											onChange={handleInputChange}
											name="awi_reset_password"
											required
											type={showPassword.resetpassword ? 'text' : 'password'}
											placeholder="New Password"
										/>
										<div className={styles.login__form__password__show}>
											{showPassword.resetpassword ? (
												<img
													width="100%"
													onClick={() => toggleShowPassword('resetpassword')}
													height="100%"
													src="/icons/password/hide.png"
													alt="hide password"
												/>
											) : (
												<img
													width="100%"
													onClick={() => toggleShowPassword('resetpassword')}
													height="100%"
													src="/icons/password/show.png"
													alt="show password"
												/>
											)}
										</div>
									</div>
									<div className={styles.login__form__password}>
										<input
											value={loginCreds.awi_reset_confirmpassword}
											onChange={handleInputChange}
											name="awi_reset_confirmpassword"
											required
											type={showPassword.resetconfirmpassword ? 'text' : 'password'}
											placeholder="Confirm New Password"
										/>
										<div className={styles.login__form__password__show}>
											{showPassword.resetconfirmpassword ? (
												<img
													width="100%"
													onClick={() => toggleShowPassword('resetconfirmpassword')}
													height="100%"
													src="/icons/password/hide.png"
													alt="hide password"
												/>
											) : (
												<img
													width="100%"
													onClick={() => toggleShowPassword('resetconfirmpassword')}
													height="100%"
													src="/icons/password/show.png"
													alt="show password"
												/>
											)}
										</div>
									</div>
								</>
							)}
							{currentProcess === 'signup' && (
								<div className={styles.signup}>
									{/* <button onClick={() => googleLogin()}>Sign in with Google 🚀 </button>
									<div className={styles.signup__partition}>
										<hr></hr>
										<span className={styles.signup__partition__OR}>OR</span>
									</div> */}
									<div className={styles.signup__form}>
										<div className={styles.login__form__password}>
											<input
												value={signupDetails.email}
												onChange={handleSignupDetailsChange}
												name="email"
												className={styles.login__form__input}
												required
												type="email"
												placeholder="E-mail"
											/>
										</div>
										<div className={styles.login__form__password}>
											<input
												value={signupDetails.password}
												onChange={handleSignupDetailsChange}
												name="password"
												required
												type={showPassword.signuppassword ? 'text' : 'password'}
												placeholder="Password"
											/>
											<div className={styles.login__form__password__show}>
												{showPassword.signuppassword ? (
													<img
														width="100%"
														onClick={() => toggleShowPassword('signuppassword')}
														height="100%"
														src="/icons/password/hide.png"
														alt="hide password"
													/>
												) : (
													<img
														width="100%"
														onClick={() => toggleShowPassword('signuppassword')}
														height="100%"
														src="/icons/password/show.png"
														alt="show password"
													/>
												)}
											</div>
										</div>
										<div className={styles.login__form__password}>
											<input
												value={signupDetails.confirmPassword}
												onChange={handleSignupDetailsChange}
												name="confirmPassword"
												required
												type={showPassword.confirmsignuppassword ? 'text' : 'password'}
												placeholder="Confirm Password"
											/>
											<div className={styles.login__form__password__show}>
												{showPassword.confirmsignuppassword ? (
													<img
														width="100%"
														onClick={() => toggleShowPassword('confirmsignuppassword')}
														height="100%"
														src="/icons/password/hide.png"
														alt="hide password"
													/>
												) : (
													<img
														width="100%"
														onClick={() => toggleShowPassword('confirmsignuppassword')}
														height="100%"
														src="/icons/password/show.png"
														alt="show password"
													/>
												)}
											</div>
										</div>
										<div className={styles.signup__aggrement}>
											<input
												onChange={handleSignupDetailsChange}
												name="acceptedPolicies"
												required
												type="checkbox"
												checked={signupDetails.acceptedPolicies}
												placeholder="Aggrement"
												className={styles.signup__links__checkbox}
											/>
											I have accepted the <span className={styles.signup__links}>Terms of Use</span> /{' '}
											<span className={styles.signup__links}>Privacy Policy</span>
										</div>
									</div>
								</div>
							)}
							<div className={styles.login__form__buttons}>
								{currentProcess === 'login' && (
									<>
										<button type="submit">Sign In</button>
									</>
								)}
								{currentProcess === 'forgotpassword' && (
									<>
										<button type="button" onClick={handleBackButtonClick}>
											Back
										</button>
										<button
											type="button"
											disabled={successfulTask === 'forgotpasswordinvitation'}
											style={{ backgroundColor: successfulTask === 'forgotpasswordinvitation' ? 'gray' : '' }}
											onClick={handleForgotPasswordSubmit}
										>
											Submit
										</button>
									</>
								)}
								{currentProcess === 'resetpassword' && (
									<>
										<button type="button" onClick={handleResetPassword}>
											Submit
										</button>
									</>
								)}

								{currentProcess === 'signup' && (
									<>
										<button type="button" onClick={handleSignUp}>
											Submit
										</button>
									</>
								)}
							</div>

							<div className={styles.form__end__options}>
								{currentProcess === 'signup' && (
									<>
										Already have an account?{' '}
										<span className={styles.signup__links} onClick={() => setCurrentProcess('login')}>
											Sign in
										</span>
									</>
								)}
							</div>
						</form>
					</div>
				</div>
			) : (
				<div className={styles.login__resetsuccess}>
					<img className={styles.login__resetsuccess__tick} src="/icons/tick/resetpasswordtick.png"></img>
					<div className={styles.login__resetsuccess__title}>Password updated!</div>
					<div className={styles.login__resetsuccess__description}>Your password has been changed successfully. Use your new password to login.</div>
					{/* <Link
						to={{
							pathname: `/login`
						}}
					>
						<button className={styles.login__resetsuccess__button}> Go to login page</button>
					</Link> */}
				</div>
			)}
		</section>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		loginUser: data => dispatch(UserActions.loginUser(data))
	};
};

export default connect(null, mapDispatchToProps)(LoginUser);
