import clsx from 'clsx';

import { getRequestFilters } from '../../../handlers/request/getRequestFilters';

import { AnnotationType, MiniUser, RequestFilterType, RequestStatusType, RequestType, userLevel } from '../../../types';

import { DatePicker, Input } from '../../Common';
import { useQuery } from '../../../util/useQuery';
import { getFullName } from '../../../util/common.utils';
import { annotationData, assignedUserLevelData, requestType, statusData } from './utils';

import styles from './styles.module.scss';

interface RequestFilterProps {
	awi_user_level: userLevel;
	toggleShowFilter: () => void;
	handleUpdateFilter: (id: number, name: string) => void;
	filter: RequestFilterType;
	handleDateChange: (date: Date, name: string) => void;
	handleDeadlineCheck: (e: React.ChangeEvent<HTMLInputElement>) => void;
	assignedUserLevel: string;
	handleResetFilter: () => void;
	handleApplyButtonClick: () => void;
}

const RequestFilter: React.FC<RequestFilterProps> = ({
	awi_user_level,
	toggleShowFilter,
	handleUpdateFilter,
	filter,
	handleDateChange,
	handleDeadlineCheck,
	assignedUserLevel,
	handleResetFilter,
	handleApplyButtonClick
}) => {
	const [getRequestFilterMetricKey, getRequestFilterMetricFunction] = getRequestFilters();
	const { data: requestFilterMetric } = useQuery(getRequestFilterMetricKey, getRequestFilterMetricFunction);

	return (
		<>
			<div className={clsx(styles.backdrop, styles.backdrop__transparent)} onClick={toggleShowFilter}></div>
			<div className={styles.requests__filter}>
				<div className={styles.requests__filter__heading}>Filters</div>

				<div className={styles.requests__filter__grid}>
					{!(awi_user_level === 'awi_annotator') && (
						<>
							<label htmlFor="user">Created By</label>
							<Input
								placeholder="Select Users"
								searchString={requestFilterMetric?.result[0].awi_users
									?.filter(user => filter.awi_created_by.includes(user.awi_id))
									.map(user => getFullName(user as MiniUser))
									.join(', ')}
								data={requestFilterMetric?.result[0].awi_users
									.filter(user => user.awi_level === 'awi_developer')
									?.map(user => {
										return { awi_id: user.awi_id, awi_label: getFullName(user as MiniUser) };
									})}
								selectedData={requestFilterMetric?.result[0].awi_users
									?.filter(user => filter.awi_created_by.includes(user.awi_id))
									?.map(user => {
										return user.awi_id;
									})}
								onClick={handleUpdateFilter}
								type="drop-down"
								name="awi_created_by"
							/>
						</>
					)}

					<label htmlFor="classes">Classes</label>
					<Input
						placeholder="Select Classes"
						searchString={requestFilterMetric?.result[0].awi_classes
							?.filter(ele => filter.awi_class_ids.includes(ele.awi_id))
							.map(ele => ele.awi_label)
							.join(', ')}
						data={requestFilterMetric?.result[0].awi_classes?.map(ele => {
							return { awi_id: ele.awi_id, awi_label: ele.awi_label };
						})}
						onClick={handleUpdateFilter}
						type="drop-down"
						name="awi_class_ids"
					/>

					<label htmlFor="Annotation Type">Annotation Type</label>
					<Input
						placeholder="Select Annotation"
						searchString={annotationData
							?.filter(ele => filter.awi_annotation_type.includes(ele.awi_label as AnnotationType))
							.map(ele => ele.awi_label)
							.join(', ')}
						data={annotationData?.map(ele => {
							return { awi_id: ele.awi_id, awi_label: ele.awi_label };
						})}
						onClick={handleUpdateFilter}
						type="drop-down"
						name="awi_annotation_type"
					/>

					<label htmlFor="Request Type">Request Type</label>
					<Input
						placeholder="Select Request Tyoe"
						searchString={requestType
							?.filter(ele => filter.awi_req_type!.includes(ele.awi_label as RequestType))
							.map(ele => ele.awi_label)
							.join(', ')}
						data={requestType?.map(ele => {
							return { awi_id: ele.awi_id, awi_label: ele.awi_label };
						})}
						onClick={handleUpdateFilter}
						type="drop-down"
						name="awi_req_type"
					/>

					<label htmlFor="Status Type">Status Types</label>
					<Input
						placeholder="Select Status Types"
						searchString={statusData
							?.filter(ele => filter.awi_status.includes(ele.awi_label as RequestStatusType))
							.map(ele => ele.awi_label)
							.join(', ')}
						data={statusData?.map(ele => {
							return { awi_id: ele.awi_id, awi_label: ele.awi_label };
						})}
						onClick={handleUpdateFilter}
						type="drop-down"
						name="awi_status"
					/>

					<div>Start date</div>
					<DatePicker
						value={filter.awi_start_date}
						onChange={(date: Date) => handleDateChange(date, 'awi_start_date')}
						dateFormat="MMMM dd, yyyy"
						maxDate={filter.awi_deadline_date || new Date()}
					/>

					<div>Deadline date</div>
					<DatePicker
						value={filter.awi_deadline_date}
						onChange={(date: Date) => handleDateChange(date, 'awi_deadline_date')}
						dateFormat="MMMM dd, yyyy"
						maxDate={new Date()}
						minDate={filter.awi_start_date || new Date(2000, 0, 0)}
					/>

					<label htmlFor="Status Type" style={{ marginBottom: '5px' }}>
						Deadline crossed
					</label>
					<label className={styles.switch}>
						<input type="checkbox" checked={filter.awi_deadline_crossed} onChange={handleDeadlineCheck}></input>
						<span className={clsx(styles.slider, styles.round)}></span>
					</label>
					{!['awi_annotator', 'awi_verifier'].includes(awi_user_level) && (
						<>
							<label htmlFor="Assigned Users">Assigned Users</label>
							<Input
								placeholder="Select Level"
								searchString={assignedUserLevel}
								data={assignedUserLevelData}
								onClick={handleUpdateFilter}
								type="drop-down"
								name="awi_user_assigned_level"
							/>
						</>
					)}
					<label></label>
					{assignedUserLevel !== 'select level' && assignedUserLevel === assignedUserLevel && (
						<Input
							placeholder="Select Users"
							searchString={requestFilterMetric?.result[0].awi_users
								?.filter(user => filter.awi_user_assigned.includes(user.awi_id))
								.map(user => getFullName(user as MiniUser))
								.join(', ')}
							data={requestFilterMetric?.result[0].awi_users
								.filter(user => user.awi_level === assignedUserLevel)
								?.map(user => {
									return { awi_id: user.awi_id, awi_label: getFullName(user as MiniUser) };
								})}
							onClick={handleUpdateFilter}
							type="drop-down"
							name="awi_user_assigned"
							selectedData={filter.awi_user_assigned}
						/>
					)}
				</div>

				<div className={styles.requests__filter__buttons}>
					<button onClick={handleApplyButtonClick}>Apply</button>
					<button onClick={handleResetFilter}>Reset</button>
				</div>
			</div>
		</>
	);
};

export default RequestFilter;
