import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { fetchAnnotations } from '../../../handlers/annotation/fetchAnnotations';

import { Content, LoggedUser } from '../../../types';

import { getImageKitUrl } from '../../../util/common.utils';
import { useQuery } from '../../../util/useQuery';

import styles from './styles.module.scss';

interface AnnotationPaginationProps {
	images: Content[];
	currentIndex: number;
	handleIndexUpdate: (index: number) => void;
	currentPage: number;
	handleNextPage: () => void;
	handlePreviousPage: () => void;
	handleGoToPage: (index: number) => void;
	request_id: number;
	loggedUser: LoggedUser;
	imagesLength: number;
	requestFor: string;
}

const AnnotationPagination: React.FC<AnnotationPaginationProps> = ({
	images,
	currentIndex,
	handleIndexUpdate,
	currentPage,
	handleNextPage,
	handlePreviousPage,
	handleGoToPage,
	request_id,
	loggedUser,
	requestFor
}) => {
	const [openPagination, setOpenPagination] = useState<boolean>(true);

	return (
		<div className={styles.pagination__container}>
			<div className={styles.pagination} style={{ width: openPagination ? 'max-content' : 0 }}>
				<div className={styles.pagination__icon}>
					{currentIndex > 5 ? (
						<div className={styles.pagination__icon__left} onClick={handlePreviousPage} title="Previous Page">
							<img src="/icons/drop-down/transparent--black.svg" style={{ transform: 'rotate(90deg)' }} />
						</div>
					) : (
						<div></div>
					)}
					<span onClick={() => handleGoToPage(0)}>01</span>
				</div>

				<div className={styles.pagination__images}>
					{images.slice(currentPage * 6, currentPage * 6 + 6).map((image, index) => (
						<PreviewImage
							request_id={request_id}
							key={image.awi_id}
							currentIndex={currentIndex}
							image={image}
							index={currentPage * 6 + index}
							handleIndexUpdate={handleIndexUpdate}
							awi_level={loggedUser.userObj.awi_level}
							requestFor={requestFor}
						/>
					))}
				</div>
				<div className={styles.pagination__icon}>
					{currentIndex < images.length - (images.length % 6 || 6) ? (
						<div className={styles.pagination__icon__right} onClick={handleNextPage} title="Next Page">
							<img src="/icons/drop-down/transparent--black.svg" />
						</div>
					) : (
						<div></div>
					)}

					{images.length - 1 > 0 && <span onClick={() => handleGoToPage(images.length - 1)}>{images.length}</span>}
				</div>
			</div>
			<div style={{ margin: 'auto' }}>
				<img
					src="/icons/drop-down/transparent--black.svg"
					style={{ transform: `rotate(${openPagination ? 90 : -90}deg)`, marginLeft: '2px' }}
					onClick={() => setOpenPagination(prev => !prev)}
				/>
			</div>
		</div>
	);
};

const PreviewImage = ({ image, index, currentIndex, handleIndexUpdate, request_id, awi_level, requestFor }) => {
	const [fetchAnnotationsKey, fetchAnnotationsFunction] = fetchAnnotations({ awi_content_id: image.awi_id, awi_request_id: request_id });
	const { data } = useQuery(fetchAnnotationsKey, fetchAnnotationsFunction);

	if (!data) {
		return null;
	}

	if ((data.result || []).length > 0 && data.result[0] && +data.result[0].awi_content_key !== +image.awi_id) {
		return null;
	}

	const imageTick = () => {
		if (!data) {
			return false;
		}

		if (requestFor === 'attributes') {
			let noOfAnnotationsWorkedOn = 0;

			if (awi_level === 'awi_annotator' || awi_level === 'awi_developer') {
				(data.result || []).forEach(item => {
					if ((item.awi_attributes || []).length > 0) {
						noOfAnnotationsWorkedOn++;
					}
				});

				if (noOfAnnotationsWorkedOn === data.result.length) {
					return true;
				} else {
					return `${noOfAnnotationsWorkedOn}/${data.result.length}`;
				}
			} else {
				(data.result || []).forEach(item => {
					if (item.awi_status !== 'pending') {
						noOfAnnotationsWorkedOn++;
					}
				});

				if (noOfAnnotationsWorkedOn === data.result.length) {
					return true;
				} else {
					return `${noOfAnnotationsWorkedOn}/${data.result.length}`;
				}
			}
		}

		if (awi_level === 'awi_annotator' || awi_level === 'awi_developer') {
			return (data?.result || []).length > 0 && currentIndex !== index;
		} else {
			if ((data.result || []).length === 0) {
				return false;
			}

			if ((data.result || []).length > 0 && data.result.filter(item => ['pending', 'updated'].includes(item.awi_status)).length === 0) {
				return true;
			} else {
				return `${(data?.result || []).filter(item => !['pending', 'updated'].includes(item.awi_status)).length}/${(data?.result || []).length}`;
			}
		}
	};
	const imageTickInfo = imageTick();

	return (
		<div
			onClick={() => handleIndexUpdate(index)}
			style={{
				backgroundImage: `url('${getImageKitUrl(image.awi_content_url)}')`,
				width: 100,
				border: currentIndex === index ? '4px solid #55A8FD' : 'none',
				boxSizing: 'border-box'
			}}
		>
			{imageTickInfo === true || typeof imageTickInfo === 'string' ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
						height: '100%',
						backgroundColor: 'rgba(0,0,0,0.5)',
						borderRadius: 10
					}}
				>
					{imageTickInfo === true ? (
						<img style={{ width: 20, height: 20 }} src="/icons/tick/green.svg" />
					) : (
						<div
							style={{
								color: 'white'
							}}
						>
							{imageTickInfo}
						</div>
					)}
				</div>
			) : (
				<div></div>
			)}
		</div>
	);
	// <div
	// 	onClick={() => handleIndexUpdate(index)}
	// 	style={{
	// 		backgroundImage: `url('${getImageKitUrl(image.awi_content_url)}')`,
	// 		width: 100,
	// 		border: currentIndex === index ? '4px solid #55A8FD' : 'none',
	// 		boxSizing: 'border-box'
	// 	}}
	// >
	// 	{
	// 		<div
	// 			style={{
	// 				display: 'flex',
	// 				justifyContent: 'center',
	// 				alignItems: 'center',
	// 				width: '100%',
	// 				height: '100%',
	// 				backgroundColor: 'rgba(0,0,0,0.5)',
	// 				borderRadius: 10
	// 			}}
	// 		>
	// 			{imageTickInfo === true ? <img style={{ width: 20, height: 20 }} src="/icons/tick/green.svg" /> : ''}
	// 			{typeof imageTickInfo === 'string' && <div>{imageTickInfo}</div>}
	// 		</div>
	// 	}
	// 	{/* {awi_level === 'awi_annotator'
	// 		? (data?.result || []).length > 0 &&
	// 		  currentIndex !== index && (
	// 				<div
	// 					style={{
	// 						display: 'flex',
	// 						justifyContent: 'center',
	// 						alignItems: 'center',
	// 						width: '100%',
	// 						height: '100%',
	// 						backgroundColor: 'rgba(0,0,0,0.5)',
	// 						borderRadius: 10
	// 					}}
	// 				>
	// 					<img style={{ width: 20, height: 20 }} src="/icons/tick/green.svg" />
	// 				</div>
	// 		  )
	// 		: (data.result || []).length > 0 && (
	// 				<div
	// 					style={{
	// 						display: 'flex',
	// 						justifyContent: 'center',
	// 						alignItems: 'center',
	// 						width: '100%',
	// 						height: '100%',
	// 						backgroundColor: 'rgba(0,0,0,0.5)',
	// 						borderRadius: 10
	// 					}}
	// 				>
	// 					{data.result.filter(item => ['pending', 'updated'].includes(item.awi_status)).length === 0 ? (
	// 						<img style={{ width: 20, height: 20 }} src="/icons/tick/green.svg" />
	// 					) : (
	// 						<p style={{ color: 'white' }}>
	// 							{data?.result.filter(item => !['pending', 'updated'].includes(item.awi_status)).length}/{data?.result.length}
	// 						</p>
	// 					)}
	// 				</div>
	// 		  )} */}
	// </div>
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps, null)(AnnotationPagination));
