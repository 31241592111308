import { client } from './../../util/client';

export const fetchGeneratedBills = (data?: { awi_requests?: number[] | undefined }) => {
	const queryKey = ['fetch-generated-bills', { data }] as const;

	const queryFunction = () =>
		client<{ success: boolean; result: any[] }>('/fetch_generated_bills', {
			baseURL: 'bill',
			method: 'POST',
			data
		}).then(res => res.data);

	return [queryKey, queryFunction] as const;
};
