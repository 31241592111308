import clsx from 'clsx';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { format, formatDistance } from 'date-fns';
import { Link } from 'react-router-dom';
import { Tab } from '@headlessui/react';

import { getRequest } from '../../../handlers/request';

import Container from '../../Container';
import RequestFilter from './RequestFilter';
import CreateRequestModal from './CreateRequestModal';
import RequestActionsModal from './RequestActionsModal';
import { Loader, Carousel, TableInfiniteScroll, TableBody, TableCell, TableHeader, TableHeaders, TableRow, InfiniteScroll, Table } from '../../Common';

import { LoggedInUser } from '../../../types';

import useRequest from './utils/useRequests';
import { initialFilterData } from './utils';
import { useInfiniteQuery } from '../../../util/useInfiniteQuery';
import { getFullName, getUserAvatar, JSONDiff } from '../../../util/common.utils';

import RequestActions from '../../../redux/actions/RequestActions';

import styles from './styles.module.scss';
import { fetchGeneratedBills } from '../../../handlers/billing/fetchGeneratedBills';
import { useQuery } from '../../../util/useQuery';
import { useEffect, useRef, useState } from 'react';
import { getRequestClasses } from '../../../handlers/class/getRequestClasses';
import AnnotationTable from './AnnotationTable';
import SplitRequestModal from './SplitRequestModal';
import RequestFlowModal from './RequestFlowModal';
import MessageScreen from '../../Common/MessageScreen';

interface RequestProps {
	loggedUser: {
		token: string;
		userObj: LoggedInUser;
	};
	setRequest: (data: { awi_id: number; awi_label: string }) => void;
}

const RequestComponent: React.FC<RequestProps> = ({ loggedUser, setRequest }) => {
	const {
		handleOpenCarousel,
		handleCloseCarousel,
		handleCreateRequestModalOpen,
		handleRequestIndex,
		handleUpdateRequest,
		requestIndex,
		selectedLevel,
		showAnnotationType,
		createRequestModalOpen,
		requestSelect,
		openCarousel,
		isUpdateRequest,
		selectedImages,
		setRequestIndex,
		setSelectedLevel,
		filter,
		toggleShowFilter,
		isFilterOpen,
		assignedUserLevel,
		handleDateChange,
		handleDeadlineCheck,
		handleUpdateFilter,
		selectedRequest,
		handleUpdateRequestSelect,
		setFilters,
		handleCloseRequestFlowModal,
		requestFlowModal,
		setRequestFlowModal
	} = useRequest();

	const filterRef = useRef(filter);

	const handleApplyButtonClick = () => {
		filterRef.current = { ...filter };
		refetchRequestData();
		toggleShowFilter();
	};

	const [getRequestKey, getRequestFunction] = getRequest({
		...filterRef.current,
		awi_deadline_date: filterRef.current.awi_deadline_date ? new Date(filterRef.current.awi_deadline_date?.setHours(23, 59, 59)) : null
	});
	const {
		data: requestData,
		isLoading,
		hasNextPage,
		fetchNextPage,
		isFetchingNextPage,
		refetch: refetchRequestData
	} = useInfiniteQuery(getRequestKey, getRequestFunction, {
		getNextPageParam: lastPage => {
			if (lastPage.next) {
				return lastPage.next;
			}
			return undefined;
		}
	});

	const handleResetFilter = () => {
		setFilters(initialFilterData);
		filterRef.current = initialFilterData;
		refetchRequestData();
		toggleShowFilter();
	};

	const [reloadRequestPage, setReloadRequestPage] = useState(false);

	useEffect(() => {
		if (reloadRequestPage) {
			window.location.reload();
		}
	}, [reloadRequestPage]);

	const [fetchBillsKey, fetchBillsFunction] = fetchGeneratedBills({
		awi_requests: selectedRequest ? [selectedRequest.awi_id] : []
	});
	const { data: billData } = useQuery(fetchBillsKey, fetchBillsFunction);

	const [getClasskey, getClassFunction] = getRequestClasses({ awi_request_id: selectedRequest?.awi_id });
	const { data: classes } = useQuery(getClasskey, getClassFunction, {
		enabled: selectedRequest === null ? false : true
	});

	const handleImagesClick = request => {
		handleOpenCarousel(request.awi_id, request.awi_contents);
	};

	const handleAnnotatedImagesClick = request => {
		handleOpenCarousel(request.awi_id, request.awi_annotated_images);
	};

	if (isLoading) {
		return (
			<Container>
				<Loader marginTop={40} width={24} />
			</Container>
		);
	}

	if (!requestData) {
		return null;
	}

	const handleImageBankAction = (id: number) => {
		const tempData = {
			awi_id: id,
			awi_label:
				requestData.pages
					.map(page => page.result)
					.flat()
					.filter(ele => ele?.awi_id === id)[0]?.awi_label || ''
		};
		localStorage.setItem('request', JSON.stringify(tempData));
		if (typeof setRequest === 'function') {
			setRequest(tempData);
		}
	};

	return (
		<Container className={styles.root__container}>
			<div className={styles.root__container__header}>
				<div className={styles.root__container__header__actions}>
					{loggedUser?.userObj.awi_level === 'awi_developer' && (
						<img
							style={{
								width: '30px'
							}}
							src="/icons/upload.svg"
							onClick={handleCreateRequestModalOpen}
							title="Create Request"
						/>
						// <div className={styles.root__container__header__actions__iconcontainer}>
						// </div>
					)}
					<div className={styles.root__container__header__actions__iconcontainer}>
						<img src={`/icons/filter/filter${JSONDiff(initialFilterData, filter) ? '' : '--selected'}.svg`} title="Filters" onClick={toggleShowFilter} />
					</div>
					{isFilterOpen && (
						<RequestFilter
							awi_user_level={loggedUser.userObj.awi_level}
							assignedUserLevel={assignedUserLevel}
							filter={filter}
							handleDateChange={handleDateChange}
							handleDeadlineCheck={handleDeadlineCheck}
							handleResetFilter={handleResetFilter}
							handleUpdateFilter={handleUpdateFilter}
							toggleShowFilter={toggleShowFilter}
							handleApplyButtonClick={handleApplyButtonClick}
						/>
					)}
				</div>
			</div>

			{(requestData?.pages[0]?.result || []).length === 0 ? (
				<div>
					<p style={{ margin: 60, textTransform: 'uppercase' }}>No request has been raised so far</p>
				</div>
			) : (
				<div style={{ display: 'flex', flexDirection: 'row', gap: 16, padding: '0 30px' }}>
					<div className={styles.request} style={{ width: requestIndex !== -1 ? 'max-content' : '100%' }}>
						<TableInfiniteScroll>
							<TableHeaders className={styles.request__table__header}>
								<TableRow>
									{requestIndex !== -1 ? (
										<>
											<TableHeader>
												<></>
											</TableHeader>
											<TableHeader>S. No.</TableHeader>
											<TableHeader>Req. Label</TableHeader>
										</>
									) : (
										<>
											<TableHeader>
												<></>
											</TableHeader>
											<TableHeader>Req. Label</TableHeader>
											<TableHeader>Raised By</TableHeader>
											<TableHeader>Deadline</TableHeader>
											<TableHeader>Status</TableHeader>
											<TableHeader>Total Img</TableHeader>
											<TableHeader>Annotated Img</TableHeader>
											<TableHeader>Classes</TableHeader>
											<TableHeader>Min Req Img</TableHeader>
											<TableHeader className={styles.request__table__header__action}>
												<div>Action</div>
											</TableHeader>
										</>
									)}
								</TableRow>
							</TableHeaders>
							<TableBody>
								<InfiniteScroll
									hasMore={Boolean(hasNextPage)}
									isLoading={isLoading || isFetchingNextPage}
									next={fetchNextPage}
									loader={
										<div style={{ margin: '0 auto', width: 'max-content' }}>
											<Loader width={24} />
										</div>
									}
								>
									{requestData?.pages.map((page, pageIndex, arr) =>
										(page?.result || []).map((request, index) => {
											return (
												<TableRow
													key={index}
													className={clsx({
														[styles.request__row_selected]: requestIndex === index + arr[0]!.result!.length * pageIndex,
														[styles.request__row]: requestSelect === request.awi_id
													})}
												>
													{requestIndex !== -1 ? (
														<>
															<TableCell className={styles.request__table__cell}>
																<input
																	type={'checkbox'}
																	checked={requestIndex === index + arr[0]!.result!.length * pageIndex || false}
																	onChange={() => handleRequestIndex(index + arr[0]!.result!.length * pageIndex, request)}
																/>
															</TableCell>
															<TableCell>
																<p style={{ textAlign: 'center' }}>{index + arr[0]!.result!.length * pageIndex + 1}</p>
															</TableCell>
															<TableCell className={styles.request__table__cell}>
																<div>
																	<p>
																		{request.awi_label} <br />
																		{/* <sub>Created on : {format(new Date(request.awi_timestamp.awi_creation), 'dd/M/yyyy')}</sub> */}
																	</p>
																</div>
															</TableCell>
														</>
													) : (
														<>
															<TableCell className={styles.request__table__cell}>
																<input
																	type={'checkbox'}
																	checked={requestIndex === index + arr[0]!.result!.length * pageIndex || false}
																	onChange={() => handleRequestIndex(index + arr[0]!.result!.length * pageIndex, request)}
																/>
															</TableCell>
															<TableCell className={styles.request__table__cell}>
																<div>
																	<p>
																		{request.awi_label} <br />
																		<sub>
																			Created on :{' '}
																			{request?.awi_timestamp?.awi_creation ? format(new Date(request?.awi_timestamp?.awi_creation), 'dd/M/yyyy') : '-'}
																		</sub>
																	</p>
																</div>
															</TableCell>
															<TableCell className={styles.request__table__cell}>
																<div className={styles.request__table__cell__user}>
																	<img src={getUserAvatar('')} />
																	<span>{getFullName(request.awi_owner)}</span>
																</div>
															</TableCell>
															<TableCell className={styles.request__table__cell}>
																<p>
																	{format(new Date(request.awi_timestamp.awi_deadline), 'dd/M/yyyy')}
																	<br />
																	<sub>{formatDistance(new Date(request.awi_timestamp.awi_deadline), new Date(), { addSuffix: true })}</sub>
																</p>
															</TableCell>
															<TableCell className={styles.request__table__cell}>
																<div
																	className={clsx(styles.request__table__cell__status, {
																		[styles.request__table__cell__status__hold]: request.awi_status === 'on-hold',
																		[styles.request__table__cell__status__completed]: request.awi_status === 'completed',
																		[styles.request__table__cell__status__failed]: request.awi_status === 'failed',
																		[styles.request__table__cell__status__draft]: request.awi_status === 'draft'
																	})}
																>
																	{request.awi_status}
																</div>
															</TableCell>
															{(request?.awi_contents || []).length > 0 ? (
																<TableCell onClick={() => handleImagesClick(request)} className={styles.request__table__cell}>
																	{(request?.awi_contents || []).length}
																</TableCell>
															) : (
																<TableCell className={styles.request__table__cell}>0</TableCell>
															)}
															{(request?.awi_annotated_images || []).length > 0 ? (
																<TableCell onClick={() => handleAnnotatedImagesClick(request)} className={styles.request__table__cell}>
																	{(request?.awi_annotated_images || []).length}
																</TableCell>
															) : (
																<TableCell className={styles.request__table__cell}>0</TableCell>
															)}

															<TableCell className={styles.request__table__cell}>
																{request.awi_class_id.length > 0 ? (
																	<Link
																		to={{
																			pathname: `/classlist`,
																			state: { awi_class_ids: request.awi_class_id, awi_request_label: request.awi_label }
																		}}
																	>
																		<span style={{ textDecoration: 'none' }}> {request.awi_class_id.length} </span>
																	</Link>
																) : (
																	request.awi_class_id.length
																)}
															</TableCell>
															<TableCell className={styles.request__table__cell}>{request.awi_min_images}</TableCell>
															<TableCell onClick={() => handleUpdateRequestSelect(request.awi_id)}>
																<div className={clsx(styles.request__table__cell, styles.request__table__cell__action)}>
																	<RequestActionsModal
																		handleUpdateRequest={handleUpdateRequest}
																		request={request}
																		handleOpenCarousel={handleOpenCarousel}
																		imageBankAction={handleImageBankAction}
																		content_keys={[]}
																		fetchRequestKey={getRequestKey}
																		setRequestFlowModal={setRequestFlowModal}
																		setReloadRequestPage={setReloadRequestPage}
																	/>
																	{loggedUser.userObj.awi_level === 'awi_manager' &&
																		(request?.awi_splitted_into?.awi_flow_fixed === false && request.awi_request_for === 'outlineandattributes' ? (
																			<SplitRequestModal
																				request_id={request.awi_id}
																				requestLabel={request.awi_label}
																				setReloadRequestPage={setReloadRequestPage}
																			></SplitRequestModal>
																		) : (
																			<img src="/icons/edit/edit_option_disabled.png" alt="set request flow" title="Set Request Flow" />
																		))}
																</div>
															</TableCell>
														</>
													)}
												</TableRow>
											);
										})
									)}
								</InfiniteScroll>
							</TableBody>
						</TableInfiniteScroll>
					</div>

					{requestIndex !== -1 && selectedRequest && (
						<div className={clsx(styles.request, styles.request__details)}>
							<div className={styles.request__details__header}>
								<div className={styles.request__details__header__title}>
									<p className={styles.request__details__header__title__label}>{selectedRequest.awi_label}</p>
									<p className={styles.request__details__header__title__time}>
										{/* Created on : {format(new Date(selectedRequest.awi_timestamp.awi_creation), 'dd/M/yyyy')} */}
									</p>
								</div>
								<div className={styles.request__details__header__status}>
									<span className={styles.request__details__header__status__action}>{selectedRequest.awi_status}</span>
									<span className={styles.request__details__header__status__qa}>{selectedRequest.awi_approved ? 'QA Approved' : 'Approval Needed'}</span>
								</div>
							</div>
							<div className={styles.request__details__description}>
								<div className={styles.request__details__description__items}>
									<div className={styles.request__details__description__items__item}>
										Deadline : {selectedRequest?.awi_timestamp?.awi_deadline ? format(new Date(selectedRequest.awi_timestamp.awi_deadline), 'dd/M/yyyy') : ''}
									</div>
									<div className={styles.request__details__description__items__item}>Req. Type : {selectedRequest.awi_type}</div>
									<div className={styles.request__details__description__items__item}>Ann. Type : {selectedRequest.awi_annotation_type}</div>
									<div className={styles.request__details__description__items__item}>Min. Img Required : {selectedRequest.awi_min_images}</div>
									<div className={styles.request__details__description__items__item}>QAC : {selectedRequest.awi_min_bqc}</div>
								</div>
								<div className={styles.request__details__description__descr}>
									<h3>Description</h3>
									<p>{selectedRequest.awi_description}</p>
								</div>

								<Tab.Group
								//  onChange={index => setIndex(index)}
								>
									<div className={styles.tabs__list__container}>
										<Tab.List className={styles.tabs__list}>
											{({ selectedIndex }) => (
												<>
													<Tab
														className={clsx(styles.tabs__list__item, {
															[styles.tabs__list__item__selected]: selectedIndex === 0
														})}
													>
														Classes
													</Tab>

													<Tab
														className={clsx(styles.tabs__list__item, {
															[styles.tabs__list__item__selected]: selectedIndex === 1
														})}
													>
														Users
													</Tab>

													{/* <Tab
														className={clsx(styles.tabs__list__item, {
															[styles.tabs__list__item__selected]: selectedIndex === 2
														})}
													>
														Progress Report
													</Tab> */}

													<Tab
														className={clsx(styles.tabs__list__item, {
															[styles.tabs__list__item__selected]: selectedIndex === 2,
															[styles.tabs__list__item__hidden]: loggedUser.userObj.awi_level === 'awi_developer'
														})}
													>
														Billing
													</Tab>
												</>
											)}
										</Tab.List>
									</div>
									<Tab.Panels>
										<Tab.Panel>
											<div style={{ backgroundColor: 'white' }}>
												<div className={styles.tabs__container}>
													<TableInfiniteScroll>
														<TableHeaders className={styles.table__header__styles}>
															<TableHeader>S NO.</TableHeader>
															<TableHeader>Class Label</TableHeader>
															<TableHeader>Color</TableHeader>
															<TableHeader>Attributes</TableHeader>
															<TableHeader>Created By</TableHeader>
															<TableHeader>Description</TableHeader>
														</TableHeaders>
														<TableBody>
															{(classes || []).map((classItem, index) => (
																<TableRow key={index}>
																	<TableCell>{index + 1}</TableCell>
																	<TableCell>{classItem.awi_label}</TableCell>
																	<TableCell>
																		<div style={{ height: '10px', width: '10px', backgroundColor: classItem.awi_color }} />
																	</TableCell>

																	<TableCell>
																		<Link
																			to={{
																				pathname: `/attributelist`,
																				state: {
																					awi_class_id: [classItem.awi_id]
																				}
																			}}
																		>
																			{classItem.awi_attributes.length}
																		</Link>
																	</TableCell>
																	<TableCell>{getFullName(classItem.awi_created_by)}</TableCell>
																	<TableCell>{classItem.awi_description}</TableCell>
																</TableRow>
															))}
														</TableBody>
													</TableInfiniteScroll>
												</div>
											</div>
										</Tab.Panel>
										<Tab.Panel>
											<div className={styles.tabs__container}>
												<AnnotationTable
													handleRequestIndex={handleRequestIndex}
													requestId={selectedRequest.awi_id}
													user_level={loggedUser.userObj.awi_level}
													awi_level={loggedUser.userObj.awi_level}
												/>
											</div>
										</Tab.Panel>
										{/* <Tab.Panel>
											<div>Not available right now</div>
										</Tab.Panel> */}
										<Tab.Panel>
											{loggedUser.userObj.awi_level === 'awi_manager' && (
												<div className={styles.billing__actions}>
													<Link
														to={{
															pathname: `/generatebills`,
															state: { awi_request_id: selectedRequest.awi_id, awi_request_label: selectedRequest.awi_label }
														}}
													>
														<button className={styles.billing__actions__button}> Generate bill</button>
													</Link>
												</div>
											)}
											<div style={{ backgroundColor: 'white', height: '100%', width: '100%' }}>
												{(billData?.result || []).length !== 0 ? (
													<TableInfiniteScroll>
														<TableHeaders className={styles.table__header__styles}>
															<TableHeader>S NO.</TableHeader>
															<TableHeader>Created on</TableHeader>
															<TableHeader>Date Range</TableHeader>
															<TableHeader>Payable amount</TableHeader>
															<TableHeader>Users</TableHeader>
															<TableHeader>STATUS</TableHeader>
														</TableHeaders>
														{
															<TableBody>
																{(billData?.result || []).map((bill, index) => (
																	<TableRow key={index}>
																		<TableCell>{index + 1}</TableCell>
																		<TableCell>{bill.awi_creation ? format(new Date(bill.awi_creation), 'MMM dd,yyyy') : '-'}</TableCell>
																		<TableCell>{`${format(new Date(bill.awi_interval.awi_start_date), 'MMM dd,yyyy')} - ${format(
																			new Date(bill.awi_interval.awi_end_date),
																			'MMM dd,yyyy'
																		)}`}</TableCell>
																		<TableCell className={styles.centerAlign}>{bill.awi_payable_amount.toFixed(2)}</TableCell>
																		<TableCell>
																			<div style={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center' }}>
																				<img style={{ width: '20px' }} src="/icons/users_icons.png"></img>
																				<span>{bill.awi_total_count}</span>
																			</div>
																		</TableCell>
																		<TableCell>
																			<div
																				style={{
																					width: 'max-content',
																					display: 'flex',
																					flexDirection: 'column',
																					gap: '5px',
																					textAlign: 'center',
																					justifyContent: 'center'
																					// alignItems: 'center'
																				}}
																			>
																				<span
																					className={clsx(styles.request__table__cell__status, {
																						[styles.request__table__cell__status__hold]: bill.awi_status === 'on-hold',
																						[styles.request__table__cell__status__completed]: bill.awi_status === 'paid',
																						[styles.request__table__cell__status__failed]: bill.awi_status === 'failed',
																						[styles.request__table__cell__status__draft]: bill.awi_status === 'draft'
																					})}
																				>
																					{bill.awi_status === 'pending' && bill.awi_pending_count < bill.awi_total_count ? 'Partially Paid' : bill.awi_status}
																				</span>
																				{bill.awi_status === 'pending' && bill.awi_pending_count < bill.awi_total_count && (
																					<span className={styles.request__table__cell__status__ratio}>{bill.awi_pending_count} users pending</span>
																				)}
																			</div>
																		</TableCell>
																	</TableRow>
																))}
															</TableBody>
														}
													</TableInfiniteScroll>
												) : (
													<div style={{ height: '100%', width: '100%' }}>
														<MessageScreen iconUrl="/icons/info.svg" title="No bills found" description="" messageType="information"></MessageScreen>
													</div>
												)}
											</div>
										</Tab.Panel>
									</Tab.Panels>
								</Tab.Group>
							</div>
						</div>
					)}
				</div>
			)}
			{openCarousel && (
				<Carousel
					handleToggleCarousel={handleCloseCarousel}
					requestName={
						requestData?.pages
							.map(page => page.result)
							.flat()
							.find(ele => ele?.awi_id === requestSelect)?.awi_label || ''
					}
					showAnnotationType={showAnnotationType}
					requestId={requestSelect}
					imageKeys={selectedImages}
				/>
			)}
			{createRequestModalOpen && (
				<CreateRequestModal
					requestsKey={getRequestKey}
					requestId={requestSelect}
					isUpdateRequest={isUpdateRequest}
					handleModalClose={handleCreateRequestModalOpen}
				/>
			)}

			{requestFlowModal && <RequestFlowModal handleModalClose={handleCloseRequestFlowModal}></RequestFlowModal>}
		</Container>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setRequest: data => dispatch(RequestActions.setRequest(data))
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RequestComponent));
