import { CancelTokenSource } from 'axios';
import { client } from './../../util/client';

export const searchLocations = ({ s, source }: { s: string; source: CancelTokenSource }) => {
	const queryKey = ['locations', { s }] as const;

	const queryFunction = () =>
		client('locations', {
			params: {
				s
			},
			cancelToken: source.token
		}).then(res => res.data);

	return [queryKey, queryFunction] as const;
};
