import Input from '../Input';
import styles from './styles.module.scss';
import { useState, useEffect } from 'react';
import { MiniUser, PostUsersToRequest } from '../../../types';
import { getUsers } from '../../../handlers/organisation';
import { getFullName } from '../../../util/common.utils';
import { fetchRequestUsers, postUserToRequest } from '../../../handlers/request';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useQuery } from '../../../util/useQuery';

interface AddUserModalProps {
	requestId?: number;
	handleCloseModal?: () => void;
}

const roles = ['awi_supervisor', 'awi_annotator', 'awi_verifier'];

const AddUserModal: React.FC<AddUserModalProps> = ({ requestId, handleCloseModal }) => {
	const [users, setUsers] = useState<MiniUser[]>();
	const [state, setState] = useState<number>(0);
	const queryClient = useQueryClient();
	const [searchUserString, setSearchUserString] = useState<string>('');
	const fetchUsers = async state => {
		const res = await getUsers({ roles: state === '' ? [] : [state], get_stats: false, searchUserString: searchUserString, verified_users: true });
		setUsers(res.result);
	};

	const [fetchRequestUsersKey, fetchRequestUsersFunction] = fetchRequestUsers({
		awi_request_id: requestId!,
		role: roles[state]
	});

	const { data: requestUsers } = useQuery(fetchRequestUsersKey, fetchRequestUsersFunction);

	const [selectUsers, setSelectUsers] = useState<number[]>([]);

	const handleSearchUser = (s: string) => {
		setSearchUserString(s);
	};

	useEffect(() => {
		let role = '';
		if (state === 0) {
			role = 'awi_supervisor';
		} else if (state === 1) {
			role = 'awi_annotator';
		} else if (state === 2) {
			role = 'awi_verifier';
		}
		fetchUsers(role);
	}, [state, searchUserString]);

	const rolesData = [
		{ awi_id: 0, awi_label: 'supervisor' },
		{ awi_id: 1, awi_label: 'annotator' },
		{ awi_id: 2, awi_label: 'verifier' }
	];

	useEffect(() => {
		if (requestUsers) {
			const userData = (requestUsers[0]?.awi_users || []).map(user => user.awi_id);
			setSelectUsers(userData);
		}
	}, [requestUsers]);

	const handleRoleChange = (id: number) => {
		setState(id);
		setSelectUsers([]);
	};

	const handleSelectUsers = (id: number) => {
		if ((requestUsers[0]?.awi_users || []).filter(user => user.awi_id === id).length > 0) {
			return;
		} else {
			setSelectUsers(prev => {
				const temp = [...prev];
				if (prev?.indexOf(id) === -1) {
					temp.push(id);
				} else {
					temp.splice(temp.indexOf(id), 1);
				}
				return [...temp];
			});
		}
	};

	const handleSubmitRequest = async () => {
		const users: PostUsersToRequest = {
			awi_request_id: requestId!,
			awi_users: selectUsers,
			awi_level: state === 0 ? 'awi_supervisor' : state === 1 ? 'awi_annotator' : 'awi_verifier'
		};

		const res = await postUserToRequest(users);

		if (res.success) {
			toast.success(res.message);
			queryClient.invalidateQueries(fetchRequestUsersKey);
			queryClient.invalidateQueries('request');
			handleCloseModal!();
		}
	};

	return (
		<>
			<div className={styles.users__backdrop} onClick={handleCloseModal}></div>
			<div className={styles.users__modal}>
				<div className={styles.users__modal__grid}>
					<label>Select role</label>
					<Input
						placeholder="Select User Role"
						data={rolesData}
						searchString={rolesData.filter(ele => ele.awi_id === state)[0].awi_label}
						onClick={handleRoleChange}
						type="drop-down"
					/>
				</div>
				<div className={styles.users__modal__grid}>
					<label>Assign To </label>
					<Input
						type="input"
						placeholder="Enter user name"
						data={users?.map(user => {
							return { awi_id: user.awi_id, awi_label: getFullName(user) };
						})}
						onClick={handleSelectUsers}
						onChange={handleSearchUser}
						selectedData={selectUsers || []}
					/>
				</div>
				{
					<div>
						{selectUsers.length - (requestUsers ? requestUsers[0]?.awi_users?.length || 0 : 0) !== 0
							? `+${selectUsers.length - (requestUsers ? requestUsers[0]?.awi_users?.length || 0 : 0)} new users`
							: null}
					</div>
				}
				<input type={'button'} className={styles.users__modal__submit} value="submit" onClick={handleSubmitRequest} />
			</div>
		</>
	);
};
export default AddUserModal;
