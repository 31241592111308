import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';

import styles from './styles.module.scss';
import { getRequestClasses } from '../../../handlers/class/getRequestClasses';
import { useQuery } from '../../../util/useQuery';
import { useEffect, useState } from 'react';
import { fetchSessionDetails } from '../../../handlers/session/fetchSessionDetails';
import { Table, TableBody, TableCell, TableHeader, TableHeaders, TableInfiniteScroll, TableRow } from '../../Common';
import RequestPriceModal from '../Billing/RequestPriceModal';

interface SessionCountsProps {
	selectedTab: string;
}

const SessionCountsModal: React.FC<SessionCountsProps> = ({}) => {
	const location = useLocation();
	const { awi_session_id, awi_request_id, awi_level } = location.state || [];

	const [fetchSessionDetailsKey, fetchSessionDetailsFunction] = fetchSessionDetails({ awi_session_id: awi_session_id });
	const { data: sessionData } = useQuery(fetchSessionDetailsKey, fetchSessionDetailsFunction);

	const [openRequestPriceModal, setOpenRequestPriceModal] = useState(false);

	const [countsData, setCountsData] = useState<any>([]);

	const [getClasskey, getClassFunction] = getRequestClasses({ awi_request_id: awi_request_id });
	const { data: requestClasses } = useQuery(getClasskey, getClassFunction);

	const [attributeLabels, setAttributeLabels] = useState<any>({});

	useEffect(() => {
		const temp_obj = {};
		if ((requestClasses || []).length > 0) {
			requestClasses.forEach(item => {
				temp_obj[item.awi_id] = item.awi_label;
				temp_obj[`${item.awi_id}_outline`] = 'Outline';
				item.awi_attributes.forEach(attr => {
					temp_obj[`${item.awi_id}_${attr.awi_id}`] = attr.awi_label;
				});
			});
		}
		setAttributeLabels(temp_obj);
	}, [requestClasses]);

	useEffect(() => {
		const counts = sessionData?.result[0]?.awi_counts || [];
		counts.sort((a, b) => {
			if (a.awi_class_id === b.awi_class_id) {
				// If classIds are equal, sort based on attributeId
				return a.awi_attribute_id - b.awi_attribute_id;
			} else {
				// Sort based on classId
				return a.awi_class_id - b.awi_class_id;
			}
		});
		setCountsData(counts);
	}, [sessionData]);
	const handleRequestPricingModalClose = () => {
		setOpenRequestPriceModal(false);
	};

	return (
		<>
			<div
				style={{
					padding: '20px',
					backgroundColor: '#F8F8F8'
				}}
			>
				<div>
					{(sessionData?.result || []).length > 0 ? (
						<>
							<div
								style={{
									display: 'flex',
									gap: '50px',
									marginBottom: '20px',
									marginTop: '20px'
								}}
							>
								<div>Request Name: {sessionData?.result[0]?.awi_request[0]?.awi_label}</div>
								<div>Session ID: {sessionData?.result[0]?.awi_id}</div>

								<div>
									Session Start date:{' '}
									{awi_level === 'annotator'
										? format(new Date(sessionData?.result[0]?.awi_annotator_info.awi_start_date), 'dd MMM, H:mm:ss')
										: format(new Date(sessionData?.result[0]?.awi_verifier_info.awi_start_date), 'dd MMM, H:mm:ss')}
								</div>
								<div>
									Session End date:{' '}
									{awi_level === 'annotator'
										? format(new Date(sessionData?.result[0]?.awi_annotator_info.awi_end_date), 'dd MMM, H:mm:ss')
										: format(new Date(sessionData?.result[0]?.awi_verifier_info.awi_end_date), 'dd MMM, H:mm:ss')}
								</div>
								<div>Session Status: {sessionData?.result[0]?.awi_status}</div>
							</div>
							<div
								style={{
									display: 'flex',
									gap: '50px',
									marginBottom: '20px',
									marginTop: '20px'
								}}
							>
								{awi_level === 'annotator' ? (
									<>
										<div>Annotated status bill amount = {sessionData?.result[0]?.awi_annotator_generated_bills?.awi_annotated_bill_amount || 'NA'}</div>
										<div>Verified status bill amount = {sessionData?.result[0]?.awi_annotator_generated_bills?.awi_verified_bill_amount || 'NA'}</div>
									</>
								) : (
									<>
										<div>Verified status bill amount = {sessionData?.result[0]?.awi_verifier_generated_bills?.awi_verified_bill_amount || 'NA'}</div>
									</>
								)}
								<img src="/icons/billing/pricestructure.svg" onClick={() => setOpenRequestPriceModal(true)}></img>
							</div>
						</>
					) : (
						<div></div>
					)}
				</div>
				<div
					style={{
						backgroundColor: 'white'
					}}
				>
					<TableInfiniteScroll>
						<TableHeaders className={styles.session__panel__table_header}>
							<TableRow>
								<TableHeader>CLASS NAME</TableHeader>
								<TableHeader>ATTRIBUTE NAME</TableHeader>
								<TableHeader>PENDING</TableHeader>
								<TableHeader>APPROVED</TableHeader>
								<TableHeader>REJECTED</TableHeader>
								<TableHeader>CORRECTED</TableHeader>
								<TableHeader>CREATED BY VERIFIER</TableHeader>
							</TableRow>
						</TableHeaders>
						<TableBody>
							{countsData.map((item, index) => {
								return (
									<TableRow key={index}>
										<TableCell>{attributeLabels[item.awi_class_id]}</TableCell>
										<TableCell>{attributeLabels[`${item.awi_class_id}_${item.awi_attribute_id}`]}</TableCell>
										<TableCell>{(item.awi_pending || []).length}</TableCell>
										<TableCell>{(item.awi_approved || []).length}</TableCell>
										<TableCell>{(item.awi_rejected || []).length}</TableCell>
										<TableCell>{(item.awi_corrected || []).length}</TableCell>
										<TableCell>{(item.awi_created || []).length}</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</TableInfiniteScroll>
				</div>
			</div>

			{openRequestPriceModal && (
				<RequestPriceModal
					attributesLabels={attributeLabels}
					requestPriceID={
						awi_level === 'annotator'
							? sessionData?.result[0]?.awi_annotator_generated_bills?.awi_request_pricing_id || -1
							: sessionData?.result[0]?.awi_verifier_generated_bills?.awi_request_pricing_id || -1
					}
					handleModalClose={handleRequestPricingModalClose}
					requestId={awi_request_id}
				></RequestPriceModal>
			)}
		</>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps, null)(SessionCountsModal));
