import clsx from 'clsx';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { addUsers } from '../../../handlers/organisation';
import { getUserDetails } from '../../../handlers/user';
import UserActions from '../../../redux/actions/UserActions';
import { LoggedInUser } from '../../../types';
import { getUserAvatar, validateEmail } from '../../../util/common.utils';
import { Link } from 'react-router-dom';
import { useQuery } from '../../../util/useQuery';

import styles from './styles.module.scss';
import NotificationModal from '../NotificationModal';
import { getCountOfNewNotifications } from '../../../handlers/notification/getCountOfNewNotifications';
import { updateNotificationsStatus } from '../../../handlers/notification/markNotificationsAsRead';
import { useMutation } from 'react-query';

interface NavbarProps {
	loggedIn: boolean;
	loggedUser: {
		token: string;
		userObj: LoggedInUser;
	};
	logoutUser: () => void;
	hideNavbar?: boolean;
}

const users = ['awi_root', 'awi_admin', 'awi_manager', 'awi_developer', 'awi_supervisor', 'awi_verifier', 'awi_annotator'];
type UserRole = 'awi_developer' | 'awi_manager' | 'awi_supervisor' | 'awi_verifier' | 'awi_annotator' | '';

const Navbar: React.FC<NavbarProps> = ({ loggedIn, loggedUser, logoutUser, hideNavbar = false }) => {
	// const [isOpen, setIsOpen] = useState(false);
	const [userEmails, setUserEmails] = useState<string[]>([]);
	const [userInput, setUserInput] = useState<string>('');
	const [selectedRole, setSelectedRole] = useState<UserRole>('');
	const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);
	const [openNotificationModal, setOpenNotificationModal] = useState<boolean>(false);

	const [loading, setLoading] = useState<boolean>(false);

	const [getUserDetailsKey, getUserDetailsFunction] = getUserDetails(loggedUser.userObj.awi_id);
	const { data: user } = useQuery(getUserDetailsKey, getUserDetailsFunction);

	const [newNotificationsCountKey, newNotificationsCountFunction] = getCountOfNewNotifications({
		awi_user_id: loggedUser.userObj.awi_id
	});

	const { data: newNotificationsCount } = useQuery(newNotificationsCountKey, newNotificationsCountFunction, {
		enabled: loggedUser.userObj.awi_level === 'awi_developer'
	});

	const updateNotificationsStatusMutation = useMutation(updateNotificationsStatus);

	const [showNotificationNumber, setShowNotificationNumber] = useState(true);

	const [currentTab, setCurrentTab] = useState<string>(window.location.href.split('/')[window.location.href.split('/').length - 1]);
	const currentUserIndex = users.findIndex(user => user === loggedUser.userObj.awi_level);
	useEffect(() => {
		setCurrentTab(window.location.href.split('/')[window.location.href.split('/').length - 1]);
	}, [window.location.href]);

	const handleLogOut = () => {
		// setIsOpen(false);
		window.location.href = '/login';
		logoutUser();
	};

	const handleInputChange = event => {
		setUserInput(event.target.value);
	};

	const handleRemoveEmails = (email: string) => {
		if (email === '') {
			return;
		}
		const temp = [...userEmails];
		temp.splice(temp.indexOf(email), 1);
		setUserEmails([...temp]);
	};

	const sendInvites = async () => {
		setLoading(true);
		const data = {
			users: [...userEmails, ...userInput.split(',')],
			awi_level: selectedRole
		};

		data.users = data.users.filter(email => validateEmail(email));

		const res = await addUsers(data);
		if (res.success) {
			toggleInviteModal();
			toast.success(res.message);
		} else {
			toast.error(res.message);
		}
		setLoading(false);
	};

	const toggleInviteModal = () => {
		setIsInviteModalOpen(prev => !prev);
		setUserEmails([]);
		setSelectedRole('');
		setUserInput('');
	};

	const toggleNotificationModal = () => {
		setOpenNotificationModal(prev => !prev);
	};

	const handleOpenNotificationModal = () => {
		setOpenNotificationModal(true);

		updateNotificationsStatusMutation.mutate(
			{ awi_user_id: loggedUser.userObj.awi_id, awi_status: 'opened' },
			{
				onSuccess: res => {
					setShowNotificationNumber(false);
				},
				onError: (err: any) => {
					const errMessage = err.response?.data.message;
					toast.error('failed to mark as read');
				}
			}
		);
	};

	if (!loggedIn && !user) {
		return null;
	}

	return (
		<header
			className={clsx(styles.rootContainer, {
				[styles.hideNavbar]: !!hideNavbar
			})}
		>
			<div className={styles.header}>
				{/* logo */}
				<div className={styles.logo}>
					<img src="/atom.svg" width="100%" height="100%" />
				</div>

				{/* nav items */}
				<nav>
					<ul className={styles.navigation}>
						{loggedUser.userObj.awi_level !== 'awi_root' && (
							<li>
								<a style={{ color: currentTab === 'requests' || currentTab === '' ? '#55a8fd' : 'black' }} href="/requests">
									Request
								</a>
							</li>
						)}
						{loggedUser.userObj.awi_level === 'awi_root' && (
							<li>
								<a style={{ color: currentTab === 'organisations' ? '#55a8fd' : 'black' }} href="/organisations">
									Organisation
								</a>
							</li>
						)}

						{['awi_admin', 'awi_manager', 'awi_supervisor'].indexOf(loggedUser.userObj.awi_level) !== -1 && (
							<li>
								<a style={{ color: currentTab === 'image-bank' ? '#55a8fd' : 'black' }} href="/image-bank">
									Imagebank
								</a>
							</li>
						)}
						{['awi_admin', 'awi_developer'].indexOf(loggedUser.userObj.awi_level) !== -1 && (
							<li>
								<a style={{ color: currentTab === 'classlist' ? '#55a8fd' : 'black' }} href="/classlist">
									Class List
								</a>
							</li>
						)}
						{['awi_admin', 'awi_manager', 'awi_supervisor'].indexOf(loggedUser.userObj.awi_level) !== -1 && (
							<li>
								<a style={{ color: currentTab === 'users' ? '#55a8fd' : 'black' }} href="/users">
									Users
								</a>
							</li>
						)}
						{['awi_admin', 'awi_manager', 'awi_verifier', 'awi_annotator', 'awi_supervisor'].indexOf(loggedUser.userObj.awi_level) !== -1 && (
							<li>
								<a style={{ color: currentTab === 'sessions' ? '#55a8fd' : 'black' }} href="/sessions">
									Sessions
								</a>
							</li>
						)}
						{['awi_developer', 'awi_manager'].indexOf(loggedUser.userObj.awi_level) !== -1 && (
							<li>
								<a style={{ color: currentTab === 'datasets' ? '#55a8fd' : 'black' }} href="/datasets">
									Datasets
								</a>
							</li>
						)}
						{['awi_admin', 'awi_manager'].indexOf(loggedUser.userObj.awi_level) !== -1 && (
							<li>
								<a style={{ color: currentTab === 'billing' ? '#55a8fd' : 'black' }} href="/billing">
									Billing
								</a>
							</li>
						)}
						{/* {['awi_developer'].indexOf(loggedUser.userObj.awi_level) !== -1 && (
							<li>
								<a style={{ color: currentTab === 'models' ? '#55a8fd' : 'black' }} href="/models">
									Models
								</a>
							</li>
						)}
						{['awi_developer'].indexOf(loggedUser.userObj.awi_level) !== -1 && (
							<Link
								to={{
									pathname: `/jobs`,
									state: { awi_dataset_id: null, awi_project_name: '' }
								}}
								style={{ textDecoration: 'none', color: 'black' }}
							>
								<li style={{ color: currentTab === 'jobs' ? '#55a8fd' : 'black' }}>Jobs</li>
							</Link>
						)} */}
					</ul>
				</nav>

				<div className={styles.userInfo}>
					{['awi_developer'].includes(loggedUser.userObj.awi_level) && (
						<div className={styles.notification}>
							<img
								onClick={handleOpenNotificationModal}
								className={styles.bell__icon}
								src="/icons/notifications.svg"
								width="30px"
								height="30px"
								alt="Notifications"
								title="Invite User"
							/>
							{showNotificationNumber && newNotificationsCount?.result !== 0 && (
								<div onClick={handleOpenNotificationModal} className={styles.notification__count}>
									{newNotificationsCount?.result}
								</div>
							)}
							{openNotificationModal && (
								<>
									<div className={styles.notification__container}>
										<NotificationModal userId={loggedUser.userObj.awi_id} handleCloseModal={toggleNotificationModal}></NotificationModal>
									</div>
								</>
							)}
						</div>
					)}
					{['awi_manager', 'awi_admin', 'awi_supervisor'].includes(loggedUser.userObj.awi_level) && (
						<img onClick={toggleInviteModal} src="/icons/addUser.svg" width="30px" height="30px" alt="invite user button" title="Invite User" />
					)}

					<a href="/user/dashboard " style={{ display: 'flex', alignItems: 'center' }} title={loggedUser.userObj.awi_level?.split('awi_')[1].toUpperCase()}>
						<img src={getUserAvatar(user!)} width="32px" height="32px" className={styles.userInfo__avatarimage} />
					</a>

					{!window.location.href.includes('/annotation') && (
						<img src="/icons/logout.svg" width="24px" height="20px" alt="logout button" title="logout" onClick={handleLogOut} />
					)}
				</div>
			</div>
			{isInviteModalOpen && (
				<>
					<div className={clsx(styles.user__backdrop, styles.backdrop)} onClick={toggleInviteModal}></div>
					<div className={styles.user__modal}>
						<div className={styles.user__modal__close_button} onClick={toggleInviteModal}>
							<img src="/icons/close.svg" />
						</div>
						<div className={styles.user__invite}>
							<label>User Email</label>
							<div className={styles.user__emails}>
								<input
									onKeyDown={event => {
										if (event.which === 13) {
											const temp = [...userEmails];
											userInput.split(' ').map(email => {
												if (email.split(',').every(mail => validateEmail(mail)) && !userEmails.includes(email)) {
													temp.push(...email.split(','));
													setUserEmails(temp);
													setUserInput('');
												}
											});
										}
									}}
									type={'email'}
									value={userInput}
									onChange={handleInputChange}
									placeholder="Add E-mails"
								/>
							</div>
							<label></label>
							<div>
								{userEmails.length > 0 ? (
									<div className={styles.user__emails__chips}>
										{userEmails.map((email, index) => (
											<p className={styles.user__emails__chip} key={index}>
												<span>{email}</span>
												<img src="/icons/close.svg" onClick={() => handleRemoveEmails(email)} width={12} />
											</p>
										))}
									</div>
								) : null}
							</div>
							<label>Role</label>
							<select
								value={selectedRole}
								onChange={(event: React.ChangeEvent) => {
									setSelectedRole((event.target as HTMLSelectElement).value as UserRole);
								}}
							>
								<option hidden>Select Role</option>
								{users.map((user, index) => {
									if (index <= currentUserIndex) {
										return null;
									}
									return (
										<option value={user} key={user}>
											{user.split('awi_')[1].toUpperCase()}
										</option>
									);
								})}
							</select>
						</div>
						<button
							disabled={
								(userEmails.length === 0 && (userInput === '' || userInput.split(',').filter(email => validateEmail(email)).length === 0)) ||
								selectedRole === '' ||
								loading
							}
							className={styles.user__button}
							onClick={sendInvites}
						>
							Send Invite
						</button>
					</div>
				</>
			)}
		</header>
	);
};

const mapStateToProps = state => {
	return {
		loggedIn: state.UserReducer.loggedIn,
		loggedUser: state.UserReducer.loggedUser
	};
};

const mapDispatchToProps = dispatch => {
	return {
		logoutUser: () => dispatch(UserActions.logoutUser())
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
