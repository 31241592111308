import styles from './styles.module.scss';

interface TableProps {
	content?: (string | number)[][];
	headers?: string[];
	colSpan?: number[][];
	subTable?: boolean;
}

const Table: React.FC<TableProps> = ({ content, headers, colSpan = [] }) => {
	return (
		<table className={styles.table}>
			<thead className={styles.table__header}>
				{(headers || []).map((header, index) => (
					<th
						className={styles.table__header__th}
						colSpan={colSpan?.findIndex(cols => cols[0] === index) !== -1 ? colSpan?.find(cols => cols[0] === index)![1] : 1}
						key={index}
					>
						<span>{header}</span>
					</th>
				))}
			</thead>
			<tbody>
				{(content || []).map((row, index) => (
					<tr key={index} className={styles.table__row}>
						{row.map((dataEle, index) => (
							<td className={styles.table__row__td} colSpan={1} key={index}>
								<span>{typeof dataEle === 'boolean' ? (dataEle ? 'true' : 'false') : dataEle}</span>
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
};
export default Table;
