import ReduxActionEnum from '../../models/ReduxActionEnum';

const RequestActions = {
	setRequest: value => {
		return {
			type: ReduxActionEnum.SET_REQUEST_ID,
			payload: value
		};
	}
};

export default RequestActions;
