import clsx from 'clsx';
import styles from './styles.module.scss';

const TableCell = ({ children, className = '', ...args }) => {
	return (
		<td
			className={clsx(styles.table__row__td, {
				[className]: className
			})}
		>
			<span
				{...args}
				style={{ textAlign: typeof children === 'number' ? 'left' : 'left', textDecoration: typeof children === 'number' ? 'underline' : 'unset' }}
			>
				{children}
			</span>
		</td>
	);
};
export default TableCell;
