import { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getFullName } from '../../../util/common.utils';

import { MiniUser } from '../../../types';

import RequestActions from '../../../redux/actions/RequestActions';

import styles from './styles.module.scss';
import Input from '../../Common/Input';

interface CreateBatchProps {
	isOpen: boolean;
	handleModalToggle: () => void;
	selectedImageLength?: number;
	requests: Array<{ awi_label: string; awi_id: number }>;
	annotators: MiniUser[];
	verifiers: MiniUser[];
	handleAssignment: (requestId: number, annotatorId: number[], verifierId: number[]) => void;
	handleToggleCarousel: () => void;
	requestID: { awi_id: number; awi_label: string };
}

const CreateBatch: React.FC<CreateBatchProps> = ({
	// isOpen,
	requestID,
	requests,
	verifiers,
	annotators,
	handleModalToggle,
	selectedImageLength,
	handleAssignment,
	handleToggleCarousel
}) => {
	const [selectedAnnotator, setSelectedAnnotator] = useState<number[]>([]);
	const [selectedVerifier, setSelectedVerifier] = useState<number[]>([]);
	const [selectedRequest, setSelectedRequest] = useState<number>(requestID?.awi_id);
	const [searchInput, setSearchInput] = useState({
		annotatorSearch: '',
		verifierSearch: ''
	});
	const handleSelectAnnotator = (annoID: number) => {
		setSelectedAnnotator(prev => {
			const temp = [...prev];
			if (prev?.indexOf(annoID) === -1) {
				temp.push(annoID);
			} else {
				temp.splice(temp.indexOf(annoID), 1);
			}
			return [...temp];
		});
	};

	const handleSelectVerifier = (annoID: number) => {
		setSelectedVerifier(prev => {
			const temp = [...prev];
			if (prev?.indexOf(annoID) === -1) {
				temp.push(annoID);
			} else {
				temp.splice(temp.indexOf(annoID), 1);
			}
			return [...temp];
		});
	};

	const handleRequestSelect = (requestId: number) => {
		setSelectedRequest(requestId);
	};

	const handleModalClosing = () => {
		setSelectedAnnotator([]);
		setSelectedVerifier([]);
		setSelectedRequest(requestID?.awi_id);
		handleModalToggle();
	};

	return (
		<>
			<div className={styles.modal}>
				<div className={styles.modal__close_button} onClick={handleModalClosing}>
					<img src="/icons/close.svg" />
				</div>
				<div className={styles.batch}>
					<p className={styles.batch__details}>*{selectedImageLength} Images Selected</p>

					<p className={styles.modal__carousel_button} onClick={handleToggleCarousel}>
						Show in Carousel
					</p>
					<div className={styles.upload__form__grid}>
						<label>Assign Annotators</label>
						<Input
							type="input"
							placeholder="Search Annotators"
							searchString={searchInput.annotatorSearch}
							onClick={handleSelectAnnotator}
							data={
								searchInput.annotatorSearch !== ''
									? annotators
											.filter(annotator => {
												return getFullName(annotator).toLowerCase().includes(searchInput.annotatorSearch.toLowerCase());
											})
											.map(annotator => {
												return { awi_id: annotator.awi_id, awi_label: getFullName(annotator) };
											})
									: annotators.map(annotator => {
											return { awi_id: annotator.awi_id, awi_label: getFullName(annotator) };
									  })
							}
							onChange={e => setSearchInput({ ...searchInput, annotatorSearch: e })}
							selectedData={selectedAnnotator}
						/>

						<label>Assign Verifiers</label>
						<Input
							type="input"
							placeholder="Search Verifiers"
							searchString={searchInput.verifierSearch}
							onClick={handleSelectVerifier}
							data={
								searchInput.verifierSearch !== ''
									? verifiers
											.filter(verifier => {
												return getFullName(verifier).toLowerCase().includes(searchInput.verifierSearch.toLowerCase());
											})
											.map(verifier => {
												return { awi_id: verifier.awi_id, awi_label: getFullName(verifier) };
											})
									: verifiers.map(verifier => {
											return { awi_id: verifier.awi_id, awi_label: getFullName(verifier) };
									  })
							}
							onChange={e => setSearchInput({ ...searchInput, verifierSearch: e })}
							selectedData={selectedVerifier}
						/>

						<label>Request Label</label>
						<Input
							type="drop-down"
							data={requests}
							searchString={requests.filter(request => request.awi_id === selectedRequest)[0]?.awi_label || ''}
							onClick={handleRequestSelect}
							placeholder="Select Request"
							required={true}
						/>
					</div>
					<button
						disabled={!selectedAnnotator && !selectedRequest}
						onClick={() => {
							handleAssignment(selectedRequest, selectedAnnotator, selectedVerifier);
						}}
					>
						Assign
					</button>
				</div>
			</div>
			<div className={styles.backdrop} onClick={handleModalClosing}></div>
		</>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser,
		requestID: { awi_id: state.RequestReducer.awi_id, awi_label: state.RequestReducer.awi_label }
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setRequest: data => dispatch(RequestActions.setRequest(data))
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateBatch));
