import { client } from '../../util/client';
import { PostImagesToRequest } from '../../types';

interface DeleteImagesResponse {
	success: boolean;
	message: string;
}

export const deleteImagesfromImageBank = async (data: { awiContentKeys: number[] }) =>
	await client<DeleteImagesResponse>('/delete_images', {
		baseURL: 'content',
		method: 'POST',
		data
	}).then(res => res.data);
