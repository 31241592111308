import { LoggedUser } from '../../types';
import { client } from './../../util/client';

export type LoginData = {
	awi_email: string;
	awi_password: string;
	awi_forgotpassword_email: string;
	awi_reset_password: string;
	awi_reset_confirmpassword: string;
	awi_verification_status?: string;
	awi_agreement_accepted: boolean;
};

type LoginResponse = {
	success: boolean;
	message: string;
} & LoggedUser;

export const login = (data: LoginData) => client<LoginResponse>('/login_user', { baseURL: 'user', method: 'POST', data }).then(res => res.data);
