import { useState, useEffect } from 'react';

import { fetchImageBank } from '../../../handlers/content';

import ImageAnnotationPreviewer from '../ImageAnnotationPreviewer';

import { useInfiniteQuery } from '../../../util/useInfiniteQuery';
import { getFullName } from '../../../util/common.utils';

import { Content } from '../../../types';
import { ShowAnnotationStatus } from '../../../types/annotations';

import styles from './styles.module.scss';
import { useQuery } from '../../../util/useQuery';
import { getRequestData } from '../../../handlers/request/getRequestData';

interface CarouselProps {
	images?: Content[];
	imageKeys?: number[];
	handleToggleCarousel?: () => void;
	requestId?: number;
	requestName?: string;
	showAnnotationType?: ShowAnnotationStatus;
	awi_reference_image?: boolean;
}

const Carousel: React.FC<CarouselProps> = ({
	images = [],
	requestName,
	imageKeys = [],
	handleToggleCarousel,
	requestId,
	showAnnotationType = 'all',
	awi_reference_image = false
}) => {
	const [current, setCurrent] = useState(0);
	const [showDetails, setShowDetails] = useState(false);
	const [showAnnotations, setShowAnnotations] = useState(false);

	const { data: request } = useQuery(...getRequestData(+JSON.parse(localStorage.getItem('request')!)?.awi_id));

	useEffect(() => {
		if (showAnnotationType !== 'all') {
			setShowAnnotations(true);
		}
	}, [showAnnotationType]);

	const handleNextClick = () => {
		if (current === imageKeys.length - 1) {
			return;
		}

		const carousel = document.getElementById('carouselImages');
		const parentWidth = carousel?.clientWidth || 0;
		const width = (carousel?.firstChild as HTMLElement).clientWidth;
		carousel!.scrollLeft += width;
		carousel!.scrollLeft -= (parentWidth - width) / 2 - 16;
		setCurrent(current => current + 1);
	};
	const handlePrevClick = () => {
		if (current === 0) {
			return;
		}
		const carousel = document.getElementById('carouselImages');
		const parentWidth = carousel?.clientWidth || 0;
		const width = (carousel?.firstChild as HTMLElement).clientWidth;
		carousel!.scrollLeft -= width;
		carousel!.scrollLeft += (parentWidth - width) / 2 - 16;
		setCurrent(current => current - 1);
	};

	const handleImageScrollUpdate = (index: number) => {
		if (current !== index) {
			const carousel = document.getElementById('carouselImages');
			const parentWidth = carousel?.clientWidth || 0;
			const width = (carousel?.firstChild as HTMLElement).clientWidth;
			if (index > current) {
				carousel!.scrollLeft += width;
				if (current === 0) {
					carousel!.scrollLeft -= (parentWidth - width) / 2 - 16;
				} else {
					carousel!.scrollLeft += 16;
				}
			} else {
				carousel!.scrollLeft -= width;
				if (current === images.length - 1) {
					carousel!.scrollLeft += (parentWidth - width) / 2 - 16;
				} else {
					carousel!.scrollLeft -= 16;
				}
			}
			setCurrent(index);
		}
	};

	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if (['ArrowLeft', 'ArrowRight'].includes(e.key)) {
				e.preventDefault();
			}
		};

		const handleWheel = (e: WheelEvent) => {
			e.preventDefault();
		};

		const handleTouchMove = (e: TouchEvent) => {
			e.preventDefault();
		};

		document.addEventListener('keydown', handleKeyDown);
		document.addEventListener('wheel', handleWheel, { passive: false });
		document.addEventListener('touchmove', handleTouchMove, { passive: false });

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
			document.removeEventListener('wheel', handleWheel);
			document.removeEventListener('touchmove', handleTouchMove);
		};
	}, []);

	const handleOpenDetails = (index: number) => {
		if (current === index) {
			setShowDetails(prev => !prev);
		} else {
			handleImageScrollUpdate(index);
		}
	};

	const [fetchImageBankKey, fetchImageBankFunction] = fetchImageBank({
		request: [],
		owner: [],
		location: [],
		classes: [],
		tags: [],
		source: [],
		awi_status: '',
		awi_annotation_status: '',
		awi_content_keys: imageKeys,
		awi_start_date: null,
		awi_end_date: null,
		awi_reference_image: awi_reference_image
	});

	const {
		data: keyImages
		// hasNextPage,
		// fetchNextPage,
		// isLoading,
		// isFetchingNextPage
	} = useInfiniteQuery(fetchImageBankKey, fetchImageBankFunction, {
		getNextPageParam: lastPage => (lastPage.next ? new URL(lastPage.next).searchParams.get('cursor') : null)
	});
	const handleToggleShowAnnotations = () => {
		setShowAnnotations(prev => !prev);
	};

	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	useEffect(() => {
		document.getElementsByTagName('body')[0].style.overflow = 'hidden';
		return () => {
			document.getElementsByTagName('body')[0].style.overflow = 'unset';
		};
	}, []);

	return (
		<>
			<div className={styles.backdrop} onClick={handleToggleCarousel}></div>
			<div className={styles.carousel}>
				<div className={styles.carousel__heading}>
					<div>
						<h3> {requestName !== '' && requestName !== undefined ? requestName + ' - ' : ''} Image Carousel</h3>
						<p>
							You have selected <span>{imageKeys.length} Images</span>
						</p>
					</div>
					<div className={styles.carousel__heading__icons}>
						<div>
							<img
								width="100%"
								height="100%"
								src={`/icons/password/${!showAnnotations ? 'show' : 'hide'}.png`}
								alt="show annotations"
								onClick={handleToggleShowAnnotations}
							/>
						</div>
					</div>
				</div>
				<div style={{ display: 'flex', gap: '10px' }}>
					<img
						style={{ transform: 'rotate(180deg)', opacity: current === 0 ? 0.5 : 1 }}
						src={`/icons/carousel/carousel_next.png`}
						alt="show annotations"
						onClick={handlePrevClick}
						className={styles.carousel__buttons}
					/>
					<div className={styles.carousel__images} id="carouselImages">
						{keyImages?.pages.map(page =>
							[...page.result].map((image, index) => (
								<div
									key={index}
									// onClick={() => {
									// 	const _currentIndex = keyImages.pages
									// 		.map(page => page.result.map(ele => ele.awi_id))
									// 		.flat()
									// 		.indexOf(image.awi_id);
									// 	setCurrentImageIndex(_currentIndex);
									// 	handleImageScrollUpdate(_currentIndex < 2 ? index : 2);
									// }}
								>
									<div>
										<ImageAnnotationPreviewer
											className={styles.carousel__images__imageContainer}
											imageUrl={image.awi_content_url}
											showAnnotations={showAnnotations}
											content_id={image.awi_content_key}
											request_id={requestId}
											showAnnotationType={showAnnotationType}
											isCarousel={true}
										/>
									</div>
									{!awi_reference_image && (
										<div className={styles.carousel__images__details}>
											<p>IMG_{image.awi_content_key}</p>
											<button onClick={() => handleOpenDetails(index)}>Image Details</button>
										</div>
									)}
									{showDetails && current === index && (
										<div className={styles.carousel__images__details__container}>
											<div className={styles.carousel__images__details__container__assign}>
												<p>Assigned</p>
												<p>Location : {(image.awi_location as any)[0]?.awi_label || '--'}</p>
											</div>
											<div className={styles.carousel__images__details__container__users}>
												<p>Uploaded By: {getFullName(image.awi_user)}</p>
											</div>
											<div>
												<p>Mapped Requests : {image.awi_request_labels.map(ele => ele.awi_label).join(', ') || '--'}</p>
											</div>
											<div className={styles.carousel__images__details__container__tags}>
												<img src="/icons/bookmark.svg" width={20} height={20} alt="bookmark tag" />
												Tags : {((image as any).awi_tags || []).map(ele => ele.awi_label).join(', ') || '--'}
											</div>
											<div className={styles.carousel__images__details__container__tags}>
												<img src="/icons/star.svg" width={20} height={20} alt="Star tag" />
												Classes : {image.awi_class_labels.map(ele => ele.awi_label).join(', ') || '--'}
											</div>
										</div>
									)}
									{awi_reference_image && (request?.awi_guidelines || []).length > 0 && (
										<div className={styles.carousel__images__guidelines}>
											<div className={styles.carousel__images__guidelines__heading}>Guidelines</div>
											<div className={styles.carousel__images__guidelines__content}>
												<div>
													{(request?.awi_guidelines || []).map((guideline, index) => (
														<div key={index}>
															{index + 1}. {guideline}
														</div>
													))}
												</div>
											</div>
										</div>
									)}
								</div>
							))
						)}
					</div>
					<img
						src={`/icons/carousel/carousel_next.png`}
						style={{ opacity: current === imageKeys.length - 1 ? 0.5 : 1 }}
						alt="show annotations"
						onClick={handleNextClick}
						className={styles.carousel__buttons}
					/>
				</div>
			</div>
		</>
	);
};
export default Carousel;
