import { MutationFunction, useMutation as useReactMutation, UseMutationOptions, UseMutationResult } from 'react-query';

/* prettier-ignore */
export const useMutation = <TData = unknown, TError = unknown, TVariables = unknown, TContext = unknown>(
	mutationFn: MutationFunction<TData, TVariables>,
	options?: UseMutationOptions<TData, TError, TVariables, TContext>
): UseMutationResult<TData, TError, TVariables, TContext> => {
	return useReactMutation<TData, TError, TVariables, TContext>(mutationFn, {
		...options
	});
};
