import { format } from 'date-fns';
import { LineChart, CartesianGrid, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts';

interface LineGraphProps {
	name: string;
	step: (string | null)[];
	metric: (number | null)[];
	timestamp: string[];
	xAxis: string;
}

const LineGraph: React.FC<LineGraphProps> = ({ name, step, metric, timestamp, xAxis: x = 'timestamp' }) => {
	if ((step || []).length === 0) return null;

	const _data = (step || []).map((ele, index) => {
		return {
			metric: metric[index],
			step: ele,
			timestamp: format(new Date(timestamp[index]), 'H:mm')
		};
	});

	return (
		<div style={{ padding: '8px 16px', border: '1px solid #115511', width: 'max-content', borderRadius: 8, paddingLeft: 0 }}>
			<p style={{ padding: 8 }}>{name}</p>
			<LineChart width={400} height={400} data={_data!}>
				<Line type={'monotone'} dataKey="metric" dot={false} stroke="#8884d8" />
				<CartesianGrid stroke="#ccc" />
				<XAxis dataKey={x} fontSize={12} />
				<YAxis fontSize={12} />
				<Tooltip />
				<Legend />
			</LineChart>
		</div>
	);
};
export default LineGraph;
