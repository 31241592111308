import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useRef, useState } from 'react';
import { format } from 'date-fns';
import clsx from 'clsx';
import { Redirect } from 'react-router-dom';
import { useInfiniteQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { getDatasets } from '../../../handlers/dataset/getDatasets';
import { fetchFilters } from '../../../handlers/dataset/fetchFilters';

import Container from '../../Container';
import { TableInfiniteScroll, TableBody, TableCell, TableHeader, TableHeaders, TableRow, Input, DatePicker, InfiniteScroll, Loader } from '../../Common';
import ClassProportionModal from '../Request/ClassProportionModal';

import { useQuery } from '../../../util/useQuery';

import { DatasetFilterType } from '../../../types/dataset';
import { AnnotationType, DatasetType, LoggedUser, MiniUser, RequestType } from '../../../types';

import { useMutation } from '../../../util/useMutation';
import { forceDownload, getFullName } from '../../../util/common.utils';
import { annotationData, requestType, datasetTypeData } from './utils/index';

import styles from './styles.module.scss';
import { exportDatasetFromDatasetPage } from '../../../handlers/dataset/exportDatasetFromDatasetPage';
import CreateJobModal from './CreateJobModal';
import { toast } from 'react-hot-toast';
interface DatasetProps {
	loggedUser: LoggedUser;
}

const DatasetPage: React.FC<DatasetProps> = ({ loggedUser }) => {
	const initialFilterData = {
		awi_creation_date: null,
		awi_annotation_type: [],
		awi_request_ids: [],
		awi_created_by: [],
		awi_class_ids: [],
		awi_location_ids: [],
		awi_tag_ids: [],
		awi_dataset_type: '' as DatasetType,
		awi_req_type: '' as RequestType
	};
	const [filters, setFilters] = useState<DatasetFilterType>(initialFilterData);
	const filterRef = useRef(initialFilterData as DatasetFilterType);
	const [getDatasetsKey, getDatasetsFunction] = getDatasets({
		...filterRef.current
	});

	const {
		data: newDatasetsData,
		hasNextPage,
		fetchNextPage,
		isLoading,
		isFetchingNextPage
	} = useInfiniteQuery(getDatasetsKey, getDatasetsFunction, {
		getNextPageParam: lastPage => {
			return lastPage.next ? new URL(lastPage.next).searchParams.get('cursor') : undefined;
		}
	});

	const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
	const [fetchFiltersKey, fetchFiltersFunction] = fetchFilters();
	const { data: filtersData } = useQuery(fetchFiltersKey, fetchFiltersFunction);

	const downloadDatasetMutation = useMutation(exportDatasetFromDatasetPage);

	const [selectedDatasetId, setSelectedDatasetId] = useState<number>(-1);
	const [selectedDataset, setSelectedDataset] = useState<any>({});
	const [openActionsModal, setOpenActionsModal] = useState<boolean>(false);
	const [openDialogBox, setOpenDialogBox] = useState<boolean>(false);
	const [openClassProportions, setOpenClassProportions] = useState<boolean>(false);
	const [openJobForm, setOpenJobForm] = useState<boolean>(false);

	const [selectedRequest, setSelectedRequest] = useState<string>('');

	const handleUpdateFilter = (id: number, name: string) => {
		if (name === 'awi_annotation_type') {
			setFilters(prev => {
				let type = '';
				if (id === 0) {
					type = 'rectangle';
				} else if (id === 1) {
					type = 'circle';
				} else if (id === 2) {
					type = 'polygon';
				} else if (id === 3) {
					type = 'dot';
				}
				const temp = [...prev[name]];
				if (prev[name]?.indexOf(type as AnnotationType) === -1) {
					temp.push(type as AnnotationType);
				} else {
					temp.splice(temp.indexOf(type as AnnotationType), 1);
				}
				return { ...prev, awi_annotation_type: temp };
			});
			return;
		}

		if (name === 'awi_req_type') {
			setFilters(prev => {
				let type = '';
				if (id === 0) {
					type = 'detector';
				} else if (id === 1) {
					type = 'classifier';
				}
				if (prev.awi_req_type === type) {
					type = '';
				}
				return { ...prev, awi_req_type: type as RequestType };
			});
			return;
		}

		if (name === 'awi_dataset_type') {
			setFilters(prev => {
				let type = '';
				if (id === 0) {
					type = 'total';
				} else if (id === 1) {
					type = 'verified';
				}
				if (prev.awi_req_type === type) {
					type = '';
				}
				return { ...prev, awi_dataset_type: type as DatasetType };
			});
			return;
		}

		if (filters[name].includes(id)) {
			setFilters(prev => ({
				...prev,
				[name]: prev[name].filter(item => item !== id)
			}));
		} else {
			setFilters(prev => ({
				...prev,
				[name]: [...prev[name], id]
			}));
		}
	};

	const handleDatasetDownload = () => {
		setOpenDialogBox(false);
		downloadDatasetMutation.mutate(
			{
				awi_dataset_id: selectedDatasetId
			},
			{
				onSuccess: res => {
					const url = res.signed_url;
					if (res.success && url) {
						const filename = url.split('\\').pop()?.split('/').pop();
						if (url.includes('amazonaws.com')) {
							forceDownload(url, filename);
						} else {
							fetch(url, {
								headers: new Headers({
									Origin: '*'
								}),
								mode: 'cors'
							})
								.then(response => response.blob())
								.then(blob => {
									const blobUrl = window.URL.createObjectURL(blob);
									forceDownload(blobUrl, filename);
								})
								.catch(e => console.error(e));
						}
					} else {
						toast.error('failed to download dataset');
					}
				}
			}
		);
	};

	const handleDateChange = (date: Date, key: string) => {
		setFilters(prev => {
			const temp = { ...prev };
			temp[key] = date;
			return { ...temp };
		});
	};

	const handleApplyFilter = () => {
		filterRef.current = filters as DatasetFilterType;
		setIsFilterOpen(false);
	};

	const handleResetFilter = () => {
		setFilters(initialFilterData);
	};

	const toggleShowFilter = () => {
		setIsFilterOpen(!isFilterOpen);
	};

	const checkFilterSelect = (): boolean => {
		return JSON.stringify(filters) === JSON.stringify(initialFilterData);
	};

	const handleClassProportionClick = (awi_id: number, requestLabel: string) => {
		setOpenActionsModal(false);
		setOpenClassProportions(true);
		setSelectedRequest(requestLabel);
		setSelectedDatasetId(awi_id);
	};

	const handleClassProportionModalClose = () => {
		setOpenClassProportions(false);
	};

	const handleCloseDialogModal = () => {
		setOpenDialogBox(false);
	};

	const handleExportDatasetClick = (awi_id: number) => {
		setOpenActionsModal(false);
		setSelectedDatasetId(awi_id);
		setOpenDialogBox(true);
	};

	const handleActionsButtonClick = (dataset: any) => {
		setSelectedDataset(dataset);
		setOpenActionsModal(true);
	};

	const handleActionsModalClose = () => {
		setOpenActionsModal(false);
	};

	const handleAddJobClick = () => {
		setOpenActionsModal(false);
		setOpenJobForm(true);
	};

	const handleAddJobModalClose = () => {
		setOpenJobForm(false);
	};

	if (!['awi_developer', 'awi_manager'].includes(loggedUser.userObj.awi_level)) {
		return <Redirect to="/"></Redirect>;
	}

	if (!newDatasetsData) {
		return null;
	}

	return (
		<Container className={styles.root__container}>
			{/* {downloadDatasetMutation.isLoading && (
				<div className={styles.loader}>
					<Loader></Loader>
				</div>
			)} */}
			<div className={styles.root__container__header}>
				<div className={styles.root__container__header__actions}>
					<div className={styles.root__container__header__actions__iconcontainer}>
						<img src={`/icons/filter/filter${checkFilterSelect() ? '' : '--selected'}.svg`} onClick={toggleShowFilter} title="Filters" />
					</div>
					{isFilterOpen && (
						<>
							<div className={clsx(styles.backdrop, styles.backdrop__transparent)} onClick={toggleShowFilter}></div>
							<div className={styles.dataset__filter}>
								<div className={styles.dataset__filter__heading}>Filters</div>
								<form>
									<div className={styles.dataset__filter__grid}>
										<div>Start date</div>
										<DatePicker
											value={filters.awi_creation_date}
											onChange={(date: Date) => handleDateChange(date, 'awi_creation_date')}
											dateFormat="MMMM dd, yyyy"
											maxDate={new Date()}
										/>
										<label>Classes</label>
										<Input
											placeholder="Select Classes"
											searchString={filtersData?.result[0].awi_classes
												?.filter(ele => filters.awi_class_ids.includes(ele.awi_id))
												.map(ele => ele.awi_label)
												.join(', ')}
											data={filtersData?.result[0].awi_classes?.map(ele => {
												return { awi_id: ele.awi_id, awi_label: ele.awi_label };
											})}
											onClick={handleUpdateFilter}
											type="drop-down"
											name="awi_class_ids"
											selectedData={filters.awi_class_ids}
										/>

										<label>Requests</label>
										<Input
											placeholder="Select Requests"
											searchString={filtersData?.result[0].awi_requests
												?.filter(ele => filters.awi_request_ids.includes(ele.awi_id))
												.map(ele => ele.awi_label)
												.join(', ')}
											data={filtersData?.result[0].awi_requests?.map(ele => {
												return { awi_id: ele.awi_id, awi_label: ele.awi_label };
											})}
											onClick={handleUpdateFilter}
											type="drop-down"
											name="awi_request_ids"
											selectedData={filters.awi_request_ids}
										/>
										<label>Created By</label>
										<Input
											placeholder="Select Users"
											searchString={filtersData?.result[0].awi_users
												?.filter(ele => filters.awi_created_by.includes(ele.awi_id))
												.map(ele => getFullName(ele as MiniUser))
												.join(', ')}
											data={filtersData?.result[0].awi_users?.map(ele => {
												return { awi_id: ele.awi_id, awi_label: getFullName(ele as MiniUser) };
											})}
											onClick={handleUpdateFilter}
											type="drop-down"
											name="awi_created_by"
											selectedData={filters.awi_created_by}
										/>

										<label>Locations</label>
										<Input
											placeholder="Select Locations"
											searchString={filtersData?.result[0].awi_locations
												?.filter(ele => filters.awi_location_ids.includes(ele.awi_id))
												.map(ele => ele.awi_label)
												.join(', ')}
											data={filtersData?.result[0].awi_locations?.map(ele => {
												return { awi_id: ele.awi_id, awi_label: ele.awi_label };
											})}
											onClick={handleUpdateFilter}
											type="drop-down"
											name="awi_location_ids"
											selectedData={filters.awi_location_ids}
										/>

										<label>Tags</label>
										<Input
											placeholder="Select Tags"
											searchString={filtersData?.result[0].awi_tags
												?.filter(ele => filters.awi_tag_ids.includes(ele.awi_id))
												.map(ele => ele.awi_label)
												.join(', ')}
											data={filtersData?.result[0].awi_tags?.map(ele => {
												return { awi_id: ele.awi_id, awi_label: ele.awi_label };
											})}
											onClick={handleUpdateFilter}
											type="drop-down"
											name="awi_tag_ids"
											selectedData={filters.awi_tag_ids}
										/>

										<label>Annotation Type</label>
										<Input
											placeholder="Select Annotation"
											searchString={annotationData
												?.filter(ele => filters.awi_annotation_type.includes(ele.awi_label as AnnotationType))
												.map(ele => ele.awi_label)
												.join(', ')}
											data={annotationData?.map(ele => {
												return { awi_id: ele.awi_id, awi_label: ele.awi_label };
											})}
											onClick={handleUpdateFilter}
											type="drop-down"
											name="awi_annotation_type"
										/>

										<label>Request Type</label>
										<Input
											placeholder="Select Request Tyoe"
											searchString={requestType
												?.filter(ele => filters.awi_req_type!.includes(ele.awi_label as RequestType))
												.map(ele => ele.awi_label)
												.join(', ')}
											data={requestType?.map(ele => {
												return { awi_id: ele.awi_id, awi_label: ele.awi_label };
											})}
											onClick={handleUpdateFilter}
											type="drop-down"
											name="awi_req_type"
										/>

										<label>Dataset Type</label>
										<Input
											placeholder="Select Dataset Type"
											searchString={datasetTypeData
												?.filter(ele => filters.awi_dataset_type.includes(ele.awi_label as AnnotationType))
												.map(ele => ele.awi_label)
												.join(', ')}
											data={datasetTypeData?.map(ele => {
												return { awi_id: ele.awi_id, awi_label: ele.awi_label };
											})}
											onClick={handleUpdateFilter}
											type="drop-down"
											name="awi_dataset_type"
										/>
									</div>

									<div className={styles.dataset__filter__buttons}>
										<button onClick={handleApplyFilter}>Apply</button>
										<button onClick={handleResetFilter}>Reset</button>
									</div>
								</form>
							</div>
						</>
					)}
				</div>
			</div>
			<div className={styles.dataset__panel__container}>
				<TableInfiniteScroll>
					<TableHeaders className={styles.dataset__panel__table_header}>
						<TableRow>
							<TableHeader>S.NO</TableHeader>
							<TableHeader>DATASET TYPE</TableHeader>
							<TableHeader>REQ. LABEL</TableHeader>
							<TableHeader>Created On</TableHeader>
							<TableHeader>Annotation type</TableHeader>
							<TableHeader>Request type</TableHeader>
							<TableHeader>NO. of Jobs</TableHeader>
							<TableHeader>NO. of Images</TableHeader>
							<TableHeader>NO. of Annotations</TableHeader>
							<TableHeader>NO. of Classes</TableHeader>
							<TableHeader>NO. of Tags + Locations</TableHeader>
							<TableHeader>ACTIONS</TableHeader>
						</TableRow>
					</TableHeaders>
					<TableBody>
						<InfiniteScroll hasMore={Boolean(hasNextPage)} isLoading={isLoading || isFetchingNextPage} next={fetchNextPage} loader={<Loader marginTop={16} />}>
							{newDatasetsData?.pages.map((page, index) => {
								return (
									<>
										{page.result.map((dataset, dataIndex) => {
											return (
												<TableRow key={dataIndex}>
													<TableCell>{index * newDatasetsData?.pages[0].result.length + (dataIndex + 1)}</TableCell>
													<TableCell>{dataset.awi_dataset_type}</TableCell>
													<TableCell>{dataset.awi_request.awi_label}</TableCell>
													<TableCell>{format(new Date(dataset.awi_timestamp.awi_created_at || null), 'dd MMM YYY, H:mm')}</TableCell>
													<TableCell>{dataset.awi_request.awi_annotation_type}</TableCell>
													<TableCell>{dataset.awi_request.awi_request_type}</TableCell>
													<TableCell>
														{(dataset?.awi_job || []).length > 0 && dataset?.awi_job[0]?.total ? (
															<Link
																to={{
																	pathname: `/jobs`,
																	state: {
																		awi_dataset_id: dataset.awi_id,
																		awi_project_name: dataset.awi_job[0]?.project_name || ''
																	}
																}}
															>
																{/* any changes in state should be reflected in navbar !imp */}
																<span style={{ textDecoration: 'none', textAlign: 'right' }}> {dataset.awi_job[0]?.total || 0}</span>
															</Link>
														) : (
															dataset.awi_job[0]?.total || 0
														)}
													</TableCell>
													<TableCell>{dataset.awi_content_keys?.length || 0}</TableCell>
													<TableCell>{dataset.awi_annotations_count || 0}</TableCell>
													<TableCell>
														{dataset.awi_class_ids?.length > 0 ? (
															<Link
																to={{
																	pathname: `/classlist`,
																	state: { awi_class_ids: dataset.awi_class_ids, awi_request_label: dataset.awi_request.awi_label }
																}}
															>
																<span style={{ textDecoration: 'none' }}> {dataset.awi_class_ids?.length} </span>
															</Link>
														) : (
															dataset.awi_class_ids?.length
														)}
													</TableCell>
													<TableCell>
														{dataset.awi_tag_ids?.length + dataset.awi_location_ids?.length > 0 ? (
															<Link
																to={{
																	pathname: `/tagsandlocations`,
																	state: { awi_tag_ids: dataset.awi_tag_ids, awi_location_ids: dataset.awi_location_ids }
																}}
															>
																<span style={{ textDecoration: 'none', color: 'black' }}>
																	{' '}
																	{dataset.awi_tag_ids?.length + dataset.awi_location_ids?.length}{' '}
																</span>
															</Link>
														) : (
															dataset.awi_tag_ids?.length + dataset.awi_location_ids?.length
														)}
													</TableCell>
													<TableCell>
														<>
															<img
																src="/icons/add/transparent--blue.svg"
																alt="request actions"
																width={20}
																height={20}
																onClick={() => handleActionsButtonClick(dataset)}
																title="Dataset actions"
																className={styles.actions__button}
															/>

															{openActionsModal && selectedDataset?.awi_id === dataset.awi_id && (
																<div className={styles.actions__modal}>
																	{/* <li onClick={() => handleAddJobClick()}>Add job</li> */}
																	<li onClick={() => handleClassProportionClick(dataset.awi_id, dataset.awi_request.awi_label)}>Class proportions</li>
																	<li onClick={() => handleExportDatasetClick(dataset.awi_id)}>Export dataset</li>
																</div>
															)}
														</>
													</TableCell>
													{}
												</TableRow>
											);
										})}
										<span style={{ height: 10, width: '100%', marginBottom: -20, display: 'block' }}></span>
										<span style={{ height: 0, width: '100%', display: 'block' }}></span>
									</>
								);
							})}
						</InfiniteScroll>
					</TableBody>
				</TableInfiniteScroll>
			</div>
			{openClassProportions && selectedDatasetId !== -1 && (
				<ClassProportionModal
					handleCloseModal={handleClassProportionModalClose}
					datasetId={selectedDatasetId}
					requestLabel={selectedRequest}
				></ClassProportionModal>
			)}

			{openActionsModal && <div className={styles.backdrop} style={{ backgroundColor: 'rgba(0,0,0,0)' }} onClick={handleActionsModalClose}></div>}

			{openDialogBox && (
				<div className={styles.dialog__modal}>
					<div className={styles.dialog__modal__note}>
						Note* - <span style={{ color: 'red' }}>Dataset will change in case of Total dataset type</span>
					</div>
					<div className={styles.dialog__modal__question}>Are you sure you want to download the dataset?</div>
					<div className={styles.dialog__modal__buttons}>
						<button onClick={() => handleDatasetDownload()} className={styles.dialog__modal__buttons}>
							Download
						</button>
						<button onClick={handleCloseDialogModal} className={styles.dialog__modal__buttons}>
							Cancel
						</button>
					</div>
				</div>
			)}
			{openDialogBox && <div className={styles.backdrop} onClick={handleCloseDialogModal}></div>}
			{openJobForm && <CreateJobModal handleModalClose={handleAddJobModalClose} dataset={selectedDataset}></CreateJobModal>}
			{openJobForm && <div className={styles.backdrop} onClick={handleAddJobModalClose}></div>}
		</Container>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps, null)(DatasetPage));
