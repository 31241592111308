import { AxiosError } from 'axios';
import { toast } from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { getClass } from '../../../handlers/class';
import { updateRequest } from '../../../handlers/request';
import { fetchUsers } from '../../../handlers/user/fetchUsers';
import { postRequest } from '../../../handlers/request/postRequest';
import { getRequestData } from '../../../handlers/request/getRequestData';
import { postReferenceImages } from '../../../handlers/content/postReferenceImages';

import { DatePicker, Input } from '../../Common';

import { useQuery } from '../../../util/useQuery';
import { useMutation } from '../../../util/useMutation';
import { byteToSize, getFullName } from '../../../util/common.utils';

import styles from './styles.module.scss';

interface CreateRequestModalProps {
	handleModalClose: () => void;
	isUpdateRequest?: boolean;
	requestId?: number;
	requestsKey: any;
}

export type RequestMini = {
	awi_label: string;
	awi_description: string;
	awi_min_bqc: number;
	awi_min_images: number;
	awi_timestamp: { awi_deadline: Date };
	awi_annotation_type: 'rectangle' | 'polygon' | 'dot' | 'circle';
	awi_type: 'classifier' | 'detector';
	awi_manager?: number;
	awi_class_id: number[];
	awi_reference_batch?: boolean;
	awi_reference_batch_ids?: number[];
	awi_request_for: 'outlineandattributes' | 'attributes' | 'outline';
	awi_guidelines?: string[];
	awi_reference_images?: {
		awi_ids: number[];
		awi_annotation_completed: boolean;
	};
};

const CreateRequestModal: React.FC<CreateRequestModalProps> = ({ handleModalClose, requestId, isUpdateRequest = false, requestsKey }) => {
	const [state, setState] = useState(0);
	const [files, setFiles] = useState<File[]>([]);

	const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFiles(prev => [...prev, ...Array.from(e.target.files!)]);
	};

	const initialRequestDataState = {
		awi_annotation_type: 'rectangle',
		awi_class_id: [],
		awi_description: '',
		awi_label: '',
		awi_manager: undefined,
		awi_min_bqc: 0,
		awi_min_images: 0,
		awi_timestamp: { awi_deadline: new Date() },
		awi_type: 'detector',
		awi_reference_batch: false,
		awi_reference_batch_ids: [],
		awi_request_for: 'outlineandattributes',
		awi_guidelines: ['']
	};

	const [fetchRequestKey, fetchRequestFunction] = getRequestData(requestId!);

	const { data: requestDetails } = useQuery(fetchRequestKey, fetchRequestFunction, { enabled: isUpdateRequest && !!requestId });

	const [requestData, setRequestData] = useState<RequestMini>(initialRequestDataState as RequestMini);

	const updateRequestMutation = useMutation(updateRequest);
	const postRequestMutation = useMutation(postRequest);
	const uploadImageMutation = useMutation(postReferenceImages);

	const queryClient = useQueryClient();

	// For guidelines input

	const handleGuidelinesChange = (index, event) => {
		const { value } = event.target;
		setRequestData(prev => {
			const temp = { ...prev };
			if (temp.awi_guidelines) {
				temp.awi_guidelines[index] = value;
			}
			return { ...temp };
		});
	};

	const handleDeleteGuideline = (index: number) => {
		const prevGuidelines = [...(requestData.awi_guidelines || [])];

		prevGuidelines.splice(index, 1);

		setRequestData(prev => {
			const temp = { ...prev };
			temp.awi_guidelines = [...prevGuidelines];
			return { ...temp };
		});
	};

	const addPoint = () => {
		const prevGuidelines = requestData.awi_guidelines;

		if (prevGuidelines) {
			setRequestData(prev => {
				const temp = { ...prev };
				temp.awi_guidelines = [...prevGuidelines, ''];
				return { ...temp };
			});
		}
	};

	useEffect(() => {
		if (isUpdateRequest && requestDetails) {
			setRequestData({
				awi_annotation_type: requestDetails.awi_annotation_type,
				awi_class_id: requestDetails.awi_classes.map(_class => _class.awi_id),
				awi_description: requestDetails.awi_description,
				awi_label: requestDetails.awi_label,
				awi_min_bqc: requestDetails.awi_min_bqc,
				awi_min_images: requestDetails.awi_min_images,
				awi_timestamp: requestDetails.awi_timestamp,
				awi_type: requestDetails.awi_type,
				awi_manager: requestDetails.awi_manager_data[0].awi_id,
				awi_reference_batch: requestDetails.awi_reference_batch,
				awi_reference_batch_ids: requestDetails?.awi_reference_images?.awi_ids || [],
				awi_request_for: requestDetails?.awi_request_for,
				awi_guidelines: requestDetails?.awi_guidelines || []
			});
		}
	}, [isUpdateRequest, requestDetails]);

	const [fetchManagerKey, fetchManagerFunction] = fetchUsers(['awi_manager']);

	const { data: manager } = useQuery(fetchManagerKey, fetchManagerFunction);

	const [getClasskey, getClassFunction] = getClass();
	const { data: classes } = useQuery(getClasskey, getClassFunction);

	const handleNextState = (_state: number) => {
		setState(_state);
	};

	const handleCreateRequest = () => {
		return null;
	};

	const handleInputChange = (event: React.ChangeEvent) => {
		const target = event.target as HTMLInputElement;

		setRequestData(prev => {
			const temp: any = { ...prev };
			const key = target.name as keyof RequestMini;
			if (key === 'awi_reference_batch') {
				temp[key] = Boolean(+target.value);
			} else if (key === 'awi_min_bqc') {
				const val = target.value;
				if (prev.awi_min_images < parseInt(val)) {
					toast.error('Quality Assuarance count cannot be more than Minimum Images');
					return prev;
				} else if (parseInt(val) < 0) {
					toast.error('Quality Assuarance count cannot be less than 0');
					return prev;
				} else if (prev.awi_min_bqc === 0) {
					temp[key] = +prev.awi_min_bqc + val;
				} else {
					temp[key] = val;
				}
			} else if (key === 'awi_timestamp') {
				temp.awi_timestamp.awi_deadline = target.value;
			} else if (key === 'awi_min_images') {
				if (+target.value < 999999) {
					temp[key] = target.value;
				}
			} else {
				temp[key] = target.value;
			}
			return { ...temp };
		});
	};
	type AnnotationType = 'rectangle' | 'circle' | 'polygon' | 'dot';

	type RequestForType = 'outlineandattributes' | 'outline' | 'attributes';

	const handleDropdownChange = (id: number, name: string) => {
		if (name === 'awi_annotation_type') {
			setRequestData(prev => {
				let type = '';
				if (id === 0) {
					type = 'rectangle';
				} else if (id === 1) {
					type = 'circle';
				} else if (id === 2) {
					type = 'polygon';
				} else if (id === 3) {
					type = 'dot';
				}
				prev[name] = type as AnnotationType;
				return { ...prev };
			});
			return;
		} else if (name === 'awi_request_for') {
			setRequestData(prev => {
				let type = '';
				if (id === 0) {
					type = 'outlineandattributes';
				} else if (id === 1) {
					type = 'outline';
				} else if (id === 2) {
					type = 'attributes';
				}
				prev[name] = type as RequestForType;
				return { ...prev };
			});
			return;
		}
	};

	const handleDateChange = (date: Date) => {
		setRequestData(prev => {
			const temp = { ...prev };
			temp.awi_timestamp.awi_deadline = date;
			return { ...temp };
		});
	};

	const handleSubmitRequest = async (event: React.FormEvent) => {
		event.preventDefault();
		const formData = new FormData();

		files.forEach(file => {
			formData.append('file', file);
		});

		if (isUpdateRequest && requestId) {
			formData.append(
				'awi_body',
				JSON.stringify({
					awi_class_id: requestData.awi_class_id,
					awi_deadline: requestData.awi_timestamp.awi_deadline,
					awi_description: requestData.awi_description,
					awi_label: requestData.awi_label,
					awi_min_images: requestData.awi_min_images,
					awi_guidelines: (requestData.awi_guidelines || []).filter(item => item !== '')
				})
			);
			updateRequestMutation.mutate(
				{
					id: requestId,
					data: formData
				},
				{
					onSuccess: () => {
						queryClient.invalidateQueries(fetchRequestKey);
						handleModalClose();
						queryClient.invalidateQueries(requestsKey);
						toast.success('Request Updated');
					}
				}
			);
		} else {
			formData.append('awi_body', JSON.stringify({ ...requestData, awi_guidelines: (requestData.awi_guidelines || []).filter(item => item !== '') }));
			postRequestMutation.mutate(
				{
					data: formData
				},
				{
					onSuccess: res => {
						if (res.success) {
							handleModalClose();
							toast.success(res.message);
							queryClient.invalidateQueries(requestsKey);
						} else {
							toast.error(res.message || 'Something went wrong');
						}
					},
					onError: (err: any) => {
						toast.error((err as AxiosError).response?.data.message);
					}
				}
			);
		}
	};

	const checkFormValidation = (keys: string[]) => {
		let isValid = true;
		keys.forEach(key => {
			if (requestData[key] === initialRequestDataState[key]) {
				isValid = false;
			}
		});

		return isValid;
	};

	const handleAddClass = (id: number) => {
		const temp = [...requestData.awi_class_id];

		if (isUpdateRequest) {
			if (!requestDetails?.awi_classes.map(_class => _class.awi_id).includes(id)) {
				if (temp.includes(id)) {
					temp.splice(temp.indexOf(id), 1);
				} else {
					temp.push(id);
				}
			}
		} else {
			if (temp.includes(id)) {
				temp.splice(temp.indexOf(id), 1);
			} else {
				temp.push(id);
			}
		}

		setRequestData(prev => {
			return { ...prev, awi_class_id: [...temp] };
		});
	};

	const handleUploadImages = () => {
		const formData = new FormData();

		files.forEach(file => {
			formData.append('file', file);
		});

		uploadImageMutation.mutate(
			{ data: formData },
			{
				onSuccess: res => {
					if (res.success) {
						toast.success(res.message);
						setFiles([]);
						queryClient.invalidateQueries(fetchRequestKey);
						setRequestData(prev => {
							return { ...prev, awi_reference_batch_ids: (res?.result || []).map(resp => resp.awi_id) };
						});
					}
				}
			}
		);
	};
	const annotationData = [
		{
			awi_id: 0,
			awi_label: 'rectangle'
		},
		{
			awi_id: 1,
			awi_label: 'circle'
		},
		{
			awi_id: 2,
			awi_label: 'polygon'
		},
		{
			awi_id: 3,
			awi_label: 'dot'
		}
	];

	return (
		<>
			<div className={styles.modal}>
				<div className={styles.modal__close_button} onClick={handleModalClose}>
					<img src="/icons/close.svg" />
				</div>
				<div style={{ padding: '24px 0' }}>
					<p style={{ fontSize: 24, fontWeight: 500 }}>{isUpdateRequest ? 'Update Request' : 'Create Request'}</p>
				</div>
				<form onSubmit={handleSubmitRequest}>
					{state === 0 && (
						<>
							<div className={styles.upload__form__grid}>
								<label>
									Request Label <span className={styles.asterisk}>*</span>
								</label>
								<input type={'text'} required onChange={handleInputChange} name="awi_label" placeholder="Enter Request Label" value={requestData.awi_label} />
								<label>Request Description</label>
								<input
									type={'text'}
									onChange={handleInputChange}
									name="awi_description"
									placeholder="Enter Request Description"
									value={requestData.awi_description}
								/>
								<label>
									Min Image Required <span className={styles.asterisk}>*</span>
								</label>
								<input
									type={'number'}
									required
									onChange={handleInputChange}
									name="awi_min_images"
									placeholder="Enter Min Image Required"
									value={Number(requestData.awi_min_images).toString()}
									maxLength={6}
									max={999999}
								/>
								<label>
									Quality Assuarance Count <span className={styles.asterisk}>*</span>
								</label>
								<input
									type={'number'}
									required
									onChange={handleInputChange}
									name="awi_min_bqc"
									disabled={isUpdateRequest}
									placeholder="Enter Min BQC count"
									max={requestData.awi_min_images}
									value={Number(requestData.awi_min_bqc).toString()}
								/>
								<div>End date</div>
								<DatePicker
									value={new Date(requestData.awi_timestamp.awi_deadline)}
									onChange={(date: Date) => handleDateChange(date)}
									dateFormat="MMMM dd, yyyy h:mm aa"
									isTimePicker={true}
									minDate={new Date()}
								/>
								<label>Type</label>
								<div style={{ display: 'flex', gap: 24 }}>
									<div style={{ display: 'flex', gap: 8 }}>
										<label>Detector</label>
										<input
											name="awi_type"
											disabled={isUpdateRequest}
											checked={requestData.awi_type === 'detector'}
											type={'radio'}
											onChange={handleInputChange}
											value="detector"
										/>
									</div>
									<div style={{ display: 'flex', gap: 8 }}>
										<label>Classifier</label>
										<input
											name="awi_type"
											disabled={isUpdateRequest}
											type={'radio'}
											checked={requestData.awi_type === 'classifier'}
											onChange={handleInputChange}
											value="classifier"
										/>
									</div>
								</div>
								<label>
									Annotation Type <span className={styles.asterisk}>*</span>
								</label>
								<Input
									placeholder="Select Users"
									searchString={annotationData
										?.filter(user => requestData.awi_annotation_type === user.awi_label)
										.map(user => user.awi_label)
										.join(', ')}
									data={annotationData?.map(annotationType => {
										return { awi_id: annotationType.awi_id, awi_label: annotationType.awi_label };
									})}
									onClick={handleDropdownChange}
									type="drop-down"
									name="awi_annotation_type"
									disabled={isUpdateRequest}
								/>

								<label>
									Classes <span className={styles.asterisk}>*</span>
								</label>
								<div>
									<Input data={classes} placeholder="Select Class" onClick={handleAddClass} selectedData={requestData.awi_class_id} />
									<div className={styles.classes__tags}>
										{(classes || [])
											.filter(_class => requestData.awi_class_id.indexOf(_class.awi_id) !== -1)
											.map((tag, index) => (
												<div key={index} className={styles.classes__tags__item}>
													<p>{tag.awi_label}</p>
													{requestDetails?.awi_classes?.findIndex(req => req.awi_id === tag.awi_id) === -1 && (
														<img src="/icons/close.svg" width={8} height={8} onClick={() => handleAddClass(tag.awi_id)} />
													)}
												</div>
											))}
									</div>
								</div>
							</div>
							<div className={styles.modal__buttons}>
								<button
									onClick={() => {
										if (checkFormValidation(['awi_label', 'awi_min_bqc', 'awi_min_images', 'awi_timestamp', 'awi_class_id'])) {
											handleNextState(1);
										}
									}}
								>
									Next
								</button>
							</div>
						</>
					)}
					{state === 1 && (
						<>
							<div className={styles.upload__form__grid}>
								<label>Assign To *</label>
								<Input
									disabled={isUpdateRequest}
									searchString={getFullName(manager?.filter(user => user.awi_id === requestData.awi_manager)[0])}
									data={manager?.map(user => {
										return { awi_id: user.awi_id, awi_label: getFullName(user) };
									})}
									onClick={(id: number) =>
										setRequestData(prev => {
											return { ...prev, awi_manager: id };
										})
									}
									
								/>
							</div>
							<div className={styles.modal__buttons}>
								<button onClick={() => handleNextState(0)}>Back</button>
								<button
									type="button"
									onClick={() => {
										if (checkFormValidation(['awi_manager'])) {
											handleNextState(2);
										} else {
											toast.error('Please select a manager');
										}
									}}
								>
									Next
								</button>
							</div>
						</>
					)}
					{state === 2 && (
						<div>
							<div>
								<div className={styles.upload__form__guidelines__heading}>
									<label>Add Guidelines</label>
									<img src="/icons/upload.svg" onClick={addPoint} alt="upload icon" title="Upload Images" />
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										gap: 16,
										marginTop: 16
									}}
								>
									{(requestData.awi_guidelines || []).map((point, index) => (
										<div
											key={index}
											style={{
												display: 'flex',
												alignItems: 'space-between',
												justifyContent: 'space-between',
												gap: 16
											}}
										>
											<div>{index + 1} .</div>
											<textarea
												key={index}
												style={{
													width: '500px',
													height: 'auto',
													overflowX: 'hidden',
													textOverflow: 'ellipsis',
													border: '1px solid grey',
													padding: '8px 16px',
													borderRadius: '6px',
													fontFamily: 'Lato',
													resize: 'none'
												}}
												value={point}
												onChange={event => handleGuidelinesChange(index, event)}
												placeholder={`Enter Point ${index + 1}`}
											/>
											<img
												onClick={() => handleDeleteGuideline(index)}
												style={{
													cursor: 'pointer'
												}}
												src="/icons/delete.svg"
											></img>
											{/* <div onClick={() => handleDeleteGuideline(index)}>
											</div> */}
										</div>
									))}
								</div>
							</div>
							<div className={styles.modal__buttons}>
								<button onClick={() => handleNextState(1)}>Back</button>
								<button
									type="button"
									onClick={() => {
										handleNextState(3);
									}}
								>
									Next
								</button>
							</div>
						</div>
					)}
					{state === 3 && (
						<>
							<>
								<div>Do you want to add reference images?</div>
								<div style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '8px'
										}}
									>
										<label htmlFor="yes">Yes</label>
										<input checked={requestData.awi_reference_batch} value={1} onChange={handleInputChange} type="radio" name="awi_reference_batch" id="yes" />
									</div>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '8px'
										}}
									>
										<label htmlFor="no">No</label>
										<input onChange={handleInputChange} value={0} checked={!requestData.awi_reference_batch} type="radio" name="awi_reference_batch" id="no" />
									</div>
								</div>

								{requestData.awi_reference_batch && (
									<>
										<div className={styles.upload__form__image}>
											<label htmlFor="uploadFile">Upload Images</label>
											<div className={styles.upload__form__image__detail}>
												<label htmlFor="uploadFile" className={styles.upload__form__image__detail__button}>
													Browse Images
												</label>
												<input id="uploadFile" hidden type={'file'} multiple onChange={handleFileUpload} />
												<p>
													*{files.length} images selected {files.length > 0 && byteToSize(files.reduce((acc, file) => acc + file.size, 0))}
												</p>
											</div>
											<label style={{ color: 'red', marginTop: '30px' }}>Note:</label>
											{(requestDetails?.awi_reference_images?.awi_ids || []).length > 0 && (
												<label>{`- ${(requestDetails?.awi_reference_images?.awi_ids || []).length} reference images are already added for this request`}</label>
											)}
											<label style={{ fontSize: '14px' }}>
												- You will be able to annotate reference images once you {isUpdateRequest ? 'update' : 'create'} request.
											</label>
											{/* <button onClick={handleUploadImages}>Upload</button> */}
										</div>
									</>
								)}

								<div className={styles.modal__buttons}>
									<button onClick={() => handleNextState(2)}>Back</button>
									<button
										type="submit"
										disabled={postRequestMutation.isLoading || updateRequestMutation.isLoading}
										onClick={() => {
											handleCreateRequest();
										}}
									>
										{isUpdateRequest ? 'Update Request' : 'Create Request'}
									</button>
								</div>
							</>

							{/* <div className={styles.modal__buttons}>
								<button onClick={() => handleNextState(1)}>Back</button>
								<button
									type="submit"
									disabled={postRequestMutation.isLoading || updateRequestMutation.isLoading}
									onClick={() => {
										if (checkFormValidation(['awi_manager'])) {
											handleCreateRequest();
										}
									}}
								>
									Submit
								</button>
							</div> */}
						</>
					)}
				</form>
			</div>
			<div className={styles.backdrop}></div>
		</>
	);
};
export default CreateRequestModal;
