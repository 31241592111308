import { useState } from 'react';
import { toast } from 'react-hot-toast';

import { fetchApps } from '../../../handlers/models/fetchApps';
import { fetchModelApps } from '../../../handlers/models/fetchModelApps';
import { postAppToModel } from '../../../handlers/models/postAppToModel';

import { Input } from '../../Common';

import { useQuery } from '../../../util/useQuery';
import { useMutation } from '../../../util/useMutation';

import styles from './styles.module.scss';
import { useQueryClient } from 'react-query';

interface AddAppModalProps {
	modelId: string;
	closeModal: () => void;
	fetchModelsKey: any;
}

const AddAppModal: React.FC<AddAppModalProps> = ({ modelId, closeModal, fetchModelsKey }) => {
	const queryClient = useQueryClient();
	const [selectedApps, setSelectedApps] = useState<{ awi_app_id: number; awi_app_name: string; awi_name: string }[]>([]);

	const [fetchAppsKey, fetchAppsFunction] = fetchApps();
	const { data: appsData, isLoading, isFetching } = useQuery(fetchAppsKey, fetchAppsFunction);

	const [fetchModelAppsKey, fetchModelAppsFunction] = fetchModelApps(modelId);
	const { data: modelApps } = useQuery(fetchModelAppsKey, fetchModelAppsFunction, { enabled: !!modelId });

	const postAddAppMutation = useMutation(postAppToModel);

	const handleSelectApps = (appId: number) => {
		if (selectedApps.findIndex(ele => ele.awi_app_id === appId) !== -1) {
			setSelectedApps(selectedApps.filter(ele => ele.awi_app_id !== appId));
		} else {
			if ((appsData.result || []).findIndex(ele => ele.awi_app_id === appId) !== -1) {
				setSelectedApps([...selectedApps, (appsData?.result || []).find(ele => ele.awi_app_id === appId)]);
			}
		}
	};

	const handleAddApp = event => {
		event.preventDefault();
		postAddAppMutation.mutate(
			{
				awi_job_uuid: modelId,
				app: [...selectedApps]
			},
			{
				onSuccess: () => {
					toast.success('App added successfully');
					Promise.all([queryClient.invalidateQueries(fetchModelAppsKey), queryClient.invalidateQueries(fetchModelsKey)]);
					closeModal();
				}
			}
		);
	};

	if (isLoading || isFetching) {
		return <div>Loading...</div>;
	}

	if (!appsData) {
		toast.error('No apps found');
		return null;
	}

	return (
		<>
			<div className={styles.backdrop} onClick={closeModal}></div>
			<div className={styles.modal}>
				<div className={styles.modal__container}>
					<div className={styles.modal__header}>
						<div className={styles.modal__header__title}>
							<p>Add Apps</p>
						</div>
					</div>
					<form className={styles.modal__form}>
						<Input
							placeholder="Select Apps"
							searchString={[...selectedApps.map(ele => ele.awi_name), ...(modelApps?.result || []).map(ele => ele.awi_name)].join(', ')}
							data={(appsData.result || []).map(ele => ({ awi_id: ele.awi_app_id, awi_label: ele.awi_name }))}
							onClick={handleSelectApps}
							selectedData={[...selectedApps.map(ele => +ele.awi_app_id), ...(modelApps?.result || []).map(ele => +ele.awi_app_id)]}
							type="drop-down"
						/>
						<button className={styles.modal__form__submit} type="button" onClick={handleAddApp}>
							Add
						</button>
					</form>
				</div>
			</div>
		</>
	);
};
export default AddAppModal;
