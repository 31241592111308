import clsx from 'clsx';
import tippy from 'tippy.js';
import { useEffect } from 'react';
import { getImageKitUrl } from '../../../util/common.utils';

import styles from './styles.module.scss';

interface ImageCardWithoutDetailsProps {
	imageUrl: string;
	isAssigned: boolean;
	contentKey: number;
	handleImageSelect: (id: number) => void;
	isSelected: boolean;
	handleSelectMulipleImages: (key: number) => void;
}

const ImageCardWithoutDetails: React.FC<ImageCardWithoutDetailsProps> = ({
	imageUrl,
	isAssigned = false,
	contentKey,
	handleImageSelect,
	isSelected,
	handleSelectMulipleImages
}) => {
	useEffect(() => {
		if (contentKey) {
			tippy(`#Key${contentKey}`, {
				content: 'Click to select'
			});
		}
	}, [contentKey]);

	return (
		<div
			onMouseMove={() => {
				handleSelectMulipleImages(contentKey);
			}}
			className={styles.image__imageCard__var}
			style={{ background: `url(${getImageKitUrl(imageUrl, 190, 190, 80)})` }}
			onClick={() => {
				handleImageSelect(+contentKey);
			}}
		>
			<div
				className={clsx(styles.image__imageCard__var, {
					[styles.image__selected]: isSelected
				})}
			>
				<div className={styles.image__details__var}>
					<div>
						<div className={styles.image__assigned__var}>{isAssigned && <span>Assigned</span>}</div>
						<img src="/icons/info/info--blue.svg" alt="Info icon" id={`Key${contentKey}`} />
					</div>
				</div>
			</div>
		</div>
	);
};
export default ImageCardWithoutDetails;
