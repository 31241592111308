import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import tippy from 'tippy.js';
import { postDownloadModel } from '../../../handlers/models/postDownloadModel';
import { useMutation } from '../../../util/useMutation';
import { TableCell, TableRow } from '../../Common';

interface ModelCellProps {
	setIsAddAppModalOpen: (id: string) => void;
	model: any;
	index: number;
	selectedProject: number;
}

const ModelCell: React.FC<ModelCellProps> = ({ setIsAddAppModalOpen, model, index, selectedProject }) => {
	useEffect(() => {
		// if () {
		tippy(`#apps_${selectedProject}_${index}`, {
			content: `<div>
                ${
									(model.awi_apps || []).length > 0
										? (model.awi_apps || [])
												.map((app: any) => {
													return `<div>
                        <span>${app.awi_name}</span>
                    </div>`;
												})
												.join('')
										: 'No Apps associated'
								}
            </div>
                `,
			allowHTML: true,
			placement: 'right-end'
		});
		// }
	}, []);

	const downloadModelMutation = useMutation(postDownloadModel, {
		onSuccess: data => {
			const bestModel = data.result[0];
			const link = document.createElement('a');
			link.href = bestModel.url;
			link.setAttribute('download', bestModel.name);
			document.body.appendChild(link);
			link.click();

			toast.success('Model Downloaded');
		},
		onError: error => {
			toast.error((error as AxiosError).response?.data.message);
		}
	});

	return (
		<TableRow key={index}>
			<TableCell>
				<div
					onClick={() => {
						setIsAddAppModalOpen(model.awi_job_uuid);
					}}
				>
					<img src="/icons/add/transparent--blue.svg" alt="eye" />
				</div>
			</TableCell>
			<TableCell>{model.awi_job_name || ''}</TableCell>
			<TableCell>{model.awi_project_name || ''}</TableCell>
			<TableCell>{model.awi_in?.network_type || ''}</TableCell>
			<TableCell>{format(new Date(model.awi_timestamp.awi_creation), ' hh:mm a, dd MMM Y ')}</TableCell>
			<TableCell>{format(new Date(model.awi_timestamp.awi_last_updation), ' hh:mm a, dd MMM Y ')}</TableCell>
			<TableCell>{model.awi_status_conditions[model.awi_status_conditions.length - 1].type || ''}</TableCell>
			<TableCell>{model.awi_out?.val_mAP50}</TableCell>
			<TableCell id={`apps_${selectedProject}_${index}`}>{(model.awi_apps || []).length}</TableCell>
			<TableCell>
				<div onClick={() => downloadModelMutation.mutate(model.awi_job_uuid)}>
					<img src="/icons/download/transparent--sky-blue.svg" alt="model" width={24} height={24} />
				</div>
			</TableCell>
		</TableRow>
	);
};
export default ModelCell;
