import { client } from './../../util/client';

type UploadImageResponse = {
	success: boolean;
	message: string;
	result?: any[];
};

export const postReferenceImages = ({ data }: { data: FormData }) =>
	client<UploadImageResponse>('/upload_reference_images', {
		baseURL: 'content',
		data: data,
		method: 'POST'
	}).then(res => res.data);
