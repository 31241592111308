import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import RequestActions from '../../../redux/actions/RequestActions';

import styles from './styles.module.scss';

interface DeleteImagesModalProps {
	handleModalClose: () => void;
	handleDeleteImages: () => void;
}

const DeleteImages: React.FC<DeleteImagesModalProps> = ({ handleModalClose, handleDeleteImages }) => {
	return (
		<>
			<div className={styles.modal}>
				<div
					style={{
						marginBottom: '20px'
					}}
				>
					Are you sure you want to Delete selected images?
				</div>
				<div
					style={{
						display: 'flex',
						gap: '20px',
						width: 'max-content',
						marginLeft: 'auto'
					}}
				>
					<button className={styles.modal__button} onClick={handleDeleteImages}>
						Yes
					</button>
					<button className={styles.modal__button} onClick={handleModalClose}>
						No
					</button>
				</div>
			</div>
			<div className={styles.backdrop} onClick={handleModalClose}></div>
		</>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser,
		requestID: { awi_id: state.RequestReducer.awi_id, awi_label: state.RequestReducer.awi_label }
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setRequest: data => dispatch(RequestActions.setRequest(data))
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteImages));
