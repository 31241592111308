import { MiniUser } from '../../types';
import { client } from '../../util/client';

export const getUserDetails = (id: number) => {
	const queryKey = ['user', { id }] as const;

	const queryFunction = () => client<{ data: MiniUser }>(`/user/${id}`, { baseURL: 'user' }).then(res => res.data.data);

	return [queryKey, queryFunction] as const;
};
