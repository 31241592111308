import { client } from './../../util/client';

type getDatasetResponse = {
	success: boolean;
	result: any;
	msg?: string;
};

export const getDatasetProportions = (data: { awi_dataset_id: number | undefined }) => {
	const queryKey = ['datasets-class-proportions', data] as const;
	const queryFunction = () =>
		client<getDatasetResponse>('/get_dataset_proportions', { baseURL: 'dataset', method: 'POST', data: { ...data } }).then(res => res.data);

	return [queryKey, queryFunction] as const;
};
