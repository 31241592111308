import { Tool } from '../../types';
import { client } from './../../util/client';

export type postNewAnnotationData = {
	awi_coords: { x: number; y: number }[];
	awi_outline_type: Tool;
	awi_class_id: number;
	awi_request_id: number;
	awi_content_key: number;
	awi_is_copied?: {
		awi_annotation_id: number | undefined;
	};
	awi_attributes?: {
		awi_id: number;
		awi_values: string[];
	}[];
	awi_status?: 'corrected' | 'pending';
	awi_outline_status?: 'corrected' | 'pending';
	awi_is_corrected?: boolean;
	awi_verifier?: boolean;
};

export const postNewAnnotation = (data: postNewAnnotationData & { awi_session_id: number }) =>
	client<{ success: boolean; result: any; message?: string }>('/create_new_annotation', { baseURL: 'annotation', method: 'POST', data }).then(res => res.data);
