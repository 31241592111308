import { clientWithoutToken } from '../../util/clientWithoutToken';

export const closeSession = ({ awi_session_id, token }: { awi_session_id: number; token: string }) =>
	clientWithoutToken(`/close_session/${awi_session_id}`, {
		baseURL: 'session',
		method: 'POST',
		headers: {
			Authorization: token
		}
	}).then(res => res.data);
