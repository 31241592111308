import { client } from './../../util/client';

type updateAnnotationData = {
	awi_id: number;
	awi_coords?: { x: number; y: number }[];
	awi_class_id?: number;
	awi_attributes?: { awi_values: string[]; awi_id: number }[];
	awi_status?: string;
	awi_session_id: number;
	awi_is_corrected?: boolean;
	content_key?: number;
	awi_update?: string;
	awi_request_for?: string;
};

export const updateAnnotation = (data: updateAnnotationData) =>
	client('/update_annotation', {
		baseURL: 'annotation',
		data: data,
		method: 'POST'
	});
