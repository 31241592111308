import { client } from './../../util/client';

export const createNewSession = (awi_request_id: number) =>
	client('/create_new_session', {
		baseURL: 'session',
		method: 'POST',
		data: {
			awi_request_id
		}
	}).then(res => res.data);
