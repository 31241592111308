import { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useQueryClient } from 'react-query';
import { motion, AnimatePresence, AnimatePresenceProps } from 'framer-motion';

import { updateClass } from '../../../handlers/class/updateClass';
import { postAttributes, postClass } from '../../../handlers/class';
import { getClassAttributes } from '../../../handlers/class/getClassAttributes';

import Input from '../../Common/Input';

import { client } from '../../../util/client';

import { useMutation } from '../../../util/useMutation';
import { useQuery } from '../../../util/useQuery';

import { AwirosClass, LoggedUser } from '../../../types';

import classStyles from './styles.module.scss';
import styles from '../Request/styles.module.scss';

interface CreateClassModalProps {
	handleModalClose: () => void;
	loggedUser: LoggedUser;
	isUpdating?: boolean;
	classId?: number;
}

const CreateClassModal: React.FC<CreateClassModalProps> = ({ handleModalClose, isUpdating, classId }) => {
	const CancelToken = axios.CancelToken;
	const source = CancelToken.source();
	const queryClient = useQueryClient();

	const initialClassData: AwirosClass = {
		awi_label: '',
		awi_description: '',
		awi_attributes: []
	};

	const initialAttributeData = {
		awi_label: '',
		awi_type: 'radio',
		awi_possible_values: ''
	};

	const [state, setState] = useState(0);
	const [addNewAttri, setAddNewAttri] = useState<boolean>(false);
	const [classData, setClassData] = useState<AwirosClass>(initialClassData);
	const [attributeData, setAttributeData] = useState<any>(initialAttributeData);
	const [query, setQuery] = useState('');
	const [selectedAttributes, setSelectedAttributes] = useState<any>([]);

	const [fetchedResponse, setFetchedResponse] = useState<any[]>([]);

	useEffect(() => {
		if (query === '') {
			setFetchedResponse([]);
		} else {
			searchAttributes(query.trim());
		}
	}, [query]);

	const [getClassAttributesKey, getClassAttributesFunction] = getClassAttributes(classId!);
	const { data: classAttributesData } = useQuery(getClassAttributesKey, getClassAttributesFunction, {
		enabled: isUpdating && !!classId
	});

	const postClassMutation = useMutation(postClass, {
		onSuccess: res => {
			if (res.success) {
				toast.success('Class created successfully');
				queryClient.invalidateQueries('class');
				handleModalClose();
			} else if (res.success === false) {
				toast.error(res.message || "Class couldn't be created");
			} else {
				toast.error("Class couldn't be created");
			}
		}
	});

	const updateClassMutation = useMutation(updateClass, {
		onSuccess: res => {
			if (res.success) {
				toast.success('Class updated');
				queryClient.invalidateQueries('class');
				handleModalClose();
			} else {
				toast.error("Class couldn't be updated");
			}
		}
	});

	const handleFormSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		if (isUpdating) {
			updateClassMutation.mutate({
				awi_id: classId!,
				awi_label: classData.awi_label,
				awi_description: classData.awi_description,
				awi_attributes: selectedAttributes
			});
		} else {
			if (selectedAttributes.length > 0) {
				const _classData = { ...classData, awi_attributes: [...selectedAttributes] };
				postClassMutation.mutate(_classData);
			} else {
				toast.error('Please select atleast one attribute');
			}
		}
	};

	useEffect(() => {
		if (classAttributesData) {
			setClassData({
				awi_label: classAttributesData.awi_label,
				awi_description: classAttributesData.awi_description,
				awi_attributes: []
			});
		}
	}, [classAttributesData]);

	const handleInputChange = (event: React.ChangeEvent) => {
		const target = event.target as HTMLInputElement;

		setClassData(prev => {
			const temp: any = { ...prev };
			const key = target.name as keyof Request;

			temp[key] = target.value.trimStart();
			return { ...temp };
		});
	};

	const handleNextState = (_state: number) => {
		setState(_state);
	};

	const handleNextClick = () => {
		const keys = ['awi_label', 'awi_description'];

		for (let index = 0; index < keys.length; index++) {
			if (classData[keys[index]] === initialClassData[keys[index]]) {
				toast.error('Please fill all the fields');
				return;
			}
		}

		handleNextState(1);
	};

	const searchAttributes = async (s: string) => {
		const res = await client('attributes', {
			params: {
				s
			},
			baseURL: '',
			cancelToken: source.token
		});

		setFetchedResponse(res.data?.response || []);
	};

	const handleAttributeInputChange = async (s: string) => {
		source.cancel();
		setQuery(s);
	};

	const handleSelectAttribute = (id: number) => {
		if ((classAttributesData?.awi_attributes || []).some((attri: any) => attri.awi_id === id)) {
			return;
		}

		const index = selectedAttributes.findIndex((attri: any) => attri.awi_id === id);
		if (index === -1) {
			const selected = fetchedResponse.find(attr => attr.awi_id === id);
			setSelectedAttributes(prev => [...prev, selected]);
			setQuery('');
		} else {
			setSelectedAttributes(prev => {
				const temp = [...prev];
				temp.splice(index, 1);
				return [...temp];
			});
		}
	};

	const handleRemoveAttribute = (index: number) => {
		setClassData(prev => {
			const temp = { ...prev };
			temp.awi_attributes = temp.awi_attributes.filter(attri => attri.awi_id !== selectedAttributes[index].awi_id);
			return { ...temp };
		});
		const temp = [...selectedAttributes];
		temp.splice(
			temp.findIndex(attri => attri.awi_id === selectedAttributes[index].awi_id),
			1
		);
		setSelectedAttributes(temp);
	};

	const handleAttributeChange = event => {
		const inputValue = event.target.value.trimStart();
		setAttributeData(prev => {
			const temp = { ...prev };
			if (event.target.name === 'attri_awi_label') {
				temp.awi_label = inputValue;
			} else if (event.target.name === 'attri_awi_type') {
				temp.awi_type = inputValue;
			} else if (event.target.name === 'attri_awi_possible_labels') {
				temp.awi_possible_values = inputValue;
			}
			return { ...temp };
		});
	};

	const addNewAttribute = async () => {
		const data = { ...attributeData };
		data.awi_possible_labels = data.awi_possible_values.split(',');

		if (attributeData.awi_label === '' || (attributeData.awi_possible_values || []).length === 0 || !attributeData.awi_type) {
			toast.error('Please fill all the fields');
			return;
		}
		const res = await postAttributes([data]);
		if (res.success) {
			toast.success('Attributes Created successfully');
			setAttributeData(initialAttributeData);
			setAddNewAttri(false);
			setSelectedAttributes(prev => [...prev, res.result[0]]);
		} else {
			toast.error(res.message || "Attributes couldn't be created");
		}
	};

	if (isUpdating && !classAttributesData) {
		return <div>Loading...</div>;
	}

	return (
		<>
			<div className={styles.backdrop}></div>
			<div className={styles.modal}>
				<div className={styles.modal__close_button} onClick={handleModalClose}>
					<img src="/icons/close.svg" />
				</div>

				<form
					onSubmit={event => {
						event.preventDefault();
					}}
				>
					{state === 0 ? (
						<>
							<div style={{ padding: '24px 0' }}>
								<p style={{ fontSize: 18, fontWeight: 400 }}>{isUpdating ? 'Update' : 'Create'} Class</p>
							</div>
							<div className={styles.upload__form__grid}>
								<label>Class Label</label>
								<input type="text" onChange={handleInputChange} placeholder="Enter Class Name" value={classData.awi_label} name="awi_label" />
								<label>Class Description</label>
								<input type="text" onChange={handleInputChange} placeholder="Enter Description" value={classData.awi_description} name="awi_description" />
							</div>
							<div className={styles.modal__buttons}>
								<button onClick={handleNextClick}>Next</button>
							</div>
						</>
					) : (
						<>
							<div style={{ padding: '24px 0' }}>
								<p style={{ fontSize: 18, fontWeight: 400 }}>Attributes</p>
							</div>
							<div className={styles.class__attributes}>
								{!addNewAttri && (
									<>
										<Input
											selectedData={[...(classAttributesData?.awi_attributes || []), ...(selectedAttributes || [])].map(classAttri => classAttri.awi_id)}
											className={classStyles.class__attributes__search}
											placeholder="Search Attributes"
											data={fetchedResponse}
											onClick={handleSelectAttribute}
											onChange={handleAttributeInputChange}
											searchString={query}
											type="input"
										/>

										{isUpdating && classAttributesData && (
											<div className={classStyles.class__attributes__tags}>
												{(classAttributesData.awi_attributes || []).map((attr, index) => (
													<div key={index} className={classStyles.class__attributes__tags__item}>
														<p>{attr.awi_label}</p>
													</div>
												))}
											</div>
										)}

										<div className={classStyles.class__attributes__tags}>
											{selectedAttributes.map((tag, index) => (
												<div key={index} className={classStyles.class__attributes__tags__item}>
													<p>{tag.awi_label}</p>
													<img src="/icons/close.svg" width={8} height={8} onClick={() => handleRemoveAttribute(index)} />
												</div>
											))}
										</div>
									</>
								)}
								{addNewAttri && (
									<AnimatedComponent>
										<motion.div
											key="content"
											initial="collapsed"
											animate="open"
											exit="collapsed"
											variants={{
												open: { opacity: 1, height: 'auto' },
												collapsed: { opacity: 0, height: 0 }
											}}
											transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
										>
											<motion.div
												variants={{ collapsed: { opacity: 0 }, open: { opacity: 1 } }}
												transition={{ duration: 1 }}
												className={styles.upload__form__grid}
											>
												<label>Attribute Name</label>
												<input placeholder="Enter Label" name="attri_awi_label" value={attributeData.awi_label} onChange={handleAttributeChange} />
												<label>Type</label>
												<div className={styles.class__attributes__type}>
													<div>
														<input
															checked={attributeData.awi_type === 'radio'}
															onChange={handleAttributeChange}
															type={'radio'}
															name="attri_awi_type"
															value="radio"
														/>
														<label>Single Select</label>
													</div>
													<div>
														<input
															checked={attributeData.awi_type === 'checkbox'}
															onChange={handleAttributeChange}
															type={'radio'}
															name="attri_awi_type"
															value="checkbox"
														/>
														<label>Multi Select</label>
													</div>
												</div>
												<label>Possible values</label>
												<input onChange={handleAttributeChange} placeholder="comma seperated values" name="attri_awi_possible_labels" />
											</motion.div>
										</motion.div>
									</AnimatedComponent>
								)}
								<div className={styles.class__attributes__buttons}>
									<button
										onClick={event => {
											event.stopPropagation();
											if (!addNewAttri) {
												setAddNewAttri(true);
											} else {
												addNewAttribute();
											}
										}}
										type="button"
									>
										{addNewAttri ? 'Create Attribute' : 'Add New Attributes'}
									</button>
									{addNewAttri && (
										<button
											onClick={event => {
												event.stopPropagation();
												setAddNewAttri(false);
											}}
											className={styles.cancel}
											type="button"
										>
											Cancel
										</button>
									)}
								</div>
								<div className={styles.class__attributes__buttons} style={{ marginTop: 'auto', cursor: 'pointer' }}>
									<button type="submit" disabled={selectedAttributes.length === 0} onClick={handleFormSubmit}>
										{isUpdating ? 'Update' : 'Create'} Class
									</button>
								</div>
							</div>
						</>
					)}
				</form>
			</div>
		</>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps)(CreateClassModal));

interface NewAnimatePresenceProps extends Omit<AnimatePresenceProps, 'children'> {
	children: React.ReactNode;
}

export function AnimatedComponent({ children }: { children: React.ReactNode }) {
	const NewAnimatePresence: React.FC<NewAnimatePresenceProps> = AnimatePresence;

	return <NewAnimatePresence>{children}</NewAnimatePresence>;
}
