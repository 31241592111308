import clsx from 'clsx';
import styles from './styles.module.scss';

interface ContainerProps {
	className?: string;
	children?: React.ReactNode;
}

const Container: React.FC<ContainerProps> = ({ className = '', children }) => {
	return <div className={clsx(styles.container, { [className]: className !== '' })}>{children}</div>;
};

export default Container;
