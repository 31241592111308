const onBoardingAgreementHtml = `
	<!DOCTYPE html>
	<html>
	
	<head>
		<meta charset="UTF-8">
		<title>End User License Agreement (EULA)</title>
		<style>
			body {
				font-family: Arial, sans-serif;
				margin: 20px;
				font-size: 16px;
			}
	
			h1 {
				text-align: center;
			}
	
			.content {
				margin: 20px;
			}

			.point {
				margin-top: 10px;
			}
	
			.section {
				margin-bottom: 20px;
			}
	
			.section h2 {
				font-size: 18px;
				margin-bottom: 10px;
			}

			.section ul {
				margin-top: 5px;
			
			}

			.section li {
				margin-top: 5px;
			}
	
			.section p {
				margin-top: 20px;
			}
		</style>
	</head>
	
	<body>
		<h1>End User License Agreement (EULA)</h1>
	
		<div class="content">

		<div class="section">
		<p>This End User License Agreement ("EULA") is entered into between Awidit Systems Private Limited (“Awiros” or "Licensor") and the User ("Licensee") for the use of the "Annotation, Training, and Orchestration Module" (referred, interchangeably, to as the "ATOM" or “Dashboard”). This EULA outlines the terms and conditions governing the use of ATOM.</p>

			</div>

			<div class="section">
		<p>IMPORTANT: PLEASE READ THIS AGREEMENT CAREFULLY BEFORE USING ATOM. BY INSTALLING, COPYING, OR OTHERWISE USING ATOM, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS EULA. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT INSTALL OR USE THE DASHBOARD.</p>

			</div>

			<div class="section">
				<h2>1. LICENSE GRANT</h2>
				<p>1.1 License Grant. Subject to the terms of this EULA, Licensor grants Licensee a non-exclusive, non-transferable, and revocable license to use the Software for the purpose of annotating, training, and orchestrating AI computer models for computer vision and other related purposes.</p>
				<p>1.2 Restrictions. Licensee may not:</p>
				<ul>
					<li>(a) Use the Software for any purpose other than as described in Section 1.1.</li>
					<li>(b) Reverse engineer, decompile, disassemble, or otherwise attempt to derive the source code of the Software.</li>
					<li>(c) Modify, adapt, translate, or create derivative works based on the Software.</li>
					<li>(d) Share, sell, sublicense, or distribute the Software to any third party.</li>
				</ul>
			</div>
	
			<div class="section">
				<h2>2. CONSENT</h2>
				<p>By using this service/platform/website, Licensee agrees to voluntarily submit personal information, which may include, but is not limited to, your age, gender, nationality, and financial information. Licensee further acknowledges that Licensee has read and understood Licensor’s privacy policy, which outlines how Licensee’s personal information will be collected, stored, processed, and used. Licensee confirms that Licensee does not have any objections or concerns regarding the collection and usage of Licensee’s personal information as described in the privacy policy. Licensee’s continued use of this service/platform/website indicates Licensee’s consent to these terms.</p>
			</div>
	
			<div class="section">
				<h2>3. CONFIDENTIALITY</h2>
				<p class="point">3.1. Awiros may disclose certain information under this Agreement it considers confidential and/or proprietary concerning its business and/or technology (“Confidential Information”) including, but not limited to, all tangible, intangible, visual, electronic, present, or future information such as:</p>
				<ul>
					<li>(a) trade secrets;</li>
					<li>(b) financial information, including pricing;</li>
					<li>(c) technical information, including research, development, procedures, algorithms, data, designs, server information, code base, Software Development kits (SDKs) and know-how;</li>
					<li>(d) business information, including operations, planning, marketing interests, and products.</li>
				</ul>
				<p class="point">3.2. Licensee will use the Confidential Information only for the Purpose described above. Licensee will use the same degree of care, but no less than a reasonable degree of care, as Licensee uses with respect to its own similar information to protect the Confidential Information and to prevent:</p>
				<ul>
					<li>(a) any use of Confidential Information not authorized in this Agreement;</li>
					<li>(b) communication of Confidential Information to any unauthorized third party.</li>
				</ul>
				<p class="point">3.3. Licensee agrees not to do the following, except with the advanced review and written approval of Awiros:</p>
				<ul>
					<li>(a) issue or release any articles, advertising, publicity or other matter relating to this Agreement;</li>
					<li>(b) make copies or manipulate the information available on the Server of Awiros;</li>
					<li>(c) reverse engineer, disassemble, decompile, translate, or attempt to discover any prototypes, software, algorithms, or underlying ideas which embody Awiros’ Confidential Information.</li>
				</ul>
				<p class="point">3.4. This Agreement imposes no obligation upon Licensee with respect to Confidential Information that:</p>
				<ul>
					<li>(a) was known to Licensee before receipt from Awiros, as evidenced by Licensee’s files and records in existence before the time of disclosure;</li>
					<li>(b) is or becomes publicly available through no fault of Licensee;</li>
					<li>(c) is rightfully received by Licensee from a third party without a duty of confidentiality;</li>
					<li>(d) is disclosed by Awiros to a third party without a duty of confidentiality on the third party;</li>
					<li>(e) is disclosed by Licensee with Awiros’ prior written approval. If Confidential Information is required to be produced by law, court order, or other governmental demand, Licensee must immediately notify Awiros of that obligation.</li>
				</ul>
				<p class="point">3.5. ALL INFORMATION CONTAINED HEREIN IS PROVIDED “AS IS”. NO OTHER WARRANTIES ARE MADE, EXPRESS OR IMPLIED.</p>
				<p class="point">3.6. Awiros does not wish to receive any confidential information from Licensee, and Awiros assumes no obligation, either express or implied, for any information disclosed by Licensee.</p>
				<p class="point">3.7. This Agreement shall remain in effect until such time as all Confidential Information of Awiros disclosed hereunder becomes publicly known and made generally available through no action or inaction of Licensee.</p>
				<p class="point">3.8. Licensee, upon completion of the purpose, shall promptly return all Confidential Information received from Awiros, together with all copies, or certify in writing that all such Confidential Information and copies thereof have been destroyed.</p>
			</div>
	
			<div class="section">
				<h2>4. OWNERSHIP</h2>
				<p>Licensor retains all rights, title, and interest in and to the Software, including all intellectual property rights. This EULA does not grant Licensee any rights in or to any patents, copyrights, trademarks, trade secrets, or any other proprietary rights of Licensor.</p>
			</div>
	
			<div class="section">
				<h2>5. SUPPORT</h2>
				<p>Licensor may, at its discretion, provide support, updates, or patches to the Software. Such support, updates, or patches shall be subject to the terms of this EULA.</p>
			</div>
	
			<div class="section">
            <h2>6. TERM AND TERMINATION</h2>
            <p>6.1 Term. This EULA is effective upon Licensee's acceptance and shall remain in effect until terminated.</p>
            <p>5.2 Termination. Licensor may terminate this EULA immediately if Licensee breaches any of its terms. Upon termination, Licensee must cease using the Software and destroy all copies of the Software in their possession.</p>
        </div>

        <div class="section">
            <h2>7. JURISDICTION AND DISPUTE RESOLUTION</h2>
            <p>This EULA is governed by the laws of India, without regard to its conflict of laws principles. Any dispute arising out of or in connection with this EULA shall be subject to the exclusive jurisdiction of the courts located in New Delhi, India.</p>
        </div>

        <div class="section">
            <h2>8. MISCELLANEOUS</h2>
            <p>8.1 Entire Agreement. This EULA constitutes the entire agreement between the parties concerning the subject matter hereof and supersedes all prior and contemporaneous understandings and agreements.</p>
            <p>8.2 Modification. This EULA may only be modified in writing and signed by both parties.</p>
            <p>8.3 No Waiver. The failure of either party to enforce any rights granted hereunder or to take action against the other party in the event of any breach shall not be deemed a waiver of such rights or a bar to subsequent enforcement of such rights.</p>
            <p>8.4 Severability. If any provision of this EULA is found to be unenforceable or invalid, that provision will be enforced to the maximum extent permissible, and the other provisions of this EULA will remain in full force and effect.</p>
        </div>
		</div>

		<p>By using the Software, Licensee agrees to be bound by the terms of this EULA and of the Terms of Use of Atom at the time of Sign Up. If Licensee does not agree with these terms, they should not use the Software.</p>
	</body>
	</html>
`;
export default onBoardingAgreementHtml;
