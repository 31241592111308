import { Tab } from '@headlessui/react';
import clsx from 'clsx';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';

import { getUsers } from '../../../handlers/organisation';
import { Annotator, Developer, LoggedInUser, Manager, MiniUser, Supervisor, Verifier } from '../../../types';
import { getFullName, getUserAvatar } from '../../../util/common.utils';
import Table from '../../Common/Table';
import Container from '../../Container';

import styles from './styles.module.scss';
import UserVerificationModal from './UserVerificationModal';

// const Tabs = ['All', 'Developer', 'Manager', 'Supervisor', 'Annotator', 'Verifier'];

interface UsersProps {
	loggedUser: {
		userObj: LoggedInUser;
	};
	setRequest: (data: { awi_id: number; awi_label: string }) => void;
}
const UsersList: React.FC<UsersProps> = ({ loggedUser }) => {
	const [users, setUsers] = useState<Array<MiniUser & { awi_user: Developer & Manager & Annotator & Supervisor & Verifier }>>();

	const [state, setState] = useState<number>(0);

	const fetchUsers = async state => {
		const res = await getUsers({ roles: state === '' ? [] : [state], get_stats: state !== '', role: state });

		setUsers(res.result);
	};

	const handleVerificationClick = (user: MiniUser & { awi_user: Developer & Manager & Annotator & Supervisor & Verifier }) => {
		setSelectedUser(user.awi_id);
	};

	const [selectedUser, setSelectedUser] = useState<Number>(-1);

	const handleVerificationModalClose = () => {
		setSelectedUser(-1);
		handleFetchUsers();
	};

	const handleFetchUsers = () => {
		let role = '';

		if (loggedUser.userObj.awi_level === 'awi_supervisor') {
		}
		if (state === 0) {
			role = '';
		} else if (state === 1) {
			role = 'awi_developer';
		} else if (state === 2) {
			role = 'awi_manager';
		} else if (state === 3) {
			role = 'awi_supervisor';
		} else if (state === 4) {
			role = 'awi_annotator';
		} else if (state === 5) {
			role = 'awi_verifier';
		}
		fetchUsers(role);
	};

	useEffect(() => {
		handleFetchUsers();
	}, [state]);

	const getHeaders = () => {
		const temp: any = [];
		temp.push('Name', 'Email', 'Role', 'Joined at', 'Status');
		// if (state === 0) {
		// 	temp.push('Name', 'Email', 'Role', 'Joined at', 'Status');
		// } else if (state === 1) {
		// 	temp.push(
		// 		'Name',
		// 		'Requests Raised',
		// 		'Live Requests',
		// 		<div style={{ display: 'flex' }}>
		// 			<div style={{ borderRight: '1px solid #ababab', width: '50%', textAlign: 'center' }}>C</div>
		// 			<div style={{ width: '50%', textAlign: 'center' }}>D</div>
		// 		</div>,
		// 		'Classes Created',
		// 		'Attributes Created'
		// 	);
		// } else if (state === 2) {
		// 	temp.push('Name', 'Requests Assigned', 'Delivered Requests', 'Images Uploaded', 'Users Onboarded');
		// } else if (state === 3) {
		// 	temp.push('Name', 'Requests Assigned', 'Delivered Requests', 'Images Uploaded', 'Images Allocated', 'Annotations Approved', 'Annotations Rejected');
		// } else if (state === 4) {
		// 	temp.push(
		// 		'Name',
		// 		'Requests Assigned',
		// 		<div>
		// 			<p style={{ textAlign: 'center', paddingBottom: 8 }}>Request Type</p>
		// 			<div style={{ display: 'flex' }}>
		// 				<div style={{ borderRight: '1px solid #ababab', width: '50%', textAlign: 'center' }}>C</div>
		// 				<div style={{ width: '50%', textAlign: 'center' }}>D</div>
		// 			</div>
		// 		</div>,
		// 		'Approved Annotations',
		// 		'Rejected Annotations',
		// 		'Pending Annotations',
		// 		'Classes Annotated',
		// 		<div>
		// 			<p style={{ textAlign: 'center', paddingBottom: 8 }}>Annotation Type</p>
		// 			<div style={{ display: 'flex' }}>
		// 				<div style={{ borderRight: '1px solid #ababab', width: '25%', textAlign: 'center' }}>C</div>
		// 				<div style={{ borderRight: '1px solid #ababab', width: '25%', textAlign: 'center' }}>D</div>
		// 				<div style={{ borderRight: '1px solid #ababab', width: '25%', textAlign: 'center' }}>P</div>
		// 				<div style={{ width: '25%', textAlign: 'center' }}>R</div>
		// 			</div>
		// 		</div>
		// 	);
		// } else if (state === 5) {
		// 	temp.push(
		// 		'Name',
		// 		'Requests Assigned',
		// 		<div>
		// 			<p style={{ textAlign: 'center', paddingBottom: 8 }}>Request Type</p>
		// 			<div style={{ display: 'flex' }}>
		// 				<div style={{ borderRight: '1px solid #ababab', width: '50%', textAlign: 'center' }}>C</div>
		// 				<div style={{ width: '50%', textAlign: 'center' }}>D</div>
		// 			</div>
		// 		</div>,
		// 		'Annotations Approved',
		// 		'Annotations Rejected',
		// 		<div>
		// 			<p style={{ textAlign: 'center', paddingBottom: 8 }}>Annotation Type</p>
		// 			<div style={{ display: 'flex' }}>
		// 				<div style={{ borderRight: '1px solid #ababab', width: '25%', textAlign: 'center' }}>C</div>
		// 				<div style={{ borderRight: '1px solid #ababab', width: '25%', textAlign: 'center' }}>D</div>
		// 				<div style={{ borderRight: '1px solid #ababab', width: '25%', textAlign: 'center' }}>P</div>
		// 				<div style={{ width: '25%', textAlign: 'center' }}>R</div>
		// 			</div>
		// 		</div>
		// 	);
		// }
		return temp;
	};

	const convertUserData = () => {
		return (users || []).map(user => {
			const temp: any[] = [];
			temp.push(
				<div className={styles.user}>
					<div className={styles.user__avatar}>
						<img width="100%" height="100%" src={getUserAvatar(user)} alt="user profile image" />
					</div>
					{getFullName(user)}
				</div>
			);
			temp.push(user.awi_email);
			temp.push(user.awi_level.split('awi_')[1]);
			temp.push(format(user.awi_timestamp?.awi_creation ? new Date(user.awi_timestamp?.awi_creation) : new Date(), 'd LLL y'));
			temp.push(
				<div style={{ display: 'flex', gap: '10px' }}>
					<div
						className={clsx(styles.user__status, {
							[styles.user__status__active]: user.awi_verification_status === 'approved' || user.awi_verification_status === 'unverified',
							[styles.user__status__unverified]: user.awi_verification_status === 'in-progress' || user.awi_verification_status === 'rejected'
						})}
					>
						{user.awi_verification_status === 'approved' && <span>Verified</span>}
						{user.awi_verification_status === 'in-progress' && <span>Unverified</span>}
						{user.awi_verification_status === 'rejected' && <span>Rejected</span>}
						{user.awi_verification_status === 'unverified' && <span>NA</span>}
						{!user.awi_verification_status && <span>NA</span>}
					</div>
					{user.awi_verification_status &&
						loggedUser.userObj.awi_level === 'awi_manager' &&
						user.awi_verification_status !== 'unverified' &&
						user.awi_verification_status !== 'rejected' && <img onClick={() => handleVerificationClick(user)} src="/icons/review.svg"></img>}
				</div>
			);
			// if (state === 0) {
			// 	temp.push(
			// 		<div className={styles.user}>
			// 			<div className={styles.user__avatar}>
			// 				<img width="100%" height="100%" src={getUserAvatar(user)} alt="user profile image" />
			// 			</div>
			// 			{getFullName(user)}
			// 		</div>
			// 	);
			// 	temp.push(user.awi_email);
			// 	temp.push(user.awi_level.split('awi_')[1]);
			// 	temp.push(format(user.awi_timestamp?.awi_creation ? new Date(user.awi_timestamp?.awi_creation) : new Date(), 'd LLL y'));
			// 	temp.push(
			// 		<div
			// 			className={clsx(styles.user__status, {
			// 				[styles.user__status__active]: !user.awi_disabled,
			// 				[styles.user__status__unverified]: !user.awi_status?.awi_verification
			// 			})}
			// 		>
			// 			{`${user.awi_verification_status}`}
			// 			{`${user.awi_verification_status}` === 'in-progress' ? <span onClick={() => handleVerificationClick(user)}>{'icon'}</span> : ''}
			// 		</div>
			// 	);
			// } else if (state === 1) {
			// 	temp.push(
			// 		<div className={styles.user}>
			// 			<div className={styles.user__avatar}>
			// 				<img width="100%" height="100%" src={getUserAvatar(user)} alt="user profile image" />
			// 			</div>
			// 			{getFullName(user)}
			// 		</div>
			// 	);
			// 	temp.push(<div style={{ width: '50%', textAlign: 'center' }}>{user.awi_user?.awi_request_raised}</div>);
			// 	temp.push(<div style={{ width: '50%', textAlign: 'center' }}>{user.awi_user?.awi_live_requests}</div>);
			// 	temp.push(
			// 		<div style={{ display: 'flex' }}>
			// 			<div style={{ borderRight: '1px solid #ababab', width: '50%', textAlign: 'center' }}>{user.awi_user?.awi_request_type?.awi_detector_count}</div>
			// 			<div style={{ width: '50%', textAlign: 'center' }}>{user.awi_user?.awi_request_type?.awi_classifier_count}</div>
			// 		</div>
			// 	);
			// 	temp.push(<div style={{ width: '50%', textAlign: 'center' }}>{user.awi_user?.awi_classes_created}</div>);
			// 	temp.push(<div style={{ width: '50%', textAlign: 'center' }}>{user.awi_user?.awi_attributes_created}</div>);
			// } else if (state === 2) {
			// 	temp.push(
			// 		<div className={styles.user}>
			// 			<div className={styles.user__avatar}>
			// 				<img width="100%" height="100%" src={getUserAvatar(user)} alt="user profile image" />
			// 			</div>
			// 			{getFullName(user)}
			// 		</div>
			// 	);
			// 	temp.push(<div style={{ width: '50%', textAlign: 'center' }}>{user.awi_user?.awi_request_assigned}</div>);
			// 	temp.push(<div style={{ width: '50%', textAlign: 'center' }}>{user.awi_user?.awi_request_delivered}</div>);
			// 	temp.push(<div style={{ width: '50%', textAlign: 'center' }}>{user.awi_user?.awi_images_uploaded}</div>);
			// 	temp.push(<div style={{ width: '50%', textAlign: 'center' }}>{user.awi_user?.awi_users_onboarded}</div>);
			// } else if (state === 3) {
			// 	temp.push(
			// 		<div className={styles.user}>
			// 			<div className={styles.user__avatar}>
			// 				<img width="100%" height="100%" src={getUserAvatar(user)} alt="user profile image" />
			// 			</div>
			// 			{getFullName(user)}
			// 		</div>
			// 	);
			// 	temp.push(<div style={{ width: '50%', textAlign: 'center' }}>{user.awi_user?.awi_request_assigned}</div>);
			// 	temp.push(<div style={{ width: '50%', textAlign: 'center' }}>{user.awi_user?.awi_request_delivered}</div>);
			// 	temp.push(<div style={{ width: '50%', textAlign: 'center' }}>{user.awi_user?.awi_images_uploaded}</div>);
			// 	temp.push(<div style={{ width: '50%', textAlign: 'center' }}>{user.awi_user?.awi_images_allocated}</div>);
			// 	temp.push(<div style={{ width: '50%', textAlign: 'center' }}>{user.awi_user?.awi_annotation_approved}</div>);
			// 	temp.push(<div style={{ width: '50%', textAlign: 'center' }}>{user.awi_user?.awi_annotation_rejected}</div>);
			// } else if (state === 4) {
			// 	temp.push(
			// 		<div className={styles.user}>
			// 			<div className={styles.user__avatar}>
			// 				<img width="100%" height="100%" src={getUserAvatar(user)} alt="user profile image" />
			// 			</div>
			// 			{getFullName(user)}
			// 		</div>
			// 	);
			// 	temp.push(user.awi_user?.awi_request_assigned);
			// 	temp.push(
			// 		<div style={{ display: 'flex' }}>
			// 			<div style={{ borderRight: '1px solid #ababab', width: '50%', textAlign: 'center' }}>{user.awi_user?.awi_request_type?.awi_detector_count}</div>
			// 			<div style={{ width: '50%', textAlign: 'center' }}>{user.awi_user?.awi_request_type?.awi_classifier_count}</div>
			// 		</div>
			// 	);
			// 	temp.push(user.awi_user?.awi_annotation_approved);
			// 	temp.push(user.awi_user?.awi_annotation_rejected);
			// 	temp.push(user.awi_user?.awi_annotation_pending);
			// 	temp.push((user.awi_user?.awi_classes_annotated || []).length > 0 ? user.awi_user?.awi_classes_annotated[0].awi_count : 0);
			// 	temp.push(
			// 		<div style={{ display: 'flex' }}>
			// 			<div style={{ borderRight: '1px solid #ababab', width: '25%', textAlign: 'center' }}>{user.awi_user?.awi_annotation_type?.awi_circle_count}</div>
			// 			<div style={{ borderRight: '1px solid #ababab', width: '25%', textAlign: 'center' }}>{user.awi_user?.awi_annotation_type?.awi_dot_count}</div>
			// 			<div style={{ borderRight: '1px solid #ababab', width: '25%', textAlign: 'center' }}>{user.awi_user?.awi_annotation_type?.awi_polygon_count}</div>
			// 			<div style={{ width: '25%', textAlign: 'center' }}>{user.awi_user?.awi_annotation_type?.awi_rectangle_count}</div>
			// 		</div>
			// 	);
			// } else if (state === 5) {
			// 	// return ["Name", "Requests Assigned", "Request Type", "Annotations Approved", "Annotations Rejected", "Annotation Type"]
			// 	temp.push(
			// 		<div className={styles.user}>
			// 			<div className={styles.user__avatar}>
			// 				<img width="100%" height="100%" src={getUserAvatar(user)} alt="user profile image" />
			// 			</div>
			// 			{getFullName(user)}
			// 		</div>
			// 	);
			// 	temp.push(user.awi_user?.awi_request_assigned);
			// 	temp.push(
			// 		<div style={{ display: 'flex' }}>
			// 			<div style={{ borderRight: '1px solid #ababab', width: '50%', textAlign: 'center' }}>{user.awi_user?.awi_request_type?.awi_detector_count}</div>
			// 			<div style={{ width: '50%', textAlign: 'center' }}>{user.awi_user?.awi_request_type?.awi_classifier_count}</div>
			// 		</div>
			// 	);
			// 	temp.push(user.awi_user?.awi_annotation_approved);
			// 	temp.push(user.awi_user?.awi_annotation_rejected);
			// 	temp.push(
			// 		<div style={{ display: 'flex' }}>
			// 			<div style={{ borderRight: '1px solid #ababab', width: '25%', textAlign: 'center' }}>{user.awi_user?.awi_annotation_type?.awi_circle_count}</div>
			// 			<div style={{ borderRight: '1px solid #ababab', width: '25%', textAlign: 'center' }}>{user.awi_user?.awi_annotation_type?.awi_dot_count}</div>
			// 			<div style={{ borderRight: '1px solid #ababab', width: '25%', textAlign: 'center' }}>{user.awi_user?.awi_annotation_type?.awi_polygon_count}</div>
			// 			<div style={{ width: '25%', textAlign: 'center' }}>{user.awi_user?.awi_annotation_type?.awi_rectangle_count}</div>
			// 		</div>
			// 	);
			// }

			return temp;
		});
	};

	return (
		<Container>
			<div>
				<Tab.Group
					onChange={index => {
						setState(index);
					}}
				>
					<Tab.List className={styles.tabs__list}>
						{({ selectedIndex }) => (
							<>
								<Tab
									className={clsx(styles.tabs__list__item, {
										[styles.tabs__list__item__selected]: selectedIndex === 0
									})}
								>
									All
								</Tab>
								<Tab
									className={clsx(styles.tabs__list__item, {
										[styles.tabs__list__item__selected]: selectedIndex === 1
									})}
								>
									Developer
								</Tab>
								<Tab
									className={clsx(styles.tabs__list__item, {
										[styles.tabs__list__item__selected]: selectedIndex === 2
									})}
								>
									Manager
								</Tab>
								<Tab
									className={clsx(styles.tabs__list__item, {
										[styles.tabs__list__item__selected]: selectedIndex === 3
									})}
								>
									Supervisor
								</Tab>
								<Tab
									className={clsx(styles.tabs__list__item, {
										[styles.tabs__list__item__selected]: selectedIndex === 4
									})}
								>
									Annotator
								</Tab>
								<Tab
									className={clsx(styles.tabs__list__item, {
										[styles.tabs__list__item__selected]: selectedIndex === 5
									})}
								>
									Verifier
								</Tab>
							</>
						)}
					</Tab.List>
					<Tab.Panels>
						<Tab.Panel>
							<Table headers={getHeaders()} content={convertUserData()} />
						</Tab.Panel>
						<Tab.Panel>
							<Table headers={getHeaders()} content={convertUserData()} />
						</Tab.Panel>
						<Tab.Panel>
							<Table headers={getHeaders()} content={convertUserData()} />
						</Tab.Panel>
						<Tab.Panel>
							<Table headers={getHeaders()} content={convertUserData()} />
						</Tab.Panel>
						<Tab.Panel>
							<Table headers={getHeaders()} content={convertUserData()} />
						</Tab.Panel>
						<Tab.Panel>
							<Table headers={getHeaders()} content={convertUserData()} />
						</Tab.Panel>
					</Tab.Panels>
				</Tab.Group>
				{selectedUser !== -1 && (
					<UserVerificationModal handleVerificationModalClose={handleVerificationModalClose} userId={selectedUser}></UserVerificationModal>
				)}
			</div>
		</Container>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps)(UsersList));
// export default UsersList;
