import clsx from 'clsx';
import axios from 'axios';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { Tab } from '@headlessui/react';
import { withRouter } from 'react-router';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { getUserStats } from '../../../handlers/request/getUsersStatsToRequest';
import { removeRequestUser } from '../../../handlers/request/removeRequestUser';

import { Table, Carousel, TableInfiniteScroll, TableHeaders, TableHeader, TableRow, InfiniteScroll, TableBody, TableCell } from '../../Common';

import { useQuery } from '../../../util/useQuery';
import { useMutation } from '../../../util/useMutation';

import { LoggedUser } from '../../../types';

import styles from './styles.module.scss';
import { set } from 'date-fns';

interface AnnotationTableProps {
	user_level?: 'awi_supervisor' | 'awi_verifier' | 'awi_annotator';
	requestId: number;
	handleRequestIndex: (index?: number) => void;
	awi_level: 'awi_verifier' | 'awi_annotator' | 'awi_supervisor' | 'awi_admin' | 'awi_manager' | 'awi_developer' | 'awi_root';
	loggedUser: LoggedUser;
}

const AnnotationTable: React.FC<AnnotationTableProps> = ({ user_level = 'awi_supervisor', requestId, awi_level, loggedUser }) => {
	const queryClient = useQueryClient();

	const CancelToken = axios.CancelToken;
	const source = CancelToken.source();

	const [state, setState] = useState<number>(0);
	const [level, setLevel] = useState<string>('');

	const [selectedTab, setSelectedTab] = useState<string>('awi_supervisor');

	const [openCarousel, setOpenCarousel] = useState(false);
	const [searchQuery, setSearchQuery] = useState<string>('');

	const [statsData, setStatsData] = useState<any>(null);

	const getStatsMutation = useMutation(getUserStats);

	// const [getStatsKey, getStatsFunction] = getUserStats({ awi_request_id: requestId, awi_user_level: selectedTab, source, searchQuery });

	// const { data: statsData } = useQuery(getStatsKey, getStatsFunction);
	const [contentKeys, setContentKeys] = useState([]);

	const [removeUser, setRemoveUser] = useState<number>(-1);

	const removeRequestUserMutation = useMutation(removeRequestUser, {
		onSuccess: () => {
			setRemoveUser(-1);
			toast.success('User removed successfully');
			// queryClient.invalidateQueries(getStatsKey);
		}
	});

	useEffect(() => {
		let level = '';
		if (state === 0) {
			level = 'awi_supervisor';
		} else if (state === 1) {
			level = 'awi_verifier';
		} else if (state === 2) {
			level = 'awi_annotator';
		}
		setLevel(level);
	}, [state]);

	useEffect(() => {
		if (user_level === 'awi_annotator') {
			setState(2);
		} else if (user_level === 'awi_verifier') {
			setState(1);
		} else {
			setState(0);
		}
	}, [user_level]);

	const handleUpdateSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		source.cancel();

		setSearchQuery(event.target.value);
	};

	const handleOpenCarousel = contentKeys => {
		setOpenCarousel(true);
		setContentKeys(contentKeys);
	};

	const handleCloseCarousel = () => {
		setOpenCarousel(false);
	};

	const handleToggleConfirmationModal = (id: number) => {
		setRemoveUser(id);
	};

	const handleRemoveUser = () => {
		removeRequestUserMutation.mutate({ awi_request_id: requestId, awi_users: [removeUser], awi_level: level });
	};

	const getHeaders = () => {
		if (state === 0) {
			return ['S. NO.', 'NAME'];
		} else if (state === 1) {
			const temp: any[] = [];
			if (['awi_supervisor', 'awi_manager'].includes(awi_level)) temp.push(<span></span>);
			temp.push(
				'S. NO.',
				'NAME',
				'NO OF IMAGES',
				'PENDING OUTLINES',
				'APPROVED OUTLINES',
				'REJECTED OUTLINES',
				'CORRECTED OUTLINES',
				'CREATED OUTLINES',
				'PENDING ATTRIBUTES',
				'APPROVED ATTRIBUTES',
				'REJECTED ATTRIBUTES',
				'CORRECTED ATTRIBUTES',
				'CREATED ATTRIBUTES'
			);
			return temp;
		} else if (state === 2) {
			const tempAnn: any[] = [];
			if (['awi_supervisor', 'awi_manager'].includes(awi_level)) tempAnn.push(<span></span>);
			tempAnn.push(
				'S. NO.',
				'NAME',
				'NO OF IMAGES',
				'PENDING OUTLINES',
				'APPROVED OUTLINES',
				'REJECTED OUTLINES',
				'CORRECTED OUTLINES',
				'CREATED OUTLINES',
				'PENDING ATTRIBUTES',
				'APPROVED ATTRIBUTES',
				'REJECTED ATTRIBUTES',
				'CORRECTED ATTRIBUTES',
				'CREATED ATTRIBUTES'
			);
			return tempAnn;
		}
	};

	const convertUserData = () => {
		if (state === 0) {
			return statsData?.result?.map((stats, index) => {
				return [index + 1, stats.awi_user_info?.awi_personal_info?.awi_firstname || '' + stats.awi_user_info?.awi_personal_info?.awi_lastname || ''];
			});
		} else if (state === 1) {
			return statsData?.result?.map((stats, index) => {
				const temp: any[] = [];
				if (['awi_supervisor', 'awi_manager'].includes(awi_level))
					temp.push(
						<button className={styles.remove__user_button} onClick={() => handleToggleConfirmationModal(stats.awi_id)}>
							Remove User
						</button>
					);

				temp.push(
					index + 1,
					stats.awi_user_info?.awi_personal_info?.awi_firstname || '' + stats.awi_user_info?.awi_personal_info?.awi_lastname || '',
					<div key={index} onClick={() => (((stats.awi_content_keys as number[]) || []).length === 0 ? null : handleOpenCarousel(stats.awi_content_keys))}>
						{(stats.awi_content_keys || []).length}
					</div>,
					stats.awi_outline.awi_pending_count,
					stats.awi_outline.awi_approved_count,
					stats.awi_outline.awi_rejected_count,
					stats.awi_outline.awi_corrected_count,
					stats.awi_outline.awi_created_count,
					stats.awi_attribute.awi_pending_count,
					stats.awi_attribute.awi_approved_count,
					stats.awi_attribute.awi_rejected_count,
					stats.awi_attribute.awi_corrected_count,
					stats.awi_attribute.awi_created_count
				);
				return temp;
			});
		} else if (state === 2) {
			return statsData?.result?.map((stats, index) => {
				const tempAnn: any[] = [];
				if (['awi_supervisor', 'awi_manager'].includes(awi_level))
					tempAnn.push(
						<button className={styles.remove__user_button} onClick={() => handleToggleConfirmationModal(stats.awi_id)}>
							Remove User
						</button>
					);

				tempAnn.push(
					index + 1,
					stats.awi_user_info?.awi_personal_info?.awi_firstname || '' + stats.awi_user_info?.awi_personal_info?.awi_lastname || '',
					<div key={index} onClick={() => (((stats.awi_content_keys as number[]) || []).length === 0 ? null : handleOpenCarousel(stats.awi_content_keys))}>
						{(stats.awi_content_keys || []).length}
					</div>,
					stats.awi_outline.awi_pending_count,
					stats.awi_outline.awi_approved_count,
					stats.awi_outline.awi_rejected_count,
					stats.awi_outline.awi_corrected_count,
					stats.awi_outline.awi_created_count,
					stats.awi_attribute.awi_pending_count,
					stats.awi_attribute.awi_approved_count,
					stats.awi_attribute.awi_rejected_count,
					stats.awi_attribute.awi_corrected_count,
					stats.awi_attribute.awi_created_count
				);
				return tempAnn;
			});
		}
	};

	useEffect(() => {
		if (!selectedTab) {
			return;
		}
		getStatsMutation.mutate(
			{ awi_request_id: requestId, awi_user_level: selectedTab, source, searchQuery },
			{
				onSuccess: response => {
					setStatsData(response);
					// if ((response.result || []).length > 0) {
					// 	setGenerateBillCounts(response.result);
					// }
					// toast.success('Bill count generated successfully');
				}
			}
		);
	}, [selectedTab]);

	const handleTabChange = event => {
		let selectedTab = '';
		if (event === 0) {
			selectedTab = 'awi_supervisor';
		} else if (event === 1) {
			selectedTab = 'awi_verifier';
		} else if (event === 2) {
			selectedTab = 'awi_annotator';
		}
		setSelectedTab(selectedTab);
	};

	return (
		<div className={styles.header}>
			{/* <div className={styles.request__searchbar}>
				<img src="/icons/search.svg" />
				<input onChange={handleUpdateSearchInput} placeholder="Search User" />
			</div> */}
			{/* <SearchComp placeholder="Search user" setSearchQuery={setSearchQuery} showFilters={true}></SearchComp> */}
			<Tab.Group
				defaultIndex={user_level === 'awi_annotator' ? 2 : user_level === 'awi_verifier' ? 1 : 0}
				onChange={index => {
					setState(index);
				}}
			>
				<div className={styles.container}>
					{/* <Tab.List className={styles.tabs__list}>
						{({ selectedIndex }) => {
							return (
								<>
									<Tab
										className={clsx(styles.tabs__list__item, {
											[styles.tabs__list__item__selected]: selectedIndex === 0
										})}
									>
										Supervisor
									</Tab>
									<Tab
										className={clsx(styles.tabs__list__item, {
											[styles.tabs__list__item__selected]: selectedIndex === 1
										})}
									>
										Verifier
									</Tab>
									<Tab
										className={clsx(styles.tabs__list__item, {
											[styles.tabs__list__item__selected]: selectedIndex === 2
										})}
									>
										Annotator
									</Tab>
								</>
							);
						}}
					</Tab.List>
					<Tab.Panels>
						<Tab.Panel>
							<Table headers={getHeaders() as string[]} content={convertUserData() as (string | number)[][]} />
						</Tab.Panel>
						<Tab.Panel>
							<Table headers={getHeaders() as string[]} content={convertUserData() as (string | number)[][]} />
						</Tab.Panel>
						<Tab.Panel>
							<Table headers={getHeaders()} content={convertUserData() as (string | number)[][]} />
						</Tab.Panel>
						<Tab.Panel>
							<Table headers={getHeaders()} content={convertUserData() as (string | number)[][]} />
						</Tab.Panel>
					</Tab.Panels> */}
				</div>
			</Tab.Group>
			<Tab.Group onChange={handleTabChange}>
				<div className={styles.session__tabs__container}>
					<Tab.List className={styles.tabs__list}>
						{({ selectedIndex }) => (
							<>
								{
									<Tab
										className={clsx(styles.tabs__list__item, {
											[styles.tabs__list__item__selected]: selectedTab === 'awi_supervisor'
										})}
									>
										Supervisor
									</Tab>
								}
								{
									<Tab
										className={clsx(styles.tabs__list__item, {
											[styles.tabs__list__item__selected]: selectedTab === 'awi_verifier',
											[styles.tabs__list__item__hidden]: loggedUser.userObj.awi_level === 'awi_annotator' || loggedUser.userObj.awi_level === 'awi_developer'
										})}
									>
										Verifier
									</Tab>
								}
								{
									<Tab
										className={clsx(styles.tabs__list__item, {
											[styles.tabs__list__item__selected]: selectedTab === 'awi_annotator',
											[styles.tabs__list__item__hidden]: user_level === 'awi_verifier' || loggedUser.userObj.awi_level === 'awi_developer'
										})}
									>
										Annotator
									</Tab>
								}
							</>
						)}
					</Tab.List>
				</div>
			</Tab.Group>
			{selectedTab === 'awi_supervisor' && (
				<div style={{ backgroundColor: 'white' }}>
					<div className={styles.progress__container}>
						<TableHeaders className={styles.progress__container__header}>
							<TableRow>
								<TableHeader>S.NO</TableHeader>

								<TableHeader>USER</TableHeader>
							</TableRow>
						</TableHeaders>
						<TableBody>
							{statsData?.result?.map((item, index) => {
								return (
									<TableRow key={index}>
										<TableCell>{index + 1}</TableCell>

										<TableCell>
											{item.awi_user_info?.awi_personal_info?.awi_firstname || '' + item.awi_user_info?.awi_personal_info?.awi_lastname || ''}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</div>
				</div>
			)}
			{selectedTab === 'awi_verifier' && (
				<div className={styles.progress__container}>
					<TableHeaders className={styles.progress__container__header}>
						<TableRow>
							<TableHeader>S.NO</TableHeader>

							<TableHeader>USER</TableHeader>
							<TableHeader>
								Number of <br /> Images
							</TableHeader>
							<TableHeader>
								<p className={styles.progress__container__header__split__heading}>Outline</p> <br />
								<div className={styles.progress__container__header__split__content}>
									<p>Pending</p>
									<p>Approved</p>
									<p>Rejected</p>
									<p>Corrected</p>
									<p>Added</p>
								</div>
							</TableHeader>

							<TableHeader>
								<p className={styles.progress__container__header__split__heading}>Attribute</p> <br />
								<div className={styles.progress__container__header__split__content}>
									<p>Pending</p>
									<p>Approved</p>
									<p>Rejected</p>
									<p>Corrected</p>
									<p>Added</p>
								</div>
							</TableHeader>
						</TableRow>
					</TableHeaders>
					<TableBody>
						{statsData?.result?.map((item, index) => {
							return (
								<TableRow key={index}>
									<TableCell>{index + 1}</TableCell>

									<TableCell>
										{item.awi_user_info?.awi_personal_info?.awi_firstname || '' + item.awi_user_info?.awi_personal_info?.awi_lastname || ''}
									</TableCell>
									<TableCell>{(item.awi_content_keys || []).length}</TableCell>
									<TableCell>
										<div className={styles.progress__container__header__split__content}>
											<p>{item.awi_outline?.awi_pending_count}</p>
											<p>{item.awi_outline?.awi_approved_count}</p>
											<p>{item.awi_outline?.awi_rejected_count}</p>
											<p>{item.awi_outline?.awi_corrected_count}</p>
											<p>{item.awi_outline?.awi_created_count}</p>
										</div>
									</TableCell>

									<TableCell>
										<div className={styles.progress__container__header__split__content}>
											<p>{item.awi_attribute?.awi_pending_count}</p>
											<p>{item.awi_attribute?.awi_approved_count}</p>
											<p>{item.awi_attribute?.awi_rejected_count}</p>
											<p>{item.awi_attribute?.awi_corrected_count}</p>
											<p>{item.awi_attribute?.awi_created_count}</p>
										</div>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</div>
			)}
			{selectedTab === 'awi_annotator' && (
				<div className={styles.progress__container}>
					<TableHeaders className={styles.progress__container__header}>
						<TableRow>
							<TableHeader>S.NO</TableHeader>

							<TableHeader>USER</TableHeader>
							<TableHeader>
								Number of <br /> Images
							</TableHeader>
							<TableHeader>
								<p className={styles.progress__container__header__split__heading}>Outline</p> <br />
								<div className={styles.progress__container__header__split__content}>
									<p>Pending</p>
									<p>Approved</p>
									<p>Rejected</p>
									<p>Corrected</p>
									<p>Added</p>
								</div>
							</TableHeader>

							<TableHeader>
								<p className={styles.progress__container__header__split__heading}>Attribute</p> <br />
								<div className={styles.progress__container__header__split__content}>
									<p>Pending</p>
									<p>Approved</p>
									<p>Rejected</p>
									<p>Corrected</p>
									<p>Added</p>
								</div>
							</TableHeader>
						</TableRow>
					</TableHeaders>
					<TableBody>
						{statsData?.result?.map((item, index) => {
							return (
								<TableRow key={index}>
									<TableCell>{index + 1}</TableCell>

									<TableCell>
										{item.awi_user_info?.awi_personal_info?.awi_firstname || '' + item.awi_user_info?.awi_personal_info?.awi_lastname || ''}
									</TableCell>
									<TableCell>{(item.awi_content_keys || []).length}</TableCell>
									<TableCell>
										<div className={styles.progress__container__header__split__content}>
											<p>{item.awi_outline?.awi_pending_count}</p>
											<p>{item.awi_outline?.awi_approved_count}</p>
											<p>{item.awi_outline?.awi_rejected_count}</p>
											<p>{item.awi_outline?.awi_corrected_count}</p>
											<p>{item.awi_outline?.awi_created_count}</p>
										</div>
									</TableCell>

									<TableCell>
										<div className={styles.progress__container__header__split__content}>
											<p>{item.awi_attribute?.awi_pending_count}</p>
											<p>{item.awi_attribute?.awi_approved_count}</p>
											<p>{item.awi_attribute?.awi_rejected_count}</p>
											<p>{item.awi_attribute?.awi_corrected_count}</p>
											<p>{item.awi_attribute?.awi_created_count}</p>
										</div>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</div>
			)}
			{removeUser !== -1 && (
				<>
					<div className={styles.backdrop}></div>
					<div className={styles.modal}>
						Are you sure you want to remove this user?
						<div className={styles.modal__buttons}>
							<button className={styles.modal__button__cancel} onClick={() => setRemoveUser(-1)}>
								Cancel
							</button>
							<button className={styles.modal__button__confirm} onClick={() => handleRemoveUser()}>
								Confirm
							</button>
						</div>
					</div>
				</>
			)}
			{openCarousel && <Carousel handleToggleCarousel={handleCloseCarousel} requestId={requestId} imageKeys={contentKeys} />}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps)(AnnotationTable));
