import { useEffect, useState } from 'react';

import { LoggedUser } from '../../../types';
import { getFullName, getUserAvatar } from '../../../util/common.utils';
import Container from '../../Container';

import { withRouter } from 'react-router-dom';
import { format } from 'date-fns';

import styles from './styles.module.scss';
import { connect } from 'react-redux';
import { getUserDetails, updatePassword } from '../../../handlers/user';
import { useQuery } from '../../../util/useQuery';
import { useMutation } from '../../../util/useMutation';
import toast from 'react-hot-toast';
import { updateUser } from '../../../handlers/user/updateUser';
import { postContact } from '../../../handlers/session/postContact';
import { useQueryClient } from 'react-query';
import { updateProfilePhoto } from '../../../handlers/user/updateProfilePhoto';
import UserActions from '../../../redux/actions/UserActions';
import { fetchUserGeneratedBills } from '../../../handlers/billing/fetchUserGeneratedBills';
import { useInfiniteQuery } from '../../../util/useInfiniteQuery';
import { InfiniteScroll, Loader, TableBody, TableCell, TableHeader, TableHeaders, TableInfiniteScroll, TableRow } from '../../Common';
import { Tab } from '@headlessui/react';
import { fetchDetailedDistribution } from '../../../handlers/billing/fetchDetailedDestribution';
import DetailView from '../Billing/DetailView';
import clsx from 'clsx';
import onBoardingAgreementHtml from '../../../util/onboardingFormAgreement';

interface DashboardProps {
	loggedUser: LoggedUser;
	loginUser: (data) => any;
}

const Dashboard: React.FC<DashboardProps> = ({ loggedUser, loginUser }) => {
	const [fetchUserKey, fetchUserFunction] = getUserDetails(loggedUser.userObj.awi_id);

	const queryClient = useQueryClient();

	const { data: user } = useQuery(fetchUserKey, fetchUserFunction);

	const [isUpdatePasswordModalOpen, setIsUpdatePasswordModalOpen] = useState<boolean>(false);

	const [oldPassword, setOldPassword] = useState<string>('');
	const [newPassword, setNewPassword] = useState<string>('');

	const [selectedTab, setSelectedTab] = useState<string>('personal');

	const [fetchGenaratedBillsKey, fetchGenaratedBillsFunction] = fetchUserGeneratedBills();
	const fetchDetailedDistributionMutation = useMutation(fetchDetailedDistribution);

	const [gallery, setGallery] = useState<any[]>([]);

	const [showDetailedView, setShowDetailedView] = useState(false);
	const [editDetails, setEditDetails] = useState(false);
	console.log(editDetails, 'editDetails');

	const usersWithOnboardingForm = ['awi_annotator', 'awi_verifier'].includes(loggedUser.userObj.awi_level);

	const [detailedViewData, setDetailedViewData] = useState<any[]>([]);
	const [selectedBill, setSelectedBill] = useState<any>(null);

	const {
		data: genaratedBills,
		hasNextPage,
		fetchNextPage,
		isLoading,
		isFetchingNextPage
	} = useInfiniteQuery(fetchGenaratedBillsKey, fetchGenaratedBillsFunction);

	const [userInput, setUserInput] = useState<{
		name: string;
		fathers_name: string;
		email: string;
		vpa: string;
		awi_aadhar_no: string;
		awi_pan_no: string;
		awi_bank_acc_no: string;
		awi_ifsc_code: string;
		awi_phone_no: string;
	}>({ name: '', email: '', vpa: '', awi_aadhar_no: '', awi_pan_no: '', awi_bank_acc_no: '', awi_ifsc_code: '', fathers_name: '', awi_phone_no: '' });

	const updatePasswordMutation = useMutation(updatePassword);
	const updateUserMutation = useMutation(updateUser);
	const postContactMutation = useMutation(postContact);

	// upload user profile photo
	const updateProfilePhotoMutation = useMutation(updateProfilePhoto);

	useEffect(() => {
		if (user) {
			setUserInput({
				name: getFullName(user),
				email: user.awi_email || '',
				vpa: user.awi_bank_details?.awi_upi_id || '',
				awi_aadhar_no: user.awi_aadhaar?.awi_no || '',
				awi_pan_no: user.awi_pancard?.awi_no || '',
				awi_bank_acc_no: user.awi_bank_details?.awi_account_no || '',
				awi_ifsc_code: user.awi_bank_details?.awi_ifsc_code || '',
				fathers_name: user.awi_personal_info?.awi_fathers_firstname || '' + user.awi_personal_info.awi_fathers_lastname || '',
				awi_phone_no: user.awi_personal_info?.awi_phone_no || ''
			});

			const tempGallery = [...gallery];

			if (user.awi_aadhaar?.awi_front_image) {
				tempGallery.push({
					imageUrl: user.awi_aadhaar.awi_front_image,
					title: 'Aadhaar Front'
				});
			}

			if (user.awi_aadhaar?.awi_back_image) {
				tempGallery.push({
					imageUrl: user.awi_aadhaar.awi_back_image,
					title: 'Aadhaar Back'
				});
			}

			if (user.awi_pancard?.awi_front_image) {
				tempGallery.push({
					imageUrl: user.awi_pancard.awi_front_image,
					title: 'Pancard'
				});
			}

			if (user.awi_bank_details?.awi_cancelled_cheque) {
				tempGallery.push({
					imageUrl: user.awi_bank_details.awi_cancelled_cheque,
					title: 'Cancelled Cheque'
				});
			}

			if (user.awi_bank_details?.awi_passbook) {
				tempGallery.push({
					imageUrl: user.awi_bank_details.awi_passbook,
					title: 'Bank Passbook'
				});
			}

			setGallery(tempGallery);
		}
	}, [user]);

	const handleUpdatePasswordModal = () => {
		setIsUpdatePasswordModalOpen(prev => !prev);
	};

	const handleUpdatePassword = (event: React.FormEvent) => {
		event.preventDefault();

		updatePasswordMutation.mutate(
			{ awi_updated_password: newPassword, awi_password: oldPassword },
			{
				onSuccess: res => {
					toast.success(res.message);
				},
				onError: (err: any) => {
					toast.error(err.response.data.message);
				}
			}
		);
	};

	const handleUpdateUser = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUserInput(prev => {
			const temp = { ...prev };
			temp[event.target.name] = event.target.value;

			return temp;
		});
	};

	const pancardValidation = (text: string) => {
		const regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
		if (regex.test(text)) {
			return true;
		}
		return false;
	};

	const aadharNoValidation = (text: number) => {
		const regex = /^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/;
		if (regex.test(text.toString())) {
			return true;
		}
		return false;
	};

	const handleEditDetails = () => {
		if (!['awi_annotator', 'awi_verifier'].includes(loggedUser.userObj.awi_level)) {
			setEditDetails(true);
		}
	};
	console.log(editDetails, 'editDetails');

	const handleSubmitForm = async () => {
		event?.preventDefault();
		const userData = {
			awi_firstname: userInput.name.trim().split(' ')[0],
			awi_lastname: userInput.name
				.trim()
				.split(' ')
				.splice(1, userInput.name.trim().split(' ').length - 1)
				.join(' '),
			awi_email: userInput.email.trim(),
			awi_vpa: userInput.vpa.trim(),
			awi_aadhar_no: userInput.awi_aadhar_no,
			awi_bank_acc_no: userInput.awi_bank_acc_no,
			awi_pan_no: userInput.awi_pan_no.trim(),
			awi_ifsc_code: userInput.awi_ifsc_code.trim()
		};

		// if (userInput.awi_pan_no !== '') {
		// 	if (!pancardValidation(userInput.awi_pan_no.trim())) {
		// 		toast.error('Please enter a valid PAN NO.');
		// 		return null;
		// 	}
		// }

		// if (userInput.awi_aadhar_no !== 0) {
		// 	if (!aadharNoValidation(userInput.awi_aadhar_no)) {
		// 		toast.error('Please enter a valid AADHAR NO.');
		// 		return null;
		// 	}
		// }

		if (checkValidUpi(userInput.vpa.trim()) || userInput.vpa.trim() === '') {
			updateUserMutation.mutateAsync(userData, {
				onSuccess: res => {
					queryClient.invalidateQueries(fetchUserKey).then(() => {
						const user = queryClient.getQueryData(fetchUserKey);
						loginUser({ token: loggedUser.token, userObj: user });
					});
					toast.success(res.message);
				},
				onError: (err: any) => {
					toast.error(err.response.data.message);
				}
			});
		} else {
			toast.error('Please enter a valid UPI ID');
		}
		setEditDetails(false);
		return null;
	};

	const validateUpi = (upi: string) => {
		const upiRegex = /[a-zA-Z0-9.\\-_]{2,256}@[a-zA-Z]{2,64}/;
		return upiRegex.test(upi);
	};
	const checkValidUpi = (upi: string) => {
		if (upi.length > 0 && !validateUpi(upi)) {
			return false;
		}
		return true;
	};

	console.log(selectedTab, 'sel yab');

	const handleCreateContact = () => {
		if (checkValidUpi(userInput.vpa.trim()) || userInput.vpa.trim() === '') {
			postContactMutation.mutate(
				{},
				{
					onSuccess: res => {
						queryClient.invalidateQueries(fetchUserKey);
						toast.success(res.message);
					}
				}
			);
			return;
		}
		toast.error('Please enter a valid UPI ID');
		return;
	};

	const handleChange = e => {
		const data = e.target.files[0];
		const formData = new FormData();
		formData.append('file', data);
		updateProfilePhotoMutation.mutate(formData, {
			onSuccess: () => {
				toast.success('Photo uploaded');
				queryClient.invalidateQueries(fetchUserKey).then(() => {
					const user = queryClient.getQueryData(fetchUserKey);
					loginUser({ token: loggedUser.token, userObj: user });
				});
			}
		});
	};

	const handleClassClick = user => {
		console.log(user, 'user');
		setSelectedBill(user);
		fetchDetailedDistributionMutation.mutate(
			{
				...user,
				awi_user_level: loggedUser.userObj.awi_level
			},
			{
				onSuccess: response => {
					console.log(response, 'response detailed');

					setDetailedViewData(response.result);
					setShowDetailedView(true);
				}
			}
		);
	};
	const handleBackClick = () => {
		setShowDetailedView(false);
	};

	if (!user) {
		return null;
	}
	console.log(editDetails, 'editDetails');

	return (
		<Container className={styles.container}>
			<div className={styles.dashboard}>
				<div className={styles.dashboard__leftpanel}>
					<div className={styles.dashboard__leftpanel__profile}>
						<div className={styles.dashboard__leftpanel__profile__avatar}>
							<img src={getUserAvatar(user)} width="100%" height={'100%'} />
							<input type="file" accept="image/*" onChange={handleChange} />
						</div>
						<div className={styles.dashboard__leftpanel__profile__name}>{userInput.name}</div>
						{loggedUser.userObj.awi_level === 'awi_annotator'
							? user?.awi_rating?.awi_payable_amount &&
							  user?.awi_rating?.awi_payable_amount_without_deduction &&
							  user.awi_rating.awi_payable_amount_without_deduction !== 0 && (
									<div>Rating : {((user.awi_rating.awi_payable_amount / user.awi_rating.awi_payable_amount_without_deduction) * 5).toFixed(2)}</div>
							  )
							: ''}
						<div className={styles.dashboard__leftpanel__profile__role}>
							{user.awi_level.split('awi_')[1].charAt(0).toUpperCase() + user.awi_level.split('awi_')[1].slice(1)}
						</div>
					</div>
					<div className={styles.dashboard__leftpanel__actions}>
						<ul>
							<li
								onClick={() => {
									setSelectedTab('personal');
								}}
								className={selectedTab === 'personal' ? styles.dashboard__leftpanel__actions__active : ''}
							>
								Personal Details
							</li>
							{usersWithOnboardingForm && (
								<>
									<li
										onClick={() => {
											setSelectedTab('contract');
										}}
										className={selectedTab === 'contract' ? styles.dashboard__leftpanel__actions__active : ''}
									>
										Agreement
									</li>
									<li
										onClick={() => {
											setSelectedTab('payment');
										}}
										className={selectedTab === 'payment' ? styles.dashboard__leftpanel__actions__active : ''}
									>
										Payment details
									</li>

									<li
										onClick={() => {
											setSelectedTab('billing');
										}}
										className={selectedTab === 'billing' ? styles.dashboard__leftpanel__actions__active : ''}
									>
										Billing Details
									</li>
								</>
							)}
						</ul>
					</div>
				</div>
				<div className={styles.dashboard__rightpanel}>
					<div>
						{selectedTab === 'personal' && (
							<div className={styles.users__header}>
								<div className={styles.users__header__heading}>User Information</div>
								<div className={styles.users__header__details}>
									<div>
										<form className={styles.users__header__user_details}>
											<div className={styles.users__header__user_details__input}>
												<label>Name </label>
												<input
													name="name"
													onChange={handleUpdateUser}
													className={editDetails ? styles.users__header__user_details__editable : ''}
													disabled={!editDetails}
													value={userInput.name}
												/>
											</div>
											<div className={styles.users__header__user_details__input}>
												<label>Father&apos;s Name </label>
												<input
													name="name"
													onChange={handleUpdateUser}
													className={clsx(styles.users__header__user_details__input, {
														[styles.users__header__user_details__editable]: editDetails
													})}
													disabled={usersWithOnboardingForm}
													value={userInput.fathers_name}
												/>
											</div>
											{usersWithOnboardingForm && (
												<div className={styles.users__header__user_details__input}>
													<label>Address </label>
													<p>{user?.awi_personal_info?.awi_address || '-'}</p>
												</div>
											)}
											<div className={styles.users__header__user_details__input}>
												<label>DOB </label>
												{user?.awi_personal_info?.awi_dob ? format(new Date(user?.awi_personal_info?.awi_dob as Date), 'd LLL y') : '-'}
											</div>
											<div className={styles.users__header__user_details__input}>
												<label>Email </label>
												<input name="email" disabled onChange={handleUpdateUser} value={userInput.email} />
											</div>
											{usersWithOnboardingForm && (
												<div className={styles.users__header__user_details__input}>
													<label>Phone No.</label>
													<input
														name="phone"
														className={clsx(styles.users__header__user_details__input, {
															[styles.users__header__user_details__editable]: editDetails
														})}
														disabled={usersWithOnboardingForm}
														value={userInput.awi_phone_no}
													/>
												</div>
											)}
											<div className={clsx(styles.users__header__user_details__input)}>
												<label>Password </label>
												<div className={styles.users__header__user_details__password}>
													<span> •••••• </span>
													<p onClick={handleUpdatePasswordModal}>Change Password</p>{' '}
												</div>
											</div>
											<div className={styles.users__header__user_details__input}>
												<label>Organisation </label>
												<input
													disabled={loggedUser.userObj.awi_level !== 'awi_admin'}
													value={(user.awi_organisation || []).length > 0 ? user.awi_organisation![0].awi_label : ''}
												/>
											</div>
											<div className={styles.users__header__user_details__input}>
												<label>Role </label>
												<input disabled value={user.awi_level.split('awi_')[1]} />
											</div>
											<div className={styles.users__header__user_details__input}>
												<label>Joining Date </label>
												{user?.awi_timestamp?.awi_creation && format(new Date(user?.awi_timestamp?.awi_creation as Date), 'd LLL y')}
											</div>
										</form>
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												marginTop: 32,
												marginLeft: 32,
												color: '#55a8fd'
											}}
										>
											{!usersWithOnboardingForm && (
												<div>
													{editDetails ? (
														<button onClick={handleSubmitForm} className={styles.button}>
															Save Changes
														</button>
													) : (
														<button onClick={handleEditDetails} className={styles.button}>
															Edit Changes
														</button>
													)}
												</div>
											)}
										</div>
									</div>
								</div>
								{isUpdatePasswordModalOpen && (
									<>
										<div className={styles.backdrop} onClick={handleUpdatePasswordModal}></div>
										<div className={styles.users__password_update_modal}>
											<p className={styles.users__password_update_modal__heading}>Update password</p>
											<form onSubmit={handleUpdatePassword} className={styles.users__password_update_modal__form}>
												<label htmlFor="">Old Password</label>
												<input type={'password'} required value={oldPassword} onChange={event => setOldPassword(event.target.value)} />
												<label htmlFor="">New Password</label>
												<input type={'password'} required value={newPassword} onChange={event => setNewPassword(event.target.value)} />
												<button className={styles.users__password_update_modal__form__submit} type="submit">
													Submit
												</button>
											</form>
										</div>
									</>
								)}
							</div>
						)}
						{selectedTab === 'contract' && (
							// <div className={styles.users__contract}>
							// 	<div className={styles.users__header__heading}>Contract Status</div>
							// 	<div className={styles.users__contract__container}>
							// 		<div className={styles.users__contract__details}>
							// 			<div className={styles.users__contract__details__heading}>NDA</div>
							// 			<div className={styles.users__contract__details__buttoncontainer}>
							// 				<div>
							// 					<button className={styles.users__contract__details__button}>Download Document</button>
							// 					<button className={styles.users__contract__details__button}>Upload Signed Document</button>
							// 				</div>
							// 				<button className={styles.users__contract__details__button} style={{ backgroundColor: '#55A8FD', color: 'white' }}>
							// 					Successful
							// 				</button>
							// 			</div>
							// 		</div>
							// 		<div className={styles.users__contract__details}>
							// 			<div className={styles.users__contract__details__heading}>Terms of Use</div>
							// 			<div className={styles.users__contract__details__buttoncontainer}>
							// 				<div>
							// 					<button className={styles.users__contract__details__button}>Download Document</button>
							// 					<button className={styles.users__contract__details__button}>Upload Signed Document</button>
							// 				</div>
							// 				<button className={styles.users__contract__details__button} style={{ backgroundColor: '#55A8FD', color: 'white' }}>
							// 					Successful
							// 				</button>
							// 			</div>
							// 		</div>
							// 		<div className={styles.users__contract__details}>
							// 			<div className={styles.users__contract__details__heading}>Privacy Policies</div>
							// 			<div className={styles.users__contract__details__buttoncontainer}>
							// 				<div>
							// 					<button className={styles.users__contract__details__button}>Download Document</button>
							// 					<button className={styles.users__contract__details__button}>Upload Signed Document</button>
							// 				</div>
							// 				<button className={styles.users__contract__details__button} style={{ backgroundColor: '#55A8FD', color: 'white' }}>
							// 					Successful
							// 				</button>
							// 			</div>
							// 		</div>
							// 	</div>
							// </div>
							<div className={styles.dashboard__rightpanel__agreement}>
								<div dangerouslySetInnerHTML={{ __html: onBoardingAgreementHtml }} />
							</div>
						)}
						{selectedTab === 'payment' && (
							// 	{['awi_annotator', 'awi_verifier'].includes(loggedUser.userObj.awi_level) && (
							<div className={styles.users__billing}>
								<div className={styles.users__header__heading}>Billing Details</div>
								<span>KYC Verification</span>
								<div className={styles.users__billing__input}>
									<label>Aadhar Number :</label>
									<p>{userInput.awi_aadhar_no}</p>
									{/* <input
										type="number"
										value={userInput.awi_aadhar_no}
										name="awi_aadhar_no"
										onChange={handleUpdateUser}
										disabled={(user.awi_kyc?.awi_aadhar?.awi_status || '') === 'pending' || (user.awi_kyc?.awi_aadhar?.awi_status || '') === 'verified'}
									/> */}
								</div>
								<div className={styles.users__billing__input}>
									<label>Pan Number :</label>
									<p>{userInput.awi_pan_no}</p>
									{/* <input
										type="string"
										value={userInput.awi_pan_no}
										name="awi_pan_no"
										onChange={handleUpdateUser}
										disabled={(user.awi_kyc?.awi_pan?.awi_status || '') === 'pending' || (user.awi_kyc?.awi_pan?.awi_status || '') === 'verified'}
									/> */}
								</div>
								<span>Account Information</span>
								<div className={styles.users__billing__input}>
									<label>Bank Account Number :</label>
									<p>{userInput.awi_bank_acc_no}</p>
									{/* <input
										type="number"
										value={userInput.awi_bank_acc_no}
										name="awi_bank_acc_no"
										onChange={handleUpdateUser}
										disabled={
											(user.awi_bank_acc_info?.awi_acc_no?.awi_status || '') === 'pending' ||
											(user.awi_bank_acc_info?.awi_acc_no?.awi_status || '') === 'verified'
										}
									/> */}
								</div>
								<div className={styles.users__billing__input}>
									<label>IFSC Code :</label>
									<p>{userInput.awi_ifsc_code}</p>
									{/* <input
										type="string"
										value={userInput.awi_ifsc_code}
										name="awi_ifsc_code"
										onChange={handleUpdateUser}
										disabled={
											(user.awi_bank_acc_info?.awi_ifsc_code?.awi_status || '') === 'pending' ||
											(user.awi_bank_acc_info?.awi_ifsc_code?.awi_status || '') === 'verified'
										}
									/> */}
								</div>

								<div className={styles.users__billing__input}>
									<label>UPI ID :</label>
									<p>{userInput.vpa}</p>

									{/* <input onChange={handleUpdateUser} disabled={user.awi_vpa !== ''} name="vpa" value={userInput.vpa} />
									<div className={styles.users__billing__input__actions}>
										{user?.awi_contact_id === '' && user.awi_vpa === '' && (
											<button className={styles.button} onClick={handleSubmitForm}>
												Save Changes
											</button>
										)}
										{user?.awi_contact_id === '' && (
											<button className={styles.button} onClick={handleCreateContact}>
												Create Contact
											</button>
										)}
									</div> */}
								</div>

								<span>Photo Gallery</span>
								<div className={styles.users__billing__gallery}>
									{gallery.map((item, index) => {
										return (
											<div className={styles.users__billing__gallery__item} key={index}>
												<img src={item.imageUrl} className={styles.users__billing__gallery__item__image} />
												<p className={styles.users__billing__gallery__item__title}>{item.title}</p>
											</div>
										);
									})}
								</div>
							</div>
						)}
						{selectedTab === 'billing' && !showDetailedView && (
							<div className={styles.dashboard__rightpanel__billing}>
								<div className={styles.users__header__heading}>Generated Bills</div>
								<div className={styles.dashboard__rightpanel__billing__table}>
									<TableInfiniteScroll>
										<TableHeaders className={styles.dashboard__rightpanel__billing__table__header}>
											<TableRow>
												<TableHeader>S.NO</TableHeader>
												<TableHeader>Bill id</TableHeader>
												<TableHeader>Request Name</TableHeader>
												<TableHeader>Billing date</TableHeader>
												<TableHeader>Date Range</TableHeader>
												<TableHeader>Payable amount</TableHeader>
												<TableHeader>Status</TableHeader>
											</TableRow>
										</TableHeaders>
										<TableBody>
											<InfiniteScroll
												hasMore={Boolean(hasNextPage)}
												isLoading={isLoading || isFetchingNextPage}
												next={fetchNextPage}
												loader={<Loader marginTop={16} />}
											>
												{genaratedBills?.pages?.map((page, index) => {
													return (
														<>
															{page.result?.map((item, dataIndex) => {
																return (
																	<TableRow key={dataIndex}>
																		<TableCell>{index * genaratedBills?.pages[0].result.length + (dataIndex + 1)}</TableCell>
																		<TableCell onClick={() => handleClassClick(item)}>{item.awi_id}</TableCell>
																		<TableCell>{(item.awi_request || []).length > 0 ? item.awi_request[0].awi_label : ''}</TableCell>
																		<TableCell>{format(new Date(item.awi_creation || null), 'dd MMM, H:mm')}</TableCell>
																		<TableCell>
																			{`${format(new Date(item.awi_billing_period.awi_start_date), 'MMM dd,yyyy')} - ${format(
																				new Date(item.awi_billing_period.awi_end_date),
																				'MMM dd,yyyy'
																			)}`}
																		</TableCell>
																		<TableCell>{item.awi_total_payable_amount}</TableCell>
																		<TableCell>{item.awi_status}</TableCell>
																	</TableRow>
																);
															})}
															<span style={{ height: 10, width: '100%', marginBottom: -20, display: 'block' }}></span>
															<span style={{ height: 0, width: '100%', display: 'block' }}></span>
														</>
													);
												})}
											</InfiniteScroll>
										</TableBody>
									</TableInfiniteScroll>
								</div>
							</div>
						)}
						{showDetailedView && (
							<div className={styles.dashboard__rightpanel__billing__detailedview}>
								<button onClick={handleBackClick} className={styles.button}>
									back
								</button>
								<DetailView selectedBill={selectedBill} detailedViewData={detailedViewData} awi_request_id={selectedBill.awi_request_id}></DetailView>
							</div>
						)}
					</div>
				</div>
			</div>
		</Container>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

const mapDispatchToProps = dispatch => {
	return {
		loginUser: data => dispatch(UserActions.loginUser(data))
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
