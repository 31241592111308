import { MiniData, MiniUser } from '../../types';
import { client } from '../../util/client';

type SessionFilterMetricResponse = {
	success: boolean;
	message?: string;
	result: {
		awi_users: MiniUser[];
		awi_classes: MiniData[];
		awi_requests: MiniData[];
	};
};

export const getNewSessionFilter = () => {
	const queryKey = ['session-filter'] as const;
	const queryFunction = () =>
		client<SessionFilterMetricResponse>('/fetch_session_filter', {
			baseURL: 'session',
			method: 'GET'
		}).then(res => res.data);
	return [queryKey, queryFunction] as const;
};
