import { client } from '../../util/client';

export const fetchRequestPrices = (data: { awi_request_id: number; awi_request_pricing_id?: number }) => {
	const queryKey = ['request-price', data] as const;
	const queryFunction = () =>
		client('/fetch_request_prices', {
			baseURL: 'request',
			method: 'POST',
			data: data
		}).then(res => res.data);

	return [queryKey, queryFunction] as const;
};
