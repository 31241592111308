import clsx from 'clsx';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { connect } from 'react-redux';
import { getMonthlyBill } from '../../../handlers/session/getMonthlyBill';
import { BillInfo, postMonthlyBill } from '../../../handlers/session/postMonthlyBill';
import { LoggedUser } from '../../../types';
import { useMutation } from '../../../util/useMutation';
import { useQuery } from '../../../util/useQuery';
import { withRouter } from 'react-router';
import styles from './styles.module.scss';

interface MonthBillingModalProps {
	handleToggleBillModal: () => void;
	loggedUser: LoggedUser;
}

const MonthBillingModal: React.FC<MonthBillingModalProps> = ({ loggedUser, handleToggleBillModal }) => {
	const queryClient = useQueryClient();

	const postMonthlyBillMutation = useMutation(postMonthlyBill);

	const [getMonthlyBillsKey, getMonthlyBillsFunction] = getMonthlyBill();
	const { data: bill } = useQuery(getMonthlyBillsKey, getMonthlyBillsFunction);

	const handleSubmitForm = (event: React.FormEvent) => {
		event.preventDefault();

		const formData = new FormData(event.target as HTMLFormElement);
		const data = Object.fromEntries(formData.entries());
		postMonthlyBillMutation.mutate(data as unknown as BillInfo, {
			onSuccess: () => {
				handleToggleBillModal();
				toast.success('Bill created successfully');
				queryClient.invalidateQueries(getMonthlyBillsKey);
			}
		});
	};

	return (
		<>
			<div className={styles.backdrop} onClick={handleToggleBillModal}></div>
			<div className={styles.modal}>
				<div className={styles.modal__header}>PAYMENT CHART</div>
				<form onSubmit={handleSubmitForm} className={styles.modal__body}>
					<div className={styles.modal__body__grid}>
						<label>Classifier Cost</label>
						<input
							type={'number'}
							disabled={Boolean(bill?.result?.awi_classifier) || false}
							value={bill?.result?.awi_classifier}
							placeholder="Enter Amount"
							step={'any'}
							required
							name="awi_classifier"
						/>

						<label>Attribute Cost</label>
						<input
							type={'number'}
							disabled={Boolean(bill?.result?.awi_attributes) || false}
							value={bill?.result?.awi_attributes}
							placeholder="Enter Amount"
							step={'any'}
							required
							name="awi_attributes"
						/>

						<label>Copy Attribute Cost</label>
						<input
							type={'number'}
							disabled={Boolean(bill?.result?.awi_copy_attributes) || false}
							value={bill?.result?.awi_copy_attributes}
							placeholder="Enter Amount"
							step={'any'}
							required
							name="awi_copy_attributes"
						/>

						<label>Corrected Attribute</label>
						<input
							type={'number'}
							disabled={Boolean(bill?.result?.awi_corrected_attributes) || false}
							value={bill?.result?.awi_corrected_attributes}
							placeholder="Enter Amount"
							step={'any'}
							required
							name="awi_corrected_attributes"
						/>

						<label>Dot Cost</label>
						<input
							type={'number'}
							disabled={Boolean(bill?.result?.awi_dot) || false}
							value={bill?.result?.awi_dot}
							placeholder="Enter Amount"
							step={'any'}
							required
							name="awi_dot"
						/>

						<label>Copy Annotation</label>
						<input
							type={'number'}
							disabled={Boolean(bill?.result?.awi_copied) || false}
							value={bill?.result?.awi_copied}
							placeholder="Enter Amount"
							step={'any'}
							required
							name="awi_copied"
						/>

						<label>Corrected Annotation</label>
						<input
							type={'number'}
							disabled={Boolean(bill?.result?.awi_corrected) || false}
							value={bill?.result?.awi_corrected}
							placeholder="Enter Amount"
							step={'any'}
							required
							name="awi_corrected"
						/>

						<label>Approved Annotation</label>
						<input
							type={'number'}
							disabled={Boolean(bill?.result?.awi_approved) || false}
							value={bill?.result?.awi_approved}
							placeholder="Enter Amount"
							step={'any'}
							required
							name="awi_approved"
						/>

						<label>Bonus</label>
						<input
							type={'number'}
							disabled={Boolean(bill?.result?.awi_bonus) || false}
							value={bill?.result?.awi_bonus}
							placeholder="Enter Amount"
							step={'any'}
							required
							name="awi_bonus"
						/>
					</div>
					<div
						className={clsx(styles.modal__buttons, {
							[styles.modal__buttons__disabled]: !!bill?.result
						})}
					>
						<button disabled={!!bill?.result || !['awi_admin', 'awi_manager'].includes(loggedUser.userObj.awi_level)}>Upload</button>
					</div>
				</form>
			</div>
		</>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps, null)(MonthBillingModal));
