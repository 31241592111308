import { connect } from 'react-redux';
import { toast } from 'react-hot-toast';
import { withRouter } from 'react-router';
import styles from './styles.module.scss';
import Container from '../../Container';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { regexExpression } from '../../../util/constants';
import { useParams } from 'react-router-dom';
import { addOnboardingDetails } from '../../../handlers/user/add_onboarding_details';
import { useMutation } from '../../../util/useMutation';
import { uploadUserFiles } from '../../../handlers/user/handlePhotoUpload';
import { getUserDetails } from '../../../handlers/user';
import { useQuery } from '../../../util/useQuery';
import { LoggedUser } from '../../../types';
import { updateProfilePhoto } from '../../../handlers/user/updateProfilePhoto';
import { useQueryClient } from 'react-query';
import MessageScreen from '../../Common/MessageScreen';
import onBoardingAgreementHtml from '../../../util/onboardingFormAgreement';

type formSteps = 'personal' | 'agreement' | 'payment';

type onboardingFormData = {
	firstName: string;
	lastName: string;
	fatherFirstName: string;
	fatherLastName: string;
	profilePhoto: string;
	dob: Date | null;
	address: string;
	panNumber: string;
	panPhoto: string;
	aadharNumber: string;
	aadharFrontPhoto: string;
	aadharBackPhoto: string;
	bankName: string;
	bankAccountNumber: string;
	confirmBankAccountNumber: string;
	ifscCode: string;
	beneficiaryName: string;
	cancelledCheckPhoto: string;
	passbookPhoto: string;
	upiId: string;
	phoneNo: string;
	otp: string;
	agreementName: string;
	agreementParentName: string;
	agreementAge: string;
	agreementAddress: string;
	agreementAccepted: boolean;
	agreementBankingDetailsAccepted: boolean;
	agreementKycDetailsAccepted: boolean;
};

const initialLoadingState = {
	profilePhoto: false,
	panPhoto: false,
	aadharFrontPhoto: false,
	aadharBackPhoto: false,
	cancelledChequePhoto: false,
	passbookPhoto: false
};

interface onboardingProps {
	loggedUser: LoggedUser;
}

const OnboardingDetails: React.FC<onboardingProps> = ({ loggedUser }) => {
	const userId = loggedUser.userObj.awi_id;

	const addOnboardingDetailsMutation = useMutation(addOnboardingDetails);
	const uploadUserFilesMutation = useMutation(uploadUserFiles);
	const queryClient = useQueryClient();

	const [fetchUserKey, fetchUserFunction] = getUserDetails(userId);

	const [loading, setLoading] = useState(initialLoadingState);

	const { data: user } = useQuery(fetchUserKey, fetchUserFunction);

	const updateProfilePhotoMutation = useMutation(updateProfilePhoto);

	if (!userId) {
		return <div>Could not get userID.</div>;
	}

	const [currentStep, setCurrentStep] = useState<formSteps>('personal');
	const [formDetails, setFormDetails] = useState<onboardingFormData>({
		firstName: '',
		lastName: '',
		fatherFirstName: '',
		fatherLastName: '',
		dob: null,
		address: '',
		panNumber: '',
		panPhoto: '',
		aadharNumber: '',
		aadharFrontPhoto: '',
		aadharBackPhoto: '',
		bankName: '',
		bankAccountNumber: '',
		confirmBankAccountNumber: '',
		ifscCode: '',
		beneficiaryName: '',
		cancelledCheckPhoto: '',
		passbookPhoto: '',
		upiId: '',
		phoneNo: '',
		otp: '',
		profilePhoto: '',
		agreementName: '',
		agreementParentName: '',
		agreementAge: '',
		agreementAddress: '',
		agreementAccepted: false,
		agreementBankingDetailsAccepted: false,
		agreementKycDetailsAccepted: false
	});
	useEffect(() => {
		if (user) {
			setFormDetails({
				firstName: user.awi_personal_info?.awi_firstname,
				lastName: user.awi_personal_info?.awi_lastname,
				fatherFirstName: user.awi_personal_info.awi_fathers_firstname || '',
				fatherLastName: user.awi_personal_info.awi_fathers_lastname || '',
				dob: user.awi_personal_info.awi_dob,
				address: user.awi_personal_info.awi_address,
				panNumber: user.awi_pancard.awi_no,
				panPhoto: user.awi_pancard.awi_front_image,
				aadharNumber: user.awi_aadhaar.awi_no,
				aadharFrontPhoto: user.awi_aadhaar.awi_front_image,
				aadharBackPhoto: user.awi_aadhaar.awi_back_image,
				bankName: user.awi_bank_details.awi_bank_name,
				bankAccountNumber: user.awi_bank_details.awi_account_no,
				confirmBankAccountNumber: user.awi_bank_details.awi_account_no,
				ifscCode: user.awi_bank_details.awi_ifsc_code,
				beneficiaryName: user.awi_bank_details.awi_beneficiary_name,
				cancelledCheckPhoto: user.awi_bank_details.awi_cancelled_cheque,
				passbookPhoto: user.awi_bank_details.awi_passbook,
				upiId: user.awi_bank_details.awi_upi_id,
				phoneNo: user.awi_personal_info.awi_phone_no,
				otp: '',
				profilePhoto: user.awi_personal_info.awi_avatar,
				agreementName: user.awi_agreement.awi_name,
				agreementParentName: user.awi_agreement.awi_parent_name,
				agreementAge: user.awi_agreement.awi_age,
				agreementAddress: user.awi_agreement.awi_address,
				agreementAccepted: user.awi_agreement.awi_accepted,
				agreementBankingDetailsAccepted: user.awi_agreement.awi_banking_agreement,
				agreementKycDetailsAccepted: user.awi_agreement.awi_kyc_agreement
			});
		}
	}, [user]);

	const [formSubmittedSuccessfully, setFormSubmittedSuccessfully] = useState(false);

	const handleCardInputChange = (event, index) => {
		const newValue = event.target.value.toUpperCase();

		if (event.target.name === 'pan') {
			if (/^[A-Z0-9]*$/.test(newValue) && newValue.length <= 1) {
				const newPanNumber = formDetails.panNumber.split('');
				newPanNumber[index] = newValue;
				if (index < 9 && newValue.length === 1) {
					document.getElementById(`panInput-${index + 1}`)?.focus();
				} else if (index > 0 && event.keyCode === 8) {
					if (newValue !== '') {
						newPanNumber[index] = '';
					} else {
						document.getElementById(`panInput-${index - 1}`)?.focus();
						newPanNumber[index - 1] = '';
					}
				}
				setFormDetails({ ...formDetails, panNumber: newPanNumber.join('') });
			}
		} else if (event.target.name === 'aadhaar') {
			if (/^[A-Z0-9]*$/.test(newValue) && newValue.length <= 1) {
				const newAadhaarNumber = formDetails.aadharNumber.split('');
				newAadhaarNumber[index] = newValue;
				if (index < 11 && newValue.length === 1) {
					document.getElementById(`aadhaarInput-${index + 1}`)?.focus();
				} else if (index > 0 && event.keyCode === 8) {
					if (newValue !== '') {
						newAadhaarNumber[index] = '';
					} else {
						document.getElementById(`aadhaarInput-${index - 1}`)?.focus();
						newAadhaarNumber[index - 1] = '';
					}
				}
				setFormDetails({ ...formDetails, aadharNumber: newAadhaarNumber.join('') });
			}
		}
	};

	const handleButtonEnabled = () => {
		let errorMessage = '';
		if (currentStep === 'personal') {
			if (formDetails.profilePhoto === '') {
				errorMessage = 'Please upload profile photo';
			} else if (formDetails.firstName === '') {
				errorMessage = 'Please enter name ';
			} else if (formDetails.fatherFirstName === '') {
				errorMessage = 'Please enter father name ';
			} else if (formDetails.dob === null) {
				errorMessage = 'Please enter date of birth';
			} else if (formDetails.address === '') {
				errorMessage = 'Please enter address';
			}
		} else if (currentStep === 'agreement') {
			if (formDetails.agreementName === '') {
				errorMessage = 'Please enter name';
			} else if (formDetails.agreementParentName === '') {
				errorMessage = 'Please enter parent name';
			} else if (formDetails.agreementAge === '') {
				errorMessage = 'Please enter age';
			} else if (formDetails.agreementAddress === '') {
				errorMessage = 'Please enter address';
			} else if (formDetails.agreementAccepted === false) {
				errorMessage = 'Please accept terms and conditions';
			}
		} else if (currentStep === 'payment') {
			if (formDetails.panNumber === '') {
				errorMessage = 'Please enter PAN number';
			} else if (formDetails.panPhoto === '') {
				errorMessage = 'Please upload PAN photo';
			} else if (formDetails.aadharNumber === '') {
				errorMessage = 'Please enter AADHAR number';
			} else if (formDetails.aadharFrontPhoto === '') {
				errorMessage = 'Please upload AADHAR front photo';
			} else if (formDetails.aadharBackPhoto === '') {
				errorMessage = 'Please upload AADHAR back photo';
			} else if (formDetails.bankName === '') {
				errorMessage = 'Please enter bank name';
			} else if (formDetails.bankAccountNumber === '') {
				errorMessage = 'Please enter bank account number';
			} else if (formDetails.ifscCode === '') {
				errorMessage = 'Please enter IFSC code';
			} else if (formDetails.beneficiaryName === '') {
				errorMessage = 'Please enter baneficiary name';
			} else if (formDetails.cancelledCheckPhoto === '' && formDetails.passbookPhoto === '') {
				errorMessage = 'Please upload cancelled check or passbook';
			} else if (formDetails.upiId === '') {
				errorMessage = 'Please enter UPI ID';
			} else if (formDetails.phoneNo === '') {
				errorMessage = 'Please enter phone number';
			} else if (!formDetails.agreementBankingDetailsAccepted) {
				errorMessage = 'Please accept banking details agreement';
			} else if (!formDetails.agreementKycDetailsAccepted) {
				errorMessage = 'Please accept KYC details agreement';
			}
		}

		return errorMessage;
	};

	const validDetails = () => {
		let errorMessage = '';
		if (!regexExpression.panCard.test(formDetails.panNumber)) {
			errorMessage = 'Invalid PAN number';
		} else if (!regexExpression.aadharCard.test(formDetails.aadharNumber)) {
			errorMessage = 'Invalid AADHAR number';
		} else if (!regexExpression.mobileNumber.test(formDetails.phoneNo)) {
			errorMessage = 'Invalid phone number';
		} else if (!regexExpression.bankAccountNumber.test(formDetails.bankAccountNumber)) {
			errorMessage = 'Invalid bank account number';
		} else if (formDetails.confirmBankAccountNumber !== formDetails.bankAccountNumber) {
			errorMessage = 'Bank account numbers do not match';
		} else if (!regexExpression.upiId.test(formDetails.upiId)) {
			errorMessage = 'Invalid UPI ID';
		} else if (!regexExpression.ifscCode.test(formDetails.ifscCode)) {
			errorMessage = 'Invalid IFSC code';
		}
		return errorMessage;
	};

	const handleButtonClick = async e => {
		let errorMessage = '';
		errorMessage = handleButtonEnabled();
		if (errorMessage === '' && currentStep === 'payment') {
			errorMessage = validDetails();
		}
		console.log(errorMessage, 'error msg');

		if (errorMessage !== '') {
			toast.error(errorMessage);
			return;
		}

		addOnboardingDetailsMutation.mutate(
			{ ...formDetails, userId: +userId, submit: currentStep === 'payment' ? true : false },
			{
				onSuccess: res => {
					toast.success(res.message);
					if (currentStep === 'payment') {
						queryClient.invalidateQueries(fetchUserKey).then(() => {
							return;
						});
						setFormSubmittedSuccessfully(true);
					}
				},
				onError: (err: any) => {
					const errMessage = err.response?.data.message;
					toast.error(errMessage);
				}
			}
		);
		if (currentStep === 'personal') {
			setCurrentStep('agreement');
		} else if (currentStep === 'agreement') {
			setCurrentStep('payment');
		} else if (currentStep === 'payment') {
			setFormSubmittedSuccessfully(true);
		}

		setLoading(initialLoadingState);
	};

	const handleBackButtonClick = () => {
		if (currentStep === 'agreement') {
			setCurrentStep('personal');
		} else if (currentStep === 'payment') {
			setCurrentStep('agreement');
		}
	};

	const handleProfilePhotoChange = async e => {
		const data = e.target.files[0];
		const formData = new FormData();
		setLoading({ ...loading, profilePhoto: true });
		formData.append('file', data);

		await updateProfilePhotoMutation.mutateAsync(formData, {
			onSuccess: res => {
				toast.success('Photo uploaded');
				setFormDetails({ ...formDetails, profilePhoto: res.fileUrl });
			}
		});

		setLoading({ ...loading, profilePhoto: false });
	};

	const handlePhotoUpload = async e => {
		const fieldName = e.target.name;

		const selectedFile = e.target.files[0];
		selectedFile['awi_key'] = fieldName;

		const formData = new FormData();
		formData.append('file', selectedFile);
		formData.append('userId', userId.toString());
		formData.append('type', fieldName);
		const tempLoadingStates = { ...loading };
		if (fieldName === 'pan') {
			tempLoadingStates['panPhoto'] = true;
		} else if (fieldName === 'aadhaar-front') {
			tempLoadingStates['aadharFrontPhoto'] = true;
		} else if (fieldName === 'aadhaar-back') {
			tempLoadingStates['aadharBackPhoto'] = true;
		} else if (fieldName === 'cancelled-cheque') {
			tempLoadingStates['cancelledChequePhoto'] = true;
		} else if (fieldName === 'passbook') {
			tempLoadingStates['passbookPhoto'] = true;
		}
		setLoading(tempLoadingStates);

		await uploadUserFilesMutation.mutateAsync(
			{ formData, fileKey: fieldName },
			{
				onSuccess: res => {
					if (res.success) {
						toast.success(res.message);
						const tempFormDetails = { ...formDetails };
						if (fieldName === 'pan') {
							tempFormDetails['panPhoto'] = res.fileUrl;
							tempLoadingStates['panPhoto'] = false;
						} else if (fieldName === 'aadhaar-front') {
							tempFormDetails['aadharFrontPhoto'] = res.fileUrl;
							tempLoadingStates['aadharFrontPhoto'] = false;
						} else if (fieldName === 'aadhaar-back') {
							tempFormDetails['aadharBackPhoto'] = res.fileUrl;
							tempLoadingStates['aadharBackPhoto'] = false;
						} else if (fieldName === 'cancelled-cheque') {
							tempFormDetails['cancelledCheckPhoto'] = res.fileUrl;
							tempLoadingStates['cancelledChequePhoto'] = false;
						} else if (fieldName === 'passbook') {
							tempFormDetails['passbookPhoto'] = res.fileUrl;
							tempLoadingStates['passbookPhoto'] = false;
						}
						setFormDetails(tempFormDetails);
					}
				},
				onError: (err: any) => {
					const errMessage = err.response?.data.message;
					toast.error(errMessage);
					console.log(err, 'jdbjkvbds');
				}
			}
		);

		setLoading(initialLoadingState);
	};

	const handleInputChange = e => {
		setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
	};

	const handleCheckboxClick = e => {
		const fieldName = e.target.name;
		const tempFormDetails = { ...formDetails };

		if (fieldName === 'agreementAccepted') {
			tempFormDetails['agreementAccepted'] = true;
		} else if (fieldName === 'agreementRejected') {
			tempFormDetails['agreementAccepted'] = false;
		} else if (fieldName === 'bankingAgreementAccepted') {
			tempFormDetails['agreementBankingDetailsAccepted'] = true;
		} else if (fieldName === 'bankingAgreementRejected') {
			tempFormDetails['agreementBankingDetailsAccepted'] = false;
		} else if (fieldName === 'kycAgreementAccepted') {
			tempFormDetails['agreementKycDetailsAccepted'] = true;
		} else if (fieldName === 'kycAgreementRejected') {
			tempFormDetails['agreementKycDetailsAccepted'] = false;
		}

		setFormDetails(tempFormDetails);
	};

	if (!['awi_annotator', 'awi_verifier'].includes(loggedUser.userObj.awi_level)) {
		location.href = '/requests';
	}

	return (
		<Container className={styles.container}>
			{(user?.awi_verification_status === 'in-progress' || formSubmittedSuccessfully) && (
				<MessageScreen
					iconUrl="/icons/info.svg"
					title="Approval Pending"
					description="Your account is under verification.
				You will gain access once the verification is complete."
				></MessageScreen>
			)}
			{user?.awi_verification_status === 'approved' && (
				<MessageScreen
					iconUrl="/icons/success.png"
					title="Onboarding Complete"
					description="Your onboarding process was successful."
					messageType="success"
				></MessageScreen>
			)}
			<div>
				{(user?.awi_verification_status === 'rejected' || user?.awi_verification_status === 'unverified') && (
					<div className={styles.form__container}>
						<h1 className={styles.form__container__heading}>SET UP YOUR ACCOUNT</h1>
						<div className={styles.form__steps}>
							<div className={styles.form__steps__step}>
								<span
									className={styles.form__steps__number}
									style={{ backgroundColor: currentStep === 'personal' ? '#55a8fd' : '', color: currentStep === 'personal' ? 'white' : '' }}
								>
									1
									<span className={styles.form__steps__heading} style={{ color: currentStep === 'personal' ? '#55a8fd' : '' }}>
										Personal Details
									</span>
								</span>
							</div>
							<hr></hr>
							<div className={styles.form__steps__step}>
								<span
									className={styles.form__steps__number}
									style={{ backgroundColor: currentStep === 'agreement' ? '#55a8fd' : '', color: currentStep === 'agreement' ? 'white' : '' }}
								>
									2
									<span className={styles.form__steps__heading} style={{ color: currentStep === 'agreement' ? '#55a8fd' : '' }}>
										Aggreement Details
									</span>
								</span>
							</div>
							<hr></hr>
							<div className={styles.form__steps__step}>
								<span
									className={styles.form__steps__number}
									style={{ backgroundColor: currentStep === 'payment' ? '#55a8fd' : '', color: currentStep === 'payment' ? 'white' : '' }}
								>
									3
									<span className={styles.form__steps__heading} style={{ color: currentStep === 'payment' ? '#55a8fd' : '' }}>
										Payment Details
									</span>
								</span>
							</div>
						</div>
						<div className={styles.form__container__body}>
							{currentStep === 'personal' && (
								<div className={styles.personal}>
									<div className={styles.personal__photo}>
										<input accept="image/*" type="file" id="profile-photo" onChange={handleProfilePhotoChange} className={styles.photo__input} />
										{!formDetails.profilePhoto && !loading.profilePhoto && (
											<label htmlFor="profile-photo">
												<div className={styles.photo__uploadcontainer}>
													<img src="/icons/upload_icon.svg" alt="Profile photo"></img>
													<span>Upload a Photo</span>
												</div>
											</label>
										)}
										{loading.profilePhoto && <div className={styles.photo__uploadcontainer}>Uploading....</div>}
										{formDetails.profilePhoto && !loading.profilePhoto && (
											<label htmlFor="profile-photo" className={styles.photo__container}>
												<img
													className={styles.photo}
													onChange={e => handleProfilePhotoChange(e)}
													src={formDetails.profilePhoto as string}
													alt="developer"
													style={{ width: '300px' }}
												></img>
												<div className={styles.photo__changecontainer}>
													<img src="/icons/upload_icon_white.svg" alt="Profile photo"></img>
													<span>Change Image</span>
												</div>
											</label>
										)}
									</div>
									<div className={styles.personal__inputcontainer}>
										<div className={styles.personal__inputrow}>
											<label className={styles.personal__label}>
												Name <span className={styles.asterisk}>*</span>
											</label>
											<input
												className={styles.personal__input}
												onChange={handleInputChange}
												placeholder="First Name"
												type="string"
												name="firstName"
												value={formDetails.firstName}
											></input>
											<input
												className={styles.personal__input}
												onChange={handleInputChange}
												placeholder="Last Name"
												type="string"
												name="lastName"
												value={formDetails.lastName}
											></input>
										</div>
										<div className={styles.personal__inputrow}>
											<label className={styles.personal__label}>
												Father&apos;s Name <span className={styles.asterisk}>*</span>
											</label>
											<input
												className={styles.personal__input}
												onChange={handleInputChange}
												placeholder="First Name"
												type="string"
												name="fatherFirstName"
												value={formDetails.fatherFirstName}
											></input>
											<input
												className={styles.personal__input}
												value={formDetails.fatherLastName}
												onChange={handleInputChange}
												placeholder="Last Name"
												type="string"
												name="fatherLastName"
											></input>
										</div>
										<div className={styles.personal__inputrow}>
											<label className={styles.personal__label}>
												Date of Birth <span className={styles.asterisk}>*</span>
											</label>
											<input
												className={styles.personal__input}
												onChange={handleInputChange}
												placeholder="DD/MM/YYYY"
												type="date"
												name="dob"
												min={'1900-01-01'}
												max={new Date().toISOString().split('T')[0]}
												value={formDetails.dob ? new Date(formDetails.dob).toISOString().split('T')[0] : ''}
											></input>
										</div>
										<div className={styles.personal__inputrow}>
											<label className={styles.personal__label}>
												Address <span className={styles.asterisk}>*</span>
											</label>
											<textarea
												className={clsx(styles.personal__input, styles.personal__address)}
												onChange={handleInputChange}
												placeholder="Enter your address"
												name="address"
												value={formDetails.address}
											></textarea>
										</div>
									</div>
								</div>
							)}
							{currentStep === 'agreement' && (
								<div className={styles.agreement}>
									<div className={styles.agreement__html}>
										<div dangerouslySetInnerHTML={{ __html: onBoardingAgreementHtml }} />
									</div>
									<div className={styles.agreement__container}>
										<span>I</span>
										<input
											className={clsx(styles.agreement__input, styles.agreement__input__name)}
											name="agreementName"
											onChange={handleInputChange}
											value={formDetails.agreementName}
											type="string"
										></input>
										, S/O{' '}
										<input
											className={clsx(styles.agreement__input, styles.agreement__input__name)}
											name="agreementParentName"
											onChange={handleInputChange}
											value={formDetails.agreementParentName}
											type="string"
										></input>
										, age{' '}
										<input
											className={clsx(styles.agreement__input, styles.agreement__input__age)}
											name="agreementAge"
											onChange={handleInputChange}
											value={formDetails.agreementAge}
											type="number"
										></input>{' '}
										<br></br>
										r/o{' '}
										<input
											className={clsx(styles.agreement__input, styles.agreement__input__address)}
											name="agreementAddress"
											onChange={handleInputChange}
											value={formDetails.agreementAddress}
										></input>{' '}
										agrees the above mentioned agreement.
									</div>
									<div className={styles.agreement__checkbox__container}>
										<>
											<input name="agreementAccepted" type="radio" checked={formDetails.agreementAccepted} onChange={handleCheckboxClick}></input>{' '}
											<span>I agree</span>
										</>
										<>
											<input name="agreementRejected" type="radio" checked={!formDetails.agreementAccepted} onChange={handleCheckboxClick}></input>{' '}
											<span>I disagree</span>
										</>
									</div>
								</div>
							)}
							{currentStep === 'payment' && (
								<div className={styles.payment}>
									<div className={styles.payment__inputrows}>
										<>
											<div className={styles.payment__inputrow}>
												<label className={styles.payment__inputrow__label}></label>
												<span>(PAN NUMBER)</span>
											</div>
											<div className={styles.payment__inputrow}>
												<label className={styles.payment__inputrow__label}>
													PAN <span className={styles.asterisk}>*</span>
												</label>
												<div>
													{Array.from({ length: 10 }, (_, index) => (
														<input
															key={index}
															id={`panInput-${index}`}
															type="text"
															value={formDetails.panNumber[index] || ''}
															onChange={e => handleCardInputChange(e, index)}
															maxLength={1}
															onKeyDown={e => {
																if (e.keyCode === 8 && index > 0) {
																	// Prevent default behavior of the Backspace key
																	e.preventDefault();
																	handleCardInputChange(e, index);
																}
															}}
															name="pan"
															className={styles.payment__cardInputDigit}
														/>
													))}
												</div>
											</div>
											<div className={styles.payment__inputrow}>
												<label className={styles.payment__inputrow__label}></label>
												<div className={styles.photo}>
													<input type="file" accept="image/*" id="pan-photo" name="pan" onChange={e => handlePhotoUpload(e)} className={styles.photo__input} />
													{!formDetails.panPhoto && !loading.panPhoto && (
														<label htmlFor="pan-photo">
															<div className={styles.photo__uploadcontainer}>
																<img src="/icons/upload_icon.svg" alt="Pan photo"></img>
																<span>Upload a Photo</span>
															</div>
														</label>
													)}
													{loading.panPhoto && <div>loading....</div>}
													{formDetails.panPhoto && !loading.panPhoto && (
														<label htmlFor="pan-photo" className={styles.photo__container}>
															<img className={styles.photo} onChange={e => handlePhotoUpload(e)} src={formDetails.panPhoto as string} alt="pan photo"></img>
															<div className={styles.photo__changecontainer}>
																<img src="/icons/upload_icon_white.svg" alt="Pan photo"></img>
																<span>Change Image</span>
															</div>
														</label>
													)}
												</div>
											</div>
										</>
										<>
											<div className={styles.payment__inputrow} style={{ marginTop: '40px' }}>
												<label className={styles.payment__inputrow__label}></label>
												<span>(AADHAR NUMBER)</span>
											</div>
											<div className={styles.payment__inputrow}>
												<label className={styles.payment__inputrow__label}>
													Aadhaar <span className={styles.asterisk}>*</span>
												</label>
												<div>
													{Array.from({ length: 12 }, (_, index) => (
														<input
															key={index}
															id={`aadhaarInput-${index}`}
															type="text"
															value={formDetails.aadharNumber[index] || ''}
															onChange={e => handleCardInputChange(e, index)}
															onKeyDown={e => {
																if (e.keyCode === 8 && index > 0) {
																	// Prevent default behavior of the Backspace key
																	e.preventDefault();
																	handleCardInputChange(e, index);
																}
															}}
															maxLength={1}
															name="aadhaar"
															className={styles.payment__cardInputDigit}
														/>
													))}
												</div>
											</div>
											<div className={styles.payment__inputrow}>
												<label className={styles.payment__inputrow__label}></label>
												<div style={{ display: 'flex', gap: '80px' }}>
													<div className={styles.photo}>
														<input
															type="file"
															id="aadhaar-front-photo"
															name="aadhaar-front"
															accept="image/*"
															onChange={e => handlePhotoUpload(e)}
															className={styles.photo__input}
														/>
														{!formDetails.aadharFrontPhoto && !loading.aadharFrontPhoto && (
															<label htmlFor="aadhaar-front-photo">
																<div className={styles.photo__uploadcontainer}>
																	<img src="/icons/upload_icon.svg" alt="Aadhaar front photo"></img>
																	<span>Upload Aadhaar Front Photo</span>
																</div>
															</label>
														)}
														{loading.aadharFrontPhoto && <div>Uploading....</div>}
														{formDetails.aadharFrontPhoto && !loading.aadharFrontPhoto && (
															<label htmlFor="aadhaar-front-photo" className={styles.photo__container}>
																<img
																	className={styles.photo}
																	onChange={e => handlePhotoUpload(e)}
																	src={formDetails.aadharFrontPhoto as string}
																	alt="aadhaar photo"
																></img>
																<div className={styles.photo__changecontainer}>
																	<img src="/icons/upload_icon_white.svg" alt="aadhaar photo"></img>
																	<span>Change Image</span>
																</div>
															</label>
														)}
													</div>
													<div className={styles.photo}>
														<input
															type="file"
															id="aadhaar-back-photo"
															name="aadhaar-back"
															accept="image/*"
															onChange={e => handlePhotoUpload(e)}
															className={styles.photo__input}
														/>
														{!formDetails.aadharBackPhoto && !loading.aadharBackPhoto && (
															<label htmlFor="aadhaar-back-photo">
																<div className={styles.photo__uploadcontainer}>
																	<img src="/icons/upload_icon.svg" alt="Aadhaar front photo"></img>
																	<span>Upload Aadhaar Back Photo</span>
																</div>
															</label>
														)}
														{loading.aadharBackPhoto && <div className={styles.photo__uploadcontainer}>Uploading....</div>}
														{formDetails.aadharBackPhoto && !loading.aadharBackPhoto && (
															<label htmlFor="aadhaar-back-photo" className={styles.photo__container}>
																<img
																	className={styles.photo}
																	onChange={e => handlePhotoUpload(e)}
																	src={formDetails.aadharBackPhoto as string}
																	alt="aadhaar back photo"
																></img>
																<div className={styles.photo__changecontainer}>
																	<img src="/icons/upload_icon_white.svg" alt="aadhaar back photo"></img>
																	<span>Change Image</span>
																</div>
															</label>
														)}
													</div>
												</div>
											</div>
										</>
									</div>
									<div className={styles.payment__heading}>Account Details</div>
									<div className={styles.payment__inputrows}>
										<div className={styles.payment__inputrow}>
											<label className={styles.payment__inputrow__label}>
												Bank Name <span className={styles.asterisk}>*</span>
											</label>
											<input
												className={styles.payment__inputrow__input}
												onChange={handleInputChange}
												placeholder="e.g: State Bank of India"
												type="string"
												name="bankName"
												value={formDetails.bankName}
											></input>
										</div>
										<div className={styles.payment__inputrow}>
											<div className={styles.payment__inputrow__container}>
												<label className={styles.payment__inputrow__label}>
													Account Number <span className={styles.asterisk}>*</span>
												</label>
												<label className={styles.payment__inputrow__note}>(Upload either cheque book or passbook photo)</label>
											</div>
											<input
												className={styles.payment__inputrow__input}
												onChange={handleInputChange}
												placeholder="XXXXXXXXXXX"
												type="string"
												name="bankAccountNumber"
												value={formDetails.bankAccountNumber}
											></input>
										</div>
										<div className={styles.payment__inputrow}>
											<label className={styles.payment__inputrow__label}></label>
											<div style={{ display: 'flex', gap: '80px' }}>
												<div className={styles.photo}>
													<input
														type="file"
														id="cancelled-cheque"
														name="cancelled-cheque"
														accept="image/*"
														onChange={e => handlePhotoUpload(e)}
														className={styles.photo__input}
													/>
													{!formDetails.cancelledCheckPhoto && !loading.cancelledChequePhoto && (
														<label htmlFor="cancelled-cheque">
															<div className={styles.photo__uploadcontainer}>
																<img src="/icons/upload_icon.svg" alt="Cancelled Cheque Photo"></img>
																<span>Upload Cancelled Cheque Photo</span>
															</div>
														</label>
													)}
													{loading.cancelledChequePhoto && <div className={styles.photo__uploadcontainer}>Uploading....</div>}
													{formDetails.cancelledCheckPhoto && !loading.cancelledChequePhoto && (
														<label htmlFor="cancelled-cheque" className={styles.photo__container}>
															<img
																className={styles.photo}
																onChange={e => handlePhotoUpload(e)}
																src={formDetails.cancelledCheckPhoto as string}
																alt="cancelled cheque photo"
															></img>
															<div className={styles.photo__changecontainer}>
																<img src="/icons/upload_icon_white.svg" alt="Cancelled Cheque Photo"></img>
																<span>Change Image</span>
															</div>
														</label>
													)}
												</div>
												<div className={styles.photo}>
													<input
														type="file"
														id="passbook"
														name="passbook"
														accept="image/*"
														onChange={e => handlePhotoUpload(e)}
														className={styles.photo__input}
													/>
													{!formDetails.passbookPhoto && !loading.passbookPhoto && (
														<label htmlFor="passbook">
															<div className={styles.photo__uploadcontainer}>
																<img src="/icons/upload_icon.svg" alt="Passbook photo"></img>
																<span>Upload Passbook Photo</span>
															</div>
														</label>
													)}
													{loading.passbookPhoto && <div className={styles.photo__uploadcontainer}>Uploading....</div>}
													{formDetails.passbookPhoto && !loading.passbookPhoto && (
														<label htmlFor="passbook" className={styles.photo__container}>
															<img
																className={styles.photo}
																onChange={e => handlePhotoUpload(e)}
																src={formDetails.passbookPhoto as string}
																alt="passbook photo"
															></img>
															<div className={styles.photo__changecontainer}>
																<img src="/icons/upload_icon_white.svg" alt="Passbook photo"></img>
																<span>Change Image</span>
															</div>
														</label>
													)}
												</div>
											</div>
										</div>
										<div className={styles.payment__inputrow}>
											<label className={styles.payment__inputrow__label}>
												Re-enter Account Number <span className={styles.asterisk}>*</span>
											</label>
											<input
												className={styles.payment__inputrow__input}
												onChange={handleInputChange}
												placeholder="1234 5678 9012"
												type="string"
												name="confirmBankAccountNumber"
												value={formDetails.confirmBankAccountNumber}
											></input>
										</div>
										<div className={styles.payment__inputrow}>
											<label className={styles.payment__inputrow__label}>
												IFSC Code <span className={styles.asterisk}>*</span>
											</label>
											<input
												className={styles.payment__inputrow__input}
												onChange={handleInputChange}
												placeholder="XXXXXXXXXXX"
												type="string"
												name="ifscCode"
												value={formDetails.ifscCode}
											></input>
										</div>
										<div className={styles.payment__inputrow}>
											<div className={styles.payment__inputrow__container}>
												<label className={styles.payment__inputrow__label}>
													Beneficiary Name <span className={styles.asterisk}>*</span>
												</label>
												<label className={styles.payment__inputrow__note}>(Should be same as your name)</label>
											</div>

											<input
												className={styles.payment__inputrow__input}
												onChange={handleInputChange}
												placeholder="Full Name"
												type="string"
												name="beneficiaryName"
												value={formDetails.beneficiaryName}
											></input>
										</div>

										<div className={styles.payment__inputrow}>
											<label className={styles.payment__inputrow__label}>
												UPI ID <span className={styles.asterisk}>*</span>
											</label>
											<input
												className={styles.payment__inputrow__input}
												onChange={handleInputChange}
												placeholder="abcd123@bankname"
												type="string"
												name="upiId"
												value={formDetails.upiId}
											></input>
										</div>
										<div className={styles.payment__inputrow}>
											<label className={styles.payment__inputrow__label}>
												Phone Number <span className={styles.asterisk}>*</span>
											</label>
											<input
												className={styles.payment__inputrow__input}
												onChange={handleInputChange}
												placeholder="e.g: 9807453219"
												type="string"
												name="phoneNo"
												value={formDetails.phoneNo}
											></input>
										</div>
										{/* <div className={styles.payment__inputrow}>
											<label className={styles.payment__inputrow__label}></label>
											<div className={styles.payment__actions}>
												<button className={styles.payment__actions__OTP}>Get OTP</button>
												<button className={styles.payment__actions__resend}>Resend</button>
											</div>
										</div>
										<div className={styles.payment__inputrow}>
											<label className={styles.payment__inputrow__label}>
												OTP <span className={styles.asterisk}>*</span>
											</label>
											<input
												className={styles.payment__inputrow__input}
												onChange={handleInputChange}
												placeholder="e.g: 323654"
												type="string"
												name="otp"
												value={formDetails.otp}
											></input>
										</div>
										<div className={styles.payment__inputrow}>
											<label className={styles.payment__inputrow__label}></label>
											<div className={styles.payment__actions}>
												<button className={styles.payment__actions__OTP}>Verify</button>
											</div>
										</div> */}

										<div>
											<div className={styles.finalagreement__container}>
												<div className={styles.finalagreement}>
													{`By entering banking details and clicking "I Agree," the user affirms the accuracy of provided information. The company is not liable for
							misinformation, and any malicious activity may result in legal action. The user is responsible for compliance with applicable laws. You affirm
							that the banking details provided by you are accurate, complete, and pertain to an account of which you are the lawful owner. The Company retains
							the right to verify the authenticity of the submitted banking details and may request additional information or documentation for verification
							purposes.`}
												</div>
												<div className={styles.agreement__checkbox__container}>
													<>
														<input
															name="bankingAgreementAccepted"
															type="radio"
															checked={formDetails.agreementBankingDetailsAccepted}
															onChange={handleCheckboxClick}
														></input>{' '}
														<span>I agree</span>
													</>
													<>
														<input
															name="bankingAgreementRejected"
															type="radio"
															checked={!formDetails.agreementBankingDetailsAccepted}
															onChange={handleCheckboxClick}
														></input>{' '}
														<span>I disagree</span>
													</>
												</div>
											</div>
											<div className={styles.finalagreement__container}>
												<div className={styles.finalagreement}>
													{`By proceeding with KYC verification, the user, confirming they are 18 years of age, gives consent to access the provided documents for verification purposes. The user consents to providing accurate and authentic information. Any discrepancies or false details may result in account suspension.`}
												</div>
												<div className={styles.agreement__checkbox__container}>
													<>
														<input
															name="kycAgreementAccepted"
															type="radio"
															checked={formDetails.agreementKycDetailsAccepted}
															onChange={handleCheckboxClick}
														></input>{' '}
														<span>I agree</span>
													</>
													<>
														<input
															name="kycAgreementRejected"
															type="radio"
															checked={!formDetails.agreementKycDetailsAccepted}
															onChange={handleCheckboxClick}
														></input>{' '}
														<span>I disagree</span>
													</>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>

						<div className={styles.container__action__container}>
							{currentStep !== 'personal' && (
								<button className={styles.container__button} onClick={e => handleBackButtonClick()}>
									Back
								</button>
							)}
							<button
								style={{ backgroundColor: handleButtonEnabled() !== '' ? 'grey' : '' }}
								className={styles.container__button}
								onClick={e => handleButtonClick(e)}
							>
								{currentStep === 'payment' ? 'Submit' : 'Next'}
							</button>
						</div>
					</div>
				)}
			</div>
		</Container>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps, null)(OnboardingDetails));
