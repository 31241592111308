import { client } from './../../util/client';

type tagsAndLocationsResponse = {
	success: boolean;
	result: {
		awi_tags: {
			awi_label: string;
			awi_id: number;
		}[];
		awi_locations: {
			awi_label: string;
			awi_id: number;
		}[];
	};
};

export const getTagsAndLocations = (data: { awi_tag_ids: number[]; awi_location_ids: number[] }) => {
	const queryKey = ['tags-and-locations', data] as const;
	const queryFunction = () =>
		client<tagsAndLocationsResponse>('/get_tags_and_location_data', { baseURL: 'dataset', method: 'POST', data: { ...data } }).then(res => res.data);

	return [queryKey, queryFunction] as const;
};
