import { useQuery as useReactQuery, QueryFunction, UseQueryOptions, QueryKey, UseQueryResult } from 'react-query';

/* prettier-ignore */
export const useQuery = <TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
	queryKey: TQueryKey,
	queryFn: QueryFunction<TQueryFnData, TQueryKey>,
	options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> = {}
): UseQueryResult<TData, TError> => {
	return useReactQuery<TQueryFnData, TError, TData, TQueryKey>(queryKey, queryFn, {
		refetchOnWindowFocus: false,
		staleTime: 1000 * 60 * 30,
		...options
	});
};
