import { CancelTokenSource } from 'axios';
import { client } from '../../util/client';
import { userStatsToRequest } from '../../types';

type usetStatsResponse = {
	success: boolean;
	message?: string;
	result: userStatsToRequest[];
};

export const getUserStats = (data: { awi_request_id: number; awi_user_level: string; searchQuery?: string; source: CancelTokenSource }) =>
	client('/request_users_progress', {
		baseURL: 'request',
		method: 'POST',
		data: {
			awi_request_id: data.awi_request_id,
			awi_user_level: data.awi_user_level,
			searchQuery: data.searchQuery
		},
		cancelToken: data.source.token
	}).then(res => res.data);
