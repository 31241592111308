import { client } from '../../util/client';

type ClientResponse = {
	success: boolean;
	message: string;
};

export const resetPassword = (data: { awi_reset_password: string; awi_reset_confirmpassword: string; token: string }) =>
	client<ClientResponse>('/reset_password', {
		baseURL: 'user',
		data: data,
		method: 'POST'
	}).then(res => res.data);
