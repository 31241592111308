import { CancelTokenSource } from 'axios';
import { Tag } from '../../types';
import { client } from '../../util/client';

interface TagsResponse {
	result: Tag[];
}

export const searchTags = ({ s, source }: { s: string; source: CancelTokenSource }) => {
	const queryKey = ['tags', { s }] as const;

	const queryFunction = () =>
		client<TagsResponse>('/tags', {
			method: 'GET',
			params: {
				s
			},
			cancelToken: source.token
		}).then(res => res.data);

	return [queryKey, queryFunction] as const;
};
