import { client } from './../../util/client';

type exportDatasetResponse = {
	success: boolean;
	message?: string;
	signed_url?: string;
	key?: string;
	result;
};

export const exportDataset = (data: {
	awi_content_keys?: string[];
	awi_class_proportions?: any[];
	awi_request_id: number;
	awi_selected_option: string;
	awi_class_proportions_for_collection: any;
	awi_class_ids: number[];
}) => {
	return client<exportDatasetResponse>('/export_dataset', { baseURL: 'request', method: 'POST', data: { ...data } }).then(res => res.data);
};
