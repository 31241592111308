import { RequestType } from '../../../../types';
import { getFirstDayOfMonth } from '../../../../util/common.utils';

export const class_pie_colors = [
	'rgba(49,203,106,0.2)',
	'rgba(97,73,111,0.2)',
	'rgba(136,77,249,0.2)',
	'rgba(21,69,225,0.2)',
	'rgba(3,169,91,0.2)',
	'rgba(146,29,111,0.2)',
	'rgba(86,108,71,0.2)',
	'rgba(212,5,116,0.2)',
	'rgba(121,150,222,0.2)',
	'rgba(234,111,24,0.2)',
	'rgba(251,12,121,0.2)',
	'rgba(59,251,165,0.2)',
	'rgba(185,193,97,0.2)',
	'rgba(253,228,37,0.2)',
	'rgba(66,166,118,0.2)',
	'rgba(229,194,33,0.2)',
	'rgba(45,145,27,0.2)',
	'rgba(143,166,69,0.2)',
	'rgba(166,129,82,0.2)',
	'rgba(112,3,218,0.2)',
	'rgba(103,44,105,0.2)',
	'rgba(111,237,191,0.2)',
	'rgba(210,255,241,0.2)',
	'rgba(49,217,24,0.2)',
	'rgba(9,197,227,0.2)',
	'rgba(122,199,158,0.2)',
	'rgba(143,241,115,0.2)',
	'rgba(99,148,40,0.2)'
];

export const attribute_pie_colors = [
	'rgba(86,34,78,0.2)',
	'rgba(182,103,19,0.2)',
	'rgba(232,213,79,0.2)',
	'rgba(103,69,118,0.2)',
	'rgba(223,233,1,0.2)',
	'rgba(83,147,45,0.2)',
	'rgba(84,111,243,0.2)',
	'rgba(134,48,191,0.2)',
	'rgba(108,160,179,0.2)',
	'rgba(169,52,153,0.2)',
	'rgba(122,125,49,0.2)',
	'rgba(138,203,166,0.2)',
	'rgba(63,36,135,0.2)',
	'rgba(190,120,233,0.2)',
	'rgba(217,201,108,0.2)',
	'rgba(72,145,42,0.2)',
	'rgba(246,175,242,0.2)',
	'rgba(142,73,124,0.2)',
	'rgba(232,38,128,0.2)',
	'rgba(187,142,47,0.2)',
	'rgba(77,204,57,0.2)',
	'rgba(95,57,13,0.2)',
	'rgba(88,109,218,0.2)',
	'rgba(161,70,224,0.2)',
	'rgba(149,218,197,0.2)',
	'rgba(15,223,138,0.2)',
	'rgba(13,105,239,0.2)',
	'rgba(248,187,143,0.2)',
	'rgba(203,102,21,0.2)',
	'rgba(250,244,3,0.2)',
	'rgba(80,254,231,0.2)',
	'rgba(113,157,96,0.2)',
	'rgba(70,86,152,0.2)',
	'rgba(100,229,234,0.2)',
	'rgba(44,130,114,0.2)',
	'rgba(90,52,1,0.2)',
	'rgba(44,66,221,0.2)',
	'rgba(215,238,74,0.2)',
	'rgba(43,228,26,0.2)',
	'rgba(84,180,233,0.2)',
	'rgba(39,114,38,0.2)',
	'rgba(141,153,251,0.2)',
	'rgba(58,59,128,0.2)',
	'rgba(60,171,247,0.2)',
	'rgba(241,86,81,0.2)',
	'rgba(249,185,99,0.2)',
	'rgba(143,142,233,0.2)',
	'rgba(174,190,89,0.2)',
	'rgba(0,233,29,0.2)',
	'rgba(9,247,215,0.2)',
	'rgba(116,94,206,0.2)',
	'rgba(37,82,6,0.2)',
	'rgba(249,134,130,0.2)',
	'rgba(246,141,136,0.2)',
	'rgba(1,207,29,0.2)',
	'rgba(236,250,87,0.2)',
	'rgba(39,202,149,0.2)',
	'rgba(177,126,101,0.2)',
	'rgba(113,132,104,0.2)',
	'rgba(88,46,22,0.2)',
	'rgba(104,162,220,0.2)',
	'rgba(182,49,0,0.2)'
];

export const values_pie_colors = [
	'rgba(80,254,231,0.2)',
	'rgba(113,157,96,0.2)',
	'rgba(70,86,152,0.2)',
	'rgba(100,229,234,0.2)',
	'rgba(44,130,114,0.2)',
	'rgba(90,52,1,0.2)',
	'rgba(44,66,221,0.2)',
	'rgba(215,238,74,0.2)',
	'rgba(43,228,26,0.2)',
	'rgba(84,180,233,0.2)',
	'rgba(39,114,38,0.2)',
	'rgba(141,153,251,0.2)',
	'rgba(58,59,128,0.2)',
	'rgba(60,171,247,0.2)',
	'rgba(241,86,81,0.2)',
	'rgba(249,185,99,0.2)',
	'rgba(143,142,233,0.2)',
	'rgba(174,190,89,0.2)',
	'rgba(0,233,29,0.2)',
	'rgba(9,247,215,0.2)',
	'rgba(116,94,206,0.2)',
	'rgba(37,82,6,0.2)',
	'rgba(249,134,130,0.2)',
	'rgba(246,141,136,0.2)',
	'rgba(1,207,29,0.2)',
	'rgba(236,250,87,0.2)',
	'rgba(39,202,149,0.2)',
	'rgba(177,126,101,0.2)',
	'rgba(113,132,104,0.2)',
	'rgba(88,46,22,0.2)',
	'rgba(104,162,220,0.2)',
	'rgba(182,49,0,0.2)'
];

export const annotationData = [
	{
		awi_id: 0,
		awi_label: 'rectangle'
	},
	{
		awi_id: 1,
		awi_label: 'circle'
	},
	{
		awi_id: 2,
		awi_label: 'polygon'
	},
	{
		awi_id: 3,
		awi_label: 'dot'
	}
];

export const requestType = [
	{
		awi_id: 0,
		awi_label: 'detector'
	},
	{
		awi_id: 1,
		awi_label: 'classifier'
	}
];

export const statusData = [
	{
		awi_id: 0,
		awi_label: 'pending'
	},
	{
		awi_id: 1,
		awi_label: 'failed'
	},
	{
		awi_id: 2,
		awi_label: 'completed'
	},
	{
		awi_id: 3,
		awi_label: 'on-hold'
	},
	{
		awi_id: 4,
		awi_label: 'draft'
	}
];

export const assignedUserLevelData = [
	{
		awi_id: 0,
		awi_label: 'awi_supervisor'
	},
	{
		awi_id: 1,
		awi_label: 'awi_verifier'
	},
	{
		awi_id: 2,
		awi_label: 'awi_annotator'
	}
];

export const initialFilterData = {
	awi_start_date: null,
	awi_deadline_date: null,
	awi_annotation_type: [],
	awi_created_by: [],
	awi_user_assigned: [],
	awi_class_ids: [],
	awi_status: [],
	awi_req_type: '' as RequestType,
	awi_deadline_crossed: false
};
