import { RequestFilterMetricResponse } from '../../types';
import { client } from '../../util/client';

export const getRequestFilters = () => {
	const queryKey = ['request-filter'] as const;
	const queryFunction = () =>
		client<RequestFilterMetricResponse>('/fetch_request_filter', {
			baseURL: 'request',
			method: 'GET'
		}).then(res => res.data);
	return [queryKey, queryFunction] as const;
};
