import clsx from 'clsx';
import { InfiniteData } from 'react-query';
import { ImageBankResponse } from '../../../handlers/content';
import { getFullName, getImageKitUrl } from '../../../util/common.utils';

import styles from './styles.module.scss';

interface ImageCardListProps {
	images: InfiniteData<ImageBankResponse>;
	handleImageSelect: (id: number) => void;
	selectedImage: number[];
	galleryView: string;
}

const ImageCardList: React.FC<ImageCardListProps> = ({ images, handleImageSelect, selectedImage, galleryView }) => {
	console.log(galleryView, images, 'kkk');
	console.log(selectedImage);

	return (
		<div className={styles.image_list}>
			<table className={styles.image_list__table}>
				<thead className={styles.image_list__table__header}>
					<th></th>
					<th>Sno.</th>
					<th>thumbnail</th>
					<th>Name</th>
					<th>uploaded by</th>
					<th>mapped requests</th>
					<th>classes</th>
				</thead>
				{images.pages.map((page, pageIndex) => {
					return page.result.map((image, index) => {
						console.log(image.awi_content_key, 'hjh');

						return (
							<tr
								key={pageIndex * page.result.length + index + 1}
								className={clsx(styles.image_list__table__row, {
									[styles.image_list__table__row__row_selected]: selectedImage.includes(+image.awi_content_key)
								})}
							>
								<td>
									<input
										onChange={() => {
											handleImageSelect(image.awi_content_key);
										}}
										type="checkbox"
										name=""
										id=""
										checked={selectedImage.includes(+image.awi_content_key)}
									/>
								</td>
								<td>{pageIndex * page.result.length + index + 1}</td>
								<td>
									<img width={24} height={24} src={getImageKitUrl(image.awi_content_url, 80, 80, 30)} />
								</td>
								<td>IMG_{image.awi_content_key}</td>
								<td>{getFullName(image.awi_user)}</td>
								<td>
									{image.awi_request_labels.length > 0 ? image.awi_request_labels.map((request, index) => <span key={index}>{request.awi_label},</span>) : '-'}
								</td>
								<td>{image.awi_class_labels.length > 0 ? image.awi_class_labels.map((_class, index) => <span key={index}>{_class.awi_label},</span>) : '-'}</td>
							</tr>
						);
					});
				})}
			</table>
		</div>
	);
};
export default ImageCardList;
