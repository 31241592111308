import clsx from 'clsx';
import HotKeys from 'react-hot-keys';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useQueryClient } from 'react-query';
import { useEffect, useRef, useState, useCallback, MouseEventHandler } from 'react';

import { getRequestData } from '../../../handlers/request/getRequestData';
import { fetchAnnotations } from '../../../handlers/annotation/fetchAnnotations';

import { useQuery } from '../../../util/useQuery';
import { getImageKitUrl } from '../../../util/common.utils';
import { getMousePos, getUpdatedCoordinates } from './utils';

import { LoggedUser } from '../../../types';
import { ShowAnnotationStatus } from '../../../types/annotations';

import styles from './styles.module.scss';
import { convertAwiObjectToToolAnnotationObjList } from '../../../util/CommonUtils';

interface ImageAnnotationPreviewerProps {
	imageUrl: string;
	showAnnotations: boolean;
	createAnnotation?: (coords: { x: number; y: number }[], cb?: () => void) => void;
	handleUpdateAnnotationCoordinates?: (coords: { x: number; y: number }[], annotation_id: number, cb?: () => void) => void;
	className?: string;
	content_id?: number;
	request_id?: number;
	isEditing?: boolean;
	handleSelectTool?: (tool: 'edit' | 'annotate') => void;
	selectIndex?: number;
	handleSelectAnnotationIndex?: (index: number) => void;
	showAnnotationType?: ShowAnnotationStatus;
	loggedUser: LoggedUser;
	isCarousel?: boolean;
	classColor?: { awi_id: number; awi_color: string }[];
	disableAnnotation?: boolean;
}

const ImageAnnotationPreviewer: React.FC<ImageAnnotationPreviewerProps> = ({
	imageUrl,
	showAnnotations = false,
	createAnnotation,
	handleUpdateAnnotationCoordinates,
	content_id,
	request_id,
	className = '',
	isEditing = false,
	selectIndex = -1,
	handleSelectAnnotationIndex,
	handleSelectTool,
	showAnnotationType = 'all',
	loggedUser,
	isCarousel = false,
	classColor = [],
	disableAnnotation = false
}) => {
	const imageRef = useRef<string>('');
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const canvasContainerRef = useRef<HTMLDivElement>(null);

	const queryClient = useQueryClient();

	const [isDrawingAnnotation, setIsDrawingAnnoations] = useState<boolean>(false);
	const [isUpdatingAnnotation, setIsUpdatingAnnotation] = useState<boolean>(false);
	const [coordinates, setCoordinates] = useState<Array<{ x: number; y: number }>>([]);
	const [selectCoordinates, setSelectCoordinates] = useState<{ x: number; y: number }>();
	const [selectCornerCoordinates, setSelectCornerCoordinates] = useState<{ x: number; y: number }>({ x: -1, y: -1 });
	const [selectCornerIndex, setSelectCornerIndex] = useState<number>(-1);

	const [initialCoords, setInintialCoords] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

	const [selectAnnotationId, setSelectAnnotationId] = useState<number>(selectIndex);
	const [selectedAnnotation, setSelectedAnnotation] = useState<any>(null);

	const [selectedAnnotations, setSelectedAnnotations] = useState<number[]>([]);
	const [isMoving, setIsMoving] = useState<boolean>(false);

	const senstivity = 0.001;

	// const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
	// const [showScaling, setShowScaling] = useState<boolean>(false);
	// const [keyInterval, setKeyInterval] = useState<NodeJS.Timeout | null>(null);

	const [canvasDimensions, setCanvasDimensions] = useState<{ width: number; height: number }>({ width: 0, height: 0 });

	//zoom and move image related
	const initialZoomProperties = {
		scale: 1,
		x: 0,
		y: 0
	};
	const [zoomProperties, setZoomProperties] = useState<{ x: number; y: number; scale: number }>(initialZoomProperties);
	const zoomLevel = 0.2;
	const transformLevel = canvasDimensions.width * zoomLevel;

	const [fetchAnnotationsKey, fetchAnnotationsFunction] = fetchAnnotations({ awi_request_id: request_id!, awi_content_id: +content_id! });

	const { data: annotations } = useQuery(fetchAnnotationsKey, fetchAnnotationsFunction, {
		enabled: !!content_id && !!request_id && content_id !== -1 && request_id !== -1,
		onSuccess() {
			reDrawAnnotations();
		}
	});

	const { data: request } = useQuery(...getRequestData(request_id!), {
		enabled: !!request_id && request_id !== -1
	});

	useEffect(() => {
		updateDimensions();
		window.addEventListener('resize', updateDimensions);
	}, []);

	useEffect(() => {
		setCoordinates([]);
	}, [isEditing]);

	useEffect(() => {
		if (imageUrl) {
			imageRef.current = imageUrl;
			updateDimensions();
			if ((annotations?.result || []).length > 0) {
				queryClient.invalidateQueries(fetchAnnotationsKey);
			}
		}
	}, [imageUrl]);

	useEffect(() => {
		if ((annotations?.result || []).length >= 0) {
			reDrawAnnotations();
		}
	}, [canvasDimensions, showAnnotations]);

	useEffect(() => {
		if ((annotations?.result || []).length >= 0) {
			reDrawAnnotations();
		}
		const tempSelectedAnnotation = (annotations?.result || []).find(ele => ele.awi_id === selectAnnotationId);
		if (tempSelectedAnnotation) {
			setSelectedAnnotation(tempSelectedAnnotation);
		} else {
			setSelectedAnnotation(null);
		}
	}, [selectedAnnotations, selectAnnotationId]);

	useEffect(() => {
		if (request && (annotations?.result || []).length >= 0) {
			reDrawAnnotations();
		}
	}, [request, annotations]);

	useEffect(() => {
		if (selectIndex !== -1) {
			if (typeof handleSelectTool === 'function' && request?.awi_request_for !== 'outline') {
				handleSelectTool('edit');
			}
		} else {
			setSelectedAnnotations([]);
		}
		setSelectAnnotationId(selectIndex);
	}, [selectIndex]);

	// useEffect(() => {
	// 	if (cursorPosition.x === -1 && cursorPosition.y === -1) {
	// 		reDrawAnnotations();
	// 	}
	// }, [cursorPosition])

	const getCanvasRatio = imageUrl => {
		if (imageUrl) {
			return new Promise((resolve, reject) => {
				const img = new Image();
				img.onload = () => {
					return resolve({ w: img.width, h: img.height });
				};
				img.onerror = reject;
				const _imageUrl = getImageKitUrl(imageUrl, -1, -1, 1);
				if (_imageUrl !== undefined && _imageUrl !== null) {
					img.src = _imageUrl;
				}
			});
		} else {
			return { w: 16, h: 9 };
		}
	};

	const updateDimensions = async () => {
		if (imageRef.current !== '') {
			const res: any = await getCanvasRatio(imageRef.current);
			const r = gcd(res.w, res.h);
			const imageAspectRatio = { w: res.w / r, h: res.h / r };
			let width = 0;
			let height = 0;

			height = canvasContainerRef.current?.offsetHeight || 0;
			width = (height * imageAspectRatio.w) / imageAspectRatio.h;
			if (width > (canvasContainerRef.current?.offsetWidth || 0)) {
				width = canvasContainerRef.current?.offsetWidth || 0;
				height = (width * imageAspectRatio.h) / imageAspectRatio.w;
			}

			if (canvasRef?.current != null) {
				canvasRef.current.width = width;
				canvasRef.current.height = height;
			}

			setCanvasDimensions({ width, height });
			if ((annotations?.result || []).length >= 0) {
				reDrawAnnotations();
			}
		}
	};

	const getDistance = (x1: number, y1: number, x2: number, y2: number) => {
		return Math.sqrt(Math.pow(Math.abs(x2 - x1), 2) + Math.pow(Math.abs(y2 - y1), 2));
	};

	const isLeft = (P0, P1, P2) => {
		return (P1.x - P0.x) * (P2.y - P0.y) - (P2.x - P0.x) * (P1.y - P0.y);
	};

	const windingNumber = (P, polygon) => {
		let wn = 0; // the winding number counter

		// loop through all edges of the polygon
		for (let i = 0; i < polygon.length - 1; i++) {
			if (polygon[i].y <= P.y) {
				if (polygon[i + 1].y > P.y) {
					if (isLeft(polygon[i], polygon[i + 1], P) > 0) {
						wn++;
					}
				}
			} else {
				if (polygon[i + 1].y <= P.y) {
					if (isLeft(polygon[i], polygon[i + 1], P) < 0) {
						wn--;
					}
				}
			}
		}
		return wn;
	};
	const isPointInsidePolygon = useCallback((x: number, y: number, polygon, pos_senstivity: number, neg_senstivity: number) => {
		let r, centreX, centreY, dist_from_centre_sq;
		if (request?.awi_annotation_type === 'circle') {
			r = getDiameter(polygon) / 2;
			centreX = (polygon[0].x + pos_senstivity + (polygon[1].x - neg_senstivity)) / 2;
			centreY = (polygon[0].y + polygon[1].y) / 2;

			dist_from_centre_sq = (x - centreX) * (x - centreX) + (y - centreY) * (y - centreY);

			if (dist_from_centre_sq < r * r) {
				return true;
			}
		} else if (request?.awi_annotation_type === 'dot') {
			r = getDiameter(polygon) / 2;
			centreX = (polygon[0].x + pos_senstivity + (polygon[0].x - neg_senstivity)) / 2;
			centreY = (polygon[0].y + polygon[0].y) / 2;

			dist_from_centre_sq = (x - centreX) * (x - centreX) + (y - centreY) * (y - centreY);

			if (dist_from_centre_sq < r * r) {
				return true;
			}
		} else {
			return windingNumber({ x, y }, polygon) !== 0;
		}
	}, []);

	const getSelectedAnnotation = (event: any) => {
		const { x, y } = getMousePos(canvasRef.current, event);
		const tempAnnotations: number[] = [];
		(annotations?.result || []).forEach(({ awi_coords, awi_id }) => {
			if (request?.awi_annotation_type === 'circle') {
				if (isPointInsidePolygon(x, y, awi_coords, 0, 0)) {
					tempAnnotations.push(awi_id);
				}
			} else if (request?.awi_annotation_type === 'dot') {
				if (isPointInsidePolygon(x, y, awi_coords, 0.005, 0.005)) {
					tempAnnotations.push(awi_id);
				}
			} else {
				// for (let i = 0; i < awi_coords.length - 1; i++) {
				// const { x: x1, y: y1 } = awi_coords[i];
				// const { x: x2, y: y2 } = awi_coords[(i + 1) % awi_coords.length];
				// if (isPointInsidePolygon(x, y, y1 > y2 ? x1 : x2, y1 > y2 ? x2 : x1, y1 > y2 ? y1 : y2, y1 > y2 ? y2 : y1))
				if (isPointInsidePolygon(x, y, awi_coords, 0, 0)) {
					if (!tempAnnotations.includes(awi_id)) {
						tempAnnotations.push(awi_id);
					} else {
						tempAnnotations.pop();
					}
					// }
				}
			}
		});

		tempAnnotations.sort((a, b) => a - b);

		tempAnnotations.forEach((id, index) => {
			if (id === tempAnnotations[index + 1]) {
				tempAnnotations.splice(index, 2);
			}
		});

		return [...tempAnnotations, -1];
	};

	const selectAnnotation = (event, selectedIndex) => {
		const { x, y } = getMousePos(canvasRef.current, event);
		const annotation = (annotations?.result || []).find(annotation => annotation.awi_id === selectedIndex);

		if (annotation) {
			const { awi_coords: coords } = annotation;

			if (coords.length === 1) {
				if (isPointInsidePolygon(x, y, coords, 0.005, 0.005)) {
					setIsUpdatingAnnotation(true);
					setIsMoving(true);
					setSelectCoordinates({ x, y });
					setCoordinates(coords);
				}
				return;
			}

			if (coords.length === 2) {
				const { x: x3, y: y3 } = coords[0];
				const { x: x4, y: y4 } = coords[1];
				const r =
					getDiameter([
						{ x: x3, y: y3 },
						{ x: x4, y: y4 }
					]) / 2;

				const centreX = (x3 + x4) / 2;
				const centreY = (y3 + y4) / 2;

				setIsUpdatingAnnotation(true);
				setIsMoving(true);
				setSelectCoordinates({ x, y });
				setSelectCornerCoordinates({ x, y });
				setCoordinates(coords);

				const dist_from_centre_sq = (x - centreX) * (x - centreX) + (y - centreY) * (y - centreY);
				if (dist_from_centre_sq > 0.7 * 0.7 * r * r) {
					setIsMoving(false);
				}
				return;
			}

			let insidePolygon = 0;

			// for (let i = 1; i <= coords.length; i++) {
			// 	const { x: x1, y: y1 } = coords[i - 1];
			// 	const { x: x2, y: y2 } = coords[i % coords.length];
			// 	if (isPointInsidePolygon(x, y, x1, x2, y1, y2)) {
			// 		insidePolygon++;
			// 	}
			// }
			insidePolygon = windingNumber({ x, y }, coords);

			if (insidePolygon === 0) {
				setSelectCornerCoordinates({ x: -1, y: -1 });
				setSelectCoordinates({ x: 0, y: 0 });
				setIsMoving(false);
			} else if (insidePolygon > 0) {
				setSelectCoordinates({ x, y });
				setIsUpdatingAnnotation(true);
				setIsMoving(true);
				setCoordinates(coords);
				setSelectCornerCoordinates({ x: -1, y: -1 });

				coords.forEach((coord, index) => {
					if (
						(x <= coord.x + 0.025 && x >= coord.x && y <= coord.y + 0.025 && y >= coord.y) ||
						(x >= coord.x - 0.025 && x <= coord.x && y >= coord.y - 0.025 && y <= coord.y) ||
						(x <= coord.x + 0.025 && x >= coord.x && y >= coord.y - 0.025 && y <= coord.y) ||
						(x >= coord.x - 0.025 && x <= coord.x && y <= coord.y + 0.025 && y >= coord.y)
					) {
						if (request?.awi_annotation_type === 'rectangle') {
							const cornerCoords = coords[(index + 2) % 4];
							setSelectCornerCoordinates(cornerCoords);
						} else {
							setSelectCornerIndex(index);
							setSelectCornerCoordinates(coords[index]);
						}
						setIsMoving(false);
						setCoordinates([]);
					}
				});
			}
		}
	};

	const fixDpi = () => {
		const dpi = window.devicePixelRatio;
		if (canvasRef.current) {
			const style_height = +getComputedStyle(canvasRef.current).getPropertyValue('height').slice(0, -2);
			const style_width = +getComputedStyle(canvasRef.current).getPropertyValue('width').slice(0, -2);
			canvasRef.current.setAttribute('height', (style_height * dpi).toString());
			canvasRef.current.setAttribute('width', (style_width * dpi).toString());
		}
	};

	/**
	 * It takes an array of coordinates and draws them on a canvas
	 * @param {{ x: number; y: number }[]} _coordinates - { x: number; y: number }[] = []
	 * @param [drawingPolygon=false] - boolean
	 */
	const reDrawAnnotations = (_coordinates: { x: number; y: number }[] = []) => {
		if (request) {
			const context = canvasRef.current?.getContext('2d');
			fixDpi();
			if (context !== null && context !== undefined) {
				context.clearRect(0, 0, canvasDimensions.width, canvasDimensions.height);

				// if (showScaling && !isCarousel && cursorPosition.x !== -1 && cursorPosition.y !== -1) {
				// 	drawPlus(cursorPosition.x, cursorPosition.y);
				// }
				if ((_coordinates || []).length > 0) {
					context.beginPath();
					const coords = scaleUpCoordinates(_coordinates);
					if (request?.awi_annotation_type === 'rectangle') {
						context.moveTo(coords[0].x, coords[0].y);
						for (let i = 1; i < coords.length; i++) {
							context.lineTo(coords[i].x, coords[i].y);
						}

						context.closePath();
					} else if (request?.awi_annotation_type === 'circle') {
						const diameter = getDiameter(coords);

						context.arc((coords[0].x + coords[1].x) / 2, (coords[0].y + coords[1].y) / 2, Math.floor(diameter / 2), 0, 2 * Math.PI);
					} else if (request?.awi_annotation_type === 'polygon') {
						context.moveTo(coords[0].x, coords[0].y);
						coords.forEach(coord => {
							context.lineTo(coord.x, coord.y);
						});
					}
					context.save();
					context.lineWidth = 1.5;
					context.strokeStyle = 'black';
					context.stroke();
				}

				if (request?.awi_annotation_type === 'polygon' && (coordinates || []).length > 0 && !isEditing) {
					const coordinatesVal = scaleUpCoordinates(coordinates);
					context.moveTo(coordinatesVal[0].x, coordinatesVal[0].y);
					context.arc(coordinatesVal[0].x, coordinatesVal[0].y, 1, 0, 2 * Math.PI, true);
					coordinatesVal.forEach(coord => {
						context.arc(coord.x, coord.y, 1, 0, 2 * Math.PI, true);
						context.lineTo(coord.x, coord.y);
					});
					context.save();
					context.lineWidth = 1.5;
					context.strokeStyle = 'black';
					context.fillStyle = 'rgba(0,0,0,0.35)';
					context.stroke();
				}

				(annotations?.result || []).forEach(annotation => {
					const { awi_coords, awi_id, awi_status } = annotation;
					context.beginPath();
					if (showAnnotationType === 'all' || awi_status === showAnnotationType) {
						if (annotation.awi_status === 'approved') {
							context.fillStyle = 'rgba(66, 179, 97,0.15)';
						} else if (annotation.awi_status === 'rejected') {
							context.fillStyle = 'rgba(184, 33, 44,0.15)';
						} else if (annotation.awi_status === 'corrected') {
							context.fillStyle = 'rgba(175, 219, 245,0.15)';
						} else if (annotation.awi_status === 'pending') {
							context.fillStyle = 'rgba(0, 0, 0,0)';
						}
						if (awi_id === selectAnnotationId) {
							context.lineWidth = 1.5;
							context.strokeStyle = 'black';
							context.fillStyle = 'rgba(0,0,0,0.15)';
						} else {
							if (isCarousel) {
								context.lineWidth = 1;
							} else {
								context.lineWidth = 1.5;
							}

							if (classColor.length > 0) {
								const classColorEle = classColor.find(color => color.awi_id === annotation.awi_classes[0].awi_id)?.awi_color;
								if (classColorEle) {
									context.strokeStyle = classColorEle;
								} else {
									context.strokeStyle = annotation.awi_classes[0].awi_color;
								}
							} else {
								context.strokeStyle = annotation.awi_classes[0].awi_color;
							}
						}

						const coords = scaleUpCoordinates(awi_coords);

						if (coords.length === 0) return;

						if (request?.awi_annotation_type === 'rectangle') {
							context.moveTo(coords[0]?.x, coords[0]?.y);
							if (annotation.awi_status !== 'pending' || awi_id === selectAnnotationId) {
								context.fillRect(coords[0].x, coords[0].y, Math.abs(coords[0].x - coords[1].x), Math.abs(coords[0].y - coords[3].y));
							}

							for (let i = 1; i < coords.length; i++) {
								context.lineTo(coords[i].x, coords[i].y);
							}
							context.closePath();
						} else if (request?.awi_annotation_type === 'circle') {
							const diameter = getDiameter(coords);
							context.arc((coords[0].x + coords[1].x) / 2, (coords[0].y + coords[1].y) / 2, Math.floor(diameter / 2), 0, 2 * Math.PI);
						} else if (request?.awi_annotation_type === 'polygon') {
							context.moveTo(coords[0].x, coords[0].y);
							if (awi_id === selectAnnotationId) context.arc(coords[0].x, coords[0].y, 1, 0, 2 * Math.PI, true);
							coords.forEach(coord => {
								context.lineTo(coord.x, coord.y);
								if (awi_id === selectAnnotationId) context.arc(coord.x, coord.y, 2.5, 0, 2 * Math.PI, true);
							});
						} else if (request?.awi_annotation_type === 'dot') {
							context.moveTo(coords[0].x, coords[0].y);
							context.arc(coords[0].x, coords[0].y, 5, 0, 2 * Math.PI, true);
						}
						context.fill();

						context.save();
						context.stroke();
					}
				});
			}
		}
	};

	const scaleUpCoordinates = (coordinates: { x: number; y: number }[]) => {
		return coordinates.map(coord => {
			return {
				x: coord.x * canvasRef.current!.width,
				y: coord.y * canvasRef.current!.height
			};
		});
	};

	const drawCircle = event => {
		const { x, y } = getMousePos(canvasRef.current, event);
		setCoordinates(prev => {
			const temp = [...prev];
			temp[1].x = x;
			temp[1].y = y;
			return [...temp];
		});
	};

	const getDiameter = (coords: { x: number; y: number }[]) => {
		return Math.sqrt(Math.pow(Math.abs(coords[0].x - coords[1].x), 2) + Math.pow(Math.abs(coords[0].y - coords[1].y), 2));
	};

	const checkAnnotation = (coords: { x: number; y: number }[]) => {
		if (coords.length === 0) return false;
		if (request?.awi_annotation_type === 'rectangle' && Math.abs(coords[0]?.x - coords[1]?.x) > 0.009 && Math.abs(coords[1]?.y - coords[3]?.y) > 0.009) {
			return true;
		} else if (request?.awi_annotation_type === 'circle' && getDiameter(coords) > 0.05) {
			return true;
		} else if (request?.awi_annotation_type === 'polygon' || request?.awi_annotation_type === 'dot') {
			return true;
		} else {
			reDrawAnnotations();
			return false;
		}
	};

	const checkHasMoved = (initialCoords: { x: number; y: number }, finalCoords: { x: number; y: number }) => {
		if (Math.abs(initialCoords.x - finalCoords.x) > senstivity || Math.abs(initialCoords.y - finalCoords.y) > senstivity) {
			return true;
		} else {
			return false;
		}
	};

	const handleMouseDown = (event: React.MouseEvent) => {
		// if (disableAnnotation) return;
		if (event.button == 0) {
			if (!['awi_annotator', 'awi_verifier', 'awi_developer'].includes(loggedUser.userObj.awi_level)) {
				return;
			}

			if (!isEditing && !isCarousel) {
				setIsDrawingAnnoations(true);
				const { x, y } = getMousePos(canvasRef.current, event);
				setInintialCoords({ x, y });
				if (request?.awi_annotation_type === 'rectangle') {
					setCoordinates([
						{ x, y },
						{ x, y },
						{ x, y },
						{ x, y }
					]);
				} else if (request?.awi_annotation_type === 'circle') {
					setCoordinates([
						{ x, y },
						{ x, y }
					]);
				} else if (request?.awi_annotation_type === 'dot') {
					setCoordinates([{ x, y }]);
				} else if (request?.awi_annotation_type === 'polygon') {
					setCoordinates(prev => [...prev, { x, y }]);
				}
			} else if (!isCarousel) {
				selectAnnotation(event, selectAnnotationId);
				setIsUpdatingAnnotation(true);
			}
		} else if (event.button === 1) {
			const { clientX, clientY } = event;
			const { x: startX, y: startY } = zoomProperties;

			const handleMouseMove = (moveEvent: MouseEvent) => {
				const dx = moveEvent.clientX - clientX;
				const dy = moveEvent.clientY - clientY;

				// Calculate the new transformation coordinates
				const newX = startX + dx;
				const newY = startY + dy;

				// Limit the transformation coordinates to the image dimensions
				const limitedX = Math.max(Math.min(newX, 0), canvasDimensions.width - canvasDimensions.width * zoomProperties.scale);
				const limitedY = Math.max(Math.min(newY, 0), canvasDimensions.height - canvasDimensions.height * zoomProperties.scale);

				setZoomProperties({
					scale: zoomProperties.scale,
					x: limitedX,
					y: limitedY
				});
			};

			const handleMouseUp = () => {
				window.removeEventListener('mousemove', handleMouseMove);
				window.removeEventListener('mouseup', handleMouseUp);
			};
			if (zoomProperties.scale > 1) window.addEventListener('mousemove', handleMouseMove);

			window.addEventListener('mouseup', handleMouseUp);
		}
	};

	/**
	 * If the user is not drawing an annotation, and the user is editing, and the selected annotation id
	 * is not -1, and the user is moving or the selected corner coordinates are not -1, and the user has
	 * moved, then update the annotation.
	 * @param event - React.MouseEvent
	 * @returns the value of the function handleMouseUp.
	 */
	const handleMouseUp = (event: React.MouseEvent) => {
		// if (disableAnnotation) return;

		if (!['awi_annotator', 'awi_verifier', 'awi_developer'].includes(loggedUser.userObj.awi_level)) {
			return;
		}

		if (isDrawingAnnotation) {
			setIsDrawingAnnoations(false);
			/* Creating a new annotation. */
			if (typeof createAnnotation === 'function' && checkAnnotation(coordinates)) {
				if (request?.awi_annotation_type === 'polygon') {
					reDrawAnnotations(coordinates);
				} else {
					setCoordinates([]);
					createAnnotation(coordinates, () => {
						setCoordinates([]);
						queryClient.invalidateQueries(fetchAnnotationsKey);
					});
				}
			}
		} else if ((isEditing && selectAnnotationId !== -1) || isCarousel) {
			if (
				(isMoving || (selectCornerCoordinates.x !== -1 && selectCornerCoordinates.y !== -1)) &&
				checkHasMoved(selectCoordinates!, getMousePos(canvasRef.current, event))
			) {
				const annotation = annotations?.result.find(annot => annot.awi_id === selectIndex);
				if (annotation) {
					const coordinates = [...annotation.awi_coords];
					if (typeof handleUpdateAnnotationCoordinates === 'function' && checkAnnotation(coordinates) && !disableAnnotation) {
						handleUpdateAnnotationCoordinates(coordinates, selectIndex, () => {
							queryClient.invalidateQueries(fetchAnnotationsKey);
							setCoordinates([]);
						});
					}
				}
			} else {
				const tempAnnotations = getSelectedAnnotation(event);
				if (tempAnnotations.length > 0) {
					if (tempAnnotations.every(items => selectedAnnotations.includes(items)) && selectedAnnotations.every(items => tempAnnotations.includes(items))) {
						setSelectAnnotationId(prev => {
							if (typeof handleSelectAnnotationIndex === 'function')
								handleSelectAnnotationIndex(selectedAnnotations[(selectedAnnotations.indexOf(prev) + 1) % selectedAnnotations.length]);
							reDrawAnnotations();

							if (selectedAnnotations[(selectedAnnotations.indexOf(prev) + 1) % selectedAnnotations.length] === -1) {
								setSelectCoordinates({ x: 0, y: 0 });
								setSelectCornerCoordinates({ x: -1, y: -1 });
							}

							return selectedAnnotations[(selectedAnnotations.indexOf(prev) + 1) % selectedAnnotations.length];
						});
					} else {
						setSelectAnnotationId(tempAnnotations[0]);
						if (typeof handleSelectAnnotationIndex === 'function') handleSelectAnnotationIndex(tempAnnotations[0]);
					}
				} else {
					setSelectAnnotationId(-1);
					reDrawAnnotations();
				}
				setSelectedAnnotations(tempAnnotations);
			}
			if (isMoving) setIsMoving(false);

			setIsUpdatingAnnotation(false);
		} else if (isEditing) {
			const tempAnnotation = getSelectedAnnotation(event);
			if (tempAnnotation.length > 0) {
				setSelectedAnnotations(tempAnnotation);
				if (typeof handleSelectAnnotationIndex === 'function') handleSelectAnnotationIndex(tempAnnotation[0]);
			}
			setIsUpdatingAnnotation(false);
		}
	};

	/**
	 * When the mouse moves out of the canvas, if the user is moving or updating an annotation, and the
	 * mouse has moved, then update the annotation
	 * @param event - React.MouseEvent
	 */
	const handleMouseMoveOut = (event: React.MouseEvent) => {
		// setCursorPosition({ x: -1, y: -1 });
		if (
			(isMoving || (isUpdatingAnnotation && selectCornerCoordinates.x !== -1 && selectCornerCoordinates.y !== -1)) &&
			checkHasMoved(selectCoordinates!, getMousePos(canvasRef.current, event))
		) {
			const annotation = (annotations?.result || []).find(annot => annot.awi_id === selectIndex);
			if (annotation) {
				if (typeof handleUpdateAnnotationCoordinates === 'function' && checkAnnotation(annotation.awi_coords)) {
					if (selectCornerCoordinates.x !== -1 && selectCornerCoordinates.y !== -1) {
						const coordinates = getUpdatedCoordinates(canvasRef, event, selectCornerCoordinates);

						handleUpdateAnnotationCoordinates(coordinates, selectAnnotationId, () => {
							queryClient.invalidateQueries(fetchAnnotationsKey);
						});
					} else {
						handleUpdateAnnotationCoordinates(annotation.awi_coords, selectAnnotationId, () => {
							queryClient.invalidateQueries(fetchAnnotationsKey);
						});
					}
				}
			}
		}
		if (isDrawingAnnotation) {
			setIsDrawingAnnoations(false);
			if (typeof createAnnotation === 'function' && checkAnnotation(coordinates)) {
				if (request?.awi_annotation_type === 'polygon') {
					if (
						coordinates.length > 1 &&
						Math.abs(coordinates[0].x - coordinates[coordinates.length - 1].x) < 0.005 &&
						Math.abs(coordinates[0].y - coordinates[coordinates.length - 1].y) < 0.005
					) {
						const coords = [...[...coordinates].splice(0, coordinates.length - 1), { x: coordinates[0].x, y: coordinates[0].y }];
						setCoordinates([]);
						createAnnotation(coords, () => {
							queryClient.invalidateQueries(fetchAnnotationsKey);
						});
					}
					reDrawAnnotations(coordinates);
				} else {
					setCoordinates([]);
					createAnnotation(coordinates, () => {
						setCoordinates([]);
						queryClient.invalidateQueries(fetchAnnotationsKey);
					});
				}
			}
		} else if (isUpdatingAnnotation) {
			setIsUpdatingAnnotation(false);
			setSelectAnnotationId(-1);
			setSelectedAnnotations([]);
			if (typeof handleSelectAnnotationIndex === 'function') handleSelectAnnotationIndex(-1);
		}
	};

	// draw a plus like cursor on complete canvas
	// const drawPlus = (x: number, y: number) => {
	// 	const ctx = canvasRef.current?.getContext('2d');
	// 	const coords = scaleUpCoordinates([{ x, y }]);
	// 	const xCoord = coords[0].x;
	// 	const yCoord = coords[0].y;
	// 	if (ctx && canvasRef.current) {
	// 		// ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
	// 		ctx.beginPath();
	// 		ctx.moveTo(xCoord, yCoord - canvasRef.current.height);
	// 		ctx.lineTo(xCoord, canvasRef.current.height + yCoord);
	// 		ctx.moveTo(xCoord - canvasRef.current.width, yCoord);
	// 		ctx.lineTo(xCoord + canvasRef.current.width, yCoord);
	// 		ctx.strokeStyle = 'red';
	// 		ctx.stroke();
	// 	}
	// };

	const handleMouseMove = (event: React.MouseEvent) => {
		if (disableAnnotation) return;
		let coordinate: any[] = [];
		// setCursorPosition(getMousePos(canvasRef.current, event));
		if (isDrawingAnnotation) {
			if (request?.awi_annotation_type === 'rectangle') {
				coordinate = getUpdatedCoordinates(canvasRef, event, initialCoords);
				setCoordinates(coordinate);
				reDrawAnnotations(coordinate);
			} else if (request?.awi_annotation_type === 'circle') {
				drawCircle(event);
				reDrawAnnotations(coordinates);
			} else {
				reDrawAnnotations(coordinates);
			}
			return;
		} else if (isEditing && selectAnnotationId !== -1 && isUpdatingAnnotation) {
			if (isMoving) {
				const { x, y } = getMousePos(canvasRef.current, event);
				const width = x - (selectCoordinates!.x || 0);
				const height = y - (selectCoordinates!.y || 0);
				if (selectIndex !== -1) {
					coordinate = [...coordinates].map(coord => {
						return {
							x: coord.x + width,
							y: coord.y + height
						};
					});

					const annotation = (annotations?.result || []).find(annot => annot.awi_id === selectIndex);
					if (annotation) {
						annotation.awi_coords = [...coordinate];
						if (request?.awi_annotation_type === 'rectangle' && coordinate.length === 4) {
							const xLen = Math.abs(coordinate[1].x - coordinate[0].x);
							const yLen = Math.abs(coordinate[3].y - coordinate[1].y);
							for (let index = 0; index < coordinate.length; index++) {
								if (coordinate[index].x < 0) {
									coordinate[1].x = xLen;
									coordinate[2].x = xLen;
									coordinate[0].x = 0;
									coordinate[3].x = 0;
								} else if (coordinate[index].y < 0) {
									coordinate[2].y = yLen;
									coordinate[3].y = yLen;
									coordinate[0].y = 0;
									coordinate[1].y = 0;
								} else if (coordinate[index].x > 1) {
									coordinate[0].x = 1 - xLen;
									coordinate[3].x = 1 - xLen;
									coordinate[1].x = 1;
									coordinate[2].x = 1;
								} else if (coordinate[index].y > 1) {
									coordinate[0].y = 1 - yLen;
									coordinate[1].y = 1 - yLen;
									coordinate[2].y = 1;
									coordinate[3].y = 1;
								}
							}
						}
					} else if (request?.awi_annotation_type === 'circle' && coordinate.length === 2) {
						const cenX = (coordinate[0].x + coordinate[1].x) / 2;
						const cenY = (coordinate[0].y + coordinate[1].y) / 2;
						const rad = getDistance(coordinate[0].x, coordinate[0].y, coordinate[1].x, coordinate[1].y) / 2;

						if (cenX - rad < 0) {
							coordinate[0].x = 0;
							coordinate[0].y = cenY;
							coordinate[1].x = 2 * rad;
							coordinate[1].y = cenY;
						}
						if (cenX + rad > 1) {
							coordinate[0].x = 1 - 2 * rad;
							coordinate[0].y = cenY;
							coordinate[1].x = 1;
							coordinate[1].y = cenY;
						}
						if (cenY - rad < 0) {
							coordinate[0].x = cenX;
							coordinate[0].y = 0;
							coordinate[1].x = cenX;
							coordinate[1].y = 2 * rad;
						}
						if (cenY + rad > 1) {
							coordinate[0].x = cenX;
							coordinate[0].y = 1 - 2 * rad;
							coordinate[1].x = cenX;
							coordinate[1].y = 1;
						}
					} else if (request?.awi_annotation_type === 'dot' && coordinate.length === 1) {
						if (coordinate[0].x - 0.02 < 0) {
							coordinate[0].x = 0.02;
						} else if (coordinate[0].x + 0.02 > 1) {
							coordinate[0].x = 1 - 0.02;
						} else if (coordinate[0].y - 0.02 < 0) {
							coordinate[0].y = 0.02;
						} else if (coordinate[0].y + 0.02 > 1) {
							coordinate[0].y = 1 - 0.02;
						}
					} else if (request?.awi_annotation_type === 'polygon' && coordinate.length > 1) {
						for (let index = 0; index < coordinate.length; index++) {
							if (coordinate[index].x < 0) {
								coordinate[index].x = 0;
							} else if (coordinate[index].y < 0) {
								coordinate[index].y = 0;
							} else if (coordinate[index].x > 1) {
								coordinate[index].x = 1;
							} else if (coordinate[index].y > 1) {
								coordinate[index].y = 1;
							}
						}
					}

					reDrawAnnotations(coordinate);
				}
			} else if (selectCornerCoordinates.x !== -1 && selectCornerCoordinates.y !== -1) {
				if (selectIndex !== -1) {
					const annotationData = annotations?.result.find(annot => annot.awi_id === selectIndex);
					if (request?.awi_annotation_type === 'circle') {
						const mousePos = getMousePos(canvasRef.current, event);
						if (annotationData) {
							coordinate = annotationData.awi_coords;
							const centreX = (coordinate[0].x + coordinate[1].x) / 2;
							const centreY = (coordinate[0].y + coordinate[1].y) / 2;
							const r = getDistance(mousePos.x, mousePos.y, centreX, centreY);

							// check if circle is out of bound and adjust it
							if (centreX - r < 0) {
								coordinate[0] = { x: 0, y: centreY };
								coordinate[1] = { x: 2 * r, y: centreY };
							} else if (centreX + r > 1) {
								coordinate[0] = { x: 1 - 2 * r, y: centreY };
								coordinate[1] = { x: 1, y: centreY };
							} else if (centreY - r < 0) {
								coordinate[0] = { x: centreX, y: 0 };
								coordinate[1] = { x: centreX, y: 2 * r };
							} else if (centreY + r > 1) {
								coordinate[0] = { x: centreX, y: 1 - 2 * r };
								coordinate[1] = { x: centreX, y: 1 };
							} else {
								coordinate[0] = { x: centreX - r, y: centreY };
								coordinate[1] = { x: centreX + r, y: centreY };
							}
						}
					} else if (request?.awi_annotation_type === 'polygon') {
						const mousePos1 = getMousePos(canvasRef.current, event);
						if (annotationData?.awi_coords) {
							coordinate = [...annotationData.awi_coords];
							if (selectCornerIndex > -1) {
								if (selectCornerIndex === 0 || selectCornerIndex === coordinate.length - 1) {
									coordinate[0] = { x: mousePos1.x, y: mousePos1.y };
									coordinate[coordinate.length - 1] = { x: mousePos1.x, y: mousePos1.y };
								} else {
									coordinate[selectCornerIndex] = { x: mousePos1.x, y: mousePos1.y };
								}
								setSelectCornerCoordinates({ x: mousePos1.x, y: mousePos1.y });
							}
						}
					} else {
						coordinate = getUpdatedCoordinates(canvasRef, event, selectCornerCoordinates);
					}
					if (checkAnnotation(coordinate)) {
						setCoordinates([...coordinate]);
						const _annotation = annotations?.result.find(annot => annot.awi_id === selectIndex);
						if (_annotation) {
							_annotation.awi_coords = coordinate;
							reDrawAnnotations(coordinate);
						}
					}
				}
			}
		}
	};

	const gcd = (a: number, b: number) => {
		return b === 0 ? a : gcd(b, a % b);
	};

	/**
	 * MoveAnnotation is a function that takes a string as an argument and returns an interval that moves
	 * the annotation on arrow key press till it is not out of bounds and released
	 * @param {string} direction - string - the direction to move the annotation
	 */
	// const moveAnnotation = (direction: string) => {
	// 	// move annotation on arrow key press till it is not out of bounds and released

	// 	const inteval = setInterval(() => {
	// 		if (selectIndex !== -1) {
	// 			const annotationData = annotations?.result.find(annot => annot.awi_id === selectIndex);
	// 			if (annotationData) {
	// 				const coordinate = annotationData.awi_coords;
	// 				if (direction === 'left') {
	// 					coordinate.forEach((coord, index) => {
	// 						if (coord.x > 0) coordinate[index] = { x: coord.x - 0.01, y: coord.y };
	// 						else clearInterval(inteval);
	// 					});
	// 				} else if (direction === 'right') {
	// 					coordinate.forEach((coord, index) => {
	// 						if (coord.x < 1) coordinate[index] = { x: coord.x + 0.01, y: coord.y };
	// 						else clearInterval(inteval);
	// 					});
	// 				} else if (direction === 'up') {
	// 					coordinate.forEach((coord, index) => {
	// 						if (coord.y > 0) coordinate[index] = { x: coord.x, y: coord.y - 0.01 };
	// 						else clearInterval(inteval);
	// 					});
	// 				} else if (direction === 'down') {
	// 					coordinate.forEach((coord, index) => {
	// 						if (coord.y < 1) coordinate[index] = { x: coord.x, y: coord.y + 0.01 };
	// 						else clearInterval(inteval);
	// 					});
	// 				}
	// 				reDrawAnnotations();
	// 			}
	// 		}
	// 	}, 100);
	// 	setKeyInterval(inteval);
	// };

	const toggleSelectAnnotation = () => {
		// select annotation on key press
		if (selectIndex === -1) {
			if ((annotations?.result || []).length > 0) {
				setSelectAnnotationId((annotations?.result || [])[0].awi_id);
				if (typeof handleSelectAnnotationIndex === 'function') handleSelectAnnotationIndex((annotations?.result || [])[0].awi_id);
			}
		} else {
			// select next annotation on key press
			const index = (annotations?.result || []).findIndex(annot => annot.awi_id === selectIndex);
			if (index !== -1) {
				if ((annotations?.result || []).length > index + 1) {
					setSelectAnnotationId((annotations?.result || [])[index + 1].awi_id);
					if (typeof handleSelectAnnotationIndex === 'function') handleSelectAnnotationIndex((annotations?.result || [])[index + 1].awi_id);
				} else {
					setSelectAnnotationId((annotations?.result || [])[0].awi_id);
					if (typeof handleSelectAnnotationIndex === 'function') handleSelectAnnotationIndex((annotations?.result || [])[0].awi_id);
				}
			}
		}
	};

	// functions related to zoom and move image in annotation tool
	const transformCoordinatesCheck = (newXTransform, newYTransform, widthIncreased, heightIncreased) => {
		if (newXTransform > 0) {
			newXTransform = 0;
		}
		if (Math.abs(newXTransform) > widthIncreased) {
			newXTransform = -1 * widthIncreased;
		}
		if (Math.abs(newYTransform) > heightIncreased) {
			newYTransform = -1 * heightIncreased;
		}
		if (newYTransform > 0) {
			newYTransform = 0;
		}
		return { newXTransform, newYTransform };
	};

	const handleCompleteAnnotation = () => {
		if (typeof createAnnotation === 'function' && checkAnnotation(coordinates)) {
			const coords = [...coordinates, { x: coordinates[0].x, y: coordinates[0].y }];
			setCoordinates([]);
			createAnnotation(coords, () => {
				queryClient.invalidateQueries(fetchAnnotationsKey);
			});
		}
	};

	const undoAnnotationPoint = () => {
		if (coordinates.length > 0 && request?.awi_annotation_type === 'polygon') {
			coordinates.pop();
			setCoordinates([...coordinates]);
			reDrawAnnotations(coordinates);
		} else {
			return;
		}
	};

	const handleReset = () => {
		setZoomProperties(initialZoomProperties);
	};
	// write the function such that the zoom in follows the mouse pointer when it is moved while pressing ctrl or cmd button

	const handleZoomInClick = () => {
		if (zoomProperties.scale === 5) {
			return;
		}
		const widthIncreased = canvasDimensions.width * (zoomProperties.scale + zoomLevel - 1);
		const heightIncreased = canvasDimensions.height * (zoomProperties.scale + zoomLevel - 1);
		const newXTransform = zoomProperties.x - (canvasDimensions.width * zoomLevel) / 2;
		const newYTransform = zoomProperties.y - (canvasDimensions.height * zoomLevel) / 2;
		const { newXTransform: finalXTransform, newYTransform: finalYTransform } = transformCoordinatesCheck(
			newXTransform,
			newYTransform,
			widthIncreased,
			heightIncreased
		);
		setZoomProperties({
			scale: +(zoomProperties.scale + zoomLevel).toFixed(2),
			x: finalXTransform,
			y: finalYTransform
		});
	};

	const handleZoomOutClick = () => {
		if (zoomProperties.scale === 1) {
			return;
		}
		const widthIncreased = canvasDimensions.width * (zoomProperties.scale - zoomLevel - 1); //think
		const heightIncreased = canvasDimensions.height * (zoomProperties.scale - zoomLevel - 1);
		const newXTransform = zoomProperties.x + (canvasDimensions.width * zoomLevel) / 2;
		const newYTransform = zoomProperties.y + (canvasDimensions.height * zoomLevel) / 2;
		const { newXTransform: finalXTransform, newYTransform: finalYTransform } = transformCoordinatesCheck(
			newXTransform,
			newYTransform, 
			widthIncreased,
			heightIncreased
		);
		setZoomProperties({
			scale: +(zoomProperties.scale - zoomLevel).toFixed(2),
			x: finalXTransform,
			y: finalYTransform
		});
		reDrawAnnotations(coordinates);
	};

	//this is the handleWheel function which controls whether we zoom in or out
	const handleWheel = e => {
		if (e.deltaY < 0) {
			handleZoomInClick();
		} else {
			handleZoomOutClick();
		}
	};
	const handleLeftKeyDown = () => {
		const widthIncreased = canvasDimensions.width * (zoomProperties.scale - 1);
		const heightIncreased = canvasDimensions.height * (zoomProperties.scale - 1);
		const newXTransform = zoomProperties.x + transformLevel;
		const newYTransform = zoomProperties.y;
		const { newXTransform: finalXTransform, newYTransform: finalYTransform } = transformCoordinatesCheck(
			newXTransform,
			newYTransform,
			widthIncreased,
			heightIncreased
		);
		setZoomProperties({
			...zoomProperties,
			x: finalXTransform,
			y: finalYTransform
		});
	};
	const handleRightKeyDown = () => {
		const widthIncreased = canvasDimensions.width * (zoomProperties.scale - 1);
		const heightIncreased = canvasDimensions.height * (zoomProperties.scale - 1);
		let newXTransform = zoomProperties.x - transformLevel;
		let newYTransform = zoomProperties.y;
		const { newXTransform: finalXTransform, newYTransform: finalYTransform } = transformCoordinatesCheck(
			newXTransform,
			newYTransform,
			widthIncreased,
			heightIncreased
		);
		setZoomProperties({
			...zoomProperties,
			x: finalXTransform,
			y: finalYTransform
		});
	};
	const handleUpKeyDown = () => {
		const widthIncreased = canvasDimensions.width * (zoomProperties.scale - 1);
		const heightIncreased = canvasDimensions.height * (zoomProperties.scale - 1);
		const newXTransform = zoomProperties.x;
		const newYTransform = zoomProperties.y + transformLevel;
		const { newXTransform: finalXTransform, newYTransform: finalYTransform } = transformCoordinatesCheck(
			newXTransform,
			newYTransform,
			widthIncreased,
			heightIncreased
		);
		setZoomProperties({
			...zoomProperties,
			x: finalXTransform,
			y: finalYTransform
		});
	};
	const handleDownKeyDown = () => {
		const widthIncreased = canvasDimensions.width * (zoomProperties.scale - 1);
		const heightIncreased = canvasDimensions.height * (zoomProperties.scale - 1);
		const newXTransform = zoomProperties.x;
		const newYTransform = zoomProperties.y - transformLevel;
		const { newXTransform: finalXTransform, newYTransform: finalYTransform } = transformCoordinatesCheck(
			newXTransform,
			newYTransform,
			widthIncreased,
			heightIncreased
		);
		setZoomProperties({
			...zoomProperties,
			x: finalXTransform,
			y: finalYTransform
		});
	};

	if (!imageUrl || !content_id) {
		return null;
	}

	return (
		<>
			{/* <HotKeys keyName="ctrl + b" onKeyDown={() => setShowScaling(!showScaling)} /> */}

			{/* arrow key annotation movement */}
			{/* <HotKeys
				keyName="left"
				onKeyDown={() => moveAnnotation('left')}
				onKeyUp={() => {
					if (keyInterval) {
						if (typeof updateAnnotation === 'function') {
							const annotation = annotations?.result.find(annot => annot.awi_id === selectAnnotationId);
							if (!annotation) return;

							updateAnnotation(annotation.awi_coords, selectAnnotationId, annotation.awi_attributes, () => {
								queryClient.invalidateQueries(fetchAnnotationsKey);
							});
						}
						clearInterval(keyInterval);
					}
				}}
			/> */}
			{/* <HotKeys
				keyName="right"
				onKeyDown={() => moveAnnotation('right')}
				onKeyUp={() => {
					if (keyInterval) {
						if (typeof updateAnnotation === 'function') {
							const annotation = annotations?.result.find(annot => annot.awi_id === selectAnnotationId);
							if (!annotation) return;

							updateAnnotation(annotation.awi_coords, selectAnnotationId, annotation.awi_attributes, () => {
								queryClient.invalidateQueries(fetchAnnotationsKey);
							});
						}
						clearInterval(keyInterval);
					}
				}}
			/> */}
			{/* <HotKeys
				keyName="up"
				onKeyDown={() => moveAnnotation('up')}
				onKeyUp={() => {
					if (keyInterval) {
						if (typeof updateAnnotation === 'function') {
							const annotation = annotations?.result.find(annot => annot.awi_id === selectAnnotationId);
							if (!annotation) return;

							updateAnnotation(annotation.awi_coords, selectAnnotationId, annotation.awi_attributes, () => {
								queryClient.invalidateQueries(fetchAnnotationsKey);
							});
						}
						clearInterval(keyInterval);
					}
				}}
			/>
			<HotKeys
				keyName="down"
				onKeyDown={() => moveAnnotation('down')}
				onKeyUp={() => {
					if (keyInterval) {
						if (typeof updateAnnotation === 'function') {
							const annotation = annotations?.result.find(annot => annot.awi_id === selectAnnotationId);
							if (!annotation) return;

							updateAnnotation(annotation.awi_coords, selectAnnotationId, annotation.awi_attributes, () => {
								queryClient.invalidateQueries(fetchAnnotationsKey);
							});
						}
						clearInterval(keyInterval);
					}
				}} */}

			<HotKeys keyName="esc" onKeyDown={() => toggleSelectAnnotation()} />

			<>
				<HotKeys keyName="esc" onKeyDown={() => toggleSelectAnnotation()} />
				<HotKeys keyName="left" onKeyDown={handleLeftKeyDown} />
				<HotKeys keyName="right" onKeyDown={handleRightKeyDown} />
				<HotKeys keyName="up" onKeyDown={handleUpKeyDown} />
				<HotKeys keyName="down" onKeyDown={handleDownKeyDown} />
				<HotKeys keyName="i" onKeyDown={handleZoomInClick} />
				<HotKeys keyName="o" onKeyDown={handleZoomOutClick} />
				<HotKeys keyName="enter" onKeyDown={() => handleCompleteAnnotation()} />
				<HotKeys keyName="ctrl+z" onKeyDown={() => undoAnnotationPoint()} />
			</>
			{!isCarousel && (
				<div className={styles.zoom__container} style={{ height: '25px' }}>
					{request?.awi_annotation_type === 'polygon' && <img onClick={handleCompleteAnnotation} src="/icons/check/check.png"></img>}
					<img onClick={handleReset} src="/icons/zoom/reset.png"></img>
					<img onClick={handleZoomInClick} src="/icons/zoom/in.png"></img>
					<img onClick={handleZoomOutClick} src="/icons/zoom/out.png"></img>
				</div>
			)}

			<div className={clsx(styles.canvasContainer, { [className]: !!className })} ref={canvasContainerRef}>
				{!showAnnotations ? (
					<img loading="lazy" src={getImageKitUrl(imageUrl, -1, -1, 40)} width="100%" height="100%" alt="Carousel Image" />
				) : (
					<div
						className={styles.canvasSubContainer}
						style={{ display: 'flex', flexDirection: 'row', width: canvasDimensions.width, height: canvasDimensions.height }}
					>
						<canvas
							style={{
								width: canvasDimensions.width * zoomProperties.scale,
								height: canvasDimensions.height * zoomProperties.scale,
								backgroundImage: `url("${getImageKitUrl(imageUrl, canvasDimensions.width, canvasDimensions.height)}")`,
								cursor: `${isEditing ? '' : 'crosshair'}`,
								transform: `translate(${zoomProperties.x}px, ${zoomProperties.y}px) `
							}}
							ref={canvasRef}
							onMouseDown={handleMouseDown}
							onMouseUp={handleMouseUp}
							onMouseMove={handleMouseMove}
							onMouseOut={handleMouseMoveOut}
							className={styles.canvas}
							onWheel={handleWheel}
						>
							{' '}
							Your Browser Does Not Support Canvas{' '}
						</canvas>
						{/* {isCarousel && selectedAnnotation && (
							<div className={styles.classdetails}>
								<div className={styles.classdetails__class}>
									<p className={styles.classdetails__class__label}>Class</p>
									<p className={styles.classdetails__class__value}>{selectedAnnotation.awi_classes[0].awi_label}</p>
								</div>
								<div>Attributes</div>
								<div>
									{(selectedAnnotation.awi_attributes || []).map((attribute, index) => (
										<div key={index} className={styles.classdetails__attribute}>
											<p className={styles.classdetails__attribute__label}>
												{(selectedAnnotation.awi_classes[0].awi_attributes || []).find(ele => ele.awi_id === attribute.awi_id)?.awi_label}
											</p>
											<p className={styles.classdetails__attribute__value}>{attribute.awi_values[0]}</p>
										</div>
									))}
								</div>
							</div>
						)} */}
					</div>
				)}
			</div>
		</>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps)(ImageAnnotationPreviewer));
