import store from '../redux/store';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { env } from '../env';

type BaseURL =
	| ''
	| 'user'
	| 'request'
	| 'org'
	| 'class'
	| 'content'
	| 'annotation'
	| 'misc'
	| 'session'
	| 'payment'
	| 'dataset'
	| 'training'
	| 'bill'
	| 'notification'
	| 'auth';

/* Extending the AxiosRequestConfig interface with a new property called baseURL. */
interface ClientOptions extends AxiosRequestConfig {
	baseURL?: BaseURL;
}

type Client = <T = any>(url: string, options?: ClientOptions) => Promise<AxiosResponse<T>>;

/**
 * It's a function that returns a function that returns a promise that returns an object.
 * @param url - The url of the request
 * @param  - url - the url of the request
 * @returns A function that returns a promise.
 */
export const client: Client = async (url, { baseURL = '', ...options } = {}) => {
	const currentStore = store.getState();
	const api = env.REACT_APP_BACKEND! + env.REACT_APP_ROUTE_CONFIG;

	if (url.startsWith('/') || baseURL === '') {
		return await axios(api + baseURL + url, {
			...options,
			headers: {
				Authorization: currentStore.UserReducer.loggedUser.token
			}
		});
	} else {
		return await axios(api + baseURL + '/' + url, {
			...options,
			headers: {
				Authorization: currentStore?.UserReducer?.loggedUser?.token || ''
			}
		});
	}
};
