import { Filter } from '../../components/Page/Billing';
import { client } from './../../util/client';

export const fetchUserwiseGeneratedBills = (data?: Filter) => {
	const queryKey = ['fetch-userwise-generated-bills', { ...data }] as const;

	const queryFunction = () =>
		client<{ success: boolean; result: any[] }>('/fetch_userwise_generated_bills', {
			baseURL: 'bill',
			method: 'POST',
			data
		}).then(res => res.data);

	return [queryKey, queryFunction] as const;
};
