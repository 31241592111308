import { connect } from 'react-redux';
import { useEffect } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Navbar from './components/Common/Navbar';

import {
	Annotation,
	AttributeList,
	BillingPage,
	ClassList,
	CreateRequest,
	Dashboard,
	Dataset,
	Imagebank,
	LoginUser,
	Organisations,
	Progress,
	RequestPage,
	Sessions,
	Transactions,
	UsersList,
	Models,
	Jobs
} from './components/Page';

import UserActions from './redux/actions/UserActions';

import { LoggedInUser, LoggedUser } from './types';

import 'tippy.js/dist/tippy.css';
import styles from './styles/global.module.scss';
import TagsAndLocations from './components/Page/TagsAndLocations';
import ModelDetails from './components/Page/ModelDetails';
import PricingForm from './components/Page/Billing/PricingForm';
import GenerateBill from './components/Page/Billing/GenerateBill';
import Counts from './components/Page/Billing/Counts';
import SessionCounts from './components/Page/Sessions/SessionCounts';
import OnBoardingDetails from './components/Page/OnBoardingDetails';

interface AppProps {
	loggedIn: boolean;
	loginUser: (data: LoggedUser) => void;
	loggedUser: {
		token: string;
		userObj: LoggedInUser;
	};
}

const App: React.FC<AppProps> = ({ loggedIn, loginUser, loggedUser }) => {
	// const [topbarAllowed, setTopbarAllowed] = useState(true);
	// const [scrollPos, setScrollPos] = useState<number>(0);

	useEffect(() => {
		setTokenData();
		// const handleScroll = () => {
		// 	setScrollPos(window.pageYOffset);
		// // };
		// window.addEventListener('scroll', handleScroll, { passive: true });

		// return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	// const scrollToTop = () => {
	// 	window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	// };

	const setTokenData = () => {
		const token = localStorage.getItem('token');
		if (token) {
			if (token === 'undefined') {
				localStorage.removeItem('token');
				toast.error('Token Expired!!! Login Again');
				return;
			}
			const tokenObj = JSON.parse(window.atob(token.split('.')[1]));
			if (new Date().getTime() > tokenObj.exp * 1000) {
				localStorage.removeItem('token');
				toast.error('Token Expired!!! Login Again');
			} else {
				loginUser({ token, userObj: tokenObj });
			}
		}
	};

	if (!loggedIn) {
		return (
			<>
				<BrowserRouter>
					<Switch>
						<Route exact path={'/'} component={LoginUser} />
						<Route path={'/login'} component={LoginUser} />
						<Route path={'/resetpassword'} component={LoginUser} />
					</Switch>
				</BrowserRouter>
				<Toaster toastOptions={{ duration: 2000 }} />
			</>
		);
	}

	return (
		<>
			<div className={styles.app}>
				<BrowserRouter>
					<Navbar hideNavbar={false} />
					{/* <button
						onClick={scrollToTop}
						className={clsx(styles.scrollButton, {
							[styles.showScrollButton]: scrollPos > 500,
							[styles.hideScrollButton]: scrollPos <= 500
						})}
					>
						Scroll
					</button> */}
					<div className={styles.app__content}>
						<Switch>
							<Route path={'/requests'} component={RequestPage} />
							<Route path={'/billing'} component={BillingPage} />
							<Route path={'/generatebills'} component={GenerateBill} />
							<Route path={'/transactions'} component={Transactions} />
							<Route path={'/onboarding'} component={OnBoardingDetails} />
							{['awi_developer', 'awi_manager'].indexOf(loggedUser.userObj.awi_level) !== -1 ? <Route path={'/datasets'} component={Dataset} /> : null}

							{loggedUser.userObj.awi_level === 'awi_root' && <Route path={'/organisations'} component={Organisations} />}
							{loggedUser.userObj.awi_level === 'awi_root' ? (
								<Route exact path={'/'} component={Organisations} />
							) : (
								<Route exact path={'/'} component={RequestPage} />
							)}
							{['awi_admin', 'awi_manager', 'awi_developer', 'awi_supervisor'].indexOf(loggedUser.userObj.awi_level) !== -1 ? (
								<Route path={'/image-bank'} component={Imagebank} />
							) : null}

							{['awi_developer'].indexOf(loggedUser.userObj.awi_level) !== -1 ? <Route path={'/create-request'} component={CreateRequest} /> : null}

							{['awi_verifier', 'awi_annotator', 'awi_supervisor', 'awi_developer'].indexOf(loggedUser.userObj.awi_level) !== -1 && (
								<Route path={'/annotation'} component={Annotation} />
							)}
							{['awi_verifier', 'awi_annotator', 'awi_supervisor', 'awi_manager', 'awi_admin'].indexOf(loggedUser.userObj.awi_level) !== -1 && (
								<Route path={'/sessions'} component={Sessions} />
							)}

							<Route path={'/jobs'} component={Jobs} />
							<Route exact path={'/models'} component={Models} />
							<Route path={'/models/:id'} component={ModelDetails} />
							<Route path={'/classlist'} component={ClassList} />
							<Route path={'/tagsandlocations'} component={TagsAndLocations} />
							<Route path={'/attributelist'} component={AttributeList} />
							{['awi_admin', 'awi_manager', 'awi_supervisor'].indexOf(loggedUser.userObj.awi_level) !== -1 && <Route path={'/users'} component={UsersList} />}
							<Route path={'/user/dashboard'} component={Dashboard} />
							<Route path={'/progress'} component={Progress} />
							<Route path={'/pricingdetails'} component={PricingForm} />
							<Route path={'/generatebillcounts'} component={Counts} />
							<Route path={'/sessioncounts'} component={SessionCounts} />
							<Route path={'*'}>
								<Redirect to={'/'} />
							</Route>
						</Switch>
					</div>
				</BrowserRouter>
			</div>
			<Toaster toastOptions={{ duration: 2000 }} />
		</>
	);
};

const mapStateToProps = state => {
	return {
		loggedIn: state.UserReducer.loggedIn,
		loggedUser: state.UserReducer.loggedUser
	};
};

const mapDispatchToProps = dispatch => {
	return {
		loginUser: data => dispatch(UserActions.loginUser(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
