import { Transaction } from '../../types/transactions';
import { client } from './../../util/client';

type TransactionResponse = {
	success: boolean;
	message?: string;
	result: Transaction[];
};

export const getTransactions = () => {
	const queryKey = ['transaction'] as const;
	const queryFunction = () =>
		client<TransactionResponse>('/fetch_transactions', {
			method: 'GET',
			baseURL: 'payment'
		}).then(res => res.data);

	return [queryKey, queryFunction] as const;
};
