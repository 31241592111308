import { ContentFilters } from './../../types/content.d';
import { client } from '../../util/client';

interface ImageBankFilterResponse {
	result: ContentFilters;
	success: boolean;
}

export const getImageBankFilters = () => {
	const queryKey = ['imagebank-filters'] as const;

	const queryFunction = () =>
		client<ImageBankFilterResponse>('/fetch_image_bank_filter', {
			baseURL: 'content',
			method: 'GET'
		}).then(res => res.data.result[0]);

	return [queryKey, queryFunction] as const;
};
