import clsx from 'clsx';
import styles from './styles.module.scss';

const TableHeader = ({ children, className = '' }) => {
	return (
		<th
			className={clsx(styles.table__header__th, {
				[className]: className
			})}
		>
			<span>{children} </span>
		</th>
	);
};
export default TableHeader;
