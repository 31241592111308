import { differenceInDays } from 'date-fns';
import { AnnotationType } from '../../types/annotations';
import { NewSession } from '../../types/session';
import { client } from '../../util/client';
import { getFirstDayOfMonth } from '../../util/common.utils';

export type SessionFilterType = {
	awi_class_ids: number[];
	awi_user_ids: number[];
	awi_annotation_type: AnnotationType[];
	awi_request_ids: number[];
	awi_start_date: Date | null;
	awi_end_date: Date | null;
	awi_is_empty_sessions: number[];
};

export type SessionResponse = {
	result: any[];
	msg?: string;
	next?: string | null;
	success: boolean;
};

export const getNewSession = (data: SessionFilterType) => {
	const queryKey = ['session', data] as const;
	const queryFunction = ({ pageParam }: { pageParam?: any }) => {
		const filters = {
			...data,
			awi_is_empty_sessions: Boolean(data.awi_is_empty_sessions[0])
		};

		return client<SessionResponse>('/fetch_new_session', {
			baseURL: 'session',
			method: 'POST',
			data: {
				...filters
			},
			params: {
				cursor: pageParam
			}
		}).then(res => res.data);
	};

	return [queryKey, queryFunction] as const;
};
