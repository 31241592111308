import { differenceInMilliseconds, millisecondsToHours, millisecondsToMinutes, millisecondsToSeconds } from 'date-fns';
import { MiniUser } from '../types';

export const getUserAvatar = (user: MiniUser | ''): string => {
	if (user === '') return 'https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500';
	else
		return (
			getImageKitUrl(user?.awi_personal_info?.awi_avatar || '') ||
			'https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
		);
};

export const getFullName = (user?: MiniUser): string => {
	if (user?.awi_id) {
		return (user.awi_personal_info.awi_firstname || '') + ' ' + (user.awi_personal_info.awi_lastname || '');
	} else {
		return '';
	}
};

export const convertToTitleCase = (str: string): string | undefined => {
	if (!str) return undefined;
	return str.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
};

export const getRole = (user?: MiniUser): string | undefined => {
	if (user?.awi_id) {
		return convertToTitleCase(user?.awi_level.split('_')[1] || '');
	} else {
		return '';
	}
};

/**
 * It takes a url, width, height and quality as parameters and returns a url with the imagekit
 * transformations applied
 * @param {string} url - The URL of the image you want to transform.
 * @param [width=300] - The width of the image.
 * @param [height=300] - The height of the image.
 * @param [q=40] - Quality of the image.
 * @returns A string
 */
export const getImageKitUrl = (url: string, width = 300, height = 300, q = 40): string | undefined => {
	const queries: string[] = [];
	if (width !== -1) {
		queries.push(`w-${width}`);
	}
	if (height !== -1) {
		queries.push(`h-${height}`);
	}
	if (q !== -1) {
		queries.push(`q-${q}`);
	}

	if (url) {
		if (!url.includes('https://awi-atom.s3.ap-southeast-1.amazonaws.com/')) {
			return url;
		}
		return 'https://ik.imagekit.io/12sthfl68/' + url.split('https://awi-atom.s3.ap-southeast-1.amazonaws.com/')[1].split('?')[0] + `?tr=${queries.join(',')}`;
	} else {
		return undefined;
	}
};

/**
 * It returns true if the email address is valid, and false if it's not
 * @param {string} email - The email address to validate.
 * @returns A boolean value.
 */
export const validateEmail = (email: string): boolean => {
	const re = /\S+@\S+\.\S+/;
	return re.test(email);
};

/**
 * It takes two dates as input and returns the difference between them in a human readable format
 * @param {Date} date1 - The date you want to compare
 * @param {Date} date2 - The date to compare to.
 * @returns A string
 */
export const dateDifference = (date1: Date, date2: Date): string => {
	const diffMiliSec = differenceInMilliseconds(date2, date1);
	return getFormattedDate(diffMiliSec);
};

/**
 * It takes a number of milliseconds and returns a string with the number of days, hours, minutes, and
 * seconds.
 * @param {number} diffMiliSec - number
 * @returns A function that takes a number and returns a string.
 */
export const getFormattedDate = (diffMiliSec: number): string => {
	let dateString = '';

	const days = Math.floor(diffMiliSec / (1000 * 60 * 60 * 24));
	const hours = millisecondsToHours(diffMiliSec);
	const minutes = millisecondsToMinutes(diffMiliSec) % 60;
	const seconds = millisecondsToSeconds(diffMiliSec) % 60;

	if (days > 0) {
		dateString += days + 'd ';
	}
	if (hours > 0) {
		dateString += hours + 'h ';
	}
	if (minutes > 0) {
		dateString += minutes + 'm ';
	}
	if (seconds > 0) {
		dateString += seconds + 's ';
	}

	return dateString || '--';
};

/**
 * Given a date, return a new date with the same year and month, but with the day set to the first day
 * of the month.
 * @param {Date} date - The date to get the first day of the month from.
 * @returns A new Date object with the year, month, and day set to the first day of the month.
 */
export const getFirstDayOfMonth = (date: Date): Date => {
	return new Date(date.getFullYear(), date.getMonth(), 1);
};

/**
 * Convert bytes to human readable format
 * @param {number} bytes - The number of bytes to convert.
 * @returns A function that takes a number and returns a string.
 */
export const byteToSize = (bytes: number): string => {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	if (bytes === 0) return '0 Byte';
	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
	return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
};

/**
 * It creates a link element, sets the href to the blob, appends the link to the body, clicks the link,
 * and then removes the link
 * @param blob - The blob object that you want to download.
 * @param filename - The name of the file you want to download.
 */
export const forceDownload = (blob, filename) => {
	const a = document.createElement('a');
	a.download = filename;
	a.href = blob;
	document.body.appendChild(a);
	a.click();
	a.remove();
};

/**
 * It takes two objects and returns true if they are equal, false if they are not.
 * @param {any} initalValue - The initial value of the object
 * @param {any} finalValue - The value that you want to compare against the initial value.
 * @returns Boolean
 */
export const JSONDiff = (initalValue: any, finalValue: any): boolean => {
	return Boolean(JSON.stringify(initalValue) === JSON.stringify(finalValue));
};
