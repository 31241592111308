import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { TableInfiniteScroll, TableBody, TableCell, TableHeader, TableHeaders, TableRow, Input } from '../../Common';
import { LoggedUser } from '../../../types';
import styles from './styles.module.scss';
import Container from '../../Container';
import { useQuery } from '../../../util/useQuery';
import { useLocation } from 'react-router-dom';
import { fetchModels } from '../../../handlers/models/fetchModels';
import { fetchProjects } from '../../../handlers/projects/fetchProjects';

interface DatasetProps {
	loggedUser: LoggedUser;
}

const ModelsPage: React.FC<DatasetProps> = ({ loggedUser }) => {
	const [selectedProject, setSelectedProject] = useState<number>(-1);

	const location = useLocation();
	const { awi_dataset_id, awi_project_name } = location.state || {};

	const [fetchProjectsKey, fetchProjectsFunctiobn] = fetchProjects();
	const { data: projects } = useQuery(fetchProjectsKey, fetchProjectsFunctiobn);

	// sort
	const [sortOrders, setSortOrders] = useState<{ [key: string]: number }>({ created_on: 0, status: 0 });

	const [fetchModelsKey, fetchModelsFunction] = fetchModels({
		awi_project_name: (projects?.result || []).find(project => project.awi_id === selectedProject)?.awi_label || '',
		awi_dataset_id,
		start_time: sortOrders.created_on,
		status: sortOrders.status
	});
	const { data: modelsData } = useQuery(fetchModelsKey, fetchModelsFunction, { enabled: selectedProject !== -1 });
	console.log(modelsData, 'modelsData');

	useEffect(() => {
		return () => {
			location.state = null;
		};
	}, []);

	useEffect(() => {
		if (!awi_project_name) {
			setSelectedProject(projects?.result?.[0]?.awi_id || -1);
		}
	}, [projects]);

	useEffect(() => {
		if (awi_project_name) {
			const tempProject = projects?.result?.find(project => project.awi_label === awi_project_name);
			if (tempProject) {
				setSelectedProject(tempProject.awi_id);
			}
		}
	}, [awi_project_name, projects]);

	const handleProjectSelection = (id: number, name: string) => {
		if (name === 'awi_project') {
			setSelectedProject(id);
		}
	};

	if (!['awi_developer'].includes(loggedUser.userObj.awi_level)) {
		return <Redirect to="/"></Redirect>;
	}

	const handleHeaderSortClick = (name: string) => {
		const newSortOrders = { ...sortOrders };
		if (newSortOrders[name] === 0) {
			newSortOrders[name] = 1;
		} else if (newSortOrders[name] === 1) {
			newSortOrders[name] = -1;
		} else if (newSortOrders[name] === -1) {
			newSortOrders[name] = 0;
		}
		setSortOrders(newSortOrders);
	};

	return (
		<Container className={styles.root__container}>
			{/* {downloadDatasetMutation.isLoading && (
				<div className={styles.loader}>
					<Loader></Loader>
				</div>
			)} */}

			<div className={styles.job__header}>
				<span>
					Project Name :{' '}
					<span style={{ color: '#55a8fd' }}>{(projects?.result || []).find(project => project.awi_id === selectedProject)?.awi_label || ''}</span>
				</span>
				<span className={styles.job__header__input}>
					Project :
					<Input
						placeholder="Select Project"
						searchString={projects?.result?.find(project => project.awi_id === selectedProject)?.awi_label || ''}
						data={(projects?.result || [])?.map(project => {
							return { awi_id: project.awi_id, awi_label: project.awi_label };
						})}
						onClick={handleProjectSelection}
						type="drop-down"
						name="awi_project"
					/>
				</span>
			</div>
			<div className={styles.job__panel__container}>
				<TableInfiniteScroll>
					<TableHeaders className={styles.job__panel__header}>
						<TableRow>
							<TableHeader>
								<></>
							</TableHeader>
							<TableHeader>Job Name</TableHeader>
							<TableHeader>Project Name</TableHeader>
							<TableHeader>Architecture</TableHeader>
							<TableHeader>
								<div
									onClick={() => {
										handleHeaderSortClick('created_on');
									}}
								>
									<span>
										CREATED ON
										{sortOrders.created_on === 0 ? (
											<img src="./icons/sorting/neutral.svg" className={styles.job__panel__header__arrow}></img>
										) : sortOrders.created_on === 1 ? (
											<img src="./icons/sorting/asc.svg" className={styles.job__panel__header__arrow}></img>
										) : (
											<img src="./icons/sorting/dsc.svg" className={styles.job__panel__header__arrow}></img>
										)}
									</span>
								</div>
							</TableHeader>
							<TableCell>
								<div
									onClick={() => {
										handleHeaderSortClick('status');
									}}
								>
									<span>
										STATUS
										{sortOrders.status === 0 ? (
											<img src="./icons/sorting/neutral.svg" className={styles.job__panel__header__arrow}></img>
										) : sortOrders.status === 1 ? (
											<img src="./icons/sorting/asc.svg" className={styles.job__panel__header__arrow}></img>
										) : (
											<img src="./icons/sorting/dsc.svg" className={styles.job__panel__header__arrow}></img>
										)}
									</span>
								</div>
							</TableCell>
						</TableRow>
					</TableHeaders>
					<TableBody>
						{(modelsData?.result || []).map((model, index) => {
							return (
								<TableRow key={index}>
									<TableCell>
										<Link to={`/models/${model.awi_job_uuid}`}>
											<div className={styles.dataset__panel__table__view__button}>
												{model.awi_scheduled_at && new Date(model.awi_scheduled_at) > new Date() ? '' : 'View'}
											</div>
										</Link>
									</TableCell>
									<TableCell>{model.awi_job_name || ''}</TableCell>
									<TableCell>{model.awi_project_name || ''}</TableCell>
									<TableCell>{model.awi_in?.network_type || ''}</TableCell>
									<TableCell>{format(new Date(model.awi_timestamp.awi_creation), 'hh:mm a, dd MMM Y')}</TableCell>
									<TableCell>
										{model.awi_scheduled_at && new Date(model.awi_scheduled_at) > new Date()
											? 'scheduled'
											: model.awi_status_conditions[model.awi_status_conditions.length - 1].type || ''}
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</TableInfiniteScroll>
			</div>
		</Container>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps, null)(ModelsPage));
