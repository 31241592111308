import { client } from './../../util/client';
export const postAppToModel = ({
	awi_job_uuid,
	app
}: {
	awi_job_uuid: string;
	app: {
		awi_name: string;
		awi_app_name: string;
		awi_app_id: number;
	}[];
}) =>
	client('/add_apps_to_model', {
		baseURL: 'training',
		method: 'POST',
		data: {
			apps: app,
			awi_job_uuid: awi_job_uuid
		}
	}).then(response => {
		return response.data;
	});
