import { client } from '../../util/client';

export const fetchUsers = (role: string[]) => {
	const queryKey = ['users', { role }] as const;
	const queryFunction = () =>
		client('/fetch_users', {
			baseURL: 'user',
			method: 'POST',
			data: {
				roles: role
			}
		}).then(res => res.data.result);

	return [queryKey, queryFunction] as const;
};
