import { client } from '../../util/client';

export const uploadUserFiles = (data: any) =>
	client('/upload_user_files', {
		baseURL: 'user',
		data: data.formData,
		method: 'POST',
		params: {
			fileKey: data.fileKey
		}
	}).then(res => res.data);
