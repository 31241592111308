import { differenceInDays } from 'date-fns';
import { client } from '../../util/client';
import { Content, ImageBankFilters } from '../../types';
import { getFirstDayOfMonth } from '../../util/common.utils';

export type ImageBankResponse = {
	result: Content[];
	next: string | null;
	success: boolean;
	awi_status: {
		_id: boolean;
		awi_total: number;
	}[];
	awi_annotation_status: {
		_id: boolean;
		awi_total: number;
	}[];
};

export const fetchImageBank = (data: ImageBankFilters) => {
	const filters = {
		...data
	};

	const queryKey = ['image-bank', { filters }] as const;

	const queryFunction = ({ pageParam }: { pageParam?: any }) => {
		let [cursor, limit] = [undefined, 40];

		if (typeof pageParam !== 'undefined') {
			[cursor, limit] = pageParam;
		}

		return client<ImageBankResponse>(`/fetch_image_bank_data`, {
			baseURL: 'content',
			method: 'POST',
			data: {
				...filters,
				limit
			},
			params: {
				cursor: cursor
			}
		}).then(res => res.data);
	};

	return [queryKey, queryFunction] as const;
};
