import clsx from 'clsx';
import { fetchNotifications } from '../../../handlers/notification/fetchNotifications';
import { useQuery } from '../../../util/useQuery';
import styles from './styles.module.scss';
import { useMutation } from '../../../util/useMutation';
import { updateNotificationsStatus } from '../../../handlers/notification/markNotificationsAsRead';
import { toast } from 'react-hot-toast';
import { useState } from 'react';

interface NotificationModalProps {
	userId: number;
	handleCloseModal?: () => void;
}

const NotificationModal: React.FC<NotificationModalProps> = ({ userId, handleCloseModal }) => {
	const [fetchNotificatiKey, fetchNotificationFunction] = fetchNotifications({
		awi_user_id: userId
	});

	const { data: notifications } = useQuery(fetchNotificatiKey, fetchNotificationFunction);

	const [showUnread, setShowUnread] = useState(true);

	const updateNotificationsStatusMutation = useMutation(updateNotificationsStatus);

	const handleMarkAsRead = () => {
		updateNotificationsStatusMutation.mutate(
			{ awi_user_id: userId, awi_status: 'read' },
			{
				onSuccess: res => {
					console.log('marked as read');
					setShowUnread(false);
				},
				onError: (err: any) => {
					const errMessage = err.response?.data.message;
					toast.error('failed to mark as read');
				}
			}
		);
	};

	return (
		<>
			<div className={styles.notification__backdrop} onClick={handleCloseModal}></div>

			<div className={styles.notification__modal}>
				<div className={styles.notification__modal__header}>
					<p>Notifications</p>
					<p className={styles.notification__modal__header__readbutton} onClick={handleMarkAsRead}>
						Mark all as read
					</p>
				</div>

				<div className={styles.notification__modal__content}>
					{(notifications?.result || []).map((notification, index) => (
						<div
							key={index}
							className={clsx(styles.notification__modal__content__notification, {
								[styles.notification__modal__content__notread]: !notification.awi_read && showUnread
							})}
						>
							{notification.awi_message}
						</div>
					))}
				</div>
			</div>
		</>
	);
};
export default NotificationModal;
