import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getTagsAndLocations } from '../../../handlers/dataset/getTagsAndLocations';
import { useLocation } from 'react-router-dom';
import { TableInfiniteScroll, TableBody, TableCell, TableHeader, TableHeaders, TableRow } from '../../Common';
import { Redirect } from 'react-router-dom';

import { useQuery } from '../../../util/useQuery';

import styles from './styles.module.scss';
import Container from '../../Container';

const TagsAndLocations = () => {
	const location = useLocation();
	const { awi_tag_ids, awi_location_ids } = location.state || { awi_location_ids: [], awi_tag_ids: [] };
	const [getTagsAndLocationKey, getTagsAndLocationFunction] = getTagsAndLocations({ awi_location_ids, awi_tag_ids });
	const { data: tagsAndLocations } = useQuery(getTagsAndLocationKey, getTagsAndLocationFunction);

	return (
		<Container>
			<div className={styles.actions}>
				<button
					onClick={() => {
						window.location.href = '/datasets';
					}}
				>
					Back
				</button>
			</div>
			<div className={styles.container}>
				<div style={{ width: '30%' }}>
					<div style={{ marginBottom: '20px' }}>TAGS</div>
					<TableInfiniteScroll>
						<TableHeaders>
							<TableRow>
								<TableHeader>S.NO</TableHeader>
								<TableHeader>TAG NAME</TableHeader>
							</TableRow>
						</TableHeaders>
						<TableBody>
							{tagsAndLocations?.result.awi_tags.map((tag, index) => {
								return (
									<>
										<TableRow key={index}>
											<TableCell>
												<span>{index + 1}</span>
											</TableCell>
											<TableCell>{tag.awi_label}</TableCell>
										</TableRow>
									</>
								);
							})}
						</TableBody>
					</TableInfiniteScroll>
				</div>
				<div style={{ width: '30%' }}>
					<div style={{ marginBottom: '20px' }}>LOCATIONS</div>
					<TableInfiniteScroll>
						<TableHeaders>
							<TableRow>
								<TableHeader>S.NO</TableHeader>
								<TableHeader>LOCATION NAME</TableHeader>
							</TableRow>
						</TableHeaders>
						<TableBody>
							{tagsAndLocations?.result.awi_locations.map((location, index) => {
								return (
									<>
										<TableRow key={index}>
											<TableCell>
												<span>{index + 1}</span>
											</TableCell>
											<TableCell>{location.awi_label}</TableCell>
										</TableRow>
									</>
								);
							})}
						</TableBody>
					</TableInfiniteScroll>
				</div>
			</div>
		</Container>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps, null)(TagsAndLocations));
