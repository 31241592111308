import { useState } from 'react';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getDatasetClasses } from '../../../handlers/dataset/getDatasetClasses';
import { useQuery } from '../../../util/useQuery';
import { DatePicker, Input } from '../../Common';
import { JobInputType } from '../../../types/dataset';

import styles from './styles.module.scss';
import { createJob } from '../../../handlers/jobs/createJob';
import { useMutation } from '../../../util/useMutation';

interface CreateJobModalProps {
	dataset?: any;
	handleModalClose: () => void;
}

const CreateJobModal: React.FC<CreateJobModalProps> = ({ dataset, handleModalClose }) => {
	const [state, setState] = useState<number>(0);

	const intialJobData = {
		awi_node: '',
		awi_job_name: '',
		awi_job_description: '',
		awi_model_type: '',
		awi_model_architecture: '',
		awi_model_framework: '',
		awi_gpu: 'no',
		awi_optimiser: 'adam',
		awi_loss_function: 'softmax',
		awi_learning_rate: 0.01, // 0 to 0.00001
		awi_decay: 0.005, // 0 to 0.0001
		awi_batch_size: 16, // 0 to 256
		awi_training_splits: 0.8, // 0 to 1
		awi_classes: [],
		awi_epoch: 30, // 0 to 150
		awi_checkpoint: 5,
		awi_schedule_job: 'no',
		awi_schedule_date: null
	};

	const [jobData, setJobData] = useState<JobInputType>(intialJobData as JobInputType);
	console.log(jobData, 'jobData');

	const handleInputChange = event => {
		setJobData(prev => {
			prev[event.target.name] = event.target.value;
			return { ...prev };
		});
	};
	const [datasetClassesKey, datasetClassesFunction] = getDatasetClasses({ awi_dataset_id: dataset?.awi_id });

	const { data: datasetClasses } = useQuery(datasetClassesKey, datasetClassesFunction);

	const createJobMutation = useMutation(createJob);

	const handleSelectChange = (id: number, name: string) => {
		if (name === 'awi_node') {
			setJobData(prev => {
				let type = '';
				if (id === 0) {
					type = 'yamuna';
				}
				prev[name] = type;
				return { ...prev };
			});
		} else if (name === 'awi_model_type') {
			setJobData(prev => {
				let type = '';
				if (id === 0) {
					type = 'detection';
				} else if (id === 1) {
					type = 'classification';
				} else if (id === 2) {
					type = 'segmentation';
				}
				if (prev[name] === type) {
					type = '';
				}
				prev[name] = type;
				return { ...prev };
			});
		} else if (name === 'awi_model_architecture') {
			setJobData(prev => {
				let type = '';
				if (id === 0) {
					type = 'yolov3';
				} else if (id === 1) {
					type = 'yolov5';
				} else if (id === 2) {
					type = 'yolov7';
				}
				prev[name] = type;
				return { ...prev };
			});
		} else if (name === 'awi_model_framework') {
			setJobData(prev => {
				let type = '';
				if (id === 0) {
					type = 'pytorch';
				} else if (id === 1) {
					type = 'tensorflow';
				} else if (id === 2) {
					type = 'darknet';
				} else if (id === 3) {
					type = 'caffe';
				}
				if (prev[name] === type) {
					type = '';
				}
				prev[name] = type;
				return { ...prev };
			});
		} else if (name === 'awi_classes') {
			setJobData(prev => {
				const temp = [...prev.awi_classes];
				if (prev.awi_classes?.indexOf(id) === -1) {
					temp.push(id);
				} else {
					temp.splice(temp.indexOf(id), 1);
				}
				return { ...prev, awi_classes: temp };
			});
		}
	};

	const handleBulletChange = event => {
		if (event.target.name === 'awi_gpu') {
			setJobData(prev => {
				prev['awi_gpu'] = event.target.value;
				return { ...prev };
			});
		} else if (event.target.name === 'awi_optimiser') {
			setJobData(prev => {
				prev['awi_optimiser'] = event.target.value;
				return { ...prev };
			});
		} else if (event.target.name === 'awi_loss_function') {
			setJobData(prev => {
				prev['awi_loss_function'] = event.target.value;
				return { ...prev };
			});
		} else if (event.target.name === 'awi_schedule_job') {
			if (event.target.value === 'no') {
				setJobData(prev => {
					prev['awi_schedule_job'] = event.target.value;
					prev['awi_schedule_date'] = null;
					return { ...prev };
				});
			} else if (event.target.value === 'yes') {
				setJobData(prev => {
					prev['awi_schedule_job'] = 'yes';
					return { ...prev };
				});
			}
			// setJobData(prev => {
			// 	prev['awi_schedule_job'] = event.target.value;
			// 	return { ...prev };
			// });
		}
	};

	const handleSliderChnage = event => {
		if (event.target.name === 'awi_learning_rate') {
			setJobData(prev => {
				prev['awi_learning_rate'] = event.target.value;
				return { ...prev };
			});
		} else if (event.target.name === 'awi_decay') {
			setJobData(prev => {
				prev['awi_decay'] = event.target.value;
				return { ...prev };
			});
		} else if (event.target.name === 'awi_batch_size') {
			setJobData(prev => {
				prev['awi_batch_size'] = event.target.value;
				return { ...prev };
			});
		} else if (event.target.name === 'awi_epoch') {
			setJobData(prev => {
				prev['awi_epoch'] = event.target.value;
				return { ...prev };
			});
		} else if (event.target.name === 'awi_checkpoint') {
			setJobData(prev => {
				prev['awi_checkpoint'] = event.target.value;
				return { ...prev };
			});
		} else if (event.target.name === 'awi_training_splits') {
			setJobData(prev => {
				prev['awi_training_splits'] = event.target.value;
				return { ...prev };
			});
		}
	};

	const checkFormValidation = (keys: string[]) => {
		let isValid = true;
		keys.forEach(key => {
			if (jobData[key] === intialJobData[key]) {
				isValid = false;
			}
		});

		return isValid;
	};

	const nodeData = [
		{
			awi_id: 0,
			awi_label: 'yamuna'
		}
	];

	const modelTypeData = [
		{
			awi_id: 0,
			awi_label: 'Detection'
		},
		{
			awi_id: 1,
			awi_label: 'Classification'
		},
		{
			awi_id: 2,
			awi_label: 'Segmentaion'
		}
	];

	const modelArchitectureData = [
		{
			awi_id: 0,
			awi_label: 'Yolov3'
		},
		{
			awi_id: 1,
			awi_label: 'Yolov5'
		},
		{
			awi_id: 2,
			awi_label: 'Yolov7'
		}
	];

	const modelFrameworkData = [
		{
			awi_id: 0,
			awi_label: 'Pytorch'
		},
		{
			awi_id: 1,
			awi_label: 'Tensorflow'
		},
		{
			awi_id: 2,
			awi_label: 'Darknet'
		},
		{
			awi_id: 3,
			awi_label: 'Caffe'
		}
	];

	const handleSubmitRequest = event => {
		event.preventDefault();

		const final_job_data = {
			job_name: jobData.awi_job_name,
			framework: jobData.awi_model_framework,
			network_type: jobData.awi_model_architecture,
			use_gpu: jobData.awi_gpu === 'yes' ? true : false,
			learning_rate: +jobData.awi_learning_rate,
			optimizer: jobData.awi_optimiser,
			loss_function: jobData.awi_loss_function,
			decay: +jobData.awi_decay,
			batch_size: +jobData.awi_batch_size,
			epochs: +jobData.awi_epoch,
			split_ratio: +jobData.awi_training_splits,
			checkpoint_interval: +jobData.awi_checkpoint,
			model_type: jobData.awi_model_type,
			classes: (datasetClasses?.result || []).filter(element => jobData.awi_classes.includes(element.awi_id)).map(ele => ele.awi_label),
			awi_dataset_id: dataset?.awi_id,
			project_name: dataset?.awi_request?.awi_label || '',
			job_scheduled: jobData.awi_schedule_job === 'yes' ? true : false,
			schedule_date: jobData.awi_schedule_date
		};

		createJobMutation.mutate(
			{ ...final_job_data },
			{
				onSuccess: () => {
					if (typeof handleModalClose === 'function') {
						handleModalClose();
					}
					toast.success('Job Created Successfully');
				},
				onError: () => {
					toast.error('Error in creating job');
				}
			}
		);
	};

	const handleDateChange = (date: Date | null) => {
		setJobData(prev => {
			prev['awi_schedule_date'] = date;
			return { ...prev };
		});
	};

	const handleNextState = (_state: number) => {
		setState(_state);
	};

	return (
		<div className={styles.create__job__modal}>
			<form onSubmit={handleSubmitRequest}>
				{state === 0 ? (
					<>
						<h2>Training Parameters</h2>
						<div className={styles.upload__form__grid}>
							<label>Select Node</label>
							<Input placeholder="Select Node" searchString={jobData.awi_node} data={nodeData} onClick={handleSelectChange} type="input" name="awi_node" />
							<label>
								Job Name<span style={{ color: 'red', fontSize: '18px' }}> *</span>
							</label>
							<input type={'text'} onChange={handleInputChange} name="awi_job_name" placeholder="Enter Job Name" value={jobData.awi_job_name} />
							<label>Job Description</label>
							<input
								type={'text'}
								onChange={handleInputChange}
								name="awi_job_description"
								placeholder="Enter Job Description"
								value={jobData.awi_job_description}
							/>
							<label>Model Type</label>
							<Input
								placeholder="Select Model Type"
								searchString={jobData.awi_model_type}
								data={modelTypeData}
								onClick={handleSelectChange}
								type="input"
								name="awi_model_type"
							/>
							<label>
								Classes<span style={{ color: 'red', fontSize: '18px' }}> *</span>
							</label>
							<Input
								placeholder="Select Classes"
								searchString={(datasetClasses?.result || [])
									.filter(element => jobData.awi_classes.includes(element.awi_id))
									.map(ele => ele.awi_label)
									.join(', ')}
								data={datasetClasses?.result || []}
								onClick={handleSelectChange}
								type="input"
								name="awi_classes"
							/>
							<label>
								Model Architecture<span style={{ color: 'red', fontSize: '18px' }}> *</span>
							</label>
							<Input
								placeholder="Select Model Architecture"
								searchString={jobData.awi_model_architecture}
								data={modelArchitectureData}
								onClick={handleSelectChange}
								type="input"
								name="awi_model_architecture"
							/>
							<label>Model Framework</label>
							<Input
								placeholder="Select Model Framework"
								searchString={jobData.awi_model_framework}
								data={modelFrameworkData}
								onClick={handleSelectChange}
								type="input"
								name="awi_model_framework"
							/>
							<label>GPU</label>
							<div onChange={handleBulletChange} className={styles.upload__form__grid__bulletcontainer}>
								<div>
									<input type="radio" value="yes" name="awi_gpu" checked={jobData.awi_gpu === 'yes'} /> Yes
								</div>
								<div>
									<input type="radio" value="no" name="awi_gpu" checked={jobData.awi_gpu === 'no'} /> No
								</div>
							</div>
						</div>
						<div>
							<button
								className={styles.upload__form__grid__buttons}
								onClick={e => {
									e.preventDefault();
									if (!checkFormValidation(['awi_job_name', 'awi_classes', 'awi_model_architecture'])) {
										toast.error('Please fill all the required fields');
									} else {
										handleNextState(1);
									}
								}}
							>
								Next
							</button>
						</div>
					</>
				) : (
					<div>
						<h2>Hyperparameters</h2>
						<div className={styles.upload__form__grid}>
							<label>Optimiser</label>
							<div onChange={handleBulletChange} className={styles.upload__form__grid__bulletcontainer}>
								<div>
									<input type="radio" value="adam" name="awi_optimiser" checked={jobData.awi_optimiser === 'adam'} /> ADAM
								</div>
								<div>
									<input type="radio" value="sgd" name="awi_optimiser" checked={jobData.awi_optimiser === 'sgd'} /> SGD
								</div>
							</div>
							<label>Loss Function</label>
							<div onChange={handleBulletChange} className={styles.upload__form__grid__bulletcontainer}>
								<div>
									<input type="radio" value="softmax" name="awi_loss_function" checked={jobData.awi_loss_function === 'softmax'} /> Softmax
								</div>
								<div>
									<input type="radio" value="l2norm" name="awi_loss_function" checked={jobData.awi_loss_function === 'l2norm'} /> L2Norm
								</div>
							</div>
							<label>Learning rate</label>
							<div className={styles.upload__form__grid__slidercontainer}>
								<div>{jobData.awi_learning_rate}</div>
								<input type="range" value={jobData.awi_learning_rate} step={0.0005} min={0} max={0.1} onChange={handleSliderChnage} name="awi_learning_rate" />
							</div>
							<label>Decay</label>
							<div className={styles.upload__form__grid__slidercontainer}>
								<div>{jobData.awi_decay}</div>
								<input type="range" value={jobData.awi_decay} step={0.0001} min={0} max={0.01} onChange={handleSliderChnage} name="awi_decay" />
							</div>
							<label>Batch size</label>
							<div className={styles.upload__form__grid__slidercontainer}>
								<div>{jobData.awi_batch_size}</div>
								<input type="range" value={jobData.awi_batch_size} step={2} min={0} max={256} onChange={handleSliderChnage} name="awi_batch_size" />
							</div>
							<label>Training splits</label>
							<div className={styles.upload__form__grid__slidercontainer}>
								<div>{jobData.awi_training_splits}</div>
								<input type="range" value={jobData.awi_training_splits} step={0.05} min={0} max={1} onChange={handleSliderChnage} name="awi_training_splits" />
							</div>
							<label>Epochs</label>
							<div className={styles.upload__form__grid__slidercontainer}>
								<div>{jobData.awi_epoch}</div>
								<input type="range" value={jobData.awi_epoch} step={5} min={0} max={150} onChange={handleSliderChnage} name="awi_epoch" />
							</div>
							<label>Checkpoint interval</label>
							<div className={styles.upload__form__grid__slidercontainer}>
								<div>{jobData.awi_checkpoint}</div>
								<input
									type="range"
									value={jobData.awi_checkpoint}
									step={1}
									min={0}
									max={jobData.awi_epoch}
									onChange={handleSliderChnage}
									name="awi_checkpoint"
								/>
							</div>

							<label>Schedule Job</label>
							<div onChange={handleBulletChange} className={styles.upload__form__grid__bulletcontainer}>
								<div>
									<input type="radio" value="yes" name="awi_schedule_job" checked={jobData.awi_schedule_job === 'yes'} /> Yes
								</div>
								<div>
									<input type="radio" value="no" name="awi_schedule_job" checked={jobData.awi_schedule_job === 'no'} /> No
								</div>
							</div>
							{jobData.awi_schedule_job === 'yes' && (
								<>
									<label>Select time</label>
									<DatePicker
										value={jobData.awi_schedule_date}
										onChange={(date: Date) => handleDateChange(date)}
										dateFormat="MMMM dd, yyyy h:mm aa"
										isTimePicker={true}
										minDate={new Date()}
									/>
								</>
							)}
						</div>
						<div className={styles.upload__form__grid__buttonscontainer}>
							<button
								className={styles.upload__form__grid__buttons}
								onClick={e => {
									e.preventDefault();
									handleNextState(0);
								}}
							>
								Back
							</button>
							<button className={styles.upload__form__grid__buttons}>{jobData.awi_schedule_date ? 'Schedule Job' : 'Submit'}</button>
						</div>
					</div>
				)}
			</form>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps, null)(CreateJobModal));
