import { client } from '../../util/client';
export const fetchApps = () => {
	const queryKey = ['dashboard-apps'];
	const queryFn = () => {
		return client('/fetch_apps', {
			baseURL: 'training',
			method: 'GET'
		}).then(response => {
			return response.data;
		});
	};

	return [queryKey, queryFn] as const;
};
