import { differenceInDays } from 'date-fns';
import { getFirstDayOfMonth } from '../../util/common.utils';
import { RequestFilterType, RequestListResponse, Requests } from './../../types/request.d';
import { client } from './../../util/client';

export const getRequest = (data: RequestFilterType) => {
	const filters = {
		...data,
		awi_start_date: differenceInDays(data.awi_start_date!, getFirstDayOfMonth(new Date())) === 0 ? '' : data.awi_start_date!
	};

	const queryKey = ['request', { filters }] as const;

	const queryFunc = ({ pageParam }: { pageParam?: any }) => {
		console.log('pageParam', pageParam);
		return client<RequestListResponse>('fetch_list', {
			baseURL: 'request',
			method: 'POST',
			data: {
				...filters
			},
			params: {
				cursor: pageParam
			}
		}).then(res => res.data);
	};
	return [queryKey, queryFunc] as const;
};
