import { client } from './../../util/client';
export const fetchFilters = () => {
	const queryKey = ['filters'] as const;
	const queryFn = () =>
		client('/fetch_filters', {
			method: 'GET',
			baseURL: 'misc'
		}).then(res => res.data);

	return [queryKey, queryFn] as const;
};
