import { toast } from 'react-hot-toast';
import ReduxActionEnum from '../../models/ReduxActionEnum';
import UserInitialState from '../states/UserInitialState';

const UserReducer = (state = UserInitialState, action) => {
	switch (action.type) {
		case ReduxActionEnum.LOGIN_ACTION:
			localStorage.setItem('token', action.payload.token);
			return {
				loggedIn: true,
				loggedUser: action.payload
			};

		case ReduxActionEnum.LOGOUT_ACTION:
			localStorage.removeItem('token');
			toast.success('Logged Out Successfully!!!');
			return {
				loggedIn: false,
				loggedUser: {}
			};

		default:
			return state;
	}
};

export default UserReducer;
