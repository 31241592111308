import { client } from './../../util/client';
import { postNewAnnotationData } from './postNewAnnotation';

export const createMultipleAnnotations = ({
	awiAnnotations,
	awiSessionId,
	awiContentKey
}: {
	awiAnnotations: postNewAnnotationData[];
	awiSessionId: number;
	awiContentKey;
}) => client('/create_annotations', { baseURL: 'annotation', method: 'POST', data: { awiAnnotations, awiSessionId, awiContentKey } }).then(res => res.data);
