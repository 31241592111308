import { client } from './../../util/client';
export const fetchTestImages = ({ jobId, epoch_val = 'epoch_0' }: { jobId: string; epoch_val: string }) => {
	const queryKey = ['testImages', { jobId, epoch_val }];
	const queryFn = () => {
		return client('/fetch_test_images', {
			method: 'POST',
			baseURL: 'training',
			data: {
				awi_job_uuid: jobId,
				epoch_val
			}
		}).then(res => {
			return res.data;
		});
	};

	return [queryKey, queryFn] as const;
};
