import { client } from './../../util/client';

type fetchFiltersResponse = {
	success: boolean;
	result: any[];
	message?: string;
};

export const fetchFilters = () => {
	const queryKey = ['dataset-filters'] as const;
	const queryFunction = () => client<fetchFiltersResponse>('/get_datasets_filters', { baseURL: 'dataset', method: 'POST' }).then(res => res.data);

	return [queryKey, queryFunction] as const;
};
