import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import { format } from 'date-fns';
import { useLocation, Redirect } from 'react-router-dom';
import { fetchJobLogs } from '../../../handlers/jobs/fetchJobLogs';
import { useInfiniteQuery } from '../../../util/useInfiniteQuery';
import { InfiniteScroll, Input, LineGraph, Loader, TableBody, TableCell, TableHeader, TableHeaders, TableInfiniteScroll, TableRow } from '../../Common';
import { fetchJobDetails } from '../../../handlers/jobs/fetchJobDetails';
import { fetchModelMetrics } from '../../../handlers/models/fetchModelMetrics';
import { fetchModelResources } from '../../../handlers/models/fetchModelResources';
import { useQuery } from '../../../util/useQuery';
import Container from '../../Container';

import styles from './styles.module.scss';
import { fetchTestImages } from '../../../handlers/jobs/fetchTestImages';
import { fetchTestImageEpoch } from '../../../handlers/jobs/fetchTestImageEpoch';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import axios from 'axios';

const resourceGraphs = ['cpu_percent_avg', 'gpu_0_memory_free', 'gpu_0_memory_used', 'gpu_0_utilization', 'load_avg', 'memory_used'];

const ModelDetails = () => {
	const queryClient = useQueryClient();

	const cancelToken = axios.CancelToken.source();

	const [epoch, setEpoch] = useState<string>('');

	const [_jobLogs, setJobLogs] = useState<{ pages: any[]; pageParams: any[] }>({ pages: [], pageParams: [] });

	const [selectedTab, setSelectedTab] = useState<number>(0);

	const location = useLocation();
	const job_uuid = location.pathname.split('/models/')[1];

	if (!job_uuid) {
		return <Redirect to="/"></Redirect>;
	}

	if (!job_uuid) {
		return <Redirect to="/"></Redirect>;
	}

	const [fetchModelDetailsKey, fetchModelDetailsFunction] = fetchJobDetails(job_uuid);
	const [fetchModelMetricsKey, fetchModelMetricsFunction] = fetchModelMetrics(job_uuid);
	const [fetchModelResourcesKey, fetchModelResourcesFunction] = fetchModelResources(job_uuid);

	const [fetchTestImageEpochsKey, fetchTestImageEpochsFunction] = fetchTestImageEpoch(job_uuid);

	const [fetchModelTestImagesKey, fetchModelTestImagesFunction] = fetchTestImages({ jobId: job_uuid, epoch_val: epoch });

	const { data: modelDetails, isLoading: modelDetailsLoading } = useQuery(fetchModelDetailsKey, fetchModelDetailsFunction, {
		enabled: !!job_uuid
	});
	const { data: modelMetrics } = useQuery(fetchModelMetricsKey, fetchModelMetricsFunction, {
		enabled: !!job_uuid && selectedTab === 2
	});

	const { data: modelResources } = useQuery(fetchModelResourcesKey, fetchModelResourcesFunction, {
		enabled: !!job_uuid && selectedTab === 3
	});

	const { data: testImageEpochs } = useQuery(fetchTestImageEpochsKey, fetchTestImageEpochsFunction, {
		onSuccess: data => {
			if (data.result.length > 0) {
				setEpoch(data.result[0]);
			}
		},
		enabled: !!job_uuid && selectedTab === 4
	});

	const { data: modelTestImages } = useQuery(fetchModelTestImagesKey, fetchModelTestImagesFunction, {
		enabled: !!job_uuid && !!epoch && epoch !== '' && selectedTab === 4
	});
	const [fetchJobLogsKey, fetchJobLogsFunction] = fetchJobLogs({
		awi_job_uuid: job_uuid,
		job_status: modelDetails?.result?.status,
		cancelToken: cancelToken.token
	});
	const {
		data: jobLogs,
		hasNextPage,
		fetchNextPage,
		isFetchingNextPage,
		isLoading
	} = useInfiniteQuery(fetchJobLogsKey, fetchJobLogsFunction, {
		getNextPageParam: lastPage => {
			return [lastPage.result.last_file, lastPage.result.last_time];
		},
		enabled: !!job_uuid && selectedTab === 1 && modelDetails !== undefined && modelDetails !== null,
		onSuccess: data => {
			queryClient.invalidateQueries(fetchModelDetailsKey);
			const _data = structuredClone(data);

			_data.pages.forEach((page, index) => {
				if (index !== 0) {
					const prevPage = data.pages[index - 1];
					if (prevPage?.total === page?.total) {
						data.pages.splice(index, 1);
					} else {
						page.result.logs = page.result.logs.splice(0, prevPage?.total);
					}
				}
			});
			setJobLogs(_data);
		}
	});

	if (modelDetailsLoading) {
		return <div>Loading...</div>;
	}

	if (!modelDetails) {
		return null;
	}

	return (
		<Container>
			<p style={{ marginBottom: 24, fontSize: 20, fontWeight: 500 }}>Model Name - {modelDetails.result?.inputs.job_name || ''}</p>
			<Tab.Group onChange={index => setSelectedTab(index)}>
				<Tab.List className={styles.tabs__list}>
					{({ selectedIndex }) => (
						<>
							<Tab
								className={clsx(styles.tabs__list__item, {
									[styles.tabs__list__item__selected]: selectedIndex === 0
								})}
							>
								Model Details
							</Tab>
							<Tab
								className={clsx(styles.tabs__list__item, {
									[styles.tabs__list__item__selected]: selectedIndex === 1
								})}
							>
								Model Logs
							</Tab>
							<Tab
								className={clsx(styles.tabs__list__item, {
									[styles.tabs__list__item__selected]: selectedIndex === 2
								})}
							>
								Metrics
							</Tab>
							<Tab
								className={clsx(styles.tabs__list__item, {
									[styles.tabs__list__item__selected]: selectedIndex === 3
								})}
							>
								Resources
							</Tab>
							<Tab
								className={clsx(styles.tabs__list__item, {
									[styles.tabs__list__item__selected]: selectedIndex === 4
								})}
							>
								Test Images
							</Tab>
						</>
					)}
				</Tab.List>
				<Tab.Panels>
					<Tab.Panel>
						<div className={styles.model__models_container}>
							<div className={styles.model__models_container__model_details}>
								<p className={styles.model__models_container__model_details__title}>Inputs</p>
								<TableInfiniteScroll>
									<TableHeaders>
										<TableHeader>Name</TableHeader>
										<TableHeader>Value</TableHeader>
									</TableHeaders>
									<TableBody>
										{(Object.entries(modelDetails.result?.inputs || {}) || [])
											.filter(ele => {
												return ['model_type', 'network_type', 'classes', 'job_name', 'epochs'].includes(ele[0] as string);
											})
											.map((ele: any, index) => {
												return (
													<TableRow key={index}>
														<TableCell>{ele[0] as string}</TableCell>
														<TableCell>{typeof ele[1] === 'object' ? ele[1].join(', ') : (ele[1] as string)}</TableCell>
													</TableRow>
												);
											})}
									</TableBody>
								</TableInfiniteScroll>
							</div>
							<div className={styles.model__models_container__model_details}>
								{modelDetails?.result.outputs && (
									<>
										<p className={styles.model__models_container__model_details__title}>Outputs</p>
										<TableInfiniteScroll>
											<TableHeaders>
												<TableHeader>Name</TableHeader>
												<TableHeader>Value</TableHeader>
											</TableHeaders>
											<TableBody>
												{(Object.entries(modelDetails?.result.outputs || {}) || []).map((ele: any, index) => {
													return (
														<TableRow key={index}>
															<TableCell>{ele[0] as string}</TableCell>
															<TableCell>{ele[1] as string}</TableCell>
														</TableRow>
													);
												})}
											</TableBody>
										</TableInfiniteScroll>
									</>
								)}
							</div>
						</div>
					</Tab.Panel>
					<Tab.Panel>
						<div style={{ maxWidth: '100vw' }} className={styles.logs__container}>
							<TableInfiniteScroll>
								<TableHeaders className={styles.logs__container__header}>
									<TableRow>
										<TableHeader>S.NO</TableHeader>
										<TableHeader>Container</TableHeader>
										<TableHeader>TIME</TableHeader>
										<TableHeader>INFO</TableHeader>
									</TableRow>
								</TableHeaders>
								<TableBody>
									<InfiniteScroll
										hasMore={
											Boolean(hasNextPage) &&
											(modelDetails?.result?.status === 'running' ||
												((jobLogs?.pages || []).length > 0 &&
													jobLogs?.pages[jobLogs?.pages.length - 1].result?.files.length > 1 &&
													jobLogs?.pages[jobLogs?.pages.length - 1].result?.files.indexOf(jobLogs?.pages[jobLogs?.pages.length - 1].result?.last_file) <
														jobLogs?.pages[jobLogs?.pages.length - 1].result?.files.length - 1))
										}
										isLoading={isLoading || isFetchingNextPage}
										next={() => {
											setTimeout(() => {
												cancelToken.cancel();
												fetchNextPage();
											}, 3000);
										}}
										loader={<Loader marginTop={16} />}
									>
										{_jobLogs?.pages.map((page, pageIndex) => {
											return (
												<>
													{[...page.result.logs.filter(ele => ele.value !== '')].map((log, logIndex) => {
														return (
															<TableRow
																key={
																	pageIndex > 0
																		? logIndex +
																		  1 +
																		  structuredClone(_jobLogs?.pages)
																				.splice(0, pageIndex)
																				.reduce((acc, val) => [...val.result.logs.filter(ele => ele.value !== '')].length + acc, 0)
																		: logIndex + 1
																}
															>
																<TableCell>
																	{pageIndex > 0
																		? logIndex +
																		  1 +
																		  structuredClone(_jobLogs?.pages)
																				.splice(0, pageIndex)
																				.reduce((acc, val) => [...val.result.logs.filter(ele => ele.value !== '')].length + acc, 0)
																		: logIndex + 1}
																</TableCell>
																<TableCell>{log.container}</TableCell>
																<TableCell>{format(new Date(log.timestamp), 'dd/MM/yyyy HH:mm:ss')}</TableCell>
																<TableCell className={styles.no_break}>{log.value}</TableCell>
															</TableRow>
														);
													})}
													<span style={{ height: 10, width: '100%', marginBottom: -20, display: 'block' }}></span>
													<span style={{ height: 0, width: '100%', display: 'block' }}></span>
												</>
											);
										})}
									</InfiniteScroll>
								</TableBody>
							</TableInfiniteScroll>
						</div>
					</Tab.Panel>
					<Tab.Panel>
						<div className={styles.model__metrics_container}>
							{(modelMetrics?.result || []).filter(ele => ele !== null).length > 0 ? (
								(modelMetrics?.result || [])
									.filter(ele => ele !== null)
									?.map((metric, index) => {
										return (
											<LineGraph
												key={index}
												name={metric.name || ''}
												step={metric.data.step}
												metric={metric.data.metric}
												timestamp={metric.data.timestamp}
												xAxis={'step'}
											/>
										);
									})
							) : (
								<div>No metrics found</div>
							)}
						</div>
					</Tab.Panel>
					<Tab.Panel>
						<div className={styles.model__metrics_container}>
							{(modelResources?.result || []).filter(ele => ele !== null && resourceGraphs.includes(ele.name)).length > 0 ? (
								(modelResources?.result || [])
									.filter(ele => ele !== null && resourceGraphs.includes(ele.name))
									?.map((metric, index) => {
										return (
											<LineGraph
												key={index}
												name={metric.name}
												step={metric.data.step}
												metric={metric.data.metric}
												timestamp={metric.data.timestamp}
												xAxis={'timestamp'}
											/>
										);
									})
							) : (
								<div>No Data</div>
							)}
						</div>
					</Tab.Panel>
					<Tab.Panel>
						<div className={styles.model__metrics_container}>
							{testImageEpochs?.result?.length > 0 ? (
								<>
									<div style={{ width: 400 }}>
										<Input
											type="input"
											searchString={epoch}
											selectedData={[testImageEpochs?.result?.indexOf(epoch)]}
											onClick={(id: number) => {
												setEpoch(testImageEpochs.result[id]);
											}}
											data={testImageEpochs?.result.map((ele, index) => {
												return { awi_id: index, awi_label: ele };
											})}
										/>
									</div>
									<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', maxWidth: '100vw', gap: 16 }}>
										<p style={{ textAlign: 'center' }}>Labels</p>
										<p style={{ textAlign: 'center' }}>Predictions</p>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												gap: 16,
												maxWidth: 400,
												alignItems: 'center',
												justifyContent: 'center',
												margin: '0 auto'
											}}
										>
											{(modelTestImages?.result.labels || []).map((ele, index) => {
												return (
													<div key={index}>
														<img src={ele} width="100%" height="100%" />
													</div>
												);
											})}
										</div>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												gap: 16,
												maxWidth: 400,
												alignItems: 'center',
												justifyContent: 'center',
												margin: '0 auto'
											}}
										>
											{(modelTestImages?.result.pred || []).map((ele, index) => {
												return (
													<div key={index}>
														<img src={ele} width="100%" height="100%" />
													</div>
												);
											})}
										</div>
									</div>
								</>
							) : (
								<div>No Images available.</div>
							)}
						</div>
					</Tab.Panel>
				</Tab.Panels>
			</Tab.Group>
		</Container>
	);
};
export default ModelDetails;
