import { client } from '../../util/client';

export const fetchTestImageEpoch = jobId => {
	const queryKey = ['test-image-epoch', { jobId }];
	const queryFn = () => {
		return client('/fetch_test_images_epochs', {
			method: 'POST',
			baseURL: 'training',
			data: {
				awi_job_uuid: jobId
			}
		}).then(res => {
			return res.data;
		});
	};

	return [queryKey, queryFn] as const;
};
