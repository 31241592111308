import { client } from './../../util/client';

export const fetchModelApps = modelId => {
	const queryKey = ['dashboard-apps', { modelId }];
	const queryFn = () => {
		return client('/fetch_model_apps', {
			baseURL: 'training',
			method: 'POST',
			data: { awi_model_uuid: modelId }
		}).then(response => {
			return response.data;
		});
	};

	return [queryKey, queryFn] as const;
};
