import clsx from 'clsx';
import { connect } from 'react-redux';
import { useQuery } from 'react-query';
import { toast } from 'react-hot-toast';
import { withRouter } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import { Pie, getElementAtEvent } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData } from 'chart.js';

import { exportDataset } from '../../../handlers/request/exportDataset';
import { getClassProportion } from '../../../handlers/request/getClassProportion';
import { getDatasetProportions } from '../../../handlers/dataset/getDatasetProportions';

import { Input } from '../../Common';

import { useMutation } from '../../../util/useMutation';
import { attribute_pie_colors, class_pie_colors, values_pie_colors } from './utils/useConstants';

import styles from './styles.module.scss';
import { forceDownload } from '../../../util/common.utils';
import { getRequestClasses } from '../../../handlers/class/getRequestClasses';

ChartJS.register(ArcElement, Tooltip, Legend);

type PieData = {
	labels?: string[];
	datasets?: {
		label?: string;
		data: number[];
		backgroundColor?: string[];
		borderColor?: string[];
		borderWidth?: number;
	}[];
};

interface ClassProportionModalProps {
	requestId?: number;
	handleCloseModal?: () => void;
	datasetId?: number;
	requestLabel?: string;
}
const ClassProportionModal: React.FC<ClassProportionModalProps> = ({ requestId, handleCloseModal, datasetId, requestLabel }) => {
	const [classProportionToShow, setClassProportionToShow] = useState<any>(undefined);
	const classRef = useRef();
	const [classId, setClassId] = useState<number>(0);

	const [selectedOption, setSelectedOption] = useState<string>('Total Images');

	const attributeRef = useRef();
	const [attributeId, setAttributeId] = useState<number>(0);
	const [attributeData, setAttributeData] = useState<PieData>({});
	const [atttributeSelectedIndex, setAtttributeSelectedIndex] = useState<number>(0);
	const [selectedClasses, setSelectedClasses] = useState<number[]>([]);

	const [valuesData, setValuesData] = useState<PieData>({});

	const [getClassProportionKey, getClassProportionFunction] = getClassProportion({
		awi_request_id: requestId,
		awi_selected_option: selectedOption === 'Total Images' ? 'total' : 'verified',
		awi_class_ids: selectedClasses
	});
	const { data: classProportions } = useQuery(getClassProportionKey, getClassProportionFunction, { enabled: requestId ? true : false });

	const [getDatasetProportionsKey, getDatasetProportionsFunction] = getDatasetProportions({ awi_dataset_id: datasetId });
	const { data: datasetProportions } = useQuery(getDatasetProportionsKey, getDatasetProportionsFunction, { enabled: datasetId ? true : false });

	const [getClasseskey, getClassesFunction] = getRequestClasses({ awi_request_id: requestId });
	const { data: requestClasses } = useQuery(getClasseskey, getClassesFunction);
	const exportDatasetMutation = useMutation(exportDataset);

	useEffect(() => {
		if (requestClasses) {
			setSelectedClasses((requestClasses || []).map(ele => ele.awi_id));
		}
	}, [requestClasses]);

	useEffect(() => {
		if (classProportions?.success === false) {
			return;
		}
		setClassProportionToShow(classProportions?.result[0] || undefined);
	}, [classProportions]);

	useEffect(() => {
		if (datasetProportions?.success === false) {
			return;
		}
		setClassProportionToShow(datasetProportions?.result[0].awi_dataset_proportions || undefined);
	}, [datasetProportions]);

	const handleClassClick = (event?, index?: number) => {
		if (index !== undefined) {
			if (datasetId) {
				setClassId(classProportionToShow?.awi_class_proportions[index].awi_id);
				return;
			}
			setClassId(classProportionToShow?.awi_class_proportions[index].awi_class_id || -1);
		} else if (event) {
			if (datasetId) {
				setClassId(classProportionToShow?.awi_class_proportions[getElementAtEvent(classRef.current as any, event)[0].index].awi_id);
				return;
			}
			setClassId(classProportionToShow?.awi_class_proportions[getElementAtEvent(classRef.current as any, event)[0].index].awi_class_id || -1);
		}
	};

	const handleAttributeClick = (event?, index?: number) => {
		const temp_data = classProportionToShow?.awi_attributes_proportions.find(ele => ele.awi_class_id === classId);
		const temp_att_id = temp_data?.awi_attribute_data[index !== undefined ? index : getElementAtEvent(attributeRef.current as any, event)[0].index].awi_id;
		setAttributeId(temp_att_id || -1);
	};

	useEffect(() => {
		// to set class id intially
		if (classId === 0 && (classProportionToShow?.awi_class_proportions.length || 0) > 0) {
			const tempClassId = datasetId ? classProportionToShow?.awi_class_proportions[0].awi_id : classProportionToShow?.awi_class_proportions[0].awi_class_id;

			if (tempClassId) {
				setClassId(tempClassId);
			}
		}
	}, [classProportionToShow]);

	useEffect(() => {
		// to set attribute id intially
		if (classId !== 0) {
			const attributesData = classProportionToShow?.awi_attributes_proportions.find(ele => ele.awi_class_id === classId);
			if ((attributesData?.awi_attribute_data || []).length > 0) {
				setAttributeId(attributesData?.awi_attribute_data[0].awi_id || -1);
			}
		}
	}, [classId, classProportionToShow]);

	useEffect(() => {
		if (classProportionToShow?.awi_attributes_proportions.length === 0) {
			setAttributeData({});
			return;
		}

		const temp_attribute_data_index = classProportionToShow?.awi_attributes_proportions.findIndex(element => element.awi_class_id === classId);

		if (temp_attribute_data_index === -1) {
			return;
		}
		const temp_attribute_data = classProportionToShow?.awi_attributes_proportions[temp_attribute_data_index || 0];

		if (!temp_attribute_data) {
			return;
		}
		const attribute_data = {
			labels: temp_attribute_data.awi_attribute_data.map(ele => ele.awi_label),
			datasets: [
				{
					label: '# of Votes',
					data: temp_attribute_data.awi_attribute_data.map(ele => ele.awi_count),
					backgroundColor: attribute_pie_colors,
					borderColor: attribute_pie_colors
				}
			]
		};
		setAttributeData(attribute_data);
	}, [classId, classProportionToShow]);

	useEffect(() => {
		if (classProportionToShow?.awi_values_proportions.length === 0) {
			setValuesData({});
			return;
		}
		const temp_data = classProportionToShow?.awi_values_proportions
			.find(ele => ele.awi_class_id === classId)
			?.awi_attributes.find(ele => ele.awi_attribute_id === attributeId);

		if (!temp_data) {
			return;
		}

		const values_data = {
			labels: temp_data.awi_values.map(ele => ele.awi_label),
			datasets: [
				{
					label: '# of Votes',
					data: temp_data.awi_values.map(ele => ele.awi_count),
					backgroundColor: values_pie_colors,
					borderColor: values_pie_colors,
					borderWidth: 1
				}
			]
		};

		setValuesData(values_data);
		setAtttributeSelectedIndex(
			classProportionToShow?.awi_attributes_proportions
				.find(ele => ele.awi_class_id === classId)
				?.awi_attribute_data.findIndex(element => element.awi_id === attributeId) || 0
		);
	}, [attributeId, classProportionToShow]);

	const class_data = {
		labels: classProportionToShow?.awi_class_proportions.map(classProportion => classProportion.awi_label),
		datasets: [
			{
				label: '# of Votes',
				data: classProportionToShow?.awi_class_proportions.map(classProportion =>
					requestId
						? classProportion.awi_pending_annotations + classProportion.awi_approved_annotations + classProportion.awi_rejected_annotations
						: classProportion.awi_count
				),
				backgroundColor: class_pie_colors,
				borderColor: class_pie_colors,
				borderWidth: 1
			}
		]
	};

	const options = {
		responsive: true,
		plugins: {
			legend: {
				display: false
			}
		}
	};

	const classProportionOptions = [
		{
			awi_id: 0,
			awi_label: 'Total Images'
		},
		{
			awi_id: 1,
			awi_label: 'Verified Images'
		}
	];

	const handleUpdateOption = (id: number) => {
		if (id === 0) {
			setSelectedOption('Total Images');
		} else {
			setSelectedOption('Verified Images');
		}
	};

	const handleExportDataset = async () => {
		if (classId === 0) {
			toast.error('Empty dataset');
			return;
		}
		const temp_data = {
			awi_class_proportions: classProportionToShow?.awi_class_proportions?.map(element => {
				return {
					awi_id: element.awi_class_id,
					awi_label: element.awi_label,
					awi_count: element.awi_approved_annotations + element.awi_pending_annotations + element.awi_rejected_annotations,
					awi_images_count: element.awi_content_keys?.length || 0
				};
			}),
			awi_attributes_proportions: classProportionToShow?.awi_attributes_proportions?.map(element => {
				return {
					awi_class_id: element.awi_class_id,
					awi_attribute_data: element.awi_attribute_data || []
				};
			}),
			awi_values_proportions: classProportionToShow?.awi_values_proportions || []
		};
		const contentKeysExportData = classProportionToShow?.awi_class_proportions?.map(element => {
			return element.awi_content_keys;
		});

		const classProportionExportdata = classProportionToShow?.awi_class_proportions?.map(element => {
			return {
				awi_id: element.awi_class_id,
				awi_class_data: {
					awi_class_metrics: {
						awi_label: element.awi_label
					}
				},
				awi_annotation_count: element.awi_approved_annotations + element.awi_pending_annotations + element.awi_rejected_annotations,
				awi_image_count: element.awi_content_keys?.length || 0
			};
		});
		exportDatasetMutation.mutate(
			{
				awi_class_proportions: classProportionExportdata || [],
				awi_content_keys: contentKeysExportData?.flat(1) || [],
				awi_request_id: requestId ? +requestId : -1,
				awi_selected_option: selectedOption === 'Total Images' ? 'total' : 'verified',
				awi_class_proportions_for_collection: temp_data,
				awi_class_ids: selectedClasses
			},
			{
				onSuccess: res => {
					const url = res.signed_url;

					if (res.success && url) {
						const filename = url.split('\\').pop()?.split('/').pop();
						if (url.includes('amazonaws.com')) {
							forceDownload(url, filename);
						} else {
							fetch(url, {
								headers: new Headers({
									Origin: '*'
								}),
								mode: 'cors'
							})
								.then(response => response.blob())
								.then(blob => {
									const blobUrl = window.URL.createObjectURL(blob);
									console.log(filename, blobUrl, 'blobUrl');

									forceDownload(blobUrl, filename);
								})
								.catch(e => console.error(e));
						}
					}
				}
			}
		);
	};

	const handleSelectClasses = (id: number, name: string) => {
		if (selectedClasses.includes(id)) {
			setSelectedClasses(prev => [...prev.filter(item => item !== id)]);
		} else {
			setSelectedClasses(prev => [...prev, id]);
		}
	};

	return (
		<div>
			<div className={styles.class__proportion__backdrop} onClick={handleCloseModal}></div>
			<div className={styles.class__proportion__modal}>
				<div className={styles.class__proportion__modal__mainheading}>Class proportions {requestLabel ? `for ${requestLabel}` : ''}</div>
				{requestId && (
					<div className={styles.class__proportion__modal__options}>
						<div className={styles.class__proportion__modal__input}>
							<Input
								placeholder="Select Users"
								searchString={selectedOption}
								selectedData={selectedOption === 'Total Images' ? [0] : [1]}
								data={classProportionOptions}
								onClick={handleUpdateOption}
								type="drop-down"
							/>
						</div>

						<div className={styles.class__proportion__modal__input}>
							<Input
								placeholder="Select Classes"
								searchString={(requestClasses || [])
									.filter(ele => selectedClasses?.includes(ele.awi_id))
									.map(ele => ele.awi_label)
									.join(', ')}
								selectedData={selectedClasses}
								data={(requestClasses || []).map(requestclass => ({
									awi_id: requestclass.awi_id,
									awi_label: requestclass.awi_label
								}))}
								onClick={handleSelectClasses}
								type="drop-down"
							/>
						</div>

						<button
							className={styles.class__proportion__modal__export}
							onClick={handleExportDataset}
							disabled={classId === 0 || Boolean(exportDatasetMutation.isLoading)}
							style={{ opacity: classId === 0 || Boolean(exportDatasetMutation.isLoading) ? 0.5 : 1 }}
						>
							{exportDatasetMutation.isLoading ? 'Downloading...' : 'Export Data Set'}
						</button>
					</div>
				)}
				<div className={styles.class__proportion__modal__container}>
					{classProportionToShow?.awi_class_proportions?.length || 0 > 0 ? (
						<div className={styles.class__proportion__modal__piecontainer}>
							<div className={styles.class__proportion__modal__heading}>Class pie-chart</div>
							<Pie ref={classRef} data={class_data} onClick={handleClassClick} options={options as any} width={300} />
							<div className={styles.class__proportion__modal__labelscontainer}>
								{classProportionToShow?.awi_class_proportions?.map((element, index) => {
									return (
										<div
											key={index}
											className={clsx(styles.class__proportion__modal__labelcontainer, {
												[styles.class__proportion__modal__selectedLabel]: classId === element.awi_class_id
											})}
											onClick={() => handleClassClick(undefined, index)}
											title={class_data?.datasets[0].data[index]}
										>
											<div className={styles.class__proportion__modal__color} style={{ backgroundColor: class_pie_colors[index] }}></div>
											<div className={styles.class__proportion__modal__label}>{element.awi_label}</div>
										</div>
									);
								})}
							</div>
						</div>
					) : (
						<div>No classes for this request</div>
					)}
					{JSON.stringify(attributeData) === '{}' ? (
						<div>No attributes filled for this request</div>
					) : (
						<div className={styles.class__proportion__modal__piecontainer}>
							<div className={styles.class__proportion__modal__heading}>Attribute pie-chart</div>
							<Pie
								data={attributeData as ChartData<'pie', number[] | undefined, string>}
								ref={attributeRef}
								onClick={handleAttributeClick}
								options={options as any}
								width={300}
							/>
							<div className={styles.class__proportion__modal__labelscontainer}>
								{attributeData.labels?.map((element, index) => {
									return (
										<div
											key={index}
											className={clsx(styles.class__proportion__modal__labelcontainer, {
												[styles.class__proportion__modal__selectedLabel]: atttributeSelectedIndex === index
											})}
											onClick={() => handleAttributeClick(undefined, index)}
											title={attributeData?.datasets ? attributeData?.datasets[0]?.data[index].toString() : ''}
										>
											<div className={styles.class__proportion__modal__color} style={{ backgroundColor: attribute_pie_colors[index] }}></div>
											<div className={styles.class__proportion__modal__label}>{element}</div>
										</div>
									);
								})}
							</div>
						</div>
					)}
					{JSON.stringify(valuesData) === '{}' ? (
						<div>No attribute values selected for this request</div>
					) : (
						<div className={styles.class__proportion__modal__piecontainer}>
							<div className={styles.class__proportion__modal__heading}>Values pie-chart</div>
							<Pie data={valuesData as ChartData<'pie', number[] | undefined, string>} options={options as any} width={300} />
							<div className={styles.class__proportion__modal__labelscontainer}>
								{valuesData.labels?.map((element, index) => {
									return (
										<div
											key={index}
											className={styles.class__proportion__modal__labelcontainer}
											title={valuesData?.datasets ? valuesData?.datasets[0]?.data[index].toString() : ''}
										>
											<div className={styles.class__proportion__modal__color} style={{ backgroundColor: values_pie_colors[index] }}></div>
											<div className={styles.class__proportion__modal__label}>{element}</div>
										</div>
									);
								})}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps, null)(ClassProportionModal));
