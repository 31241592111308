import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

import { getRequestData } from '../../../handlers/request/getRequestData';
import { updateRequestThreshold } from '../../../handlers/request/updateRequestThreshold';
import { useMutation } from '../../../util/useMutation';

import { useQuery } from '../../../util/useQuery';

import styles from './styles.module.scss';

interface UpdateRequestModalProps {
	requestId: number;
	handleCloseModal: () => void;
}

const UpdateRequestModal: React.FC<UpdateRequestModalProps> = ({ requestId, handleCloseModal }) => {
	const [updateRequestState, setUpdateRequestState] = useState<{ awi_threshold: number; awi_min_session_images: number; awi_allow_verifier: boolean }>({
		awi_threshold: 0,
		awi_min_session_images: 0,
		awi_allow_verifier: false
	});

	const [fetchRequestKey, fetchRequestFunction] = getRequestData(requestId!);

	const { data: requestData } = useQuery(fetchRequestKey, fetchRequestFunction, { enabled: !!requestId });

	const updateRequestMutation = useMutation(updateRequestThreshold);

	const initialRequestDataState = {
		awi_threshold: requestData?.awi_threshold || 0,
		awi_min_session_images: requestData?.awi_min_session_images || 0,
		awi_allow_verifier: requestData?.awi_allow_verifier || false
	};

	useEffect(() => {
		if (requestData) {
			setUpdateRequestState({
				awi_threshold: requestData?.awi_threshold || 0,
				awi_min_session_images: requestData?.awi_min_session_images || 0,
				awi_allow_verifier: requestData?.awi_allow_verifier || false
			});
		}
	}, [requestData]);

	const handleUpdateRequest = () => {
		const { awi_threshold, awi_min_session_images, awi_allow_verifier } = updateRequestState;

		updateRequestMutation.mutate(
			{ awi_threshold, awi_min_session_images, awi_allow_verifier, request_id: requestId },
			{
				onSuccess: () => {
					toast.success('Request updated successfully');
					handleCloseModal();
				}
			}
		);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		let input = e.target.value.replace(/[^0-9]/g, '');
		if (input !== e.target.value) {
			// Input was invalid, set error message
			toast.error('Only integers are allowed');
			return;
		}
		setUpdateRequestState(prevState => ({ ...prevState, [name]: value }));
	};

	if (!requestData) return null;

	return (
		<>
			<div className={styles.backdrop} onClick={handleCloseModal}></div>
			<div className={styles.modal}>
				<div className={styles.modal__close_button} onClick={handleCloseModal}>
					<img src="/icons/close.svg" />
				</div>
				<div style={{ padding: '24px 0' }}>
					<p style={{ fontSize: 24, fontWeight: 500 }}>Update Request</p>
				</div>
				<form onSubmit={handleUpdateRequest}>
					<div className={styles.upload__form__grid}>
						<label htmlFor="min_images">Min. Images per session</label>
						<input
							value={updateRequestState.awi_min_session_images}
							id="min_images"
							type={'tel'}
							name="awi_min_session_images"
							onChange={handleInputChange}
							placeholder="Min Image per session"
							maxLength={3}
						/>
						<label htmlFor="max_images">Max. Images per day</label>
						<input
							type={'tel'}
							value={updateRequestState.awi_threshold}
							id="max_images"
							name="awi_threshold"
							onChange={handleInputChange}
							placeholder="Max images per day"
							maxLength={3}
						/>
					</div>
					<div className={styles.modal__buttons}>
						<button disabled={JSON.stringify(updateRequestState) === JSON.stringify(initialRequestDataState)}>Update Request</button>
					</div>
				</form>
			</div>
		</>
	);
};

export default UpdateRequestModal;
