import { client } from './../../util/client';

export const fetchModelMetrics = (modelId: string) => {
	const queryKey = ['modelMetrics', modelId];
	const queryFn = () => {
		return client('/fetch_job_metrics', {
			method: 'POST',
			baseURL: 'training',
			data: {
				awi_job_uuid: modelId
			}
		}).then(res => {
			return res.data;
		});
	};

	return [queryKey, queryFn] as const;
};
