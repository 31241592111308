import ReduxActionEnum from '../../models/ReduxActionEnum';
import requestInitialState from '../states/RequestInitialState';

const RequestReducer = (state = requestInitialState, action) => {
	switch (action.type) {
		case ReduxActionEnum.SET_REQUEST_ID:
			return {
				...state,
				awi_id: action.payload.awi_id,
				awi_label: action.payload.awi_label
			};
		default:
			return state;
	}
};

export default RequestReducer;
