import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import HotKeys from 'react-hot-keys';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useQueryClient } from 'react-query';
import { useState, useEffect, useRef } from 'react';

import { updateAnnotation, postNewAnnotation, getAnnotationSession, createMultipleAnnotations } from '../../../handlers/annotation';
import {
	closeSession,
	closeNewSession,
	createNewSession,
	closeVerifierSession,
	addVerifierToSession,
	createVerifierSession,
	updateSessionVerificationComplete
} from '../../../handlers/session';

import AnnotationDetails from './AnnotationDetails';
import AnnotationTools from './AnnotationTools';
import AnnotationPagination from './AnnotationPagination';

import { ImageAnnotationPreviewer } from '../../Common';
import Container from '../../Container';

import { LoggedUser, Requests } from '../../../types';
import { Annotation as AnnotationType } from '../../../types/annotations';

import { useQuery } from '../../../util/useQuery';
import { useMutation } from '../../../util/useMutation';
import RequestActions from '../../../redux/actions/RequestActions';

import styles from './styles.module.scss';
interface AnnotationProps {
	setRequest: (data: { awi_id?: number; awi_label: string }) => void;
	loggedUser: LoggedUser;
}

const Annotation: React.FC<AnnotationProps> = ({ loggedUser }) => {
	const _sessionId = useRef(-1);
	const _requestId = useRef(-1);
	const _verifierSessionId = useRef(-1);
	const token = useRef('');

	const [currentClass, setCurrentClass] = useState<number>(-1);
	const [classColor, setClassColor] = useState<{ awi_id: number; awi_color: string }[]>([]);

	const [currentImage, setCurrentImage] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(0);
	// const [tool, setTool] = useState<Tool>('rectangle');

	const [selectAnnotationId, setSelectAnnotationId] = useState<number>(-1);

	const [requestId, setRequestId] = useState<number | undefined>(undefined);

	const [isEditing, setIsEditing] = useState<boolean>(loggedUser.userObj.awi_level === 'awi_annotator' ? false : true);

	const [copyAnnotations, setCopyAnnotations] = useState<AnnotationType[]>([]);

	// const [newSession, setNewSession] = useState<SessionContent[]>([]);

	const closeSessionMutation = useMutation(closeSession);
	const createVerificationSessionMutation = useMutation(createVerifierSession);
	const closeVerifierSessionMutation = useMutation(closeVerifierSession);

	const queryClient = useQueryClient();

	const postNewSessionMutation = useMutation(createNewSession);
	const closeNewSessionMutation = useMutation(closeNewSession);
	const updateCompleteVerificationMutation = useMutation(updateSessionVerificationComplete);

	const [getAnnotationSessionKey, getAnnotationSessionFunction] = getAnnotationSession({ awi_request_id: requestId! });

	const { data: annotationSession, isLoading } = useQuery(getAnnotationSessionKey, getAnnotationSessionFunction, {
		enabled: _requestId.current !== -1
	});

	const handleCompleteverification = () => {
		if (loggedUser.userObj.awi_level !== 'awi_annotator') {
			updateCompleteVerificationMutation.mutate(_sessionId.current, {
				onSuccess: result => {
					if (result.success === false) {
						toast.error(result.msg || 'Session verification failed');
					} else {
						toast.success('Session completed');
						window.location.href = '/annotation';
					}
				}
			});
		} else {
			closeNewSessionMutation.mutate(
				{ awi_session_id: _sessionId.current, awi_request_id: _requestId.current, token: token.current },
				{
					onSuccess: () => {
						toast.success('Session completed');
						window.location.href = '/annotation';
					}
				}
			);
		}
	};

	useEffect(() => {
		if (
			loggedUser.userObj.awi_level === 'awi_annotator' &&
			_sessionId.current === -1 &&
			annotationSession &&
			annotationSession?.result?.awi_session_id === -1
		) {
			postNewSessionMutation.mutate(+JSON.parse(localStorage.getItem('request')!).awi_id, {
				onSuccess: data => {
					toast.success('Session created');
					_sessionId.current = data.result.awi_id;
				},
				onError: () => {
					toast.error('Session creation failed');
				}
			});
		}
		_requestId.current = +JSON.parse(localStorage.getItem('request')!).awi_id;
	}, [annotationSession]);

	useEffect(() => {
		if (localStorage.getItem('token')) {
			token.current = localStorage.getItem('token')!;
		}

		if (typeof window !== 'undefined') {
			window.addEventListener('beforeunload', event => {
				event.preventDefault();
				if (loggedUser.userObj.awi_level === 'awi_annotator') {
					closeNewSessionMutation.mutate({
						awi_session_id: _sessionId.current,
						awi_request_id: _requestId.current,
						token: token.current
					});
				} else if (loggedUser.userObj.awi_level === 'awi_verifier') {
					closeSessionMutation.mutate({ awi_session_id: _sessionId.current, token: token.current });
					// closeVerifierSessionMutation.mutate({ awi_session_id: _verifierSessionId.current, token: token.current });
				}
				// return (event.returnValue = '');
			});
		} else {
			document.addEventListener('beforeunload', event => {
				event.preventDefault();
				if (loggedUser.userObj.awi_level === 'awi_annotator') {
					closeNewSessionMutation.mutate({
						awi_session_id: _sessionId.current,
						awi_request_id: _requestId.current,
						token: token.current
					});
				} else if (loggedUser.userObj.awi_level === 'awi_verifier') {
					closeSessionMutation.mutate({ awi_session_id: _sessionId.current, token: token.current });
					// closeVerifierSessionMutation.mutate({ awi_session_id: _verifierSessionId.current, token: token.current });
				}
				// return (event.returnValue = '');
			});
		}
		const requestState = localStorage.getItem('request');
		if (requestState && +JSON.parse(requestState).awi_id) {
			setRequestId(+JSON.parse(requestState).awi_id);
			_requestId.current = +JSON.parse(requestState).awi_id;
		}
	}, []);

	// useEffect(() => {
	// 	if (loggedUser.userObj.awi_level !== 'awi_annotator') {
	// 		if (fetchSessionData?.result) {
	// 			setNewSession(fetchSessionData?.result?.awi_content_keys || []);
	// 		}
	// 	}
	// }, [fetchSessionData]);

	// useEffect(() => {
	// 	if (_sessionId.current !== -1 && loggedUser.userObj.awi_level === 'awi_verifier') {
	// 		createVerificationSessionMutation.mutate(
	// 			{ awi_session_id: _sessionId.current, awi_request_id: _requestId.current },
	// 			{
	// 				onSuccess: data => {
	// 					if (data.success) {
	// 						_verifierSessionId.current = data.result.awi_id;
	// 					} else {
	// 						toast.error(data.message || 'Error creating verifier session');
	// 					}
	// 				}
	// 			}
	// 		);
	// 	}
	// }, [_sessionId.current]);

	const handleSelectTool = (tool: string) => {
		if (tool === 'edit') {
			setIsEditing(true);
		} else {
			setSelectAnnotationId(-1);
			setIsEditing(false);
		}
	};

	const handlePasteAnnotations = async () => {
		if (annotationSession?.result?.awi_request_for === 'attributes') {
			return;
		}
		if ((copyAnnotations || []).length > 0) {
			// if (+copyAnnotations[0].awi_content_key !== +annotationSession?.result?.awi_contents[currentImage].awi_content_key) {
			createMultipleAnnotationMutation.mutate(
				{
					awiAnnotations: copyAnnotations.map(annotation => {
						return {
							awi_request_id: requestId!,
							awi_content_key: +annotationSession?.result?.awi_contents[currentImage].awi_content_key,
							awi_coords: annotation.awi_coords,
							awi_outline_type: requestDetails!.awi_annotation_type,
							awi_class_id: annotation.awi_classes[0].awi_id,
							awi_attributes: [...(annotation.awi_attributes || [])]
						};
					}),
					awiSessionId: _sessionId.current,
					awiContentKey: +annotationSession?.result?.awi_contents[currentImage].awi_content_key
				},
				{
					onSuccess: res => {
						if (res.success) {
							handleSelectAnnotationIndex(res.result.awi_id);
							toast.success('Annotation pasted successfully');
						} else {
							toast.error(res.message || 'Something went wrong');
						}
					}
				}
			);

			// } else {
			// 	toast.error('Cannot paste annotations from same image');
			// }
		} else {
			toast.error('Nothing to paste');
		}
	};

	const handleCopyAnnotations = (annotations: AnnotationType[]) => {
		if (annotationSession?.result?.awi_request_for === 'attributes') {
			return;
		}

		if (selectAnnotationId !== -1) {
			setCopyAnnotations(annotations.filter(annotation => annotation.awi_id === selectAnnotationId));
			toast.success('Annotation Copied');
			return;
		} else {
			if ((annotations || []).length > 0) {
				setCopyAnnotations(annotations);
				toast.success('Annotation Copied');
			} else {
				toast.error('Nothing to copy');
			}
		}
	};

	const handleSelectAnnotationIndex = (id: number) => {
		setSelectAnnotationId(id);
		if (id === -1 && annotationSession?.result?.awi_request_for !== 'attributes') {
			handleSelectTool('annotate');
		}
	};

	const [requestDetails, setRequestDetails] = useState<Requests>();

	const handleUpdateImageIndex = (index: number) => {
		setCurrentImage(index);
		setSelectAnnotationId(-1);
		if (annotationSession?.result?.awi_request_for !== 'attributes') {
			handleSelectTool(requestDetails?.awi_annotation_type as string);
		}
	};

	const handleNextPage = () => {
		setCurrentPage(prev => {
			const nextPage = (prev + 1) * 6 < annotationSession?.result?.awi_contents.length ? prev + 1 : prev;
			handleUpdateImageIndex(nextPage * 6);
			return nextPage;
		});
	};

	const handlePreviousPage = () => {
		setCurrentPage(next => {
			const prevPage = (next - 1) * 6 >= 0 ? next - 1 : next;
			handleUpdateImageIndex(prevPage * 6);
			return next - 1;
		});
	};

	const handleGoToPage = index => {
		handleUpdateImageIndex(index);
		setCurrentPage((index - (index % 6)) / 6);
	};

	const goToNextimage = () => {
		if (currentImage < annotationSession?.result?.awi_contents.length - 1) {
			handleGoToPage(currentImage + 1);
		}
	};

	const goToPreviousImage = () => {
		if (currentImage > 0) {
			handleGoToPage(currentImage - 1);
		}
	};

	const handleUpdateCurrentClass = (id: number) => {
		setCurrentClass(id);
	};

	const handleUpdateCurrentClassColor = (id: number, color: string) => {
		const temp = [...classColor];
		temp.forEach((classObj, index) => {
			if (classObj.awi_id === id) {
				temp[index].awi_color = color;
			}
		});
		setClassColor([...temp]);
	};

	const fetchAnnotationsData = () => {
		if (annotationSession?.success && requestId!) {
			// setTool(annotationSession?.result.awi_annotation_type);
			setRequestDetails(annotationSession.result);
			if (currentClass === -1) {
				handleUpdateCurrentClass(annotationSession?.result.awi_classes[0].awi_id);
				const temp = [...classColor];
				(annotationSession!.result?.awi_classes || []).forEach(classObj => {
					temp.push({ awi_id: classObj.awi_id, awi_color: classObj.awi_color });
				});
				setClassColor([...temp]);
			}
			if ((annotationSession.result?.awi_session_id || -1) !== -1) {
				_sessionId.current = annotationSession.result?.awi_session_id;
			}
		} else {
			if (annotationSession?.message) {
				toast.error(annotationSession?.message, {
					duration: 5000
				});
			}
		}
	};

	const createMultipleAnnotationMutation = useMutation(createMultipleAnnotations, {
		onSuccess: res => {
			if (res.success) {
				queryClient.invalidateQueries([
					'annotations',
					{ awi_content_id: +annotationSession?.result?.awi_contents[currentImage].awi_content_key, awi_request_id: requestId! }
				]);
				return;
			} else {
				toast.error(res.message || 'Something went wrong');
			}
		},
		onError: (error: AxiosError) => {
			toast.error(error.response?.data.message || 'Something went wrong');
			if (error.response?.data.redirect) {
				window.location.href = '/request';
			}
		}
	});

	const addNewAnnotationMutation = useMutation(postNewAnnotation, {
		onSuccess: res => {
			if (res.success) {
				queryClient.invalidateQueries([
					'annotations',
					{ awi_content_key: +annotationSession?.result?.awi_contents[currentImage].awi_content_key, awi_request_id: requestId! }
				]);
				return;
			} else {
				toast.error(res.message || 'Something went wrong');
			}
		},
		onError: (error: AxiosError) => {
			toast.error(error.response?.data.message || 'Something went wrong');
			if (error.response?.data.redirect) {
				window.location.href = '/request';
			}
		}
	});

	const updateAnnotationMutation = useMutation(updateAnnotation, {
		onError: (error: AxiosError) => {
			toast.error(error.response?.data.message || 'Something went wrong');
			if (error.response?.data.redirect) {
				window.location.href = '/request';
			}
		}
	});

	const checkSessionCompleted = () => {
		return -1;
	};

	const handleCreateAnnotations = (coords: { x: number; y: number }[], cb?: () => void) => {
		if (requestDetails && annotationSession?.result?.awi_contents) {
			createMultipleAnnotationMutation.mutate(
				{
					awiAnnotations: [
						{
							awi_request_id: requestDetails.awi_id,
							awi_content_key: +annotationSession?.result?.awi_contents[currentImage].awi_content_key,
							awi_coords: coords,
							awi_outline_type: requestDetails.awi_annotation_type || 'rectangle',
							awi_class_id: currentClass,
							awi_attributes: []
						}
					],
					awiSessionId: _sessionId.current,
					awiContentKey: +annotationSession?.result?.awi_contents[currentImage].awi_content_key
				},
				{
					onSuccess: res => {
						if (res.success) {
							res.result?.length > 0 ? handleSelectAnnotationIndex(res.result[0].awi_id) : '';
							toast.success('Annotation created');
							if (typeof cb === 'function') {
								cb();
							}
						} else {
							toast.error(res.message || 'Something went wrong');
							if (res.redirect) {
								window.location.href = '/request';
							}
						}
					},
					onError: (error: AxiosError) => {
						toast.error(error.response?.data.message || 'Something went wrong');
						if (error.response?.data.redirect) {
							window.location.href = '/request';
						}
					}
				}
			);
		}
	};

	const handleUpdateAnnotationCoordinates = (coords: { x: number; y: number }[], annotationId: number, cb?: () => void) => {
		updateAnnotationMutation.mutate(
			{
				awi_session_id: _sessionId.current,
				awi_id: annotationId,
				awi_coords: coords,
				awi_status: loggedUser.userObj.awi_level === 'awi_annotator' ? '' : 'corrected',
				awi_is_corrected: loggedUser.userObj.awi_level === 'awi_annotator' ? false : true,
				content_key: +annotationSession?.result?.awi_contents[currentImage].awi_content_key,
				awi_update: 'coordinates'
			},
			{
				onSuccess: () => {
					if (!!requestDetails) {
						queryClient.invalidateQueries([
							'annotations',
							{ awi_content_key: +annotationSession?.result?.awi_contents[currentImage].awi_content_key, awi_request_id: requestId! }
						]);
					}
					toast.success('Annotation updated');
					if (typeof cb === 'function') {
						cb();
					}
				}
			}
		);
		return null;
	};

	useEffect(() => {
		if (annotationSession?.success) {
			fetchAnnotationsData();
		}
	}, [annotationSession]);

	if (isLoading) {
		return (
			<Container>
				<p>Loading.....</p>
			</Container>
		);
	}

	if (!annotationSession) {
		return null;
	}

	if (!Boolean(annotationSession?.success)) {
		return (
			<Container>
				<p>
					{annotationSession?.msg ||
						`There are no images to ${
							loggedUser.userObj.awi_level === 'awi_verifier' || loggedUser.userObj.awi_level === 'awi_supervisor' ? 'Verify' : 'Annotate'
						}`}
				</p>
			</Container>
		);
	}

	if ((annotationSession?.result?.awi_contents || []).length === 0) {
		return (
			<Container>
				<p>
					{annotationSession?.message ||
						`There are no images to ${
							loggedUser.userObj.awi_level === 'awi_verifier' || loggedUser.userObj.awi_level === 'awi_supervisor' ? 'Verify' : 'Annotate'
						}`}
				</p>
			</Container>
		);
	}

	if (_sessionId.current === -1 && ['awi_annotator', 'awi_verifier'].includes(loggedUser.userObj.awi_level)) {
		return null;
	}

	return (
		<>
			{['awi_annotator', 'awi_verifier', 'awi_developer'].includes(loggedUser.userObj.awi_level) && (
				<>
					<HotKeys keyName="e" onKeyDown={() => handleSelectTool('edit')}></HotKeys>
					<HotKeys keyName="q" onKeyDown={() => handleSelectTool('create')}></HotKeys>
				</>
			)}
			<>
				<HotKeys keyName="d" onKeyDown={goToNextimage}></HotKeys>
				<HotKeys keyName="a" onKeyDown={goToPreviousImage}></HotKeys>
			</>

			<Container className={styles.annotation}>
				<div className={styles.annotation__tool}>
					{['awi_annotator', 'awi_verifier', 'awi_developer'].includes(loggedUser.userObj.awi_level) && (
						<AnnotationTools
							isEditing={isEditing}
							handleSelectTool={handleSelectTool}
							annotator_name={
								(annotationSession?.result?.annotatorInfo?.awi_personal_info?.awi_firstname || '') +
								(annotationSession?.result?.annotatorInfo?.awi_personal_info?.awi_lastname || '')
							}
						/>
					)}
					<div className={styles.annotation__tool__image_previewer}>
						<ImageAnnotationPreviewer
							handleSelectAnnotationIndex={handleSelectAnnotationIndex}
							selectIndex={selectAnnotationId}
							handleSelectTool={handleSelectTool}
							request_id={requestId!}
							content_id={annotationSession?.result?.awi_contents[currentImage]?.awi_content_key}
							isEditing={isEditing}
							imageUrl={annotationSession?.result?.awi_contents[currentImage]?.awi_content_url}
							showAnnotations
							createAnnotation={handleCreateAnnotations}
							handleUpdateAnnotationCoordinates={handleUpdateAnnotationCoordinates}
							classColor={classColor}
							disableAnnotation={
								updateAnnotationMutation.isLoading ||
								createMultipleAnnotationMutation.isLoading ||
								addNewAnnotationMutation.isLoading ||
								!['awi_annotator', 'awi_verifier', 'awi_developer'].includes(loggedUser.userObj.awi_level) ||
								annotationSession?.result?.awi_request_for === 'attributes'
							}
						/>
					</div>
				</div>
				<AnnotationPagination
					handleNextPage={handleNextPage}
					handlePreviousPage={handlePreviousPage}
					handleGoToPage={handleGoToPage}
					currentPage={currentPage}
					images={annotationSession?.result?.awi_contents}
					handleIndexUpdate={handleUpdateImageIndex}
					currentIndex={currentImage}
					request_id={requestId!}
					requestFor={annotationSession?.result?.awi_request_for}
				/>
				<AnnotationDetails
					request_id={requestId!}
					content_key={annotationSession?.result?.awi_contents[currentImage]?.awi_content_key}
					currentImage={currentImage}
					totalImages={(annotationSession?.result?.awi_contents || []).length}
					handleCopyAnnotations={handleCopyAnnotations}
					handlePasteAnnotations={handlePasteAnnotations}
					selectIndex={selectAnnotationId}
					handleSelectAnnotationIndex={handleSelectAnnotationIndex}
					copyAnnotations={copyAnnotations}
					handleCompleteverification={handleCompleteverification}
					isLoadingSaveSession={Boolean(closeNewSessionMutation.isLoading || closeSessionMutation.isLoading)}
					handleSelectTool={handleSelectTool}
					currentClass={currentClass}
					handleUpdateCurrentClass={handleUpdateCurrentClass}
					sessionId={_sessionId.current}
					checkSessionCompleted={checkSessionCompleted}
					handleUpdateCurrentClassColor={handleUpdateCurrentClassColor}
					classColor={classColor}
				/>
			</Container>
		</>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setRequest: data => dispatch(RequestActions.setRequest(data))
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Annotation));
