import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { LoggedUser } from '../../../types';
import { format } from 'date-fns';
import Table from '../../Common/Table';
import Container from '../../Container';
import { useQuery } from '../../../util/useQuery';
import { getTransactions } from '../../../handlers/transactions/getTransactions';

interface TransactionsProps {
	loggedUser: LoggedUser;
}

const TransactionsPage: React.FC<TransactionsProps> = ({}) => {
	const [getTransactionKey, getTransactionFunction] = getTransactions();
	const { data: transactions } = useQuery(getTransactionKey, getTransactionFunction);

	const getHeaders = () => {
		const temp: any = [];
		temp.push('S.NO', 'TRANSACTION ID', 'TRANSACTION TIME', 'RECEIVER ID', 'BILLING AMOUNT', 'STATUS', 'REQUEST LABEL');
		return temp;
	};

	if (!transactions) {
		return null;
	}

	const convertUserData = () => {
		return (transactions?.result || []).map((transaction, index) => {
			const temp: any[] = [];
			temp.push(index + 1);
			temp.push(transaction.awi_transaction_id);
			temp.push(format(new Date(transaction.awi_timestamp.awi_created_at), 'k:mm aaa , dd/LL/yyyy'));
			temp.push(transaction.awi_user_info.awi_vpa);
			temp.push(transaction.awi_amount / 100);
			temp.push(transaction.awi_status);
			return temp;
		});
	};
	return (
		<Container>
			<Table headers={getHeaders()} content={convertUserData()}></Table>
		</Container>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps, null)(TransactionsPage));
