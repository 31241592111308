import { client } from './../../util/client';

export type RequestClassProportionResponse = {
	success: boolean;
	message?: string;
	result: {
		awi_class_proportions: {
			awi_approved_annotations: number;
			awi_content_keys: string[];
			awi_label: object;
			awi_pending_annotations: number;
			awi_rejected_annotations: number;
			awi_class_id: number;
		}[];
		awi_attributes_proportions: {
			awi_attribute_data: {
				awi_id: number;
				awi_class_id: number;
				awi_label: string;
				awi_count: number;
			}[];
			awi_class_id: number;
		}[];
		awi_values_proportions: {
			awi_class_id: number;
			awi_attributes: {
				awi_attribute_id: number;
				awi_values: {
					awi_label: string;
					awi_count: number;
				}[];
			}[];
		}[];
	}[];
};

export const getClassProportion = (data: { awi_request_id: number | undefined; awi_selected_option: string; awi_class_ids: number[] }) => {
	const queryKey = ['class-proportion', data] as const;
	const queryFunc = () =>
		client<RequestClassProportionResponse>('request_class_proportion', {
			baseURL: 'request',
			method: 'POST',
			data
		}).then(res => res.data);

	return [queryKey, queryFunc] as const;
};
