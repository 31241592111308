import { client } from './../../util/client';

type getAnnotationSessionResponse = {
	success: boolean;
	result: any;
	message?: string;
	msg?: string;
};

export const getAnnotationSession = (data: { awi_request_id: number }) => {
	const queryKey = ['annotationSession', data] as const;
	const queryFunction = () =>
		client<getAnnotationSessionResponse>('/fetch_request_images', { baseURL: 'request', data: { ...data }, method: 'POST' }).then(res => res.data);

	return [queryKey, queryFunction] as const;
};
