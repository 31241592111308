import clsx from 'clsx';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

import { approveBQC } from '../../../handlers/misc';
import { fetchSample } from '../../../handlers/request/fetchSample';

import UpdateRequest from './UpdateRequestModal';
import ClassProportionModal from './ClassProportionModal';

import { AddUserModal } from '../../Common';

import { useMutation } from '../../../util/useMutation';
import { useQuery } from '../../../util/useQuery';

import { LoggedInUser } from '../../../types';

import styles from './styles.module.scss';
import { useQueryClient } from 'react-query';
import { updateRequest } from '../../../handlers/request';
import { add } from 'date-fns';
import { addDataToAttributesRequest } from '../../../handlers/request/addDataToAttributeRequest';

interface RequestActionsModalProps {
	imageBankAction: (id: number) => void;
	request_id: number;
	loggedUser: {
		token: string;
		userObj: LoggedInUser;
	};
	handleOpenCarousel: (request_id: number, imageKeys: number[]) => void;
	handleUpdateRequest: (id: number) => void;
	bqcCheck?: boolean;
	content_keys: number[];
	requestLabel?: string;
	fetchRequestKey: string;
	requestFor?: string;
	request: any;
	setRequestFlowModal: (value: boolean) => void;
	setReloadRequestPage: (value: boolean) => void;
}

const RequestActionsModal: React.FC<RequestActionsModalProps> = ({
	imageBankAction,
	handleOpenCarousel,
	// request_id,
	loggedUser,
	handleUpdateRequest,
	// bqcCheck,
	// content_keys,
	fetchRequestKey,
	// requestLabel,
	// requestFor,
	request,
	setRequestFlowModal,
	setReloadRequestPage
}) => {
	const request_id = request?.awi_id;
	const bqcCheck = request.awi_approved;
	const requestLabel = request.awi_label;
	const requestFor = request.awi_request_for;
	const addDataToAttributeRequest =
		request.awi_request_for === 'outline' && request.awi_derived_from?.awi_parent_req_id && !request.awi_data_transferred_to_attribute_request;
	const queryClient = useQueryClient();
	const [openRequestActionModal, setOpenRequestModal] = useState<boolean>(false);
	const [openUpdateRequestModal, setOpenUpdateRequestModal] = useState<boolean>(false);
	const [openAddUsersModal, setOpenAddUsersModal] = useState<boolean>(false);
	const [openClassProportionModal, setOpenClassProportionModal] = useState<boolean>(false);

	const [fetchSampleImagesKey, fetchSampleImagesFunction] = fetchSample(request_id);

	const { refetch } = useQuery(fetchSampleImagesKey, fetchSampleImagesFunction, {
		enabled: false
	});

	const approveBQCMutation = useMutation(approveBQC);
	const addDataToAttributesRequestMutation = useMutation(addDataToAttributesRequest);
	const updateRequestMutation = useMutation(updateRequest, {
		onSuccess: () => {
			handleCloseModal();
			toast.promise(queryClient.invalidateQueries(fetchRequestKey), {
				loading: 'Updating Request',
				success: () => 'Request Updated',
				error: () => 'Error Updating Request'
			});
		}
	});

	const handleCloseModal = () => {
		setOpenRequestModal(false);
	};

	const handleCloseAddUserModal = () => {
		setOpenAddUsersModal(false);
	};

	const handleCloseClassProportionModal = () => {
		setOpenClassProportionModal(false);
	};

	const handleApproveBQC = (request_id: number) => {
		approveBQCMutation.mutate(request_id, {
			onSuccess: res => {
				handleCloseModal();
				toast.success(res.message);
			}
		});
	};

	const handleAddDataToAttributeRequest = () => {
		if (addDataToAttributesRequestMutation.isLoading) {
			return;
		}
		addDataToAttributesRequestMutation.mutate(
			{ awi_request_id: request_id },
			{
				onSuccess: res => {
					handleCloseModal();
					toast.success(res.message);
					setTimeout(() => {
						setReloadRequestPage(true);
					}, 1000);
				}
			}
		);
	};

	// const handleRemoveImages = () => {
	// 	removeImagesMutation.mutate(
	// 		{ awi_content_key: content_keys, awi_request_id: request_id },
	// 		{
	// 			onSuccess: res => {
	// 				toast.success(res.message);
	// 			}
	// 		}
	// 	);
	// };

	return (
		<>
			{['awi_annotator', 'awi_verifier'].includes(loggedUser.userObj.awi_level) ? (
				<Link to={`/annotation`}>
					<img
						src={`/icons/request-action/${loggedUser.userObj.awi_level === 'awi_annotator' ? 'annotate/' : 'verify/'}transparent--blue.svg`}
						alt="request actions"
						width={20}
						height={20}
						onClick={() => {
							imageBankAction(request_id);
						}}
						title={loggedUser.userObj.awi_level === 'awi_annotator' ? 'Annotate' : 'Verify'}
					/>
				</Link>
			) : (
				<>
					<img
						src="/icons/add/transparent--blue.svg"
						alt="request actions"
						width={20}
						height={20}
						onClick={() => {
							if (loggedUser.userObj.awi_level === 'awi_developer') {
								setOpenRequestModal(true);
								return;
							}
							request?.awi_splitted_into?.awi_flow_fixed === true ? setOpenRequestModal(true) : setRequestFlowModal(true);
						}}
						title="Request Actions"
					/>
					{openRequestActionModal && (
						<>
							<div onClick={handleCloseModal} className={clsx(styles.backdrop, styles.backdrop__transparent)}></div>
							<ul className={styles.request__actions__modal}>
								{loggedUser.userObj.awi_level === 'awi_developer' && (
									<>
										<li>
											<span
												onClick={() => {
													handleCloseModal();
													handleUpdateRequest(request_id);
												}}
											>
												Update Request
											</span>
										</li>
										{!bqcCheck && (
											<li>
												<span
													onClick={() => {
														handleApproveBQC(request_id);
													}}
												>
													Approve BQC
												</span>
											</li>
										)}

										<li>
											<span
												onClick={async () => {
													handleCloseModal();
													const res = await refetch();

													if ((res?.data?.awi_content_key || []).length === 0) {
														toast.error('No sample images found');
														return;
													}
													handleOpenCarousel(request_id, res?.data?.awi_content_key);
												}}
											>
												Show Samples
											</span>
										</li>

										{request?.awi_reference_images?.awi_annotation_completed === false && (request?.awi_reference_images?.awi_ids || []).length > 0 && (
											<a href={`/annotation`}>
												<li>
													<span onClick={() => imageBankAction(request_id)}>Annotate ref images</span>
												</li>
											</a>
										)}
									</>
								)}
								{['awi_annotator', 'awi_verifier', 'awi_supervisor'].includes(loggedUser.userObj.awi_level) && (
									<li>
										<a href={`/annotation`}>
											<span onClick={() => imageBankAction(request_id)}>{loggedUser.userObj.awi_level === 'awi_annotator' ? 'Annotate' : 'Verify'}</span>
										</a>
									</li>
								)}
								{['awi_manager', 'awi_supervisor'].includes(loggedUser.userObj.awi_level) && (
									<>
										{!(
											(requestFor === 'attributes' && request.awi_system_generated_outlines !== true) ||
											request.awi_data_transferred_to_attribute_request
										) && (
											<li>
												<a href="/image-bank">
													<span onClick={() => imageBankAction(request_id)}>Add Images</span>
												</a>
											</li>
										)}
										{
											<li>
												<span
													onClick={() => {
														handleCloseModal();
														setOpenAddUsersModal(true);
													}}
												>
													Add Users
												</span>
											</li>
										}
										<li>
											<span
												onClick={() => {
													handleCloseModal();
													setOpenUpdateRequestModal(true);
												}}
											>
												Update Request
											</span>
										</li>
										{loggedUser.userObj.awi_level === 'awi_manager' && request.awi_status !== 'completed' && (
											<li>
												<span
													onClick={() => {
														const formData = new FormData();
														formData.append('awi_body', JSON.stringify({ awi_status: 'completed' }));
														return updateRequestMutation.mutate({
															id: request_id,
															data: formData
														});
													}}
												>
													Complete Request
												</span>
											</li>
										)}
										{/* <li>
											<span
												onClick={() => {
													handleCloseModal();
													handleRemoveImages();
												}}
											>
												Remove Images
											</span>
										</li> */}
									</>
								)}
								<li>
									<span
										onClick={() => {
											handleCloseModal();
											setOpenClassProportionModal(true);
										}}
									>
										Class Proportions
									</span>
								</li>
								{['awi_manager'].includes(loggedUser.userObj.awi_level) && (
									<Link
										to={{
											pathname: `/pricingdetails`,
											state: { awi_request_id: request_id, awi_request_name: requestLabel }
										}}
									>
										<li>
											<span>Pricing details</span>
										</li>
									</Link>
								)}
								{['awi_manager'].includes(loggedUser.userObj.awi_level) && addDataToAttributeRequest && (
									<li onClick={handleAddDataToAttributeRequest} style={{ opacity: addDataToAttributesRequestMutation.isLoading ? 0.5 : 1 }}>
										Add data to attribute req
									</li>
								)}
							</ul>
						</>
					)}
				</>
			)}

			{openAddUsersModal && <AddUserModal requestId={request_id} handleCloseModal={handleCloseAddUserModal} />}
			{openClassProportionModal && (
				<ClassProportionModal requestId={request_id} requestLabel={requestLabel} handleCloseModal={handleCloseClassProportionModal} />
			)}
			{openUpdateRequestModal && (
				<UpdateRequest
					requestId={request_id}
					handleCloseModal={() => {
						setOpenUpdateRequestModal(false);
					}}
				/>
			)}
			{addDataToAttributesRequestMutation.isLoading && <div className={styles.loader}>Adding data to attribute request...</div>}
		</>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps, null)(RequestActionsModal));
