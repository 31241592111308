import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import styles from './styles.module.scss';

import { LoggedUser } from '../../../types';
import { Input } from '../../Common';
import Container from '../../Container';
import clsx from 'clsx';
import { QueryClient, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useMutation } from '../../../util/useMutation';
import { BillInfo, getMonthlyBill, PaymentAnnotationParametersInfo, paymentClassParametersInfo, postMonthlyBill } from '../../../handlers/session';
import { useQuery } from '../../../util/useQuery';
import { toast } from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { getRequestClasses } from '../../../handlers/class/getRequestClasses';
import { addRequestPrices } from '../../../handlers/request/addRequestPrices';
import { fetchRequestPrices } from '../../../handlers/request/fetchRequestPrices';
import { updateRequestPrices } from '../../../handlers/request/updateRequestPrices';
import { getRequestData } from '../../../handlers/request/getRequestData';

interface PricingFormProps {
	loggedUser: LoggedUser;
}

export type AnnotationType = 'rectangle' | 'polygon' | 'dot' | 'circle';

export type Filter = {
	awi_user: number[];
	awi_request: number[];
	awi_start_date: Date;
	awi_end_date: Date;
	awi_annotation_type: AnnotationType[];
};

const PricingForm: React.FC<PricingFormProps> = ({ loggedUser }) => {
	const location = useLocation();
	const addRequestPricesMutation = useMutation(addRequestPrices);
	const queryClient = useQueryClient();

	const { awi_request_id, awi_request_name } = location.state;
	const [requestDataKey, requestDataFunction] = getRequestData(awi_request_id);
	const { data: requestInfo } = useQuery(requestDataKey, requestDataFunction);

	const [paymentData, setPaymentData] = useState<paymentClassParametersInfo>(undefined);
	const [pricingId, setPricingId] = useState<number | null>(null);

	const [getClasskey, getClassFunction] = getRequestClasses({ awi_request_id: awi_request_id });
	const { data: requestClasses } = useQuery(getClasskey, getClassFunction);
	const [attributeLabels, setAttributeLabels] = useState<any>({});
	let currentClassId = -1;

	const [addingRequestPrice, setAddingRequestPrice] = useState(false);

	const [fetchRequestPricesKey, fetchRequestPricesFunction] = fetchRequestPrices({ awi_request_id: awi_request_id });
	const { data: requestPrices, refetch: refetchRequestPrices } = useQuery(fetchRequestPricesKey, fetchRequestPricesFunction);

	const handlePricingSelection = (pricing_id: number) => {
		const temp_payment_data = structuredClone(requestPrices?.result?.find(ele => ele.awi_id === pricing_id));
		if (temp_payment_data) {
			temp_payment_data.awi_prices.sort((a, b) => {
				return a.awi_class_id - b.awi_class_id;
			});
			setPaymentData([...temp_payment_data.awi_prices]);
			setPricingId(pricing_id);
		}
	};

	useEffect(() => {
		currentClassId = -1;
	}, [paymentData]);

	const handleAddRequestPricingButtonClick = () => {
		setAddingRequestPrice(true);
		if ((requestPrices?.result || []).length > 0) {
			const temp_payment_data = structuredClone(requestPrices?.result?.find(ele => ele.awi_active === true));
			if (temp_payment_data) {
				temp_payment_data.awi_prices.sort((a, b) => {
					return a.awi_class_id - b.awi_class_id;
				});
				setPaymentData([...temp_payment_data.awi_prices]);
				setPricingId(temp_payment_data.awi_id);
				toast.success("You're editing the active pricings");
			}
		} else {
			handleResetRequestPricing();
		}
	};

	const handleResetRequestPricing = () => {
		const tempPaymentData: any[] = [];
		requestClasses.forEach(item => {
			if (requestInfo?.awi_request_for !== 'attributes') {
				tempPaymentData.push({
					awi_class_id: item.awi_id,
					awi_attribute_id: 'outline',
					awi_approved_cost: 0,
					awi_corrected_cost: 0,
					awi_rejected_cost: 0,
					awi_pending_cost: 0,
					awi_verified_cost: 0,
					awi_created_cost: 0
				});
			}

			if (requestInfo?.awi_request_for !== 'outline') {
				item.awi_attributes.forEach(attr => {
					tempPaymentData.push({
						awi_class_id: item.awi_id,
						awi_attribute_id: attr.awi_id,
						awi_approved_cost: 0,
						awi_corrected_cost: 0,
						awi_rejected_cost: 0,
						awi_pending_cost: 0,
						awi_verified_cost: 0,
						awi_created_cost: 0
					});
				});
			}
		});

		setPaymentData([...tempPaymentData]);
	};

	const setActiveRequestPricing = () => {
		if ((requestPrices?.result || []).length > 0) {
			const temp_payment_data = structuredClone(requestPrices?.result?.find(ele => ele.awi_active === true));
			if (temp_payment_data) {
				temp_payment_data.awi_prices.sort((a, b) => {
					return a.awi_class_id - b.awi_class_id;
				});

				setPaymentData([...temp_payment_data.awi_prices]);
				setPricingId(temp_payment_data.awi_id);
			}
		}
	};

	const handleInputChange = (value: number, classId: number, attributeId: number | string, keyName: string) => {
		// if (value < 0) {
		// 	toast.error('Negative value cannot be added');
		// 	return;
		// }
		const temp_payment_data = paymentData ? [...paymentData] : [];
		const index = temp_payment_data.findIndex((ele: any) => ele.awi_class_id === classId && ele.awi_attribute_id === attributeId);

		temp_payment_data[index][keyName] = value.toString();

		setPaymentData([...temp_payment_data]);
	};

	useEffect(() => {
		setActiveRequestPricing();
	}, [requestPrices]);

	useEffect(() => {
		const temp_obj = {};
		if ((requestClasses || []).length > 0) {
			requestClasses.forEach(item => {
				temp_obj[item.awi_id] = item.awi_label;
				temp_obj[`${item.awi_id}_outline`] = 'Outline';
				item.awi_attributes.forEach(attr => {
					temp_obj[`${item.awi_id}_${attr.awi_id}`] = attr.awi_label;
				});
			});
		}
		setAttributeLabels(temp_obj);
	}, [requestClasses]);
	console.log(paymentData, 'jksdhfv');

	const handleBackButtonClick = () => {
		if (addingRequestPrice) {
			setActiveRequestPricing();
			setAddingRequestPrice(false);
		} else {
			window.location.href = '/requests';
		}
	};

	const handleAddRequestPricings = (event: React.FormEvent) => {
		event.preventDefault();

		addRequestPricesMutation.mutate(
			{
				awi_prices: paymentData,
				awi_request_id: awi_request_id
			},
			{
				onSuccess: response => {
					if (response.success === true) {
						toast.success('Payment Details Added Successfully');
						setAddingRequestPrice(false);
						refetchRequestPrices();
					} else {
						toast.error('Error in Adding Payment Details');
					}
				}
			}
		);

		queryClient.invalidateQueries(fetchRequestPricesKey);
	};

	return (
		<Container>
			<div>
				<div>
					<div className={styles.pricing__form__request__name}>Request Name : {awi_request_name || ''}</div>
					<div className={styles.pricing__form__container}>
						<div className={styles.pricing__form__container__header}>
							<div className={styles.pricing__form__container__header__left}>
								<img style={{ rotate: '90deg' }} src="/icons/drop-down/transparent--black.svg" onClick={handleBackButtonClick} title="Back"></img>
								{addingRequestPrice && (
									<div onClick={handleResetRequestPricing} style={{ color: '#55a8fd', textDecoration: 'underline' }} title="Reset prices">
										Reset
									</div>
								)}
								{/* <button onClick={() => setActiveRequestPricing()}>Use Active Pricings</button> */}
							</div>
							<div className={styles.pricing__form__heading}>Pricing Chart</div>
							{addingRequestPrice ? (
								<div></div>
							) : (
								<div className={styles.pricing__form__container__header__right}>
									<Input
										searchString={`${
											requestPrices?.result?.findIndex(ele => ele.awi_id === pricingId) !== -1
												? `Pricing_${requestPrices?.result?.findIndex(ele => ele.awi_id === pricingId) + 1}`
												: ''
										}`}
										placeholder="Select Pricing"
										data={(requestPrices?.result || []).map((item, index) => ({
											awi_id: item.awi_id,
											awi_label: `Pricing_${index + 1} ${item.awi_active ? '*' : ''}`
										}))}
										onClick={(id: number) => handlePricingSelection(id)}
									/>
									<img onClick={handleAddRequestPricingButtonClick} src="/icons/request/addIcon.svg" title="Add Pricing Structure"></img>
								</div>
							)}
						</div>

						{paymentData ? (
							<>
								<div className={clsx(styles.pricing__form__class__grid__header)} style={{ borderBottom: '1px solid #D2D2D2', paddingBottom: '10px' }}>
									<span>Class </span>
									<span>Attribute </span>
									<span>Pending </span>
									<span>Approved </span>
									<span>Rejected </span>
									<span>Corrected </span>
									<span>Verified </span>
									<span>Created </span>
								</div>
								<div className={styles.pricing__form__class__grid__container}>
									<form>
										{paymentData?.map((item, index) => {
											let display = false;
											if (item.awi_class_id !== currentClassId) {
												display = true;
												currentClassId = item.awi_class_id;
											}

											return (
												<>
													<div className={styles.pricing__form__class__grid__row}>
														{/* <label>{item.awi_attribute_id === 'outline' ? attributeLabels[item.awi_class_id] : ''}</label> */}
														<label>{display ? attributeLabels[item.awi_class_id] : ''}</label>
														<label>{attributeLabels[`${item.awi_class_id}_${item.awi_attribute_id}`]}</label>

														<input
															type={'number'}
															value={item.awi_pending_cost}
															placeholder="Enter Amount"
															step={'any'}
															required
															name="awi_pending_cost"
															style={{ color: addingRequestPrice ? 'gray' : 'black', borderBottom: addingRequestPrice ? '' : 'none' }}
															disabled={!addingRequestPrice}
															onChange={event => handleInputChange(+event.target.value, item.awi_class_id, item.awi_attribute_id, 'awi_pending_cost')}
														></input>
														<input
															type={'number'}
															value={item.awi_approved_cost}
															placeholder="Enter Amount"
															step={'any'}
															required
															name="awi_approved_cost"
															style={{ color: addingRequestPrice ? 'gray' : 'black', borderBottom: addingRequestPrice ? '' : 'none' }}
															disabled={!addingRequestPrice}
															onChange={event => handleInputChange(+event.target.value, item.awi_class_id, item.awi_attribute_id, 'awi_approved_cost')}
														></input>
														<input
															type={'number'}
															value={item.awi_rejected_cost}
															placeholder="Enter Amount"
															step={'any'}
															required
															name="awi_rejected_cost"
															style={{ color: addingRequestPrice ? 'gray' : 'black', borderBottom: addingRequestPrice ? '' : 'none' }}
															disabled={!addingRequestPrice}
															onChange={event => handleInputChange(+event.target.value, item.awi_class_id, item.awi_attribute_id, 'awi_rejected_cost')}
														></input>
														<input
															type={'number'}
															value={item.awi_corrected_cost}
															placeholder="Enter Amount"
															step={'any'}
															required
															name="awi_corrected_cost"
															style={{ color: addingRequestPrice ? 'gray' : 'black', borderBottom: addingRequestPrice ? '' : 'none' }}
															disabled={!addingRequestPrice}
															onChange={event => handleInputChange(+event.target.value, item.awi_class_id, item.awi_attribute_id, 'awi_corrected_cost')}
														></input>
														<input
															type={'number'}
															value={item.awi_verified_cost}
															placeholder="Enter Amount"
															step={'any'}
															required
															style={{ color: addingRequestPrice ? 'gray' : 'black', borderBottom: addingRequestPrice ? '' : 'none' }}
															name="awi_verified_cost"
															disabled={!addingRequestPrice}
															onChange={event => handleInputChange(+event.target.value, item.awi_class_id, item.awi_attribute_id, 'awi_verified_cost')}
														></input>
														<input
															type={'number'}
															value={item.awi_created_cost}
															placeholder="Enter Amount"
															step={'any'}
															style={{ color: addingRequestPrice ? 'gray' : 'black', borderBottom: addingRequestPrice ? '' : 'none' }}
															required
															name="awi_verified_cost"
															disabled={!addingRequestPrice}
															onChange={event => handleInputChange(+event.target.value, item.awi_class_id, item.awi_attribute_id, 'awi_created_cost')}
														></input>
													</div>
												</>
											);
										})}
										{/* {requestClasses &&
									(requestClasses || []).map((item, index) => {
										return (
											<>
												<div>
													<div className={styles.pricing__form__class__grid}>
														<div className={styles.pricing__form__class__grid__row}>
															<label style={{ color: '#55a8fd' }}>{item.awi_label}</label>
															<label>{'-'}</label>

															<input
																type={'number'}
																value={paymentData?.awi_class_based_cost?.[index]?.awi_pending_cost}
																onChange={event => handleClassAnnotationInputChange(event, index, paymentData?.awi_class_based_cost?.[index].awi_class_id)}
																placeholder="Enter Amount"
																step={'any'}
																required
																name="awi_pending_cost"
															></input>
															<input
																type={'number'}
																value={paymentData?.awi_class_based_cost?.[index]?.awi_approved_cost}
																onChange={event => handleClassAnnotationInputChange(event, index, paymentData?.awi_class_based_cost?.[index].awi_class_id)}
																placeholder="Enter Amount"
																step={'any'}
																required
																name="awi_approved_cost"
															></input>
															<input
																type={'number'}
																value={paymentData?.awi_class_based_cost?.[index]?.awi_rejected_cost}
																onChange={event => handleClassAnnotationInputChange(event, index, paymentData?.awi_class_based_cost?.[index].awi_class_id)}
																placeholder="Enter Amount"
																step={'any'}
																required
																name="awi_rejected_cost"
															></input>
															<input
																type={'number'}
																value={paymentData?.awi_class_based_cost?.[index]?.awi_corrected_cost}
																onChange={event => handleClassAnnotationInputChange(event, index, paymentData?.awi_class_based_cost?.[index].awi_class_id)}
																placeholder="Enter Amount"
																step={'any'}
																required
																name="awi_corrected_cost"
															></input>
															<input
																type={'number'}
																value={paymentData?.awi_class_based_cost?.[index]?.awi_verified_cost}
																onChange={event => handleClassAnnotationInputChange(event, index, paymentData?.awi_class_based_cost?.[index].awi_class_id)}
																placeholder="Enter Amount"
																step={'any'}
																required
																name="awi_verified_cost"
															></input>
														</div>
														{(item.awi_attributes || []).map((attribute, attributeIndex) => {
															return (
																<div key={attributeIndex} className={styles.pricing__form__class__grid__row}>
																	<label></label>
																	<label>{attribute.awi_label}</label>

																	<input
																		type={'number'}
																		value={paymentData?.awi_class_based_cost?.[index]?.awi_attributes?.[attributeIndex]?.awi_pending_cost}
																		onChange={event => handleClassInputChange(event, index, attributeIndex)}
																		placeholder="Enter Amount"
																		step={'any'}
																		required
																		name="awi_pending_cost"
																	></input>
																	<input
																		type={'number'}
																		value={paymentData?.awi_class_based_cost?.[index]?.awi_attributes?.[attributeIndex]?.awi_approved_cost}
																		onChange={event => handleClassInputChange(event, index, attributeIndex)}
																		placeholder="Enter Amount"
																		step={'any'}
																		required
																		name="awi_approved_cost"
																	></input>
																	<input
																		type={'number'}
																		value={paymentData?.awi_class_based_cost?.[index]?.awi_attributes?.[attributeIndex]?.awi_rejected_cost}
																		onChange={event => handleClassInputChange(event, index, attributeIndex)}
																		placeholder="Enter Amount"
																		step={'any'}
																		required
																		name="awi_rejected_cost"
																	></input>
																	<input
																		type={'number'}
																		value={paymentData?.awi_class_based_cost?.[index]?.awi_attributes?.[attributeIndex]?.awi_corrected_cost}
																		onChange={event => handleClassInputChange(event, index, attributeIndex)}
																		placeholder="Enter Amount"
																		step={'any'}
																		required
																		name="awi_corrected_cost"
																	></input>
																	<input
																		type={'number'}
																		value={paymentData?.awi_class_based_cost?.[index]?.awi_attributes?.[attributeIndex]?.awi_verified_cost}
																		onChange={event => handleClassInputChange(event, index, attributeIndex)}
																		placeholder="Enter Amount"
																		step={'any'}
																		required
																		name="awi_verified_cost"
																	></input>
																</div>
															);
														})}
													</div>
												</div>
											</>
										);
									})} */}
									</form>
								</div>
								{addingRequestPrice && (
									<div className={styles.pricing__form__submitcontainer}>
										<button type="submit" onClick={handleAddRequestPricings} className={styles.pricing__form__submitcontainer__button}>
											Submit
										</button>
									</div>
								)}
							</>
						) : (
							<div>No Pricings has been created so far! </div>
						)}
					</div>
				</div>
			</div>
		</Container>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps, null)(PricingForm));
