import clsx from 'clsx';
import ReactDatePicker from 'react-datepicker';
import { setHours, setMinutes } from 'date-fns';
import styles from './styles.module.scss';
import 'react-datepicker/dist/react-datepicker.css';

interface DatePickerProps {
	value: Date | null;
	onChange: (date: Date) => void;
	dateFormat: string;
	isTimePicker?: boolean;
	disabled?: boolean;
	className?: string;
	maxDate?: Date;
	showMonthYear?: boolean;
	minDate?: Date;
	minTime?: Date;
	showMonthYearPicker?: boolean;
}

const DatePicker: React.FC<DatePickerProps> = ({
	value,
	onChange,
	dateFormat,
	isTimePicker = false,
	disabled = false,
	className = '',
	maxDate,
	showMonthYear = false,
	minDate,
	minTime,
	showMonthYearPicker = false
}) => {
	return (
		<div>
			<ReactDatePicker
				selected={value}
				onChange={onChange}
				className={clsx(styles.datepicker, className)}
				showTimeSelect={isTimePicker}
				timeIntervals={15}
				timeCaption="Time"
				disabled={disabled}
				dateFormat={dateFormat}
				maxDate={maxDate}
				peekNextMonth={showMonthYear}
				showMonthDropdown={showMonthYear}
				showYearDropdown={showMonthYear}
				showMonthYearPicker={showMonthYearPicker}
				dropdownMode="select"
				minDate={minDate}
				minTime={minTime}
				maxTime={minTime && setHours(setMinutes(new Date(), 45), 23)}
			/>
		</div>
	);
};

export default DatePicker;
