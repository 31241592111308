import clsx from 'clsx';
import { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getRequestData } from '../../../handlers/request/getRequestData';

import { LoggedUser } from '../../../types';
import { useQuery } from '../../../util/useQuery';

import styles from './styles.module.scss';
import { Carousel } from '../../Common';

interface AnnotationToolProps {
	handleSelectTool: (tool: 'edit' | 'annotate') => void;
	isEditing: boolean;
	loggedUser: LoggedUser;
	annotator_name: string;
}

const AnnotationTools: React.FC<AnnotationToolProps> = ({ isEditing, handleSelectTool, loggedUser, annotator_name }) => {
	const { data: request } = useQuery(...getRequestData(+JSON.parse(localStorage.getItem('request')!).awi_id));
	const { data: parentReqData } = useQuery(...getRequestData(+request?.awi_derived_from.awi_parent_req_id));

	const [openRequestDetails, setOpenRequestDetails] = useState<boolean>(false);

	const [openReferenceImageCarousel, setOpenReferenceImageCarousel] = useState<boolean>(false);

	const handleRefernceImageClick = () => {
		setOpenRequestDetails(false);
		setOpenReferenceImageCarousel(true);
	};

	return (
		<>
			<div className={styles.annotation__tool__icons}>
				<div>
					<div className={clsx({ [styles.annotation__tool__icons__selected]: isEditing })} onClick={() => handleSelectTool('edit')} title="edit">
						<img src="/icons/edit/transparent--black.svg" alt="Edit icon" />
					</div>
					{/* {loggedUser.userObj.awi_level === 'awi_annotator' && ( */}
					<div
						className={clsx({
							[styles.annotation__tool__icons__selected]: !isEditing,
							[styles.annotation__tool__icons__disabled]: true
						})}
						onClick={() => handleSelectTool('annotate')}
						title={true ? 'disabled' : 'create'}
					>
						<img src="/icons/annotation/transparent--black-rectangle.svg" alt="annotation tool" />
					</div>
					{/* )} */}
					{/* <div>
					<img src="/icons/rewind/transparent--black.svg" alt="undo" />
				</div>
				<div>
					<img style={{ transform: 'scaleX(-1)' }} src="/icons/rewind/transparent--black.svg" alt="redo" />
				</div>
				<div>
					<img src="/icons/reset/transparent--black.svg" alt="Reset annotations" />
				</div> */}
				</div>
				{/* <div>
				<img src="/icons/add/transparent--black.svg" alt="zoom in" />
				<img src="/icons/remove/transparent--black.svg" alt="zoom out" />
				<img src="/icons/hand/transparent--black.svg" alt="pan icon" />
				<img src="/icons/comment/transparent--black.svg" alt="comment annotation" />
			</div> */}
				<div className={styles.request__info}>
					<div className={styles.request__info__label} onClick={() => setOpenRequestDetails(prev => !prev)}>
						{request?.awi_label}
						<img src="/icons/drop-down/transparent--black.svg" style={{ width: '14px', transform: `rotate(${openRequestDetails ? 180 : 0}deg)` }} />
					</div>

					{openRequestDetails && (
						<div className={styles.request__info__modal}>
							<span>
								<span style={{ color: 'rgb(85, 168, 253)' }}>Request Desription</span> : {request?.awi_description}
							</span>
							<button className={styles.request__info__modal__refbutton} onClick={handleRefernceImageClick}>
								Open Reference Images
							</button>
						</div>
					)}
					{openRequestDetails && <div className={clsx(styles.backdrop)} onClick={() => setOpenRequestDetails(false)}></div>}
				</div>
				{loggedUser.userObj.awi_level === 'awi_verifier' && (
					<div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
						<span style={{ color: '#55a8fd' }}>Annotator Name :</span> {annotator_name}
					</div>
				)}
			</div>
			{openReferenceImageCarousel && (
				<>
					<Carousel
						handleToggleCarousel={() => setOpenReferenceImageCarousel(false)}
						requestName={request?.awi_label || ''}
						showAnnotationType={'all'}
						requestId={parentReqData?.awi_id || request?.awi_id}
						imageKeys={parentReqData?.awi_reference_images?.awi_ids || request?.awi_reference_images?.awi_ids || []}
						awi_reference_image={true}
					/>
				</>
			)}
			{openReferenceImageCarousel && (
				<div className={clsx(styles.backdrop, styles.backdrop__transparent)} onClick={() => setOpenReferenceImageCarousel(false)}></div>
			)}
		</>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps, null)(AnnotationTools));
