import { client } from '../../util/client';

type updateData = {
	awi_deadline?: Date;
	awi_class_id?: number[];
	awi_label?: string;
	awi_description?: string;
	awi_min_images?: number;
	awi_status?: 'completed';
	awi_guidelines?: string[];
};

export const updateRequest = ({ id, data }: { id: number; data: FormData }) =>
	client(`/update_request/${id}`, {
		baseURL: 'request',
		method: 'PATCH',
		data: data
	});
