import { Tab } from '@headlessui/react';
import styles from './styles.module.scss';
import Container from '../../Container';
import clsx from 'clsx';
import { useQuery } from '../../../util/useQuery';
import { useRef, useState } from 'react';
import { getFormattedDate, getFullName } from '../../../util/common.utils';
import MonthBillingModal from './MonthBillingModal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { LoggedUser } from '../../../types';
import FilterModal from './FilterModal';
import { useQueryClient } from 'react-query';
import TableInfiniteScroll from '../../Common/TableInfiniteScroll';
import TableHeaders from '../../Common/TableInfiniteScroll/TableHeaders';
import TableHeader from '../../Common/TableInfiniteScroll/TableHeader';
import TableBody from '../../Common/TableInfiniteScroll/TableBody';
import TableRow from '../../Common/TableInfiniteScroll/TableRow';
import TableCell from '../../Common/TableInfiniteScroll/TableCell';
import { fetchUserwiseGeneratedBills } from '../../../handlers/billing/fetchUserwiseGeneratedBill';
import { useMutation } from '../../../util/useMutation';
import { payBill } from '../../../handlers/billing/payBill';
import { toast } from 'react-hot-toast';

interface BillingProps {
	loggedUser: LoggedUser;
}

export type AnnotationType = 'rectangle' | 'polygon' | 'dot' | 'circle';

export type Filter = {
	awi_user: number[];
	awi_request: number[];
	awi_start_date: Date;
	awi_end_date: Date;
	awi_annotation_type: AnnotationType[];
	awi_user_type: String;
};

const BillingPage: React.FC<BillingProps> = ({ loggedUser }) => {
	const initialFilterData: Filter = {
		awi_user: ['awi_annotator', 'awi_verifier'].includes(loggedUser.userObj.awi_level) ? [loggedUser.userObj.awi_id] : [],
		awi_request: [],
		awi_start_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
		awi_end_date: new Date(),
		awi_annotation_type: [],
		awi_user_type: 'awi_annotator'
	};
	const queryClient = useQueryClient();

	const [openBillModal, setOpenBillModal] = useState<boolean>(false);
	const [openFilter, setFilterOpen] = useState<boolean>(false);

	const [openBillPayModal, setOpenBillPayModal] = useState<boolean>(false);
	const [selectedBill, setSelectedBill] = useState<any>(null);

	const payBillMutation = useMutation(payBill);

	const [filter, setFilter] = useState<Filter>({
		awi_user: ['awi_annotator', 'awi_verifier'].includes(loggedUser.userObj.awi_level) ? [loggedUser.userObj.awi_id] : [],
		awi_request: [],
		awi_start_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
		awi_end_date: new Date(),
		awi_annotation_type: [],
		awi_user_type: 'awi_annotator'
	});

	const filterRef = useRef(initialFilterData);

	const [fetchBillsKey, fetchBillsFunction] = fetchUserwiseGeneratedBills(filterRef.current);
	const { data: billData, refetch: refetchBillData } = useQuery(fetchBillsKey, fetchBillsFunction);

	const handleFilterChange = (id: number | Date | AnnotationType, name: string) => {
		if (name === 'awi_start_date' || name === 'awi_end_date') {
			setFilter(prev => ({ ...prev, [name]: id as Date }));
		} else if (name === 'awi_annotation_type') {
			if (filter[name].includes(id as AnnotationType)) {
				setFilter(prev => ({ ...prev, [name]: prev[name].filter((item: AnnotationType) => item !== id) }));
			} else {
				setFilter(prev => ({ ...prev, [name]: [...prev[name], id as AnnotationType] }));
			}
		} else if (filter[name].includes(id)) {
			setFilter(prev => ({
				...prev,
				[name]: prev[name].filter(item => item !== id)
			}));
		} else {
			setFilter(prev => ({
				...prev,
				[name]: [...prev[name], id]
			}));
		}
	};

	const handleFilterApply = () => {
		filterRef.current = filter;
		refetchBillData();
		setFilterOpen(false);
	};

	const handleFilterReset = () => {
		setFilter(initialFilterData);
		filterRef.current = initialFilterData;
		refetchBillData();
		setFilterOpen(false);
	};

	const handleToggleBillModal = () => {
		setOpenBillModal(prev => !prev);
	};

	const handleToggleFilterModal = () => {
		setFilterOpen(prev => !prev);
	};

	const handleTabChange = (index: number) => {
		let level = '';
		if (index === 0) {
			level = 'awi_annotator';
		} else if (index === 1) {
			level = 'awi_verifier';
		}
		if (level !== filter.awi_user_type) {
			setFilter(prev => ({ ...prev, awi_user_type: level }));
		}
		filterRef.current = { ...filterRef.current, awi_user_type: level };
		refetchBillData();
	};

	const handleCloseBillPayModal = () => {
		setOpenBillPayModal(prev => !prev);
		setSelectedBill(null);
	};

	const handlePayButtonClick = bill => {
		setSelectedBill(bill);
		setOpenBillPayModal(prev => !prev);
	};

	const handlePayBill = bill => {
		payBillMutation.mutate(selectedBill, {
			onSuccess: () => {
				toast.success('Bill paid successfully');
				handleCloseBillPayModal();
				queryClient.invalidateQueries(fetchBillsKey);
			},
			onError: error => {
				console.log(error);
				toast.error('Error in paying bill');
			}
		});
	};

	return (
		<Container className={styles.rootContainer}>
			<Tab.Group onChange={index => handleTabChange(index)}>
				<div className={styles.tabs__list__container}>
					<Tab.List className={styles.tabs__list}>
						{({ selectedIndex }) => (
							<>
								{loggedUser.userObj.awi_level !== 'awi_verifier' && (
									<Tab
										className={clsx(styles.tabs__list__item, {
											[styles.tabs__list__item__selected]: filter.awi_user_type === 'awi_annotator'
										})}
									>
										Annotators
									</Tab>
								)}
								{loggedUser.userObj.awi_level !== 'awi_annotator' && (
									<Tab
										className={clsx(styles.tabs__list__item, {
											[styles.tabs__list__item__selected]: filter.awi_user_type === 'awi_verifier'
										})}
									>
										Verifiers
									</Tab>
								)}
							</>
						)}
					</Tab.List>
					<div className={styles.tabs__list__container__right}>
						<div style={{ position: 'relative' }}>
							<div
								className={styles.icons}
								onClick={event => {
									event.stopPropagation();
									handleToggleFilterModal();
								}}
							>
								<img src="/icons/filter/filter.svg" alt="filter icons" width="100%" height="100%" title="Filters" />
							</div>
							{openFilter && (
								<FilterModal
									handleFilterApply={handleFilterApply}
									handleFilterReset={handleFilterReset}
									filter={filter}
									handleFilterChange={handleFilterChange}
									handleToggleFilterModal={handleToggleFilterModal}
								/>
							)}
						</div>
						{/* <div className={styles.icons} onClick={handleToggleBillModal}>
							<img src="/icons/billing/billing.svg" alt="billing icons" width="100%" height="100%" />
						</div> */}
						{/* <a href="/transactions">
							<div className={styles.icons}>
								<img src="/icons/download/transparent--black.svg" alt="filter icons" width="100%" height="100%" />
							</div>
						</a> */}
					</div>
				</div>
				<Tab.Panels>
					<Tab.Panel>
						<TableInfiniteScroll>
							<TableHeaders>
								<TableHeader>S.NO</TableHeader>
								<TableHeader>NAME</TableHeader>
								<TableHeader>REQUEST</TableHeader>
								<TableHeader>TOTAL AMOUNT</TableHeader>
								<TableHeader>PAID AMOUNT</TableHeader>
								<TableHeader>PENDING AMOUNT</TableHeader>
								<TableHeader>{''}</TableHeader>
							</TableHeaders>
							<TableBody>
								{(billData?.result || []).map((bill, index) => {
									return (
										<TableRow key={index}>
											<TableCell>{index + 1}</TableCell>
											<TableCell>{getFullName(bill.awi_user)}</TableCell>
											<TableCell>{bill.awi_request.awi_label}</TableCell>
											<TableCell>{bill.awi_total_amount.toFixed(2)}</TableCell>
											<TableCell>{bill.awi_paid_amount.toFixed(2)}</TableCell>
											<TableCell>{(bill.awi_total_amount - bill.awi_paid_amount).toFixed(2)}</TableCell>
											<TableCell>
												<button
													disabled={parseInt((bill.awi_total_amount - bill.awi_paid_amount).toFixed(2)) === 0}
													onClick={() => handlePayButtonClick(bill)}
													className={clsx(styles.rootContainer__paybutton, {
														[styles.rootContainer__paybutton__disabled]: parseInt((bill.awi_total_amount - bill.awi_paid_amount).toFixed(2)) === 0
													})}
												>
													Pay
												</button>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</TableInfiniteScroll>
					</Tab.Panel>
					<Tab.Panel>
						<TableInfiniteScroll>
							<TableHeaders>
								<TableHeader>S.NO</TableHeader>
								<TableHeader>NAME</TableHeader>
								<TableHeader>REQUEST</TableHeader>
								<TableHeader>TOTAL AMOUNT</TableHeader>
								<TableHeader>PAID AMOUNT</TableHeader>
								<TableHeader>PENDING AMOUNT</TableHeader>
								<TableHeader>{''}</TableHeader>
							</TableHeaders>
							<TableBody>
								{(billData?.result || []).map((bill, index) => {
									return (
										<TableRow key={index}>
											<TableCell>{index + 1}</TableCell>
											<TableCell>{getFullName(bill.awi_user)}</TableCell>
											<TableCell>{bill.awi_request.awi_label}</TableCell>
											<TableCell>{bill.awi_total_amount.toFixed(2)}</TableCell>
											<TableCell>{bill.awi_paid_amount.toFixed(2)}</TableCell>
											<TableCell>{(bill.awi_total_amount - bill.awi_paid_amount).toFixed(2)}</TableCell>
											<TableCell>
												<button
													disabled={parseInt((bill.awi_total_amount - bill.awi_paid_amount).toFixed(2)) === 0}
													onClick={() => handlePayButtonClick(bill)}
													className={clsx(styles.rootContainer__paybutton, {
														[styles.rootContainer__paybutton__disabled]: parseInt((bill.awi_total_amount - bill.awi_paid_amount).toFixed(2)) === 0
													})}
													type="button"
													title="Pay Pending Amount"
												>
													Pay
												</button>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</TableInfiniteScroll>
					</Tab.Panel>
				</Tab.Panels>
			</Tab.Group>
			{openBillModal && <MonthBillingModal handleToggleBillModal={handleToggleBillModal} />}
			{openBillPayModal && (
				<div className={styles.modal}>
					<div>
						Are you sure you want to pay Rs.{(selectedBill?.awi_total_amount - selectedBill?.awi_paid_amount).toFixed(2)} to{' '}
						{getFullName(selectedBill?.awi_user)} for work done in {selectedBill?.awi_request?.awi_label}?
					</div>

					<div className={styles.billpaymodal__buttons}>
						<button onClick={handlePayBill}>Pay</button>
						<button onClick={handleCloseBillPayModal}>Cancel</button>
					</div>
				</div>
			)}
			{openBillPayModal && <div className={styles.backdrop} onClick={handleCloseBillPayModal}></div>}
		</Container>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps, null)(BillingPage));
