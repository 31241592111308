import GeneralReducer from './GeneralReducer';
import SettingsReducer from './SettingsReducer';
import UserReducer from './UserReducer';
import RequestReducer from './RequestReducer';
import { combineReducers } from 'redux';

const RootReducer = combineReducers({
	UserReducer,
	GeneralReducer,
	SettingsReducer,
	RequestReducer
});

export default RootReducer;
