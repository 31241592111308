import { client } from './../../util/client';
export const deleteAnnotation = ({
	awiAnnotationIds,
	awiSessionId,
	awiContentKey
}: {
	awiAnnotationIds: number[];
	awiSessionId: number;
	awiContentKey: number | null;
}) =>
	client(`/delete_annotations`, {
		baseURL: 'annotation',
		method: 'DELETE',
		data: { awiAnnotationIds, awiSessionId, awiContentKey }
	});
