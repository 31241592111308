import clsx from 'clsx';
import { fetchFilters } from '../../../handlers/misc';
import { useQuery } from '../../../util/useQuery';
import DatePicker from '../../Common/DatePicker';
import Input from '../../Common/Input';
import styles from './styles.module.scss';

import { Filter } from './index';

interface FilterModalProps {
	handleToggleFilterModal: () => void;
	handleFilterChange: (id: number | Date, name: string) => void;
	filter: Filter;
	handleFilterApply: () => void;
	handleFilterReset: () => void;
}

const FilterModal: React.FC<FilterModalProps> = ({ handleToggleFilterModal, filter, handleFilterChange, handleFilterApply, handleFilterReset }) => {
	const [fetchFiltersKey, fetchFiltersFunction] = fetchFilters();
	const { data: filters } = useQuery(fetchFiltersKey, fetchFiltersFunction);

	if (!filters) return null;

	return (
		<>
			<div className={clsx(styles.backdrop__transparent, styles.backdrop)} onClick={handleToggleFilterModal}></div>
			<div className={styles.filters}>
				<p className={styles.filters__heading}>Filters</p>
				{/* <form
					className={styles.filters__grid__container}
					onSubmit={event => {
						event.preventDefault();
						handleToggleFilterModal();
					}}
				> */}
				<div className={styles.filters__grid__elements}>
					<label htmlFor="awi_user">User</label>
					<Input
						searchString={filters.result.users
							.filter(user => {
								return filter.awi_user.includes(user.awi_id);
							})
							.map(user => user.awi_label)}
						onClick={handleFilterChange}
						name="awi_user"
						placeholder="Enter Name"
						data={filters.result.users}
					/>
				</div>
				<div className={styles.filters__grid__elements}>
					<label htmlFor="name">Start Time</label>
					<DatePicker
						value={filter.awi_start_date}
						onChange={(date: Date) => handleFilterChange(date, 'awi_start_date')}
						dateFormat="MMMM dd, yyyy"
						maxDate={filter.awi_end_date || new Date()}
					/>
				</div>

				<div className={styles.filters__grid__elements}>
					<label htmlFor="name">End Time</label>
					<DatePicker
						value={filter.awi_end_date}
						onChange={(date: Date) => handleFilterChange(date, 'awi_end_date')}
						dateFormat="MMMM dd, yyyy"
						maxDate={new Date()}
						minDate={filter.awi_start_date || new Date(2000, 0, 0)}
					/>
				</div>

				<div className={styles.filters__grid__elements}>
					<label htmlFor="name">Request</label>
					<Input
						searchString={filters.result.requests
							.filter(request => {
								return filter.awi_request.includes(request.awi_id);
							})
							.map(request => request.awi_label)}
						onClick={handleFilterChange}
						name="awi_request"
						placeholder="Enter Request "
						data={filters.result.requests}
					/>
				</div>
				<div className={styles.filters__grid__elements}>
					<label htmlFor="name">Annotation&nbsp;Type</label>
					<Input
						placeholder="Enter Annotation Type"
						searchString={filter.awi_annotation_type.join(', ')}
						data={[
							{ awi_id: 'rectangle', awi_label: 'rectangle' },
							{ awi_id: 'polygon', awi_label: 'polygon' },
							{ awi_id: 'circle', awi_label: 'circle' },
							{ awi_id: 'dot', awi_label: 'dot' }
						]}
						onClick={handleFilterChange}
						name="awi_annotation_type"
					/>
				</div>
				<div className={styles.filters__grid__container__buttons}>
					<button className={styles.filters__grid__container__buttons__button} type="submit" onClick={handleFilterApply}>
						Apply
					</button>
					<button className={styles.filters__grid__container__buttons__button} type="reset" onClick={handleFilterReset}>
						Reset
					</button>
				</div>
				{/* </form> */}
			</div>
		</>
	);
};
export default FilterModal;
