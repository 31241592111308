import { useState, useEffect } from 'react';
import { RequestResponse, RequestFilterType, RequestStatusType } from '../../../../types';
import { AnnotationType, ShowAnnotationStatus } from '../../../../types/annotations';
import { initialFilterData } from './useConstants';

const useRequest = () => {
	const [requestSelect, setRequestSelect] = useState<number>(-1);
	const [isTimelineOpen, setIsTimelineOpen] = useState<boolean>(false);
	const [createRequestModalOpen, setCreateRequestModalOpen] = useState<boolean>(false);
	const [openCarousel, setOpenCarousel] = useState(false);
	const [isUpdateRequest, setIsUpdateRequest] = useState(false);
	const [selectedLevel, setSelectedLevel] = useState<'awi_supervisor' | 'awi_verifier' | 'awi_annotator'>('awi_annotator');

	const [showAnnotationType, setShowAnnotationType] = useState<ShowAnnotationStatus>('all');

	const [selectedImages, setSelectedImages] = useState<any[]>([]);

	const [requestIndex, setRequestIndex] = useState<number>(-1);
	const [selectedRequest, setSelectedRequest] = useState<RequestResponse | null>(null);

	const [filter, setFilters] = useState<RequestFilterType>(initialFilterData);

	const [assignedUserLevel, setAssignedUserLevel] = useState<string>('select level');

	const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
	const [requestFlowModal, setRequestFlowModal] = useState(false);

	useEffect(() => {
		return () => {
			setRequestSelect(-1);
			setIsTimelineOpen(false);
			setCreateRequestModalOpen(false);
			setOpenCarousel(false);
			setIsUpdateRequest(false);
			setSelectedLevel('awi_annotator');
			setShowAnnotationType('all');
			setSelectedImages([]);
			setRequestIndex(-1);
			setFilters(initialFilterData);
			setAssignedUserLevel('select level');
			setIsFilterOpen(false);
			setSelectedRequest(null);
		};
	}, []);

	const handleCloseRequestFlowModal = () => {
		setRequestFlowModal(false);
	};

	const handleUpdateRequestSelect = (requestId: number) => {
		setRequestSelect(requestId);
	};

	/**
	 * `handleOpenCarousel` is a function that takes in a `requestId`, an array of `imagesKey`, and an
	 * `annotationStatus` and sets the `requestSelect`, `openCarousel`, `selectedImages`, and
	 * `showAnnotationType` state variables
	 * @param {number} requestId - The id of the request that the images are associated with.
	 * @param {number[]} imagesKey - number[] - this is an array of image keys that you want to show in the
	 * carousel.
	 * @param {ShowAnnotationStatus} [annotationStatus=all] - ShowAnnotationStatus = 'all'
	 */
	const handleOpenCarousel = (requestId: number, imagesKey: number[], annotationStatus: ShowAnnotationStatus = 'all') => {
		setOpenCarousel(true);
		handleUpdateRequestSelect(requestId);
		setSelectedImages(imagesKey);
		setShowAnnotationType(annotationStatus as ShowAnnotationStatus);
	};

	/**
	 * When the user clicks the close button, the carousel will close and the requestSelect will be set to
	 * -1.
	 */
	const handleCloseCarousel = () => {
		handleUpdateRequestSelect(-1);
		setOpenCarousel(false);
	};

	/**
	 * `handleTimelineModalToggle` is a function that toggles the state of `isTimelineOpen` by setting it
	 * to the opposite of its current value.
	 */
	const handleTimelineModalToggle = () => {
		setIsTimelineOpen(prev => !prev);
	};

	/**
	 * When the user clicks the button, toggle the modal open/closed and if the user is updating a request,
	 * set the update request state to false.
	 */
	const handleCreateRequestModalOpen = () => {
		setCreateRequestModalOpen(prev => !prev);
		if (isUpdateRequest) {
			setIsUpdateRequest(false);
		}
	};

	/**
	 * If the index is the same as the current request index, set the request index to -1, otherwise set
	 * the request index to the index
	 * @param {number} [index] - The index of the request that was clicked.
	 */
	const handleRequestIndex = (index: number, request: RequestResponse) => {
		if (index === requestIndex) {
			setRequestIndex(-1);
			setSelectedRequest(null);
		} else {
			setRequestIndex(index);
			setSelectedRequest(request);
		}
	};

	/**
	 * When the user clicks on the update button, the modal opens and the requestSelect state is set to
	 * the id of the request that was clicked on.
	 * @param {number} id - number - the id of the request that is being updated
	 */
	const handleUpdateRequest = (id: number) => {
		setIsUpdateRequest(true);
		handleCreateRequestModalOpen();
		setRequestSelect(id);
	};

	/**
	 * When the date changes, set the filters state to the previous state, then set the key to the date.
	 * @param {Date} date - Date - The date that was selected
	 * @param {string} key - string - the key of the filter you want to change
	 */
	const handleDateChange = (date: Date, key: string) => {
		setFilters(prev => {
			const temp = { ...prev };
			temp[key] = date;
			return { ...temp };
		});
	};

	/**
	 * It sets the `filters` state to the `initialFilterData` constant.
	 */

	/**
	 * When the checkbox is clicked, set the value of the 'awi_deadline_crossed' key in the filters object
	 * to the opposite of what it currently is.
	 */
	const handleDeadlineCheck = () => {
		setFilters(prev => {
			const temp = { ...prev };
			temp['awi_deadline_crossed'] = !temp['awi_deadline_crossed'];
			return { ...temp };
		});
	};

	/**
	 * "If the name is awi_annotation_type, then set the filters to the previous filters, and if the id is
	 * 0, then set the type to rectangle, and if the id is 1, then set the type to circle, and if the id
	 * is 2, then set the type to polygon, and if the id is 3, then set the type to dot, and if the
	 * previous filters awi_annotation_type index of the type is -1, then push the type to the temp, and
	 * if the previous filters awi_annotation_type index of the type is not -1, then splice the temp index
	 * of the type, and return the previous filters with the awi_annotation_type set to the temp, and if
	 * the name is awi_user_assigned_level, then if the id is 0, then set the type to awi_supervisor, and
	 * if the id is 1,
	 * @param {number} id - number, name: string
	 * @param {string} name - string
	 */
	const handleUpdateFilter = (id: number, name: string) => {
		if (name === 'awi_annotation_type') {
			setFilters(prev => {
				let type = '';
				if (id === 0) {
					type = 'rectangle';
				} else if (id === 1) {
					type = 'circle';
				} else if (id === 2) {
					type = 'polygon';
				} else if (id === 3) {
					type = 'dot';
				}
				const temp = [...prev.awi_annotation_type];
				if (prev.awi_annotation_type?.indexOf(type as AnnotationType) === -1) {
					temp.push(type as AnnotationType);
				} else {
					temp.splice(temp.indexOf(type as AnnotationType), 1);
				}
				return { ...prev, awi_annotation_type: temp };
			});
			return;
		}

		if (name === 'awi_user_assigned_level') {
			let type = '';
			if (id === 0) {
				type = 'awi_supervisor';
			} else if (id === 1) {
				type = 'awi_verifier';
			} else if (id === 2) {
				type = 'awi_annotator';
			}
			setAssignedUserLevel(type);
			return;
		}

		if (name === 'awi_status') {
			setFilters(prev => {
				let type = '';
				if (id === 0) {
					type = 'pending';
				} else if (id === 1) {
					type = 'failed';
				} else if (id === 2) {
					type = 'completed';
				} else if (id === 3) {
					type = 'on-hold';
				} else if (id === 4) {
					type = 'draft';
				}
				const temp = [...prev.awi_status];
				if (prev.awi_status?.indexOf(type as RequestStatusType) === -1) {
					temp.push(type as RequestStatusType);
				} else {
					temp.splice(temp.indexOf(type as RequestStatusType), 1);
				}
				return { ...prev, awi_status: temp };
			});
			return;
		}

		if (name === 'awi_req_type') {
			setFilters(prev => {
				let type = '';
				if (id === 0) {
					type = 'detector';
				} else if (id === 1) {
					type = 'classifier';
				}
				if (prev.awi_req_type === type) {
					type = '';
				}
				return { ...prev, awi_req_type: type } as RequestFilterType;
			});
			return;
		}

		if (filter[name].includes(id)) {
			setFilters(prev => ({
				...prev,
				[name]: prev[name].filter(item => item !== id)
			}));
		} else {
			setFilters(prev => ({
				...prev,
				[name]: [...prev[name], id]
			}));
		}
	};

	/**
	 * When the user clicks the filter button, toggle the state of the filter menu.
	 */
	const toggleShowFilter = () => {
		setIsFilterOpen(!isFilterOpen);
	};

	return {
		handleOpenCarousel,
		handleCloseCarousel,
		handleTimelineModalToggle,
		handleCreateRequestModalOpen,
		handleUpdateRequest,
		handleRequestIndex,
		requestSelect,
		isTimelineOpen,
		createRequestModalOpen,
		openCarousel,
		isUpdateRequest,
		selectedLevel,
		selectedImages,
		requestIndex,
		showAnnotationType,
		setShowAnnotationType,
		setRequestSelect,
		setSelectedLevel,
		setRequestIndex,
		filter,
		setFilters,
		handleDateChange,
		handleDeadlineCheck,
		handleUpdateFilter,
		isFilterOpen,
		toggleShowFilter,
		assignedUserLevel,
		setAssignedUserLevel,
		selectedRequest,
		handleUpdateRequestSelect,
		handleCloseRequestFlowModal,
		requestFlowModal,
		setRequestFlowModal
	};
};

export default useRequest;
