import { client } from './../../util/client';

export const updateClass = ({
	awi_id,
	awi_color = '',
	awi_attributes = [],
	awi_description = '',
	awi_label = ''
}: {
	awi_id: number;
	awi_color?: string;
	awi_attributes?: number[];
	awi_label?: string;
	awi_description?: string;
}) =>
	client(`/update_class/${awi_id}`, {
		baseURL: 'class',
		method: 'PATCH',
		data: {
			awi_color,
			awi_attributes,
			awi_description,
			awi_label
		}
	}).then(res => res.data);
