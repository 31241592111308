export const getUpdatedCoordinates = (canvasRef, event, initialCoords) => {
	let { x, y } = getMousePos(canvasRef.current, event);
	const temp = initialCoords;

	x = Math.min(x, 1);
	y = Math.min(y, 1);
	const width = x - temp.x;
	const height = y - temp.y;

	let coordinates: { x: number; y: number }[] = [];

	if (width === 0 || height === 0) {
		coordinates = [
			{
				x: temp.x,
				y: temp.y
			},
			{
				x: temp.x,
				y: temp.y
			},
			{
				x: temp.x,
				y: temp.y
			},
			{
				x: temp.x,
				y: temp.y
			}
		];
	} else if (width > 0 && height > 0) {
		coordinates = [
			{
				x: temp.x,
				y: temp.y
			},
			{
				x,
				y: temp.y
			},
			{
				x,
				y
			},
			{
				x: temp.x,
				y
			}
		];
	} else if (width > 0 && height < 0) {
		coordinates = [
			{
				x: temp.x,
				y
			},
			{
				x,
				y
			},
			{
				x,
				y: temp.y
			},
			{
				x: temp.x,
				y: temp.y
			}
		];
	} else if (width < 0 && height > 0) {
		coordinates = [
			{
				x,
				y: temp.y
			},
			{
				x: temp.x,
				y: temp.y
			},
			{
				x: temp.x,
				y: y
			},
			{
				x,
				y
			}
		];
	} else {
		coordinates = [
			{
				x,
				y
			},
			{
				x: temp.x,
				y
			},
			{
				x: temp.x,
				y: temp.y
			},

			{
				x,
				y: temp.y
			}
		];
	}

	return coordinates;
};

// const scaleDownCoordinates = (coordinates: { x: number; y: number }[], width, height) => {
// 	return coordinates.map(coord => {
// 		let scaledDownX = coord.x / width;
// 		let scaledDownY = coord.y / height;
// 		if (scaledDownX > 0.999) scaledDownX = 0.999;
// 		if (scaledDownX < 0.001) scaledDownX = 0.001;
// 		if (scaledDownY > 0.999) scaledDownY = 0.999;
// 		if (scaledDownY < 0.001) scaledDownY = 0.001;

// 		return { x: scaledDownX, y: scaledDownY };
// 	});
// };

export const getMousePos = (canvas, evt) => {
	const rect = canvas.getBoundingClientRect();
	return {
		x: (evt.clientX - rect.left) / (rect.right - rect.left),
		y: (evt.clientY - rect.top) / (rect.bottom - rect.top)
	};
};
