import { getProgress } from '../../../handlers/organisation';
import { useQuery } from '../../../util/useQuery';
import Container from '../../Container';

import { useState } from 'react';
import { DatePicker, TableBody, TableCell, TableHeader, TableHeaders, TableInfiniteScroll, TableRow } from '../../Common';

const Progress = () => {
	const [date, setDate] = useState(new Date());

	const [getProgressKey, getProgressFunction] = getProgress(date);

	const { data: progress } = useQuery(getProgressKey, getProgressFunction);

	if (!progress) return null;

	return (
		<Container>
			<h1>Progress</h1>
			<DatePicker
				onChange={date => {
					setDate(date);
				}}
				value={date}
				showMonthYearPicker
				dateFormat="MMMM yyyy"
				maxDate={new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1)}
			/>
			<TableInfiniteScroll>
				<TableHeaders>
					<TableRow>
						<TableHeader>Day</TableHeader>
						<TableHeader>Annotations</TableHeader>
						<TableHeader>Total Annotations</TableHeader>
					</TableRow>
				</TableHeaders>
				<TableBody>
					{progress &&
						Object.entries(progress.result).map((key, index, progress) => {
							return (
								<TableRow key={index}>
									<TableCell>{key[0]}</TableCell>
									<TableCell>{(key[1] as any).annotations}</TableCell>
									<TableCell>{[...progress].splice(0, index + 1).reduce((acc, val) => acc + (val[1] as any).annotations, 0)}</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</TableInfiniteScroll>
		</Container>
	);
};
export default Progress;
