import { client } from '../../util/client';

export const fetchUserGeneratedBills = (data?: any) => {
	const queryKey = ['fetch-user-generated-bills', { data }] as const;

	const queryFunction = () =>
		client<{ success: boolean; result: any[] }>('/fetch_user_bills', {
			baseURL: 'bill',
			method: 'POST',
			data
		}).then(res => res.data);

	return [queryKey, queryFunction] as const;
};
