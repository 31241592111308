import { client } from './../../util/client';

export const updateRequestThreshold = ({
	awi_threshold,
	awi_min_session_images,
	awi_allow_verifier,
	request_id
}: {
	request_id: number;
	awi_threshold?: number;
	awi_min_session_images?: number;
	awi_allow_verifier?: boolean;
}) =>
	client(`/update_request_threshold/${request_id}`, {
		baseURL: 'request',
		method: 'PATCH',
		data: {
			awi_threshold,
			awi_min_session_images,
			awi_allow_verifier
		}
	});
