import { client } from '../../util/client';

type ClientResponse = {
	success: boolean;
	message: string;
};

export const updatePassword = (data: { awi_updated_password: string; awi_password: string }) =>
	client<ClientResponse>('/update_password', {
		baseURL: 'user',
		data: data,
		method: 'PATCH'
	}).then(res => res.data);
