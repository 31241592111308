import { client } from '../../util/client';

type ClientResponse = {
	success: boolean;
	message: string;
};

export const verifyUserDetails = (data: { awi_field: string; awi_value: boolean; awi_user_id: number; awi_submit: boolean }) =>
	client<ClientResponse>('/verify_user_details', {
		baseURL: 'user',
		data: data,
		method: 'POST'
	}).then(res => res.data);
