import clsx from 'clsx';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import { Tab } from '@headlessui/react';
import { withRouter } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useInfiniteQuery } from 'react-query';

import { getNewSession } from '../../../handlers/session/getNewSessions';
// import { getNewSessionFilter } from '../../../handlers/session/getNewSessionFilter';
import { getVerifierSessions } from '../../../handlers/session/getVerifierSessions';

import SessionFilterModal from './SessionFilterModal';
import Container from '../../Container';

import { Loader, TableInfiniteScroll, TableBody, InfiniteScroll, TableCell, TableHeader, TableHeaders, TableRow, Carousel } from '../../Common';

import { LoggedUser } from '../../../types';

import { useQuery } from '../../../util/useQuery';
import { dateDifference, getFirstDayOfMonth, getFullName } from '../../../util/common.utils';

import styles from './styles.module.scss';

interface SessionsProps {
	loggedUser: LoggedUser;
}

type AnnotationType = 'rectangle' | 'circle' | 'polygon' | 'dot';

type SessionFilterType = {
	awi_class_ids: number[];
	awi_user_ids: number[];
	awi_annotation_type: AnnotationType[];
	awi_request_ids: number[];
	awi_start_date: Date | null;
	awi_end_date: Date | null;
	awi_is_empty_sessions: number[];
	awi_is_rejected_annotation_present: boolean;
	awi_user_assigned_level: string;
	selectedTab: string;
};

const Sessions: React.FC<SessionsProps> = ({ loggedUser }) => {
	const initialFilterData = {
		awi_class_ids: [],
		awi_user_ids: loggedUser.userObj.awi_level === 'awi_annotator' || loggedUser.userObj.awi_level === 'awi_verifier' ? [loggedUser.userObj.awi_id] : [],
		awi_annotation_type: [],
		awi_request_ids: [],
		awi_start_date: null,
		awi_end_date: null,
		awi_is_empty_sessions: [],
		awi_is_rejected_annotation_present: false,
		awi_user_assigned_level: 'select level',
		selectedTab: loggedUser.userObj.awi_level === 'awi_verifier' ? 'verifier' : 'annotator'
	};
	// const queryClient = useQueryClient();
	const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
	const [filter, setFilters] = useState<SessionFilterType>(initialFilterData);
	const filterRef = useRef(initialFilterData as SessionFilterType);
	const [openCarousel, setOpenCarousel] = useState(false);
	const [selectedSession, setSelectedSession] = useState<any>(null);

	const [getNewSessionKey, getNewSessionFunction] = getNewSession({
		...filterRef.current,
		awi_end_date: filterRef.current.awi_end_date ? new Date(filterRef.current.awi_end_date.setHours(23, 59, 59)) : null
	});

	const {
		data: newSessionData,
		hasNextPage,
		fetchNextPage,
		isLoading,
		isFetchingNextPage
	} = useInfiniteQuery(getNewSessionKey, getNewSessionFunction, {
		getNextPageParam: lastPage => {
			return lastPage.next ? new URL(lastPage.next).searchParams.get('cursor') : undefined;
		}
	});

	// const [getNewSessionFilterMetric, getNewSessionFilterMetricFunction] = getNewSessionFilter();
	// const { data: sessionFilterMetric } = useQuery(getNewSessionFilterMetric, getNewSessionFilterMetricFunction);

	const [getVerifierSessionKeys, getVerifierSessionFunction] = getVerifierSessions(filter);
	// const {
	// 	data: verifierSessionData,
	// 	hasNextPage: hasNextPageVerifier,
	// 	fetchNextPage: fetchNextPageVerifier,
	// 	isLoading: isLoadingVerifier,
	// 	isFetchingNextPage: isFetchingNextPageVerifier
	// } = useInfiniteQuery(getVerifierSessionKeys, getVerifierSessionFunction, {
	// 	getNextPageParam: lastPage => {
	// 		return lastPage.next ? new URL(lastPage.next).searchParams.get('cursor') : undefined;
	// 	}
	// 	// enabled: loggedUser.userObj.awi_level !== 'awi_developer' && loggedUser.userObj.awi_level !== 'awi_annotator'
	// });

	// const [selectedTab, setSelectedTab] = useState<string>(loggedUser.userObj.awi_level === 'awi_verifier' ? 'verifier' : 'annotator');

	// useEffect(() => {
	// 	if (loggedUser.userObj.awi_level === 'awi_annotator' || loggedUser.userObj.awi_level === 'awi_verifier') {
	// 		setFilters(prev => {
	// 			return { ...prev, awi_user_ids: [loggedUser.userObj.awi_id] };
	// 		});
	// 	}
	// 	if (loggedUser.userObj.awi_level === 'awi_verifier') {
	// 		setSelectedTab('verifier');
	// 		setFilters(prev => {
	// 			return { ...prev, selectedTab: 'verifier' };
	// 		});
	// 	}
	// }, []);

	const handleDateChange = (date: Date, key: string) => {
		setFilters(prev => {
			const temp = { ...prev };
			temp[key] = date;
			return { ...temp };
		});
	};

	const handleApplyFilter = () => {
		filterRef.current = filter as SessionFilterType;
		setIsFilterOpen(false);
	};

	const handleUpdateFilter = (id: number, name: string) => {
		if (name === 'awi_user_assigned_level') {
			setFilters(prev => {
				let type = '';
				if (id === 0) {
					type = 'awi_verifier';
				} else if (id === 1) {
					type = 'awi_annotator';
				}

				return { ...prev, awi_user_assigned_level: type, awi_user_ids: [] };
			});
			return;
		}

		if (name === 'awi_annotation_type') {
			setFilters(prev => {
				let type = '';
				if (id === 0) {
					type = 'rectangle';
				} else if (id === 1) {
					type = 'circle';
				} else if (id === 2) {
					type = 'polygon';
				} else if (id === 3) {
					type = 'dot';
				}
				const temp = [...prev.awi_annotation_type];
				if (prev.awi_annotation_type?.indexOf(type as AnnotationType) === -1) {
					temp.push(type as AnnotationType);
				} else {
					temp.splice(temp.indexOf(type as AnnotationType), 1);
				}
				return { ...prev, awi_annotation_type: temp };
			});
			return;
		}

		if (name === 'awi_is_empty_sessions') {
			setFilters(prev => {
				let temp = [...prev.awi_is_empty_sessions];
				if (id === 0) {
					temp = [];
				} else {
					temp = [id];
				}
				return { ...prev, awi_is_empty_sessions: temp };
			});
			return;
		}

		if (name === 'awi_is_rejected_annotation_present') {
			setFilters(prev => {
				let temp = prev.awi_is_rejected_annotation_present;

				return { ...prev, awi_is_rejected_annotation_present: !temp };
			});
			return;
		}

		if (filter[name].includes(id)) {
			setFilters(prev => ({
				...prev,
				[name]: prev[name].filter(item => item !== id)
			}));
		} else {
			setFilters(prev => ({
				...prev,
				[name]: [...prev[name], id]
			}));
		}
	};

	const handleResetFilter = () => {
		setFilters(initialFilterData);
		filterRef.current = initialFilterData;
		setIsFilterOpen(false);
	};

	const annotationData = [
		{
			awi_id: 0,
			awi_label: 'rectangle'
		},
		{
			awi_id: 1,
			awi_label: 'circle'
		},
		{
			awi_id: 2,
			awi_label: 'polygon'
		},
		{
			awi_id: 3,
			awi_label: 'dot'
		}
	];

	const handleCloseCarousel = () => {
		setOpenCarousel(false);
		setSelectedSession(null);
	};

	const handleImagesClick = session => {
		setOpenCarousel(true);
		setSelectedSession(session);
	};

	const handleTabChange = event => {
		// if (event === 0) {
		// 	setSelectedTab('annotator');
		// } else if (event === 1) {
		// 	setSelectedTab('verifier');
		// }
		setFilters({ ...initialFilterData, selectedTab: event === 0 ? 'annotator' : 'verifier', awi_user_ids: initialFilterData.awi_user_ids });
		filterRef.current = { ...filter, selectedTab: event === 0 ? 'annotator' : 'verifier' } as SessionFilterType;
	};

	const checkFilterSelect = (): boolean => {
		if (loggedUser.userObj.awi_level === 'awi_annotator' || loggedUser.userObj.awi_level === 'awi_verifier') {
			const tempFilter = { ...filter };
			tempFilter.awi_user_ids = [];
			return JSON.stringify(tempFilter) === JSON.stringify(initialFilterData);
		}

		return JSON.stringify(filter) === JSON.stringify(initialFilterData);
	};

	if (!newSessionData && loggedUser.userObj.awi_level === 'awi_annotator') {
		return null;
	}

	// if (!verifierSessionData && loggedUser.userObj.awi_level === 'awi_verifier') {
	// 	return null;
	// }

	const toggleShowFilter = () => {
		setIsFilterOpen(!isFilterOpen);
	};

	return (
		<Container className={styles.rootContainer}>
			<Tab.Group onChange={handleTabChange}>
				<div className={styles.session__tabs__container}>
					{!['awi_annotator', 'awi_verifier'].includes(loggedUser.userObj.awi_level) && (
						<Tab.List className={styles.tabs__list}>
							{({ selectedIndex }) => (
								<>
									{loggedUser.userObj.awi_level !== 'awi_verifier' && (
										<Tab
											className={clsx(styles.tabs__list__item, {
												[styles.tabs__list__item__selected]: filter.selectedTab === 'annotator'
											})}
										>
											Annotator
										</Tab>
									)}
									{loggedUser.userObj.awi_level !== 'awi_annotator' && (
										<Tab
											className={clsx(styles.tabs__list__item, {
												[styles.tabs__list__item__selected]: filter.selectedTab === 'verifier'
											})}
										>
											Verifier
										</Tab>
									)}
								</>
							)}
						</Tab.List>
					)}

					<div className={styles.session__tabs__container__right}>
						<img
							className={styles.session__tabs__container__icons}
							src={`/icons/filter/filter${checkFilterSelect() ? '' : '--selected'}.svg`}
							onClick={toggleShowFilter}
							title="Filters"
						/>
						{isFilterOpen && (
							<SessionFilterModal
								handleToggleFilterModal={toggleShowFilter}
								filter={filter}
								annotationData={annotationData}
								handleFilterChange={handleUpdateFilter}
								handleResetFilter={handleResetFilter}
								handleDateChange={handleDateChange}
								selectedTab={filter.selectedTab}
								handleApplyFilter={handleApplyFilter}
							/>
						)}
					</div>
				</div>
				<Tab.Panels>
					{loggedUser.userObj.awi_level !== 'awi_verifier' && (
						<Tab.Panel>
							<div className={styles.session__panel__container}>
								<TableInfiniteScroll>
									<TableHeaders className={styles.session__panel__table_header}>
										<TableRow>
											<TableHeader>S.NO</TableHeader>
											<TableHeader>START TIME</TableHeader>
											<TableHeader>END TIME</TableHeader>
											{loggedUser.userObj.awi_level !== 'awi_annotator' && <TableHeader>USER</TableHeader>}
											<TableHeader>REQUEST LABEL</TableHeader>
											<TableHeader>
												<p key="type of anno">
													TYPE OF <br /> ANNOTATION
												</p>
											</TableHeader>
											<TableHeader>
												Number of <br /> Images
											</TableHeader>
											<TableHeader>CLASSES</TableHeader>
											<TableHeader>
												Annotated <br /> <p>amt.</p>
											</TableHeader>
											<TableHeader>
												Verified <br /> amt.
											</TableHeader>
											<TableHeader>
												<p className={styles.session__panel__table_header__split__heading}>Outline</p> <br />
												<div className={styles.session__panel__table_header__split__content}>
													<p>Pending</p>
													<p>Approved</p>
													<p>Rejected</p>
													<p>Corrected</p>
													<p>Added</p>
												</div>
											</TableHeader>

											<TableHeader>
												<p className={styles.session__panel__table_header__split__heading}>Attribute</p> <br />
												<div className={styles.session__panel__table_header__split__content}>
													<p>Pending</p>
													<p>Approved</p>
													<p>Rejected</p>
													<p>Corrected</p>
													<p>Added</p>
												</div>
											</TableHeader>
										</TableRow>
									</TableHeaders>
									<TableBody>
										<InfiniteScroll
											hasMore={Boolean(hasNextPage)}
											isLoading={isLoading || isFetchingNextPage}
											next={fetchNextPage}
											loader={<Loader marginTop={16} />}
										>
											{newSessionData?.pages?.map((page, index) => {
												return (
													<>
														{page.result?.map((item, dataIndex) => {
															return (
																<TableRow key={dataIndex}>
																	<TableCell>{index * newSessionData?.pages[0].result.length + (dataIndex + 1)}</TableCell>

																	<TableCell>{format(new Date(item.awi_annotator_info?.awi_start_date || null), 'dd MMM, H:mm:ss')}</TableCell>
																	<TableCell>
																		{item.awi_status === 'annotating'
																			? '--'
																			: format(new Date(item.awi_annotator_info?.awi_end_date || null), 'dd MMM, H:mm:ss') || ''}
																	</TableCell>
																	{loggedUser.userObj.awi_level !== 'awi_annotator' && (
																		<TableCell>{getFullName(item.awi_user.filter(ele => ele.awi_level === 'awi_annotator')[0])}</TableCell>
																	)}
																	<TableCell>{item.awi_request[0]?.awi_label}</TableCell>
																	<TableCell>rectangle</TableCell>
																	<TableCell onClick={() => handleImagesClick(item)}>{(item.awi_content_keys || []).length} </TableCell>
																	<TableCell>
																		{(item.awi_class_ids || []).length > 0 ? (
																			<Link
																				to={{
																					pathname: `/sessioncounts`,
																					state: { awi_session_id: item.awi_id, awi_request_id: item.awi_request_id, awi_level: filter.selectedTab }
																				}}
																			>
																				<span style={{ textDecoration: 'none', color: 'black' }}> {(item.awi_class_ids || []).length} </span>
																			</Link>
																		) : (
																			(item.awi_class_ids || []).length
																		)}
																	</TableCell>
																	<TableCell>
																		<p>{item?.awi_annotator_generated_bills?.awi_annotated_bill_amount || '-'}</p>
																	</TableCell>
																	<TableCell>
																		<p>{item?.awi_annotator_generated_bills?.awi_verified_bill_amount || '-'}</p>
																	</TableCell>
																	<TableCell>
																		<div className={styles.session__panel__table_header__split__content}>
																			<p>{item.awi_outline.awi_pending_count}</p>
																			<p>{item.awi_outline.awi_approved_count}</p>
																			<p>{item.awi_outline.awi_rejected_count}</p>
																			<p>{item.awi_outline.awi_corrected_count}</p>
																			<p>{item.awi_outline.awi_created_count}</p>
																		</div>
																	</TableCell>

																	<TableCell>
																		<div className={styles.session__panel__table_header__split__content}>
																			<p>{item.awi_attribute.awi_pending_count}</p>
																			<p>{item.awi_attribute.awi_approved_count}</p>
																			<p>{item.awi_attribute.awi_rejected_count}</p>
																			<p>{item.awi_attribute.awi_corrected_count}</p>
																			<p>{item.awi_attribute.awi_created_count}</p>
																		</div>
																	</TableCell>
																</TableRow>
															);
														})}
														<span style={{ height: 10, width: '100%', marginBottom: -20, display: 'block' }}></span>
														<span style={{ height: 0, width: '100%', display: 'block' }}></span>
													</>
												);
											})}
										</InfiniteScroll>
									</TableBody>
								</TableInfiniteScroll>
							</div>
						</Tab.Panel>
					)}
					{loggedUser.userObj.awi_level !== 'awi_annotator' && (
						<Tab.Panel>
							<div className={styles.session__panel__container}>
								<TableInfiniteScroll>
									<TableHeaders className={styles.session__panel__table_header}>
										<TableRow>
											<TableHeader>S.NO</TableHeader>
											<TableHeader>START TIME</TableHeader>
											<TableHeader>END TIME</TableHeader>
											<TableHeader>ANNOTATOR</TableHeader>
											{loggedUser.userObj.awi_level !== 'awi_verifier' && <TableHeader>VERIFIER</TableHeader>}
											<TableHeader>REQUEST LABEL</TableHeader>
											<TableHeader>
												<p key="type of anno">
													TYPE OF <br /> ANNOTATION
												</p>
											</TableHeader>
											<TableHeader>
												Number of <br /> Images
											</TableHeader>
											<TableHeader>CLASSES</TableHeader>
											<TableHeader>
												Verified <br /> amt.
											</TableHeader>
											<TableHeader>
												<p className={styles.session__panel__table_header__split__heading}>Outline</p> <br />
												<div className={styles.session__panel__table_header__split__content}>
													<p>Pending</p>
													<p>Approved</p>
													<p>Rejected</p>
													<p>Corrected</p>
													<p>Added</p>
												</div>
											</TableHeader>

											<TableHeader>
												<p className={styles.session__panel__table_header__split__heading}>Attribute</p> <br />
												<div className={styles.session__panel__table_header__split__content}>
													<p>Pending</p>
													<p>Approved</p>
													<p>Rejected</p>
													<p>Corrected</p>
													<p>Added</p>
												</div>
											</TableHeader>
										</TableRow>
									</TableHeaders>
									<TableBody>
										<InfiniteScroll
											hasMore={Boolean(hasNextPage)}
											isLoading={isLoading || isFetchingNextPage}
											next={fetchNextPage}
											loader={<Loader marginTop={16} />}
										>
											{newSessionData?.pages?.map((page, index) => {
												return (
													<>
														{page.result?.map((item, dataIndex) => {
															return (
																<TableRow key={dataIndex}>
																	<TableCell>{index * newSessionData?.pages[0].result.length + (dataIndex + 1)}</TableCell>

																	<TableCell>{format(new Date(item.awi_verifier_info?.awi_start_date || null), 'dd MMM, H:mm:ss')}</TableCell>
																	<TableCell>
																		{item.awi_status === 'verifying' ? '--' : format(new Date(item.awi_verifier_info?.awi_end_date), 'dd MMM, H:mm:ss')}
																	</TableCell>
																	<TableCell>{getFullName(item.awi_user.filter(ele => ele.awi_level === 'awi_annotator')[0])}</TableCell>
																	{loggedUser.userObj.awi_level !== 'awi_verifier' && (
																		<TableCell>
																			{(item.awi_user.filter(ele => ele.awi_level === 'awi_verifier') || []).length > 0
																				? getFullName(item.awi_user.filter(ele => ele.awi_level === 'awi_verifier')[0])
																				: ''}
																		</TableCell>
																	)}
																	<TableCell>{item.awi_request[0]?.awi_label}</TableCell>
																	<TableCell>rectangle</TableCell>
																	<TableCell onClick={() => handleImagesClick(item)}>{(item.awi_content_keys || []).length} </TableCell>
																	<TableCell>
																		{(item.awi_class_ids || []).length > 0 ? (
																			<Link
																				to={{
																					pathname: `/sessioncounts`,
																					state: { awi_session_id: item.awi_id, awi_request_id: item.awi_request_id, awi_level: filter.selectedTab }
																				}}
																			>
																				<span style={{ textDecoration: 'none', color: 'black' }}> {(item.awi_class_ids || []).length} </span>
																			</Link>
																		) : (
																			(item.awi_class_ids || []).length
																		)}
																	</TableCell>
																	<TableCell>
																		<p>{item?.awi_verifier_generated_bills?.awi_verified_bill_amount || '-'}</p>
																	</TableCell>
																	<TableCell>
																		<div className={styles.session__panel__table_header__split__content}>
																			<p>{item.awi_outline.awi_pending_count}</p>
																			<p>{item.awi_outline.awi_approved_count}</p>
																			<p>{item.awi_outline.awi_rejected_count}</p>
																			<p>{item.awi_outline.awi_corrected_count}</p>
																			<p>{item.awi_outline.awi_created_count}</p>
																		</div>
																	</TableCell>

																	<TableCell>
																		<div className={styles.session__panel__table_header__split__content}>
																			<p>{item.awi_attribute.awi_pending_count}</p>
																			<p>{item.awi_attribute.awi_approved_count}</p>
																			<p>{item.awi_attribute.awi_rejected_count}</p>
																			<p>{item.awi_attribute.awi_corrected_count}</p>
																			<p>{item.awi_attribute.awi_created_count}</p>
																		</div>
																	</TableCell>
																</TableRow>
															);
														})}
														<span style={{ height: 10, width: '100%', marginBottom: -20, display: 'block' }}></span>
														<span style={{ height: 0, width: '100%', display: 'block' }}></span>
													</>
												);
											})}
										</InfiniteScroll>
									</TableBody>
								</TableInfiniteScroll>
							</div>
						</Tab.Panel>
					)}
				</Tab.Panels>
			</Tab.Group>

			{openCarousel && (
				<Carousel
					handleToggleCarousel={handleCloseCarousel}
					requestName={selectedSession.awi_request[0]?.awi_label || ''}
					showAnnotationType={'all'}
					requestId={selectedSession.awi_request[0]?.awi_id}
					imageKeys={selectedSession.awi_content_keys}
				/>
			)}
		</Container>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps, null)(Sessions));
