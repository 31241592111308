import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { LoggedUser } from '../../../types';
import Table from '../../Common/Table';
import Container from '../../Container';
import { useLocation } from 'react-router-dom';
import { getClassAttributes } from '../../../handlers/class/getClassAttributes';
import { useQuery } from '../../../util/useQuery';
import { useEffect, useState } from 'react';

interface ClassListProps {
	loggedUser: LoggedUser;
}

const AttributeList: React.FC<ClassListProps> = ({}) => {
	const location = useLocation();
	const { awi_class_id } = location.state || [];

	const [fetchAttributesKey, fetchAttributesFunction] = getClassAttributes(awi_class_id);
	const { data: attributeInfo } = useQuery(fetchAttributesKey, fetchAttributesFunction);
	const [attributeData, setAttributeData] = useState<{ awi_label: string; awi_possible_labels: string[]; awi_type: string }[]>([]);

	useEffect(() => {
		setAttributeData(attributeInfo?.awi_attributes || []);
	}, [attributeInfo]);

	const headers = ['S. no', 'Attribute Name', 'TYPE OF POSSIBLE VALUES', 'Possible Values'];

	const convertClassData = () => {
		return (attributeData || []).map((attribute, index) => {
			return [index + 1, attribute.awi_label, attribute.awi_type, attribute.awi_possible_labels.join(',')];
		});
	};

	return (
		<Container>
			{(attributeData || []).length > 0 ? <Table headers={headers} content={convertClassData()} /> : <div>No attribute data available</div>}
		</Container>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps, null)(AttributeList));
