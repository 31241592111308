import clsx from 'clsx';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { Wheel } from '@uiw/react-color';
import { withRouter } from 'react-router';
import { useQueryClient } from 'react-query';
import { useState, useEffect, useRef } from 'react';

import { getClassAttributes } from '../../../handlers/class/getClassAttributes';
import { updateAnnotation } from '../../../handlers/annotation/updateAnnotation';
import { AnnotationResponse } from '../../../handlers/annotation/fetchAnnotations';

import Input from '../Input';

import { useQuery } from '../../../util/useQuery';
import { useMutation } from '../../../util/useMutation';

import { LoggedUser } from '../../../types';
import { MiniData } from '../../../types';
import { Annotation } from '../../../types/annotations';

import styles from './styles.module.scss';

interface AnnotationAccordionProps {
	className?: string;
	iconsClassName?: string;
	classId: number;
	classOptions: MiniData[];
	handleDeleteAnnotations: (id: number[]) => void;
	handleUpdateAnnotationsStatus: (id: number[], awi_status: string) => void;
	annotationId: number;
	loggedUser: LoggedUser;
	status: 'pending' | 'approved' | 'rejected';
	fetchAnnotationsKey: string;
	handleCopyAnnotations: (anno: number) => void;
	selectIndex?: number;
	handleSelectAnnotationIndex?: (index: number) => void;
	handleUpdateCurrentClass: (classId: number) => void;
	sessionId: number;
	handleUpdateCurrentClassColor: (id: number, color: string) => void;
	classColor: { awi_id: number; awi_color: string }[];
	content_key: number;
	annotationNumber: number;
	requestFor: any;
	annotation_type: string;
}

const AnnotationAccordion: React.FC<AnnotationAccordionProps> = ({
	// className = '',
	handleDeleteAnnotations,
	annotationId,
	iconsClassName = '',
	classId,
	classOptions,
	loggedUser,
	handleUpdateAnnotationsStatus,
	fetchAnnotationsKey,
	// status,
	handleCopyAnnotations,
	selectIndex,
	handleSelectAnnotationIndex,
	handleUpdateCurrentClass,
	handleUpdateCurrentClassColor,
	sessionId,
	classColor,
	content_key,
	annotationNumber,
	requestFor,
	annotation_type
}) => {
	const ref = useRef<HTMLDivElement>(null);

	const [hex, setHex] = useState('#F05E7C');
	const [openColorPicker, setOpenColorPicker] = useState<boolean>(false);
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const queryClient = useQueryClient();

	const [fetchClassAttributesKey, fetchClassAttributesFunction] = getClassAttributes(classId);

	const data: AnnotationResponse | undefined = queryClient.getQueryData(fetchAnnotationsKey);

	const [attributes, setAttributes] = useState<
		{
			awi_id: number;
			awi_values: string[];
			awi_corrected_values: string[];
			awi_status?: string;
			awi_added_by?: {
				awi_id: number;
				awi_level: string;
			};
		}[]
	>([]);
	const [annotation, setAnnotation] = useState<Annotation | undefined>(undefined);
	const attributesRef = useRef<{ awi_id: number; awi_values: string[]; awi_corrected_values: string[] }[]>([]);

	const updateAnnotationMutation = useMutation(updateAnnotation, {
		onError: (error: AxiosError) => {
			toast.error(error.response?.data.message || 'Something went wrong');
			if (error.response?.data.redirect) {
				window.location.href = '/redirect';
			}
		}
	});

	const { data: classInfo } = useQuery(fetchClassAttributesKey, fetchClassAttributesFunction, {
		enabled: !!classId && classId !== -1
	});

	useEffect(() => {
		if (ref.current && selectIndex !== -1 && annotationId !== -1 && selectIndex === annotationId) {
			ref.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
		}
	}, [ref, selectIndex, annotationId]);

	useEffect(() => {
		if (classColor.length > 0) {
			const classEle = classColor.find(ele => ele.awi_id === classId)!;
			setHex(classEle.awi_color);
		}
	}, [classColor, classId]);

	useEffect(() => {
		if (selectIndex !== -1 && annotationId !== -1 && selectIndex === annotationId) {
			setIsOpen(true);
		} else {
			setIsOpen(false);
		}
	}, [annotationId, selectIndex]);

	useEffect(() => {
		if (data) {
			const annotation = ((data as any)?.result || []).find((anno: Annotation) => anno.awi_id === annotationId);

			if (annotation) {
				setAttributes(
					annotation!.awi_attributes.map(ele => {
						return {
							awi_id: ele.awi_id,
							awi_values: [...ele.awi_values],
							awi_corrected_values: [...(ele?.awi_corrected_values || [])],
							awi_status: ele.awi_status
						};
					})
				);
				setAnnotation(annotation);
				attributesRef.current = [
					...annotation!.awi_attributes.map(ele => {
						return {
							awi_id: ele.awi_id,
							awi_values: [...ele.awi_values],
							awi_corrected_values: [...(ele.awi_corrected_values || [])],
							awi_status: ele.awi_status
						};
					})
				];
			}
		}

		return () => {
			setAttributes([]);
			attributesRef.current = [];
		};
	}, [JSON.stringify(data)]);

	const handleUpdateClassColor = (color: string) => {
		handleUpdateCurrentClassColor(classId, color);
	};

	const handleOutlineStatusUpdate = (annotationId: number, awi_status: string) => {
		updateAnnotationMutation.mutate(
			{
				awi_id: annotationId,
				awi_status: awi_status,
				awi_session_id: sessionId,
				awi_update: 'outlinestatus'
			},
			{
				onSuccess: () => {
					queryClient.invalidateQueries(fetchAnnotationsKey).then(() => {
						return;
					});
					toast.success('Attributes updated');
				}
			}
		);
	};

	const handleUpdateAttributeStatus = (awi_ids: number[], awi_status: 'approved' | 'rejected') => {
		//TODO- u need to comapre attributes with earlier attributes while deciding status
		const temp = annotation?.awi_attributes || [];
		awi_ids.forEach(awi_id => {
			const index = temp.findIndex(ele => ele.awi_id === awi_id);

			if (index !== -1) {
				temp[index].awi_status = awi_status;
			}
		});

		updateAnnotationMutation.mutate(
			{
				awi_id: annotationId,
				awi_attributes: temp,
				awi_session_id: sessionId,
				content_key: content_key,
				awi_update: 'attributes',
				awi_request_for: requestFor
			},
			{
				onSuccess: resp => {
					queryClient.invalidateQueries(fetchAnnotationsKey).then(() => {
						return;
					});
					toast.success('Attributes updated');
				}
			}
		);
	};

	const handleAnnotationStatusChange = (annotationId: number, awi_status: string) => {
		updateAnnotationMutation.mutate(
			{
				awi_id: annotationId,
				awi_status: awi_status,
				awi_session_id: sessionId,
				awi_update: 'entire_annotation_status',
				awi_request_for: requestFor,
				content_key: content_key
			},
			{
				onSuccess: () => {
					queryClient.invalidateQueries(fetchAnnotationsKey).then(() => {
						return;
					});
					toast.success('Attributes updated');
				}
			}
		);
	};

	const handleAddAttributes = (awi_id: number, awi_values: string) => {
		if (!['awi_annotator', 'awi_verifier', 'awi_developer'].includes(loggedUser.userObj.awi_level)) {
			return;
		}
		const temp = [...attributes];
		const index = temp.findIndex(ele => ele.awi_id === awi_id);

		if (loggedUser.userObj.awi_level === 'awi_verifier') {
			if (awi_values === '') {
				if (index !== -1) {
					temp[index].awi_corrected_values = [];
				}
			} else if (index === -1) {
				temp.push({ awi_id, awi_values: [], awi_corrected_values: [awi_values] });
			} else {
				if (classInfo.awi_attributes.find(ele => ele.awi_id === awi_id).awi_type === 'radio') {
					if ((temp[index].awi_corrected_values || []).indexOf(awi_values) === -1) {
						temp[index].awi_corrected_values = [awi_values];
					}
				} else {
					if (temp[index].awi_corrected_values.indexOf(awi_values) === -1) {
						temp[index].awi_corrected_values.push(awi_values);
					} else {
						temp[index].awi_corrected_values = temp[index].awi_corrected_values.filter(ele => ele !== awi_values);
					}
				}
			}
		} else {
			if (awi_values === '') {
				if (index !== -1) {
					temp.splice(index, 1);
				}
			} else if (index === -1) {
				temp.push({ awi_id, awi_values: [awi_values], awi_corrected_values: [awi_values] });
			} else {
				if (classInfo.awi_attributes.find(ele => ele.awi_id === awi_id).awi_type === 'radio') {
					if (temp[index].awi_values.indexOf(awi_values) === -1) {
						temp[index].awi_values = [awi_values];
						temp[index].awi_corrected_values = [awi_values];
					} else {
						temp.splice(index, 1);
					}
				} else {
					if (temp[index].awi_values.indexOf(awi_values) === -1) {
						temp[index].awi_values.push(awi_values);
						temp[index].awi_corrected_values.push(awi_values);
					} else {
						temp[index].awi_values.splice(temp[index].awi_values.indexOf(awi_values), 1);
						temp[index].awi_corrected_values.splice(temp[index].awi_corrected_values.indexOf(awi_values), 1);
					}
				}
			}
		}

		setAttributes(structuredClone(temp));
	};

	const handleUpdateAttributes = (passedattributes: any[] = []) => {
		const temp_attributes: any[] = [];
		const lastStateOfAttributes = annotation?.awi_attributes || [];

		passedattributes.forEach(ele => {
			if (ele.awi_values.length === 0 && ele.awi_corrected_values.length === 0) {
				return;
			} else if (loggedUser.userObj.awi_level === 'awi_annotator') {
				temp_attributes.push({
					awi_id: ele.awi_id,
					awi_values: ele.awi_values,
					awi_corrected_values: ele.awi_values,
					awi_status: 'pending',
					awi_added_by: {
						awi_id: loggedUser.userObj.awi_id,
						awi_level: loggedUser.userObj.awi_level
					}
				});
			} else {
				const lastStateAttribute = lastStateOfAttributes.find(item => item.awi_id === ele.awi_id);

				let temp_status = 'pending';
				if (JSON.stringify(lastStateAttribute?.awi_corrected_values) === JSON.stringify(ele.awi_corrected_values)) {
					temp_status = lastStateAttribute?.awi_status || 'pending';
				} else {
					temp_status = JSON.stringify((ele.awi_values || []).sort()) !== JSON.stringify((ele.awi_corrected_values || []).sort()) ? 'corrected' : 'pending';
				}

				temp_attributes.push({
					awi_id: ele.awi_id,
					awi_values: ele.awi_values,
					awi_corrected_values: ele.awi_corrected_values,
					awi_status: temp_status,

					awi_added_by:
						(ele.awi_values || []).length === 0
							? {
									awi_id: loggedUser.userObj.awi_id,
									awi_level: loggedUser.userObj.awi_level
							  }
							: ele.awi_added_by
				});
			}
		});

		updateAnnotationMutation.mutate(
			{
				awi_id: annotationId,
				awi_attributes: temp_attributes,
				awi_session_id: sessionId,
				content_key: content_key,
				awi_update: 'attributes',
				awi_class_id: classInfo.awi_id,
				awi_request_for: requestFor
			},
			{
				onSuccess: () => {
					queryClient.invalidateQueries(fetchAnnotationsKey).then(() => {
						return;
					});
					if (
						typeof handleSelectAnnotationIndex === 'function' &&
						(loggedUser.userObj.awi_level === 'awi_annotator' || loggedUser.userObj.awi_level === 'awi_developer')
					) {
						handleSelectAnnotationIndex(-1);
					}
					toast.success('Attributes updated');
				}
			}
		);
	};

	const handleResetAttributes = () => {
		let tempAttributes: any[] = [];
		if (loggedUser.userObj.awi_level === 'awi_verifier') {
			const temp = [...attributes];

			temp.forEach((ele, index) => {
				ele.awi_corrected_values = [];
			});
			tempAttributes = temp;
		}
		console.log('attributes in reset', tempAttributes);
		setAttributes([...tempAttributes]);

		handleUpdateAttributes([...tempAttributes]);

		// setAttributes([]);
		// updateAnnotationMutation.mutate(
		// 	{
		// 		awi_id: annotationId,
		// 		awi_attributes: [],
		// 		awi_session_id: sessionId,
		// 		content_key: content_key,
		// 		awi_update: 'attributes'
		// 	},
		// 	{
		// 		onSuccess: () => {
		// 			queryClient.invalidateQueries(fetchAnnotationsKey).then(() => {
		// 				return;
		// 			});
		// 			toast.success('Attributes updated');
		// 		}
		// 	}
		// );
	};

	const handleUpdateClass = (id: number) => {
		if (!['awi_annotator', 'awi_verifier', 'awi_developer'].includes(loggedUser.userObj.awi_level)) {
			return;
		}

		if (id !== classInfo.awi_id) {
			setAttributes([]);

			updateAnnotationMutation.mutate(
				{ awi_id: annotationId, awi_class_id: id, awi_attributes: [], awi_session_id: sessionId, awi_update: 'class' },
				{
					onSuccess: () => {
						handleUpdateCurrentClass(id);
						queryClient.invalidateQueries(fetchAnnotationsKey).then(() => {
							return;
						});
						toast.success('Class updated');
					}
				}
			);
		}
	};

	const checkAttributes = () => {
		const a1 = [...attributes];
		const a2 = [...attributesRef.current];

		if (a1 && a2) {
			a1.sort((a, b) => {
				a.awi_values.sort();
				b.awi_values.sort();
				a.awi_corrected_values.sort();
				b.awi_corrected_values.sort();
				return a.awi_id > b.awi_id ? 1 : b.awi_id > a.awi_id ? -1 : 0;
			});
			a2.sort((a, b) => {
				a.awi_values.sort();
				b.awi_values.sort();
				a.awi_corrected_values.sort();
				b.awi_corrected_values.sort();
				return a.awi_id > b.awi_id ? 1 : b.awi_id > a.awi_id ? -1 : 0;
			});

			if (JSON.stringify(a1) === JSON.stringify(a2)) {
				return true;
			} else {
				return false;
			}
		}
		return true;
	};

	if (!classInfo) {
		return null;
	}

	return (
		<div
			className={clsx(styles.annotation, {
				[styles.annotation__accordion_selected]: annotationId === selectIndex,
				[styles.annotation__accordion__button__approved]: annotation?.awi_status === 'approved',
				[styles.annotation__accordion__button__rejected]: annotation?.awi_status === 'rejected',
				[styles.annotation__accordion__button__corrected]: annotation?.awi_status === 'corrected'
			})}
		>
			<div
				className={clsx(styles.annotation__accordion, {
					// [styles.annotation__accordion_selected]: annotationId === selectIndex,
					[styles.annotation__accordion__button__approved]: annotation?.awi_status === 'approved' || false,
					[styles.annotation__accordion__button__rejected]: annotation?.awi_status === 'rejected' || false,
					[styles.annotation__accordion__button__corrected]: annotation?.awi_status === 'corrected' || false
				})}
				style={{ padding: '8px 24px 8px 24px', paddingBottom: '12px' }}
				onClick={event => {
					event.stopPropagation();
					if (annotationId === selectIndex) {
						if (typeof handleSelectAnnotationIndex === 'function') handleSelectAnnotationIndex(-1);
						setIsOpen(false);
					} else if (typeof handleSelectAnnotationIndex === 'function') {
						handleSelectAnnotationIndex(annotationId);
						setIsOpen(true);
					}
				}}
				ref={ref}
			>
				<div className={styles.annotation__accordion__class}>
					<div
						onClick={event => {
							event.stopPropagation();
							setOpenColorPicker(prev => !prev);
						}}
						style={{ backgroundColor: hex }}
						className={styles.annotation__accordion__button__color_picker}
						title="Select class annotations colour"
					>
						{openColorPicker && ['awi_annotator', 'awi_verifier'].includes(loggedUser.userObj.awi_level) && (
							<div>
								<div className={styles.backdrop}></div>
								<div className={styles.color__pallet}>
									<Wheel
										color={'#FFF'}
										onChange={color => {
											setHex(color.hex);
											handleUpdateClassColor(color.hex);
										}}
									/>
								</div>
							</div>
						)}
					</div>
					<div
						onClick={event => {
							event.stopPropagation();
						}}
						className={styles.annotation__accordion__class__dropdown}
					>
						<Input
							type="drop-down"
							onClick={handleUpdateClass}
							searchString={classInfo.awi_label}
							placeholder="Select Class"
							selectedData={[classInfo.awi_id]}
							data={
								['awi_annotator', 'awi_verifier', 'awi_developer'].includes(loggedUser.userObj.awi_level) && requestFor !== 'attributes'
									? classOptions.map(ele => {
											return { awi_id: ele.awi_id, awi_label: ele.awi_label };
									  })
									: []
							}
						/>
					</div>
				</div>

				<div className={clsx(styles.annotation__accordion__button__icons, { [iconsClassName]: !iconsClassName })}>
					{loggedUser.userObj.awi_level === 'awi_verifier' ? (
						<>
							<img
								src="/icons/correct.svg"
								onClick={event => {
									event.stopPropagation();
									if (!(annotation?.awi_status === 'approved')) {
										handleAnnotationStatusChange(annotationId, 'approved');
									}
								}}
								alt="Approve Annotation"
								title="Approve Annotation"
							/>

							<img
								src="/icons/reject.svg"
								onClick={event => {
									event.stopPropagation();
									// if (!(annotation?.awi_status === 'rejected')) {
									handleAnnotationStatusChange(annotationId, 'rejected');
									// }
								}}
								alt="Reject Annotation"
								title="Reject Annotation"
							/>
						</>
					) : null}
					{requestFor !== 'attributes' && ['awi_annotator', 'awi_verifier'].includes(loggedUser.userObj.awi_level) && (
						<img
							src="/icons/copy.svg"
							onClick={event => {
								event.stopPropagation();
								handleCopyAnnotations(annotationId);
							}}
							alt="Copy Annotation"
							title="Copy Annotation"
						/>
					)}

					{['awi_annotator', 'awi_verifier', 'awi_developer'].includes(loggedUser.userObj.awi_level) && (
						<>
							{requestFor !== 'attributes' && (
								<img
									src="/icons/delete.svg"
									alt="Delete Annotation"
									onClick={event => {
										event.stopPropagation();
										handleDeleteAnnotations([annotationId]);
									}}
									title="Delete Annotation"
								/>
							)}
							{requestFor !== 'outline' && (
								<span className={styles.annotation__accordion__button__value}>
									{((data?.result || [])?.find(item => item.awi_id === annotationId)?.awi_attributes || []).length}
								</span>
							)}
						</>
					)}
				</div>
			</div>
			{isOpen && (
				<>
					<div
						onClick={event => {
							event.stopPropagation();
						}}
						className={styles.annotation__accordion__panel}
					>
						<div className={clsx(styles.annotation__accordion)}>
							{/* <p>Select Attributes</p>
								{loggedUser.userObj.awi_level === 'awi_verifier' && (
									<>
										<img
											src="/icons/correct.svg"
											onClick={event => {
												event.stopPropagation();
												const attributeIdsToBeUpdated = (annotation?.awi_attributes || [])
													.filter(ele => ele.awi_status !== 'corrected')
													.map(attribute => attribute.awi_id);
												if ((attributeIdsToBeUpdated || []).length > 0) {
													handleUpdateAttributeStatus(attributeIdsToBeUpdated, 'approved');
												}
											}}
											alt="Approve Attributes"
											title="Approve Attributes"
										/>
										<img
											src="/icons/reject.svg"
											onClick={event => {
												event.stopPropagation();
												handleUpdateAttributeStatus(
													(annotation?.awi_attributes || []).map(attribute => attribute.awi_id),
													'rejected'
												);
											}}
											alt="Reject Attributes"
											title="Reject Attributes"
										/>
									</>
								)} */}
						</div>

						{requestFor !== 'attributes' && (
							<div
								className={clsx(styles.annotation__accordion__panel__attributes, {
									// [styles.annotation__accordion_selected]: annotationId === selectIndex
									[styles.annotation__accordion__panel__attributes__approved]: annotation?.awi_outline_status === 'approved',
									[styles.annotation__accordion__panel__attributes__rejected]: annotation?.awi_outline_status === 'rejected',
									[styles.annotation__accordion__panel__attributes__corrected]: annotation?.awi_outline_status === 'corrected'
								})}
							>
								<div className={clsx(styles.annotation__accordion, styles.annotation__accordion__attributeheader)}>
									<span className={styles.annotation__accordion__panel__attributes__label}>Outline </span>
									<div className={styles.annotation__accordion__attributeheader__buttons}>
										{loggedUser.userObj.awi_level === 'awi_verifier' && (
											<>
												{annotation?.awi_outline_status !== 'corrected' && (
													<img
														src="/icons/correct.svg"
														onClick={event => {
															event.stopPropagation();
															if (annotation?.awi_outline_status !== 'approved') {
																handleOutlineStatusUpdate(annotationId, 'approved');
															}
														}}
														alt="Approve Outline"
														title="Approve Outline"
													/>
												)}

												{annotation?.awi_outline_status !== 'corrected' && (
													<img
														src="/icons/reject.svg"
														onClick={event => {
															event.stopPropagation();
															if (annotation?.awi_outline_status !== 'rejected') {
																handleOutlineStatusUpdate(annotationId, 'rejected');
															}
														}}
														alt="Reject Outline"
														title="Reject Outline"
													/>
												)}
											</>
										)}
									</div>
								</div>
								<div className={styles.annotation__accordion__panel__attributes__possible_values}>
									{['rectangle', 'circle', 'dot', 'polygon'].map((values, index) => {
										return (
											<span
												className={clsx(styles.annotation__accordion__panel__attributes__possible_values__span, {
													[styles.annotation__accordion__panel__attributes__possible_values__span_selected]: values === annotation_type,
													[styles.annotation__accordion__panel__attributes__possible_values__span_disabled]: values !== annotation_type
												})}
												key={index}
											>
												{values}
											</span>
										);
									})}
								</div>
							</div>
						)}

						{requestFor !== 'outline' &&
							(classInfo.awi_attributes || [])
								.sort((a, b) => (a.awi_label > b.awi_label ? 1 : b.awi_label > a.awi_label ? -1 : 0))
								.map(attribute => {
									const temp_attribute = attributes[attributes.findIndex(ele => attribute.awi_id === ele.awi_id)];

									return (
										<div
											key={attribute.awi_id}
											className={clsx(styles.annotation__accordion__panel__attributes, {
												// [styles.annotation__accordion_selected]: annotationId === selectIndex
												[styles.annotation__accordion__panel__attributes__approved]: temp_attribute?.awi_status === 'approved',
												[styles.annotation__accordion__panel__attributes__rejected]: temp_attribute?.awi_status === 'rejected',
												[styles.annotation__accordion__panel__attributes__corrected]: temp_attribute?.awi_status === 'corrected'
											})}
										>
											<div className={clsx(styles.annotation__accordion, styles.annotation__accordion__attributeheader)}>
												<span className={styles.annotation__accordion__panel__attributes__label}>{attribute.awi_label} </span>
												<div className={styles.annotation__accordion__attributeheader__buttons}>
													{loggedUser.userObj.awi_level === 'awi_verifier' && (temp_attribute?.awi_values || []).length !== 0 && (
														<>
															{temp_attribute?.awi_status !== 'corrected' && (
																<img
																	src="/icons/correct.svg"
																	onClick={event => {
																		event.stopPropagation();
																		if (temp_attribute?.awi_status !== 'approved') {
																			handleUpdateAttributeStatus([attribute.awi_id], 'approved');
																		}
																	}}
																	alt="Approve Attribute"
																	title="Approve Attribute"
																/>
															)}
															{temp_attribute?.awi_status !== 'corrected' && (
																<img
																	src="/icons/reject.svg"
																	onClick={event => {
																		event.stopPropagation();
																		if (temp_attribute?.awi_status !== 'rejectqed') {
																			handleUpdateAttributeStatus([attribute.awi_id], 'rejected');
																		}
																	}}
																	alt="Reject Attribute"
																	title="Reject Attribute"
																/>
															)}
														</>
													)}
												</div>
											</div>
											<div className={styles.annotation__accordion__panel__attributes__possible_values}>
												{attribute.awi_possible_labels.map((values, index) => {
													return (
														<span
															className={clsx(styles.annotation__accordion__panel__attributes__possible_values__span, {
																[styles.annotation__accordion__panel__attributes__possible_values__span_selected]:
																	attributes.findIndex(ele => attribute.awi_id === ele.awi_id) !== -1 &&
																	(attributes[attributes.findIndex(ele => attribute.awi_id === ele.awi_id)].awi_corrected_values || []).indexOf(values) !== -1
															})}
															key={attribute.awi_id.toString() + index.toString()}
															onClick={event => {
																event.stopPropagation();
																handleAddAttributes(attribute.awi_id, values);
															}}
														>
															{values}
														</span>
													);
												})}
												<span
													className={clsx(styles.annotation__accordion__panel__attributes__possible_values__span, {
														[styles.annotation__accordion__panel__attributes__possible_values__span_selected]:
															attributes.findIndex(ele => attribute.awi_id === ele.awi_id) === -1 || (temp_attribute?.awi_corrected_values || []).length === 0
													})}
													onClick={event => {
														event.stopPropagation();
														handleAddAttributes(attribute.awi_id, '');
													}}
													title="Deselect attribute values"
												>
													{'NA'}
												</span>
											</div>
										</div>
									);
								})}

						{['awi_annotator', 'awi_verifier', 'awi_developer'].includes(loggedUser.userObj.awi_level) && requestFor !== 'outline' && (
							<div className={styles.annotation__accordion__panel__attributes__buttons}>
								<button onClick={() => handleUpdateAttributes(attributes)} title="Save attribute values" disabled={checkAttributes()}>
									save
								</button>
								<button onClick={handleResetAttributes} title="Reset attribute values">
									reset
								</button>
							</div>
						)}
					</div>
				</>
			)}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		loggedUser: state.UserReducer.loggedUser
	};
};

export default withRouter(connect(mapStateToProps, null)(AnnotationAccordion));
